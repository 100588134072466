export const addChat = (chat) => {
    return {
        type: "CHAT_ADD",
        payload: chat
    };
};

export const removeChat = (ID, RoomID) => {
    return {
        type: "CHAT_REMOVE",
        payload: {
            ID,
            RoomID
        }
    }
};

export const bulkAddChat = chats => {
    return {
        type: "CHAT_BULK_ADD",
        payload: chats
    };
};

export const cleanChat = () => {
    return {
        type: "CHAT_CLEAN"
    };
}
export const chatRemoveSpinner = (ChatRoomID, ChatID) => {
    return {
        type: "CHAT__SPINNER__REMOVE",
        payload: {
            ChatRoomID,
            ChatID
        }
    };
}
export const chatUpdate = (ChatRoomID, ChatID, MessageType, MessageContent) => {
    return {
        type: "CHAT__UPDATE",
        payload: {
            ChatRoomID,
            ChatID,
            MessageType,
            MessageContent
        }
    };
}

export const chatResend = (ChatRoomID, ChatID, MessageType, MessageContent) => {
    return {
        type: "CHAT__RESEND",
        payload: {
            ChatRoomID,
            ChatID,
            MessageType,
            MessageContent
        }
    };
}