export const compareUsers = (a, b) => {
    if (!Array.isArray(a) || !Array.isArray(b)) return false;
    if (a.length !== b.length) return false;
    for (let item of b) {
        let found = a.find(t => t.ID === item.ID);
        if (!found) return false;
    };
    return true;
};

export const addUser = (ID, FirstName, LastName, Image, LastSeen, Roles) => {
    return {
        type: "USERS_ADD",
        payload: {
            ID,
            FirstName,
            LastName,
            Image,
            LastSeen,
            Roles
        }
    };
};

export const removeUser = id => {
    return {
        type: "USERS_REMOVE",
        payload: id
    }
};

export const bulkAddUsers = users => {
    return {
        type: "USERS_BULK_ADD",
        payload: users
    };
};

export const cleanUsers = () => {
    return {
        type: "USERS_CLEAN"
    };
}