import React from 'react';
import "./index.scss";

import CustomInput from "../../components/customComponents/CustomInput";
import Button from "../../components/customComponents/Button";

import * as backendModule from "../../modules/backendModule";
import axios from "axios";

import { useDispatch } from 'react-redux';
import * as timestampActions from "../../actions/timestampAction";

import { useNavigate } from "react-router-dom";
import animateModule from "../../modules/animateModule";

export default function Sidebar(props) {
    const curNavigate = useNavigate();
    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".route__wrapper"));

    const [settingsModal, setSettingsModal] = React.useState(false);
    const openSettingsModal = () => {
        setSettingsModal(m => !m);
    }

    const [userSettingsModal, setUserSettingsModal] = React.useState(false);
    const openUserSettings = () => {
        setUserSettingsModal(m => !m);
    }

    const curDispatch = useDispatch();

    const logout = () => {
        let data;
        axios({
            method: "GET",
            url: `${backendModule.backendURL}/auth/logout`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                curDispatch(timestampActions.setTimestamp());
            }
            else {

            }
        }).catch(() => {

        });
    }

    const [editProfile, setEditProfile] = React.useState(false);
    const editProfileHandler = () => {
        setEditProfile(m => !m);
    }

    const [changePassword, setChangePassword] = React.useState(false);
    const changePasswordHandler = () => {
        setChangePassword(m => !m);
    }

    const newLoggedUserFirstNameRef = React.useRef(null);
    const newLoggedUserLastNameRef = React.useRef(null);
    const loggedMsgRef = React.useRef(null);

    const saveLoggedUser = (id) => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/editAgentUser`,
            data: {
                id: id,
                firstName: newLoggedUserFirstNameRef.current.value,
                lastName: newLoggedUserLastNameRef.current.value,
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                editProfileHandler();
                props.checkLogin();
            }
            else {
                loggedMsgRef.current.innerText = 'Something went wrong!';
            }
        }).catch(() => {
            loggedMsgRef.current.innerText = 'Something went wrong!';
        });
    }

    const oldPasswordRef = React.useRef(null);
    const newPasswordRef = React.useRef(null);

    const saveNewPassword = (id) => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/changePassword`,
            data: {
                id: id,
                newPassword: newPasswordRef.current.value,
                oldPassword: oldPasswordRef.current.value
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                changePasswordHandler();
                props.checkLogin();
            }
            else {
                loggedMsgRef.current.innerText = 'Something went wrong!';
            }
        }).catch(() => {
            loggedMsgRef.current.innerText = 'Something went wrong!';
        });
    }

    const sidebarRef = React.useRef(null);

    React.useEffect(() => {
        sidebarRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, [sidebarRef.current, props.navbar]);

    return (
        <>
            {settingsModal && <div className='component__settingsModal'>
                <div id="box">
                    <div id="menu" style={{ display: editProfile || changePassword ? 'none' : '' }}>
                        <Button className='settingsBtns' value='Edit your profile' accent='#282F37' onClick={() => editProfileHandler()} />
                        <Button className='settingsBtns' value='Change password' accent='#282F37' onClick={() => changePasswordHandler()} />
                        <Button className='settingsBtns' value='Cancel' accent='red' onClick={() => setSettingsModal(false)} />
                    </div>
                    {editProfile && <div style={{ marginTop: '20px' }}>
                        <CustomInput ref={newLoggedUserFirstNameRef} defaultValue={props.loggedUser.FirstName} autocomplete="off" placeholder="First name" accent="#FFF" theme="dark" type="text" style={{ width: "100%", marginBottom: '25px' }} />
                        <CustomInput ref={newLoggedUserLastNameRef} defaultValue={props.loggedUser.LastName} autocomplete="off" placeholder="Last name" accent="#FFF" theme="dark" type="text" style={{ width: "100%", marginBottom: '25px' }} />
                        <div id="controles">
                            <Button value='Cancel' accent='red' onClick={() => editProfileHandler()} />
                            <Button value='Save' accent='#3F7CEA' onClick={() => saveLoggedUser(props.loggedUser.ID)} />
                        </div>
                    </div>}
                    {changePassword && <div style={{ marginTop: '20px' }}>
                        <CustomInput ref={oldPasswordRef} autocomplete="off" placeholder="Old password" accent="#FFF" theme="dark" type="password" style={{ width: "100%", marginBottom: '25px' }} />
                        <CustomInput ref={newPasswordRef} autocomplete="off" placeholder="New password" accent="#FFF" theme="dark" type="password" style={{ width: "100%", marginBottom: '25px' }} />
                        <div id="controles">
                            <Button value='Cancel' accent='red' onClick={() => changePasswordHandler()} />
                            <Button value='Save' accent='#3F7CEA' onClick={() => saveNewPassword(props.loggedUser.ID)} />
                        </div>
                    </div>}
                    <p id="msg" ref={loggedMsgRef}></p>
                </div>
            </div>}
            <div style={{ display: props.navbar ? 'grid' : '' }} ref={sidebarRef} className='component__sidebar'>
                {props.loggedUser?.UserInfo?.Flags.isAdmin && <div id={!window.location.href.includes('analitycs') && !window.location.href.includes('ladies') && !window.location.href.includes('chat') && !window.location.href.includes('paymentPageStats') && !window.location.href.includes('generalstatistics') && !window.location.href.includes('chStatistics') && !window.location.href.includes('users') && !window.location.href.includes('domains') && !window.location.href.includes('email') && !window.location.href.includes('packages') && !window.location.href.includes('regSurveys') && !window.location.href.includes('delSurveys') && !window.location.href.includes('delUsers') && !window.location.href.includes('secondary') && !window.location.href.includes('gifts') && !window.location.href.includes('giftcards') && !window.location.href.includes('banners') && !window.location.href.includes('boosts') && !window.location.href.includes('tools') && !window.location.href.includes('stickers') && !window.location.href.includes('badges') && !window.location.href.includes('stickerCategories') && !window.location.href.includes('buildABitch') && !window.location.href.includes('cronJobs') && !window.location.href.includes('streams') ? 'active' : ''} onClick={() => {animateNavigate('/'); props.setActiveTab('dashboard'); props.setNavbar(false)}} className='component__sidebar__item'>
                    <div><img src='images/dashboardicon.svg' alt='' /><p>Dashboard</p></div>
                </div>}
                
                {props.loggedUser?.UserInfo?.Flags.isAdmin && <div id={window.location.href.includes('generalstatistics') ? 'active' : ''} onClick={() => {animateNavigate('generalstatistics'); props.setActiveTab('generalstatistics'); props.setNavbar(false)}} className='component__sidebar__item'>
                    <div><img src='images/dashboardicon.svg' alt='' /><p>GeneralStatistics</p></div>
                </div>}
                {props.loggedUser?.UserInfo?.Flags.isAdmin && <div id={window.location.href.includes('analitycs') ? 'active' : ''} onClick={() => {animateNavigate('analitycs'); props.setActiveTab('analitycs'); props.setNavbar(false)}} className='component__sidebar__item'>
                    <div><img src='images/anal.png' alt='' /><p>Analitycs</p></div>
                </div>}
                {props.loggedUser?.UserInfo?.Flags.isAdmin && <div id={window.location.href.includes('emailanalityc') ? 'active' : ''} onClick={() => {animateNavigate('emailanalityc'); props.setActiveTab('emailanalityc'); props.setNavbar(false)}} className='component__sidebar__item'>
                    <div><img src='images/anal.png' alt='' /><p>Email Analitycs</p></div>
                </div>}
                {props.loggedUser?.UserInfo?.Flags.isAdmin && <div id={window.location.href.includes('ladies') ? 'active' : ''} onClick={() => {animateNavigate('/ladies'); props.setActiveTab('ladies'); props.setNavbar(false)}} className='component__sidebar__item'>
                    <div><img src='images/ladiesicon.svg' alt='' /><p>Ladies</p></div>
                </div>}
                {!props.loggedUser?.UserInfo?.Flags.isStreamer && <div id={window.location.href.includes('chat') ? 'active' : ''} onClick={() => {animateNavigate('/chat'); props.setActiveTab('chat'); props.setNavbar(false)}} className='component__sidebar__item'>
                    <div><img src='images/chaticon.svg' alt='' /><p>Chat</p></div>
                </div>}
                {!props.loggedUser?.UserInfo?.Flags.isStreamer && <div id={window.location.href.includes('chStatistics') ? 'active' : ''} onClick={() => {animateNavigate('/chStatistics'); props.setActiveTab('chStatistics'); props.setNavbar(false)}} className='component__sidebar__item'>
                    <div><img src='images/chaticon.svg' alt='' /><p>Chat Statistics</p></div>
                </div>}
                {props.loggedUser?.UserInfo?.Flags.isAdmin && <div id={window.location.href.includes('users') ? 'active' : ''} onClick={() => {animateNavigate('/users'); props.setActiveTab('users'); props.setNavbar(false)}} className='component__sidebar__item'>
                    <div><img src='images/usersicon.svg' alt='' /><p>Users</p></div>
                </div>}
                {props.loggedUser?.UserInfo?.Flags.isAdmin && <div id={window.location.href.includes('domains') ? 'active' : ''} onClick={() => {animateNavigate('/domains'); props.setActiveTab('domains'); props.setNavbar(false)}} className='component__sidebar__item'>
                    <div><img src='images/domainsicon.png' alt='' /><p>Domains</p></div>
                </div>}
                {props.loggedUser?.UserInfo?.Flags.isAdmin && <div id={window.location.href.includes('emailservers') ? 'active' : ''} onClick={() => {animateNavigate('/emailservers'); props.setActiveTab('emailservers'); props.setNavbar(false)}} className='component__sidebar__item'>
                    <div><img src='images/serversicon.svg' alt='' /><p>Email servers</p></div>
                </div>}
                {props.loggedUser?.UserInfo?.Flags.isAdmin && <div id={window.location.href.includes('packages') ? 'active' : ''} onClick={() => {animateNavigate('/packages'); props.setActiveTab('packages'); props.setNavbar(false)}} className='component__sidebar__item'>
                    <div><img src='images/packagesicon.svg' alt='' /><p>Packages</p></div>
                </div>}
                {props.loggedUser?.UserInfo?.Flags.isAdmin && <div id={window.location.href.includes('secondary') ? 'active' : ''} onClick={() => {animateNavigate('/secondary'); props.setActiveTab('secondary'); props.setNavbar(false)}} className='component__sidebar__item'>
                    <div><img src='images/usersicon.svg' alt='' /><p>Clients</p></div>
                </div>}
                {props.loggedUser?.UserInfo?.Flags.isAdmin && <div id={window.location.href.includes('gifts') ? 'active' : ''} onClick={() => {animateNavigate('/gifts'); props.setActiveTab('gifts'); props.setNavbar(false)}} className='component__sidebar__item'>
                    <div><img src='images/giftsIcon.svg' alt='' /><p>Gifts</p></div>
                </div>}
                {/* {props.loggedUser?.UserInfo?.Flags.isAdmin && <div id={window.location.href.includes('giftcards') ? 'active' : ''} onClick={() => {animateNavigate('/giftcards'); props.setActiveTab('giftcards'); props.setNavbar(false)}} className='component__sidebar__item'>
                    <div><img src='images/gift_cards_icon.svg' alt='' /><p>Gift Cards</p></div>
                </div>} */}
                {/* {props.loggedUser?.UserInfo?.Flags.isAdmin && <div id={window.location.href.includes('banners') ? 'active' : ''} onClick={() => {animateNavigate('/banners'); props.setActiveTab('banners'); props.setNavbar(false)}} className='component__sidebar__item'>
                    <div><img src='images/cpanel__banners-icon.svg' alt='' /><p>Banners</p></div>
                </div>} */}
                {/* {props.loggedUser?.UserInfo?.Flags.isAdmin && <div id={window.location.href.includes('boosts') ? 'active' : ''} onClick={() => {animateNavigate('/boosts'); props.setActiveTab('boosts'); props.setNavbar(false)}} className='component__sidebar__item'>
                    <div><img src='images/cpanel__boosts-icon.svg' alt='' /><p>Boosts</p></div>
                </div>} */}
                {/* {props.loggedUser?.UserInfo?.Flags.isAdmin && <div id={window.location.href.includes('stickers') ? 'active' : ''} onClick={() => {animateNavigate('/stickers'); props.setActiveTab('stickers'); props.setNavbar(false)}} className='component__sidebar__item'>
                    <div><img src='images/cpanel__stickers-icon.svg' alt='' /><p>Stickers</p></div>
                </div>} */}
                {/* {props.loggedUser?.UserInfo?.Flags.isAdmin && <div id={window.location.href.includes('stickerCategories') ? 'active' : ''} onClick={() => {animateNavigate('/stickerCategories'); props.setActiveTab('stickerCategories'); props.setNavbar(false)}} className='component__sidebar__item'>
                    <div><img src='images/cpanel__stickersCategory-icon.svg' alt='' /><p>StickerCategories</p></div>
                </div>} */}
                {/* {props.loggedUser?.UserInfo?.Flags.isAdmin && <div id={window.location.href.includes('badges') ? 'active' : ''} onClick={() => {animateNavigate('/badges'); props.setActiveTab('badges'); props.setNavbar(false)}} className='component__sidebar__item'>
                    <div><img src='images/cpanel__badges-icon.svg' alt='' /><p>Badges</p></div>
                </div>} */}
                {props.loggedUser?.UserInfo?.Flags.isAdmin && <div id={window.location.href.includes('transactionHistory') ? 'active' : ''} onClick={() => {animateNavigate('/transactionHistory'); props.setActiveTab('transactionHistory'); props.setNavbar(false)}} className='component__sidebar__item'>
                    <div><img src='images/payment_history.png' alt='' /><p>Transaction history</p></div>
                </div>}
                {props.loggedUser?.UserInfo?.Flags.isAdmin && <div id={window.location.href.includes('paymentPageStats') ? 'active' : ''} onClick={() => {animateNavigate('/paymentPageStats'); props.setActiveTab('paymentPageStats'); props.setNavbar(false)}} className='component__sidebar__item'>
                    <div><img src='images/payment_page_stats.png' alt='' /><p>Payment Page Stats</p></div>
                </div>}
                {props.loggedUser?.UserInfo?.Flags.isAdmin && <div id={window.location.href.includes('buildABitch') ? 'active' : ''} onClick={() => {animateNavigate('/buildABitch'); props.setActiveTab('buildABitch'); props.setNavbar(false)}} className='component__sidebar__item'>
                    <div><img src='images/build_a_bitch.png' alt='' /><p>Build a bitch</p></div>
                </div>}
                {props.loggedUser?.UserInfo?.Flags.isAdmin && <div id={window.location.href.includes('secondaryUserEndpoints') ? 'active' : ''} onClick={() => {animateNavigate('/secondaryUserEndpoints'); props.setActiveTab('buildABitch'); props.setNavbar(false)}} className='component__sidebar__item'>
                    <div><img src='images/build_a_bitch.png' alt='' /><p>SecUser Endpoints</p></div>
                </div>}
                {props.loggedUser?.UserInfo?.Flags.isAdmin && <div id={window.location.href.includes('tools') ? 'active' : ''} onClick={() => {animateNavigate('/tools'); props.setActiveTab('tools'); props.setNavbar(false)}} className='component__sidebar__item'>
                    <div><img src='images/tools.svg' alt='' /><p>Tools</p></div>
                </div>}
                {props.loggedUser?.UserInfo?.Flags.isAdmin && <div id={window.location.href.includes('regSurveys') ? 'active' : ''} onClick={() => {animateNavigate('/regSurveys'); props.setActiveTab('surveys'); props.setNavbar(false)}} className='component__sidebar__item'>
                    <div><img src='images/surveys.png' alt='' /><p>Users Surveys</p></div>
                </div>}
                {props.loggedUser?.UserInfo?.Flags.isAdmin && <div id={window.location.href.includes('delSurveys') ? 'active' : ''} onClick={() => {animateNavigate('/delSurveys'); props.setActiveTab('surveys'); props.setNavbar(false)}} className='component__sidebar__item'>
                    <div><img src='images/surveys.png' alt='' /><p>Del. Surveys</p></div>
                </div>}
                {props.loggedUser?.UserInfo?.Flags.isAdmin && <div id={window.location.href.includes('delUsers') ? 'active' : ''} onClick={() => {animateNavigate('/delUsers'); props.setActiveTab('delUsers'); props.setNavbar(false)}} className='component__sidebar__item'>
                    <div><img src='images/deleted__user.png' alt='' /><p>Del. Users</p></div>
                </div>}
                {props.loggedUser?.UserInfo?.Flags.isStreamer && <div id={window.location.href.includes('streams') ? 'active' : ''} onClick={() => {animateNavigate('/streams'); props.setActiveTab('streams'); props.setNavbar(false)}} className='component__sidebar__item'>
                    <div><img src='images/streams.png' alt='' /><p>Stream</p></div>
                </div>}
                {props.loggedUser?.UserInfo?.Flags.isAdmin && <div id={window.location.href.includes('cronJobs') ? 'active' : ''} onClick={() => {animateNavigate('/cronJobs'); props.setActiveTab('cronJobs'); props.setNavbar(false)}} className='component__sidebar__item'>
                    <div><img src='images/cronjob__icon.png' alt='' /><p>Cron Jobs</p></div>
                </div>}
                <div style={{ display: props.logged ? 'flex' : 'none' }} id="profile">
                    <div id="profile-user">
                        <div id="profile-image"><p>{String(props.loggedUser?.UserInfo?.Username).slice(0, 1)}</p></div>
                        <p>{props.loggedUser?.UserInfo?.Username}</p>
                    </div>
                    <div id="settings-icon" onClick={() => openUserSettings()}><p>...</p></div>
                    <div id="settings-modal" style={{ display: userSettingsModal ? 'flex' : 'none' }}>
                        <span onClick={() => logout()}><img src="images/logout.png" alt="" /><p>Logout</p></span>
                        {props.loggedUser?.UserInfo?.Flags.isMessageAgent && <span onClick={() => openSettingsModal()}><img src="images/settings.png" alt="" /><p>Settings</p></span>}
                    </div>
                </div>
            </div>
        </>
    )
}
