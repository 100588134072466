import React from 'react';
import "./index.scss";

import CustomInput from '../../components/customComponents/CustomInput';
import Button from '../../components/customComponents/Button';
import Spinner from '../../components/customComponents/Spinner';
import { FilteredCustomTable } from "../../components/customComponents/Table";
import DataSorter from "../../components/customComponents/DataSorter";

import * as backendModule from "../../modules/backendModule";
import axios, { all } from "axios";
import moment from 'moment';

import Dropdown from '../../components/customComponents/Dropdown';

import { animateBox } from "../../modules/componentAnimation";

export default function UsersSurveys(props) {
  const [userSurveysfilters, setUserSurveysFilters] = React.useState([]);
  const [userSurveysOrders, setUserSurveysOrders] = React.useState([]);

  const [usersSurveys, setUsersSurveys] = React.useState([]);
  const [usersSurveyIsLoading, setUsersSurveyIsLoading] = React.useState(false);
  const [usersSurveyHasMoreData, setUsersSurveyHasMoreData] = React.useState(true);
  const usersSurveyObserverRef = React.useRef(null);

  const getAllUsersSurveys = () => {
    setUsersSurveyIsLoading(true);
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/usersSurvey/getAllUsersSurveys`,
      data: {
        filters: userSurveysfilters,
        orders: userSurveysOrders
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === 'ok') {
        setUsersSurveys(res.data.data);
        setUsersSurveyHasMoreData(res.data.data.length)
      }
      else {
      }
    }).catch((err) => {
      console.log("ERROR while getting all users surveys ::", err)
    }).finally(() => {
      setUsersSurveyIsLoading(false)
    });
  }

  const getMoreUsersSurveys = () => {
    if (!usersSurveyIsLoading && usersSurveyHasMoreData) {
      setUsersSurveyIsLoading(true);
      axios({
        method: "POST",
        url: `${backendModule.backendURL}/usersSurvey/getAllUsersSurveys`,
        data: {
          // filters: filters ? [...filters, {name: "ID", op: "notIn", value: usersSurveys.length ? usersSurveys.map(elem => elem.ID) : []}] : [{name: "ID", op: "notIn", value: usersSurveys.length ? usersSurveys.map(elem => elem.ID) : []}],
          filters: [...(Array.isArray(userSurveysfilters) ? userSurveysfilters : []), { name: "ID", op: "notIn", value: usersSurveys.length ? usersSurveys.map(elem => elem.ID) : []}],
          orders: userSurveysOrders
        },
        ...backendModule.axiosConfig
      }).then(res => {
        if (res.data.status === 'ok') {
          let newData = res.data.data;
          setUsersSurveys([...usersSurveys, ...newData])
          setUsersSurveyHasMoreData(res.data.data.length)
        }
        else {
        }
      }).catch((err) => {
        console.log("ERROR while getting all users surveys ::", err)
      }).finally(() => {
        setUsersSurveyIsLoading(false)
      })
    }
  }

  const removeUsersSurvey = (ID) => {
    if(!ID) return;

    axios({
      method: "POST",
      url: `${backendModule.backendURL}/usersSurvey/removeUsersSurvey`,
      data: {
        ID: ID
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === 'ok') {
        getAllUsersSurveys();
      }
      else {
      }
    }).catch((err) => {
      console.log("ERROR while deleting survey :::", err)
    })

  }

  React.useEffect(() => {
    getAllUsersSurveys();
    // getAllDeletionSurveys();
  }, [userSurveysfilters, userSurveysOrders]);

  React.useEffect(() => {
    if(!usersSurveyObserverRef.current) return;
    if (usersSurveyObserverRef.current) {
      const observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && !usersSurveyIsLoading && usersSurveyHasMoreData) {
          setTimeout(getMoreUsersSurveys, 300)
        }
      }, { threshold: 1 });

      setTimeout(() => observer.observe(usersSurveyObserverRef.current), 300);

      return () => {try{
        observer.unobserve(usersSurveyObserverRef.current);
      }catch{

      }}
    }
  }, [usersSurveyHasMoreData, usersSurveyObserverRef.current]);


  const addUsersSurveysToTable = () => {
    if (usersSurveys.length) {
      return usersSurveys.map((item) => {
        return [
          { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.ID}</p></div> },
          { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.UserID}</p></div> },
          { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.DomainID}</p></div> },
          { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.OverallExperience}</p></div> },
          { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.UserFriendliness}</p></div> },
          { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.ProfileCreation}</p></div> },
          { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Messaging}</p></div> },
          { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{moment(item.updatedAt).format('DD.MM.YYYY.')}</p></div> },
          {
            keyID: String(item.ID), type: "groupNewline", group: [
              { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'left' }}>Additional Features : <span style={{ color: '#6dcf55' }}>{item.AdditionalFeatures}</span></p></div> }
            ]
          },
          {
            keyID: String(item.ID), type: "groupNewline", group: [
              { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'left' }}>Overall Suggestions : <span style={{ color: '#6dcf55' }}>{item.OverallUserExperienceSuggestions}</span></p></div> }
            ]
          },
          {
            keyID: String(item.ID), type: "groupNewline", group: [
              {
                keyID: String(item.ID),
                type: "button",
                text: "Remove Survey",
                onClick: e=> { removeUsersSurvey(item.ID) }
              }
            ]
          }
        ];
      });
    }
  }

  return (
    <div className='route__surveys'>
      <div className='route__surveys__users'>
        <p className='route__surveys__users__headline'>Users Survey after 7days past registration</p>
        <DataSorter sorters={[
          {name: 'OverallExperience', value: 'OverallExperience'},
          {name: 'UserFriendliness', value:'UserFriendliness'},
          {name: 'ProfileCreation', value:'ProfileCreation'},
          {name: 'Messaging', value:'Messaging'},
          {name: 'updatedAt', value:'updatedAt'},
        ]} setOrders={setUserSurveysOrders} />
        <FilteredCustomTable
          id='table'
          accent='#000'
          dropdownBackground='#000'
          inputBottomBorderColor='#FFF'
          placeholderStyle={{backgroundColor: '#000'}}
          filterHeadStyle={{backgroundColor: 'rgba(25, 35, 67, 0.2)'}}
          tableHeadStyle={{backgroundColor: 'rgba(25, 35, 67, 0.2)'}}
          buttonStyle={{border: '1px solid #FFF', backgroundColor: '#000'}}
          theme='dark'
          headers={['ID', 'UserID', 'DomainID', 'OverallExperience', 'UserFriendliness', 'ProfileCreation', 'Messaging', 'Updated at']}
          filters={[
            { name: "ID", friendlyName: "ID", type: "string" },
            { name: "UserID", friendlyName: "UserID", type: "string" },
            { name: "OverallExperience", friendlyName: "OverallExperience", type: "number" },
            { name: "UserFriendliness", friendlyName: "UserFriendliness", type: "number" },
            { name: "ProfileCreation", friendlyName: "ProfileCreation", type: "number" },
            { name: "Messaging", friendlyName: "Messaging", type: "number" },
            { name: "updatedAt", friendlyName: "Updated at", type: "date" }
          ]}
          filterCB={f => setUserSurveysFilters(f)}
          data={usersSurveys.length > 0 ? addUsersSurveysToTable() : [[{ keyID: 'noData', type: 'custom', data: <p>No data to display</p> }]]}
        />
        {!usersSurveyIsLoading && usersSurveyHasMoreData && usersSurveys.length > 0 && (
          <div ref={usersSurveyObserverRef}></div>
        )}
      </div>
    </div>
  )
}