import React from 'react';
import "./index.scss";

import ChatSidebar from "../Chat/ChatSidebar";
import ChatContent from "../Chat/ChatContent";

import { useSelector } from 'react-redux';

import { useParams } from 'react-router-dom';
import axios from 'axios';

import * as backendModule from "../../modules/backendModule";
import * as socketModule from '../../modules/socketModule';
import Spinner from '../../components/customComponents/Spinner';

export default function ChatStatistics(props) {

  const [secondaryUsers, setSecondaryUsers] = React.useState([]);
  const [ladies, setLadies] = React.useState([]);
  const [filters, setFilters] = React.useState([]);
  const [orders, setOrders] = React.useState([]);
  const [canPaginate, setCanPaginate] = React.useState(false);

  const [condition, setCondition] = React.useState({ name: 'Condition', value: null })
  const [conditionDropdownOpened, setConditionDropdownOpened] = React.useState(false);

  const [filterName, setFilterName] = React.useState({ name: 'Action', value: 'String' })
  const [filterNameDropdownOpened, setFilterNameDropdownOpened] = React.useState(false);
  const [requestValueDropdownOpened, setRequestValueDropdownOpened] = React.useState(false);

  const filterRequestValue = React.useRef();


  const [filterParams, setFilterParams] = React.useState()

  const getAllSecondaryUsers = (filterItem, filterReq, filterValue) => {

    let finalData;

    if (filterItem && filterReq && filterValue) {
      finalData = {
        SecondaryUserID: props.ID,
        filterItem: filterItem,
        filterReq: filterReq,
        filterValue: filterValue,
      };
    }

    setCanPaginate(false);
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/users/getAllSecondaryUsers`,
      data: {
        ...finalData,
        limit: 1000,
        messageStats: true
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === 'ok') {
        let secUsers = [];
        let ladUsers = []
        res.data.data.forEach(element => {
          if (element.Username.includes('--internal-')) {
            ladUsers.push(element)
          } else {
            secUsers.push(element)
          }
        });
        setLadies(ladUsers);
        setSecondaryUsers(secUsers)
        if (res.data.data.length === 20) {
          setTimeout(() => setCanPaginate(true), 1000);
        }
      }
    }).catch((e) => {
    });
  }

  React.useEffect(() => {
    getAllSecondaryUsers();
  }, [filters, orders]);

  const filterReqValDays = React.useRef();
  const filterReqValHours = React.useRef();
  const filterReqValMins = React.useRef();
  const filterReqValSecs = React.useRef();


  return <div className='route__chatStatistics'>
    <div className='route__chatStatistics__usersTable'>


    <div className="route__dashboardV2__table__filter" style={{background: '#080B16', padding:'0px'}}>
      <div className="route__dashboardV2__table__filter__item" onClick={() => setFilterNameDropdownOpened(d => d = !d)}>
        <p>{filterName.name}</p>
        <div className="route__dashboardV2__table__filter__item__dropdown"
          style={{
            padding: filterNameDropdownOpened ? '10px' : '0px',
            maxHeight: filterNameDropdownOpened ? '1000px' : '0',
            border: filterNameDropdownOpened ? '1px solid #192343' : '0px'
          }}>
          <p onClick={() => setFilterName({ name: 'ID', value: 'String' })}>ID</p>
          <p onClick={() => setFilterName({ name: 'FirstName', value: 'String' })}>First Name</p>
          <p onClick={() => setFilterName({ name: 'LastName', value: 'String' })}>Last Name</p>
          <p onClick={() => setFilterName({ name: 'MS-sentMessages', value: 'Number' })}>Sent Messages</p>
          <p onClick={() => setFilterName({ name: 'MS-receivedMessages', value: 'Number' })}>Received Messages</p>
          <p onClick={() => setFilterName({ name: 'MS-totalRooms', value: 'Number' })}>Total Chat Rooms</p>
          <p onClick={() => setFilterName({ name: 'MS-generatedMessages', value: 'Number' })}>Generated Messages</p>
          <p onClick={() => setFilterName({ name: 'MS-conversationInitRate', value: 'Number' })}>Conv Init Rate</p>
          <p onClick={() => setFilterName({ name: 'MS-timeToFirstMsg', value: 'Time' })}>TimeToFirstMsg</p>
        </div>
      </div>

      <div className="route__dashboardV2__table__filter__item" onClick={() => setConditionDropdownOpened(d => d = !d)}>
        <p>{condition.name}</p>
        <div className="route__dashboardV2__table__filter__item__dropdown"
          style={{
            padding: conditionDropdownOpened ? '10px' : '0px',
            maxHeight: conditionDropdownOpened ? '1000px' : '0',
            border: conditionDropdownOpened ? '1px solid #192343' : '0px'
          }}>
          {filterName.value === 'String' ? <p onClick={() => setCondition({ name: 'Equals', value: 'Equals' })}>Equals</p> : null}
          {filterName.value === 'String' ? <p onClick={() => setCondition({ name: 'Contains', value: 'Contains' })}>Contains</p> : null}
          {filterName.value === 'String' ? <p onClick={() => setCondition({ name: 'Starts with', value: 'StartsWith' })}>Starts with</p> : null}
          {filterName.value === 'Number' ? <p onClick={() => setCondition({ name: 'Equals', value: '===' })}>Equals</p> : null}
          {filterName.value === 'Number' ? <p onClick={() => setCondition({ name: 'Greater Than', value: 'GreaterThan' })}>Greater Than</p> : null}
          {filterName.value === 'Number' ? <p onClick={() => setCondition({ name: 'Less Than', value: 'LessThan' })}>Less Than</p> : null}
          {filterName.value === 'Time' ? <p onClick={() => setCondition({ name: 'Equals', value: 'TimeEquals' })}>Equals</p> : null}
          {filterName.value === 'Time' ? <p onClick={() => setCondition({ name: 'Greater Than', value: 'TimeGreaterThan' })}>Greater Than</p> : null}
          {filterName.value === 'Time' ? <p onClick={() => setCondition({ name: 'Less Than', value: 'TimeLessThan' })}>Less Than</p> : null}
        </div>
      </div>

      {filterName.value === 'Time' ? <div className="route__dashboardV2__table__filter__timeBox">
      <input ref={filterReqValDays} type="number" placeholder='dd' className="route__dashboardV2__table__filter__timeBox__day"
          onChange={() => { setFilterParams({ filterType: 'time', filterValue: `${filterReqValDays.current.value}:${filterReqValHours.current.value}:${filterReqValMins.current.value}:${filterReqValSecs.current.value}` }) }} />
        <input ref={filterReqValHours} type="number" placeholder='hh' className="route__dashboardV2__table__filter__timeBox__hour"
          onChange={() => { setFilterParams({ filterType: 'time', filterValue: `${filterReqValDays.current.value}:${filterReqValHours.current.value}:${filterReqValMins.current.value}:${filterReqValSecs.current.value}` }) }} />
        <input ref={filterReqValMins} type="number" placeholder='mm' className="route__dashboardV2__table__filter__timeBox__min" maxLength={2}
          onChange={() => { setFilterParams({ filterType: 'time', filterValue: `${filterReqValDays.current.value}:${filterReqValHours.current.value}:${filterReqValMins.current.value}:${filterReqValSecs.current.value}` }) }} />
        <input ref={filterReqValSecs} type="number" placeholder='ss' className="route__dashboardV2__table__filter__timeBox__sec" maxLength={2}
          onChange={() => { setFilterParams({ filterType: 'time', filterValue: `${filterReqValDays.current.value}:${filterReqValHours.current.value}:${filterReqValMins.current.value}:${filterReqValSecs.current.value}` }) }} />
      </div> : null}
      {filterName.value === 'String' || filterName.value === 'Number' ? <input ref={filterRequestValue} style={{background: '#080B16'}} onChange={() => { setFilterParams({ filterType: 'string', filterValue: filterRequestValue.current.value }) }}></input> : null}


      <div className="route__dashboardV2__table__filter__btn" onClick={() => { getAllSecondaryUsers(filterName.name, condition.value, filterParams) }}>Filter</div>
      <div className="route__dashboardV2__table__filter__btn" onClick={() => { getAllSecondaryUsers() }}>Reset</div>

    </div>


      <div className='route__chatStatistics__usersTable__gents'>
        <p className='route__chatStatistics__usersTable__gents__headline'>Gentlemen</p>
        <div className='route__chatStatistics__usersTable__gents__head'>
          <p>ID</p>
          <p>First Name</p>
          <p>Last Name</p>
          <p>Sent Messages</p>
          <p>Received Messages</p>
          <p>Total Chat Rooms</p>
          <p><p>Generated Messages</p><p>Lady started chat</p></p>
          <p>Conv. Init. Rate</p>
          <p>Time to first Msg</p>
        </div>
        {secondaryUsers.length ? secondaryUsers.map((item, index) => {
          return <div className='route__chatStatistics__usersTable__gents__item' style={{ backgroundColor: index % 2 === 0 ? '#192343' : '#1923438e' }}>
            <p>{item.ID}</p>
            <p>{item.FirstName}</p>
            <p>{item.LastName}</p>
            <p style={{textAlign: 'center'}}>{item.messageStats.sentMessages}</p>
            <p style={{textAlign: 'center'}}>{item.messageStats.receivedMessages}</p>
            <p style={{textAlign: 'center'}}>{item.messageStats.totalRooms}</p>
            <p style={{textAlign: 'center'}}>{item.messageStats.generatedMessages}</p>
            <p style={{textAlign: 'center'}}>{item.messageStats.conversationInitRate}%</p>
            <p style={{textAlign: 'center'}}>{item.messageStats.timeToFirstMsg ? item.messageStats.timeToFirstMsg : 'N/A'}</p>
          </div>
        }): <p style={{padding: '20px', color:'white'}}>No results</p> }
      </div>


      <div className='route__chatStatistics__usersTable__ladies'>
        <p className='route__chatStatistics__usersTable__ladies__headline'>Ladies</p>
        <div className='route__chatStatistics__usersTable__ladies__head'>
          <p>ID</p>
          <p>First Name</p>
          <p>Last Name</p>
          <p>Sent Messages</p>
          <p>Received Messages</p>
          <p>Total Chat Rooms</p>
        </div>
        {ladies.length ? ladies.map((item, index) => {
          return <div className='route__chatStatistics__usersTable__ladies__item' style={{ backgroundColor: index % 2 === 0 ? '#192343' : '#1923438e' }}>
            <p>{item.ID}</p>
            <p>{item.FirstName}</p>
            <p>{item.LastName}</p>
            <p style={{textAlign: 'center'}}>{item.messageStats.sentMessages}</p>
            <p style={{textAlign: 'center'}}>{item.messageStats.receivedMessages}</p>
            <p style={{textAlign: 'center'}}>{item.messageStats.totalRooms}</p>
          </div>
        }) : <p style={{padding: '20px', color:'white'}}>No results</p>}
      </div>
    </div>
  </div>

}