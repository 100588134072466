import React from 'react';
import "./index.scss";

import CustomInput from '../../components/customComponents/CustomInput';
import Button from '../../components/customComponents/Button';
import Spinner from '../../components/customComponents/Spinner';
import { FilteredCustomTable } from "../../components/customComponents/Table";
import DataSorter from "../../components/customComponents/DataSorter";

import * as backendModule from "../../modules/backendModule";
import axios, { all } from "axios";
import moment from 'moment';

import Dropdown from '../../components/customComponents/Dropdown';

import { animateBox } from "../../modules/componentAnimation";

export default function DeletionSurveys(props) {
  const [deletionSurveysFilters, setDeletionSurveysFilters] = React.useState([]);
  const [deletionSurveysOrders, setDeletionSurveysOrders] = React.useState([]);

  const [deletionSurveys, setDeletionSurveys] = React.useState([]);
  const [deletionSurveyIsLoading, setDeletionSurveyIsLoading] = React.useState(false);
  const [deletionSurveyHasMoreData, setDeletionSurveyHasMoreData] = React.useState(true);
  const deletionSurveyObserverRef = React.useRef(null);

  const getAllDeletionSurveys = () => {
    setDeletionSurveyIsLoading(true);
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/deletionSurvey/getAllDeletionSurveys`,
      data: {
        filters: deletionSurveysFilters,
        orders: deletionSurveysOrders
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === 'ok') {
        setDeletionSurveys(res.data.data);
        setDeletionSurveyHasMoreData(res.data.data.length)
      }
      else {
      }
    }).catch((err) => {
      console.log("ERROR while getting all deletion surveys ::", err)
    }).finally(() => {
      setDeletionSurveyIsLoading(false)
    });
  }

  const getMoreDeletionSurveys = () => {
    if (!deletionSurveyIsLoading && deletionSurveyHasMoreData) {
      setDeletionSurveyIsLoading(true);
      axios({
        method: "POST",
        url: `${backendModule.backendURL}/deletionSurvey/getAllDeletionSurveys`,
        data: {
          // filters: filters ? [...filters, {name: "ID", op: "notIn", value: usersSurveys.length ? usersSurveys.map(elem => elem.ID) : []}] : [{name: "ID", op: "notIn", value: usersSurveys.length ? usersSurveys.map(elem => elem.ID) : []}],
          filters: [...(Array.isArray(deletionSurveysFilters) ? deletionSurveysFilters : []), { name: "ID", op: "notIn", value: deletionSurveys.length ? deletionSurveys.map(elem => elem.ID) : []}],
          orders: deletionSurveysOrders
        },
        ...backendModule.axiosConfig
      }).then(res => {
        if (res.data.status === 'ok') {
          let newData = res.data.data;
          setDeletionSurveys([...deletionSurveys, ...newData])
          setDeletionSurveyHasMoreData(res.data.data.length)
        }
        else {
        }
      }).catch((err) => {
        console.log("ERROR while getting all deletion surveys ::", err)
      }).finally(() => {
        setDeletionSurveyIsLoading(false)
      })
    }
  }
  
  const removeDeletionSurvey = (ID) => {
    if(!ID) return;

    axios({
      method: "POST",
      url: `${backendModule.backendURL}/deletionSurvey/removeDeletionSurvey`,
      data: {
        ID: ID
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === 'ok') {
        getAllDeletionSurveys();
      }
      else {
      }
    }).catch((err) => {
      console.log("ERROR while deleting survey :::", err)
    })

  }

  React.useEffect(() => {
    getAllDeletionSurveys();
  }, [deletionSurveysFilters, deletionSurveysOrders]);

  React.useEffect(() => {
    if(!deletionSurveyObserverRef.current) return;
    if (deletionSurveyObserverRef.current) {
      const observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && !deletionSurveyIsLoading && deletionSurveyHasMoreData) {
          setTimeout(getMoreDeletionSurveys, 300)
        }
      }, { threshold: 1 });

      setTimeout(() => observer.observe(deletionSurveyObserverRef.current), 300);

      return () => {try{
        observer.unobserve(deletionSurveyObserverRef.current);
      }catch{

      }}
    }
  }, [deletionSurveyHasMoreData, deletionSurveyObserverRef.current]);


  const addDeletionSurveysToTable = () => {
    if (deletionSurveys.length) {
      return deletionSurveys.map((item) => {
        return [
          { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.ID}</p></div> },
          { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.UserID}</p></div> },
          { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.DomainID}</p></div> },
          { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.AccountUsage}</p></div> },
          { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.OverallExperience}</p></div> },
          { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{moment(item.updatedAt).format('DD.MM.YYYY.')}</p></div> },
          {
            keyID: String(item.ID), type: "groupNewline", group: [
              { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'left' }}>Reasons for using Cupid : <span style={{ color: '#6dcf55' }}>{item.ReasonForUsingCupid}</span></p></div> }
            ]
          },
          {
            keyID: String(item.ID), type: "groupNewline", group: [
              { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'left' }}>Reason For Account Deletion : <span style={{ color: '#6dcf55' }}>{item.ReasonsForAccountDeletion}</span></p></div> }
            ]
          },
          {
            keyID: String(item.ID), type: "groupNewline", group: [
              { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'left' }}>Improvement Suggestions : <span style={{ color: '#6dcf55' }}>{item.ImprovementSuggestions}</span></p></div> }
            ]
          },
          {
            keyID: String(item.ID), type: "groupNewline", group: [
              { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'left' }}>Final Comment : <span style={{ color: '#6dcf55' }}>{item.FinalComment}</span></p></div> }
            ]
          },
          {
            keyID: String(item.ID), type: "groupNewline", group: [
              {
                keyID: String(item.ID),
                type: "button",
                text: "Remove Survey",
                onClick: e=> { removeDeletionSurvey(item.ID) }
              }
            ]
          }
        ];
      });
    }
  }

  

  return (
    <div className='route__surveys'>
      <div className='route__surveys__users'>
        <p className='route__surveys__users__headline'>Deletion Surveys</p>
        <DataSorter sorters={[
          {name: 'AccountUsage', value:'AccountUsage'},
          {name: 'OverallExperience', value: 'OverallExperience'},
          {name: 'updatedAt', value:'updatedAt'},
        ]} setOrders={setDeletionSurveysOrders} />
        <FilteredCustomTable
          id='table'
          accent='#000'
          dropdownBackground='#000'
          inputBottomBorderColor='#FFF'
          placeholderStyle={{backgroundColor: '#000'}}
          filterHeadStyle={{backgroundColor: 'rgba(25, 35, 67, 0.2)'}}
          tableHeadStyle={{backgroundColor: 'rgba(25, 35, 67, 0.2)'}}
          buttonStyle={{border: '1px solid #FFF', backgroundColor: '#000'}}
          theme='dark'
          headers={['ID', 'UserID', 'DomainID', 'AccountUsage', 'OverallExperience', 'Updated at']}
          filters={[
            { name: "ID", friendlyName: "ID", type: "string" },
            { name: "UserID", friendlyName: "UserID", type: "string" },
            { name: "AccountUsage", friendlyName: "AccountUsage", type: "number" },
            { name: "OverallExperience", friendlyName: "OverallExperience", type: "number" },
            { name: "updatedAt", friendlyName: "Updated at", type: "date" }
          ]}
          filterCB={f => setDeletionSurveysFilters(f)}
          data={deletionSurveys.length > 0 ? addDeletionSurveysToTable() : [[{ keyID: 'noData', type: 'custom', data: <p>No data to display</p> }]]}
        />
        {!deletionSurveyIsLoading && deletionSurveyHasMoreData && deletionSurveys.length > 0 && (
          <div ref={deletionSurveyObserverRef}></div>
        )}
      </div>
    </div>
  )
}