import React from 'react';
import "./index.scss";

import CustomInput from '../../components/customComponents/CustomInput';
import Button from '../../components/customComponents/Button';
import Dropdown from '../../components/customComponents/Dropdown';
import { FilteredCustomTable } from "../../components/customComponents/Table";
import Spinner from '../../components/customComponents/Spinner';
import DataSorter from "../../components/customComponents/DataSorter";

import { countries } from '../../countries';

import { animateBox } from "../../modules/componentAnimation";
import { lookingFor } from "../../modules/miscModule";

import * as backendModule from "../../modules/backendModule";
import axios, { all } from "axios";
import moment from 'moment';

export default function Ladies(props) {
  const [spinner, setSpinner] = React.useState(false);

  const firstNameRef = React.useRef(null);
  const lastNameRef = React.useRef(null);
  const dateOfBirthRef = React.useRef(null);
  const weightRef = React.useRef(null);
  const heightRef = React.useRef(null);
  const alcoholRef = React.useRef(null);
  const isVerifiedRef = React.useRef(null);
  const cigarettesRef = React.useRef(null);
  const isPremiumLadyRef = React.useRef(null);
  const profesionRef = React.useRef(null);
  const religionRef = React.useRef(null);
  const kidsRef = React.useRef(null);
  const maritalStatusRef = React.useRef(null);
  const zodiacRef = React.useRef(null);
  const msgRef = React.useRef(null);
  const hairColorRef = React.useRef(null);
  const cityRef = React.useRef();
  const bioRef = React.useRef();
  const [country, setCountry] = React.useState('US');
  const [gender, setGender] = React.useState('Female');
  const [images, setImages] = React.useState([]);
  const [languages, setLanguages] = React.useState([]);
  const [ladyLookingFor, setLadyLookingFor] = React.useState([]);
  const languagesRef = React.useRef(null);
  const [interests, setInterests] = React.useState([]);
  const interestsRef = React.useRef(null);

  const openPhotosModal = (e, isEdit, curLady) => {
    animateBox(e, <UploadPhotosModal curLady={curLady} isEdit={isEdit} />);
  }

  const UploadPhotosModal = (props) => {
    const uploadMsgRef = React.useRef(null);
    const [selectedImage, setSelectedImage] = React.useState(null);
    const [addedPhotos, setAddedPhotos] = React.useState(images);

    const addPhoto = (img) => {
      if (!selectedImage) {
        return uploadMsgRef.current.innerText = 'Select photo please';
      }
      else {
        setAddedPhotos(prev => {
          return [
            ...prev,
            img
          ]
        });
      }
    }

    const deletePhoto = (index) => {
      let tmp = addedPhotos;
      tmp.splice(index, 1);
      setAddedPhotos(prev => {
        return prev.splice(index, 1);
      });
    }

    return <div className='route__ladies__addNewModal__uploadPhotosModal'>
      <div id='box'>
        <div id='box-upload'>
          <CustomInput
            placeholder="Upload photo" accent="#FFF" theme="dark" type="file" accept="image/*" style={{ width: "100%", marginBottom: '25px' }}
            id="ladie-img-input"
            name='ladiephoto'
            onChange={
              (event) => {
                setSelectedImage(event.target.files[0]);
              }
            }
          />
          <Button className='uploadButton' value='Upload' accent='rgb(72, 82, 92)' onClick={() => addPhoto(selectedImage)} />
        </div>
        <span id='uploaded-links'>
          {
            addedPhotos.map((item, index) => {
              return <span><p>{item.name}</p><p id='delete-photo' onClick={() => deletePhoto(index)}>X</p></span>
            })
          }
        </span>
        <div id="controles">
          <Button value='Cancel' accent='transparent' style={{ borderBottom: '1px solid #e53333', borderRadius: '0px', color: '#e53333' }} onClick={props.onClose} />
          <Button value='Save' accent='transparent' style={{ borderBottom: '1px solid #00A3FF', borderRadius: '0px', color: '#00A3FF' }} onClick={async () => {
            if (addedPhotos.length < 1) {
              return uploadMsgRef.current.innerText = 'Upload photo please';
            }
            else {
              if (props.isEdit === false) {
                setImages(addedPhotos);
              }
              else {
                let imgs = [];
                addedPhotos.map((image, index) => {
                  
                });
                for (let i=0; i<=addedPhotos.length-1; i++) {
                  let image = addedPhotos[i];
                  let imgFile = new FormData();
                  imgFile.append('image', image);
                  imgFile.append('ImageName', image.name);
                  imgFile.append('tag', 'lady-img');

                  await axios({
                    method: "POST",
                    url: `${backendModule.backendURL}/images/uploadImage`,
                    data: imgFile,
                    ...backendModule.axiosConfig,
                    headers: {
                      "Content-Type": "multipart/form-data"
                    }
                  }).then(imgres => {
                    imgs.push(imgres.data.data);
                  }).catch(() => {

                  }).finally(() => {
                    if (i === addedPhotos.length - 1) {
                      axios({
                        method: "POST",
                        url: `${backendModule.backendURL}/ladies/editLady`,
                        data: {
                          ID: props.curLady.ID,
                          DomainID: props.curLady.DomainID,
                          FirstName: props.curLady.FirstName,
                          LastName: props.curLady.LastName,
                          DateOfBirth: props.curLady.DateOfBirth,
                          Weight: props.curLady.Weight,
                          Height: props.curLady.Height,
                          SpeakingLanguages: props.curLady.SpeakingLanguages,
                          ConsumesAlcohol: props.curLady.ConsumesAlcohol,
                          VerifiedProfile: props.curLady.VerifiedProfile,
                          SmokesCigarettes: props.curLady.SmokesCigarettes,
                          Profesion: props.curLady.Profesion,
                          ZodiacSign: props.curLady.ZodiacSign,
                          Religion: props.curLady.Religion,
                          Kids: props.curLady.Kids,
                          MaritalStatus: props.curLady.MaritalStatus,
                          Interests: props.curLady.Interests,
                          Images: [...props.curLady.Images, ...imgs],
                          City: props.curLady.City,
                          Bio: props.curLady.Bio
                        },
                        ...backendModule.axiosConfig
                      }).then(res => {
                        if (res.data.status === 'ok') {
                          getAllLadies();
                        }
                      }).catch(() => null);
                    }
                  });
                };
              }
              props.onClose();
            }
          }} />
        </div>
        <p id='msg' ref={uploadMsgRef}></p>
      </div>
    </div>
  }

  const openDomainsModal = (e, isEdit, setNewSelectedDomain) => {
    animateBox(e, <SelectDomainModal isEdit={isEdit} setNewSelectedDomain={setNewSelectedDomain} />);
  }

  const [selectedDomain, setSelectedDomain] = React.useState(null);

  const SelectDomainModal = (props) => {
    const [filters, setFilters] = React.useState(false);
    const [domains, setDomains] = React.useState(false);

    const getAllDomains = () => {
      axios({
        method: "POST",
        url: `${backendModule.backendURL}/domains/getAllDomains`,
        data: {
          filters: filters
        },
        ...backendModule.axiosConfig
      }).then(res => {
        if (res.data.status === 'ok') {
          setDomains(res.data);
        }
        else {
          setDomains('GET_ALL_DOMAINS_ERROR');
        }
      }).catch(() => {
        setDomains('GET_ALL_DOMAINS_ERROR');
      });
    }

    React.useEffect(() => {
      getAllDomains();
    }, [filters]);

    const addDomainsToTable = () => {
      if (domains.data) {
        return domains?.data?.map((item) => {
          return [
            { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.ID}</p></div> },
            { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Domain}</p></div> },
            { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.WebsiteName}</p></div> },
            { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center', background: item.forOffer ? 'green' : 'red', width: 'fit-content', marginLeft: 'auto', marginRight: 'auto', padding: '5px', borderRadius: '10px' }}>{item.forOffer ? 'YES' : 'NO'}</p></div> },
            { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{moment(item.updatedAt).format('DD.MM.YYYY.')}</p></div> },
            {
              keyID: String(item.ID), type: "groupNewline", group: [
                {
                  keyID: String(item.ID), type: "button", text: "Select", triggerDropdown: true, onClick: e => {
                    if (props.isEdit === true) {
                      props.setNewSelectedDomain({ ID: item.ID, URL: item.Domain });
                    }
                    else {
                      setSelectedDomain({ ID: item.ID, URL: item.Domain });
                    }
                    props.onClose();
                  }
                }
              ]
            }
          ];
        });
      }
    }

    return <div className='route__ladies__addNewModal__selectDomainModal'>
      <div id='box'>
        <div id='table'>
          <FilteredCustomTable
            id='table'
            accent='#48525C'
            theme='dark'
            headers={['ID', 'Domain', 'Website name', 'For offer', 'Created at']}
            filters={[
              { name: "ID", friendlyName: "ID", type: "number" },
              { name: "Domain", friendlyName: "Domain", type: "string" },
              { name: "WebsiteName", friendlyName: "Website name", type: "string" },
              { name: "updatedAt", friendlyName: "Date", type: "date" },
            ]}
            filterCB={f => setFilters(f)}
            data={domains.data?.length > 0 ? addDomainsToTable() : [[{ keyID: 'noData', type: 'custom', data: <p>No data to display</p> }]]}
          />
        </div>
        <div id="controles">
          <Button value='Cancel' accent='transparent' style={{ borderBottom: '1px solid #e53333', borderRadius: '0px', color: '#e53333' }} onClick={props.onClose} />
        </div>
      </div>
    </div>
  }

  const sendLadyData = (uploadedImages, close) => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/ladies/addLady`,
      data: {
        DomainID: selectedDomain.ID,
        FirstName: firstNameRef.current.value,
        LastName: lastNameRef.current.value,
        DateOfBirth: dateOfBirthRef.current.value,
        Weight: weightRef.current.value,
        Height: heightRef.current.value,
        SpeakingLanguages: languages,
        ConsumesAlcohol: alcoholRef.current.checked ? true : false,
        VerifiedProfile: isVerifiedRef.current.checked ? true : false,
        SmokesCigarettes: cigarettesRef.current.checked ? true : false,
        IsPremiumLady: isPremiumLadyRef.current.checked ? true : false,
        Profesion: profesionRef.current.value,
        ZodiacSign: zodiacRef.current.value,
        Religion: religionRef.current.value,
        Kids: kidsRef.current.value,
        MaritalStatus: maritalStatusRef.current.value,
        Interests: ladyLookingFor,
        Images: uploadedImages,
        Country: country,
        Gender: gender === 'Male' ? 1 : gender === 'Female' ? 0 : gender === 'Other' ? null : '',
        HairColor: String(hairColorRef.current.value).toLowerCase(),
        City: cityRef.current.value,
        Bio: bioRef.current.value
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === 'ok') {
        getAllLadies();
      }
      else {
        msgRef.current.innerText = 'Something went wrong'
      }
    }).catch(() => {
      msgRef.current.innerText = 'Something went wrong'
    }).finally(() => {
      setSpinner(false);
      close();
      setLadyLookingFor([]);
      setLanguages([]);
      setImages([]);
      setSelectedDomain(null);
    });
  }

  const addLady = async (close) => {
    if (
      firstNameRef.current.value &&
      lastNameRef.current.value &&
      dateOfBirthRef.current.value &&
      weightRef.current.value &&
      heightRef.current.value &&
      cigarettesRef.current.value &&
      isPremiumLadyRef.current.value &&
      profesionRef.current.value &&
      zodiacRef.current.value &&
      kidsRef.current.value &&
      maritalStatusRef.current.value &&
      country &&
      hairColorRef.current.value &&
      ladyLookingFor.length > 0 &&
      languages.length > 0 &&
      images.length > 0 &&
      selectedDomain
    ) {
      setSpinner(true);
    }

    if(!firstNameRef.current.value || !lastNameRef.current.value){
      return msgRef.current.innerText = 'First or last name missing!';
    }

    if(!dateOfBirthRef.current.value){
      return msgRef.current.innerText = 'Date of birth missing!';
    }

    if(!weightRef.current.value || !heightRef.current.value){
      return msgRef.current.innerText = 'Weight or height missing!';
    }

    if(!profesionRef.current.value || !zodiacRef.current.value){
      return msgRef.current.innerText = 'Profesion or zodiac sign missing!';
    }

    if(!kidsRef.current.value || !maritalStatusRef.current.value){
      return msgRef.current.innerText = 'Kids or marital status missing!';
    }

    if(!hairColorRef.current.value){
      return msgRef.current.innerText = 'Hair color missing!';
    }

    if(ladyLookingFor.length < 1 || languages.length < 1){
      return msgRef.current.innerText = 'Looking for or speaking languages missing!';
    }

    if(images.length < 1){
      return msgRef.current.innerText = 'Upload images please!';
    }

    if (!selectedDomain){
      return msgRef.current.innerText = 'Select domain please!';
    }

    let imgs = [];
    for (let i=0; i<=images.length-1; i++) {
      let image = images[i];

      let imgFile = new FormData();
      imgFile.append('image', image);
      imgFile.append('ImageName', image.name);
      imgFile.append('tag', 'lady-img');

      await axios({
        method: "POST",
        url: `${backendModule.backendURL}/images/uploadImage`,
        data: imgFile,
        ...backendModule.axiosConfig,
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }).then(imgres => {
        imgs.push(imgres.data.data);
      }).catch(() => {

      }).finally(() => {
        if (i === images.length - 1) {
          sendLadyData(imgs, close);
        }
      });
    };
  }

  const [ladies, setLadies] = React.useState(false);
  const [filters, setFilters] = React.useState([]);
  const [orders, setOrders] = React.useState([]);
  const [canPaginate, setCanPaginate] = React.useState(false);

  const getAllLadies = () => {
    setCanPaginate(false);
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/ladies/getAllLadies2`,
      data: {
        offset: 0,
        filters: filters,
        orders: orders
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === 'ok') {
        setLadies(res.data);
        if (res.data.data.length === 20) {
          setTimeout(() => setCanPaginate(true), 1000);
        }
      }
      else {
        setLadies('GET_ALL_USERS_ERROR');
      }
    }).catch(() => {
      setLadies('GET_ALL_USERS_ERROR');
    });
  }

  React.useEffect(() => {
    getAllLadies();
  }, [filters, orders]);

  const scrollTriggerRef = React.useRef(null);
  const [loadingData, setLoadingData] = React.useState(false);
  

  const paginationData = (isInViewPort) => {
    if (isInViewPort && canPaginate) {
      axios({
        method: "POST",
        url: `${backendModule.backendURL}/ladies/getAllLadies2`,
        data: {
          orders: orders,
          offset: ladies.data.length,
        },
        ...backendModule.axiosConfig
      }).then(res => {
        if (res.data.status === "ok") {
          if (res.data.data.length > 0) {
            setLoadingData(true);
            setTimeout(() => setLoadingData(false), 100);
            res.data.data?.map((item) => {
              ladies.data.push(item);
            });
            setCanPaginate(false);
            setTimeout(() => {
              setCanPaginate(true);
            }, 1000);
          }
        };
      }).catch(() => {
        setLadies({ status: 'error', data: 'Data fetch failed' });
      }).finally(() => {
        setSpinner(false);
      });
    }
  }

  const useIsInViewport = () => {
    const [isIntersecting, setIsIntersecting] = React.useState(false);

    const observer = React.useMemo(
      () =>
        new IntersectionObserver(([entry]) =>
          setIsIntersecting(entry.isIntersecting),
        ),
      [],
    );

    React.useEffect(() => {
      observer.observe(scrollTriggerRef.current);
      return () => {
        observer.disconnect();
      };
    }, [scrollTriggerRef, observer]);

    if (isIntersecting) {
      paginationData(isIntersecting);
    }
  }

  useIsInViewport();

  const deleteLady = (id) => {
    setSpinner(true);
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/ladies/removeLady`,
      data: {
        ID: id
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === 'ok') {
        getAllLadies();
      }
      else {

      }
    }).catch(() => {

    }).finally(() => {
      setSpinner(false);
    });
  }

  const addLadiesToTable = () => {
    if (ladies.data) {
      return ladies?.data?.map((item) => {
        return [
          { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.ID}</p></div> },
          { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.FirstName} {item.LastName}</p></div> },
          { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{moment(item.DateOfBirth).format('DD.MM.YYYY.')}</p></div> },
          { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Weight}</p></div> },
          { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Height}</p></div> },
          { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Profesion}</p></div> },
          { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.ZodiacSign}</p></div> },
          { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{moment(item.updatedAt).format('DD.MM.YYYY.')}</p></div> },
          {
            keyID: String(item.ID), type: "groupNewline", group: [
              { keyID: String(item.ID), type: "custom", data: <div id='table-country'><p>Country:</p> <strong>{item.Country}</strong> <p>Hair color:</p> <strong>{item.HairColor}</strong></div> },
            ]
          },
          {
            keyID: String(item.ID), type: "groupNewline", group: [
              {
                keyID: String(item.ID), type: "button", text: "Delete", triggerDropdown: true, triggerData: e => {
                  return (<div style={{ display: 'flex', flexDirection: 'column', justfyContent: 'center', alignItems: 'center' }}>
                    <p style={{ color: 'white' }}>Are you sure?</p>
                    <br></br>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Button style={{ display: spinner ? 'none' : 'block', marginRight: '10px' }} accent='rgb(72, 82, 92)' value='YES' onClick={() => deleteLady(item.ID)} />
                      <Button style={{ display: spinner ? 'none' : 'block' }} accent='rgb(72, 82, 92)' value='NO' onClick={c => { e() }} />
                      <div style={{ display: spinner ? 'block' : 'none' }}><Spinner color='white' width='32px' /></div>
                    </div>
                  </div>);
                },
              },
              {
                keyID: String(item.ID), type: "button", text: "More details", triggerDropdown: true, onClick: e => {
                  animateBox(e, <MoreLadyDetails curLady={
                    {
                      DomainID: item.DomainID,
                      FirstName: item.FirstName,
                      LastName: item.LastName,
                      DateOfBirth: item.DateOfBirth,
                      Weight: item.Weight,
                      Height: item.Height,
                      SpeakingLanguages: JSON.parse(item.SpeakingLanguages),
                      ConsumesAlcohol: item.ConsumesAlcohol,
                      VerifiedProfile: item.VerifiedProfile,
                      SmokesCigarettes: item.SmokesCigarettes,
                      Profesion: item.Profesion,
                      ZodiacSign: item.ZodiacSign,
                      Religion: item.Religion,
                      Kids: item.Kids,
                      MaritalStatus: item.MaritalStatus,
                      Interests: JSON.parse(item.Interests),
                      Images: JSON.parse(item.Images),
                      HairColor: item.HairColor,
                      Country: item.Country,
                      City: item.City,
                      Bio: item.Bio
                    }
                  } />);
                }
              },
              {
                keyID: String(item.ID), type: "button", text: "Edit", triggerDropdown: true, onClick: e => {
                  animateBox(e, <EditLady curLady={
                    {
                      ID: item.ID,
                      DomainID: item.DomainID,
                      FirstName: item.FirstName,
                      LastName: item.LastName,
                      DateOfBirth: item.DateOfBirth,
                      Weight: item.Weight,
                      Height: item.Height,
                      SpeakingLanguages: item.SpeakingLanguages,
                      ConsumesAlcohol: item.ConsumesAlcohol,
                      VerifiedProfile: item.VerifiedProfile,
                      SmokesCigarettes: item.SmokesCigarettes,
                      Profesion: item.Profesion,
                      ZodiacSign: item.ZodiacSign,
                      Religion: item.Religion,
                      Kids: item.Kids,
                      MaritalStatus: item.MaritalStatus,
                      Interests: item.Interests,
                      Images: item.Images,
                      HairColor: item.HairColor,
                      Country: item.Country,
                      Gender: item.Gender,
                      City: item.City,
                      Bio: item.Bio
                    }
                  } />)
                }
              }
            ]
          }
        ];
      });
    }
  }

  const MoreLadyDetails = (props) => {

    const [domainName, setDomainName] = React.useState('')

    axios({
      method: "POST",
      url: `${backendModule.backendURL}/domains/getDomainByID`,
      data: {
          ID: props.curLady.DomainID
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === 'ok') {
        setDomainName(res.data.data.Domain)
      }
    }).catch((err) => {
      console.log("ERROR::: ", err)
    });

    return <div className='route__ladies__moreDetailsModal'>
      <div id='box'>
        <span id='domain-id'><strong>DOMAIN ID:</strong> <p>{props.curLady.DomainID}</p></span>
        <span id='domain-id'><strong>DOMAIN name:</strong> {domainName && <p>{domainName}</p>}</span>
        <div id='gridbox'>
          <img src={props.curLady.Images[0]} alt='' />
          <div id='top-right'>
            <p><strong>Name:</strong> {props.curLady.FirstName} {props.curLady.LastName}</p>
            <p><strong>Date of birth:</strong> {moment(props.curLady.DateOfBirth).format('DD.MM.YYYY.')}</p>
            <p><strong>Weight:</strong> {props.curLady.Weight} kg</p>
            <p><strong>Height:</strong> {props.curLady.Height} cm</p>
            <p><strong>Consumes alcohol:</strong> {props.curLady.ConsumesAlcohol === true ? 'YES' : 'NO'}</p>
            <p><strong>Verified Profile:</strong> {props.curLady.VerifiedProfile === true ? 'YES' : 'NO'}</p>
            <p><strong>Smokes cigarettes:</strong> {props.curLady.SmokesCigarettes == 1 ? 'YES' : 'NO'}</p>
            <p><strong>Profesion:</strong> {props.curLady.Profesion}</p>
          </div>
          <div id='middle-left'>
            <p><strong>Religion:</strong> {props.curLady.Religion}</p>
            <p><strong>Kids:</strong> {props.curLady.Kids}</p>
            <p><strong>Country:</strong> {props.curLady.Country}</p>
            <p><strong>City:</strong> {props.curLady.City}</p>
            <span><strong>Interests:</strong> {props.curLady.Interests.map((item) => { return <p> - {item}</p> })}</span>
          </div>
          <div id='middle-right'>
            <p><strong>Zodiac sign:</strong> {props.curLady.ZodiacSign}</p>
            <p><strong>Marital status:</strong> {props.curLady.MaritalStatus}</p>
            <p><strong>Hair color:</strong> {props.curLady.HairColor}</p>
            <span><strong>Speaking languages:</strong> {props.curLady.SpeakingLanguages.map((item) => { return <p> - {item}</p> })}</span>
          </div>
          <div id="controles">
            <Button value='Close' accent='transparent' style={{ borderBottom: '1px solid #e53333', borderRadius: '0px', color: '#e53333' }} onClick={() => props.onClose()} />
          </div>
          <div id="controles" style={{ justifyContent: 'flex-end' }}>
            <Button value='More photos' accent='transparent' style={{ borderBottom: '1px solid white', borderRadius: '0px', color: 'white' }} onClick={(e) => animateBox(e, <MorePhotos isEdit={false} images={props.curLady.Images} />)} />
          </div>
        </div>
      </div>
    </div>
  }

  const MorePhotos = (props) => {
    const [newImages, setNewImages] = React.useState([]);
    React.useEffect(() => {
      if (props.isEdit) {
        axios({
          method: "POST",
          url: `${backendModule.backendURL}/ladies/getLadyByID`,
          data: {
            ID: props.curLady.ID
          },
          ...backendModule.axiosConfig
        }).then(res => {
          setNewImages(JSON.parse(res.data.data.Images));
        }).catch(() => {

        });
      }
    }, []);

    const [selected, setSelected] = React.useState(null);
    const selectPhoto = (img) => {
      if (props.isEdit) {
        setSelected(img);
      }
    }

    const [curImages, setCurImages] = React.useState(props.images);
    const tmp = (!props.isEdit ? curImages : newImages);

    const deleteImage = (img) => {
      (!props.isEdit ? curImages : newImages).map((item, index) => {
        if (item === img) {
          tmp.splice(index, 1);
          (!props.isEdit ? setCurImages(tmp) : setNewImages(tmp));
          setSelected(null);
          setSpinner(true);
          axios({
            method: "POST",
            url: `${backendModule.backendURL}/ladies/editLady`,
            data: {
              ID: props.curLady.ID,
              DomainID: props.curLady.DomainID,
              FirstName: props.curLady.FirstName,
              LastName: props.curLady.LastName,
              DateOfBirth: props.curLady.DateOfBirth,
              Weight: props.curLady.Weight,
              Height: props.curLady.Height,
              SpeakingLanguages: props.curLady.SpeakingLanguages,
              ConsumesAlcohol: props.curLady.ConsumesAlcohol,
              VerifiedProfile: props.curLady.VerifiedProfile,
              SmokesCigarettes: props.curLady.SmokesCigarettes,
              Profesion: props.curLady.Profesion,
              ZodiacSign: props.curLady.ZodiacSign,
              Religion: props.curLady.Religion,
              Kids: props.curLady.Kids,
              MaritalStatus: props.curLady.MaritalStatus,
              Interests: props.curLady.Interests,
              Images: !props.isEdit ? curImages : newImages,
              City: props.curLady.City,
              Bio: props.curLady.Bio
            },
            ...backendModule.axiosConfig
          }).then(res => {
            if (res.data.status === 'ok') {
              getAllLadies();
            }
            else {

            }
          }).catch(() => {

          }).finally(() => {
            setSpinner(false);
          });
        }
      });
    }

    return <div className='route__ladies__moreDetailsModal__morePhotosModal'>
      <div id='box'>
        <div id='images-box'>
          <div style={{ display: props.isEdit && newImages.length < 1 ? 'block' : 'none', width: '100%' }}><Spinner color='white' width='32px' align='center' /></div>
          {
            !props.isEdit ?
              curImages.map((item) => {
                return <img style={{ border: selected === item ? '3px solid #00A3FF' : '' }} src={item} alt='' onClick={() => selectPhoto(item)} />
              }) : ''
          }
          {
            props.isEdit ?
              newImages.map((item) => {
                return <img style={{ border: selected === item ? '3px solid #00A3FF' : '' }} src={item} alt='' onClick={() => selectPhoto(item)} />
              }) : ''
          }
        </div>
        <div id="controles" style={{ display: !spinner ? 'flex' : 'none' }}>
          <Button value='Close' accent='transparent' style={{ borderBottom: '1px solid #e53333', borderRadius: '0px', color: '#e53333' }} onClick={() => props.onClose()} />
          <Button accent='transparent' style={{ display: props.isEdit && selected !== null ? 'block' : 'none', borderBottom: '1px solid #e53333', borderRadius: '0px', color: '#e53333' }} value='Delete' onClick={() => deleteImage(selected)} />
          <div style={{ width: '100%', display: spinner ? 'block' : 'none' }}><Spinner color='white' align='right' width='32px' /></div>
        </div>
      </div>
    </div>
  }

  const EditLady = (props) => {
    const newFirstNameRef = React.useRef(null);
    const newLastNameRef = React.useRef(null);
    const newDateOfBirthRef = React.useRef(null);
    const newWeightRef = React.useRef(null);
    const newHeightRef = React.useRef(null);
    const newProfesionRef = React.useRef(null);
    const newSmokesCigarettesRef = React.useRef(null);
    const newIsPremiumLadyRef = React.useRef(null);
    const newConsumesAlcoholRef = React.useRef(null);
    const newIsVerifiedRef = React.useRef(null);
    const newZodiacSignRef = React.useRef(null);
    const newReligionRef = React.useRef(null);
    const newKidsRef = React.useRef(null);
    const newMaritalStatusRef = React.useRef(null);
    const newLanguagesRef = React.useRef(null);
    const newInterestsRef = React.useRef(null);
    const editMsgRef = React.useRef(null);
    const newCountryRef = React.useRef(null);
    const newHairColorRef = React.useRef(null);
    const newCityRef = React.useRef();
    const newBioRef = React.useRef();
    const [newLanguages, setNewLanguages] = React.useState([]);
    const [newInterests, setNewInterests] = React.useState([]);
    const [newSelectedDomain, setNewSelectedDomain] = React.useState(null);
    const [newGender, setNewGender] = React.useState(Number(props.curLady.Gender) === 1 ? 'Male' : Number(props.curLady.Gender) === 0 ? 'Female' : Number(props.curLady.Gender) === 'Other' ? null : '');

    React.useEffect(() => {
      setNewLanguages(JSON.parse(props.curLady.SpeakingLanguages));
      setNewInterests(props.curLady.Interests);

      if (props.curLady.ConsumesAlcohol === true) {
        newConsumesAlcoholRef.current.checked = true;
      }
      if (props.curLady.VerifiedProfile === true) {
        newIsVerifiedRef.current.checked = true;
      }
      if (props.curLady.SmokesCigarettes == 1) {
        newSmokesCigarettesRef.current.checked = true;
      }
      // if (props.curLady.SmokesCigarettes == 1) {
      //   newIsPremiumLadyRef.current.checked = true;
      // }
    }, []);

    const saveLady = (id, close) => {
      setSpinner(true);
      axios({
        method: "POST",
        url: `${backendModule.backendURL}/ladies/getLadyByID`,
        data: {
          ID: props.curLady.ID
        },
        ...backendModule.axiosConfig
      }).then(res => {
        axios({
          method: "POST",
          url: `${backendModule.backendURL}/ladies/editLady`,
          data: {
            ID: id,
            DomainID: newSelectedDomain !== null ? newSelectedDomain.ID : props.curLady.DomainID,
            FirstName: newFirstNameRef.current.value,
            LastName: newLastNameRef.current.value,
            DateOfBirth: newDateOfBirthRef.current.value,
            Weight: newWeightRef.current.value,
            Height: newHeightRef.current.value,
            SpeakingLanguages: newLanguages,
            ConsumesAlcohol: newConsumesAlcoholRef.current.checked ? true : false,
            VerifiedProfile: newIsVerifiedRef.current.checked ? true : false,
            SmokesCigarettes: newSmokesCigarettesRef.current.checked ? true : false,
            Profesion: newProfesionRef.current.value,
            ZodiacSign: newZodiacSignRef.current.value,
            Religion: newReligionRef.current.value,
            Kids: newKidsRef.current.value,
            MaritalStatus: newMaritalStatusRef.current.value,
            Interests: newInterests,
            Images: res.data.data.Images,
            HairColor: String(newHairColorRef.current.value).toLowerCase(),
            Country: newCountryRef.current.value,
            Gender: newGender === 'Male' ? 1 : newGender === 'Female' ? 0 : newGender === 'Other' ? null : '',
            City: newCityRef.current.value,
            Bio: newBioRef.current.value
          },
          ...backendModule.axiosConfig
        }).then(res => {
          if (res.data.status === 'ok') {
            getAllLadies();
          }
          else {

          }
        }).catch((err) => {
          console.log("Saving Lady Error", err)
        }).finally(() => {
          setSpinner(false);
          close();
        });
      }).catch((err) => {
        console.log("Saving Lady Error", err)
      });
    }

    return <div className='route__ladies__editModal'>
      <div id='box'>
        <h1>Edit {props.curLady.FirstName} {props.curLady.LastName}</h1>
        <div id='gridbox'>
          <CustomInput autocomplete="off" ref={newFirstNameRef} defaultValue={props.curLady.FirstName} placeholder="First name" accent="#FFF" theme="dark" type="text" style={{ width: "100%", marginBottom: '25px' }} />
          <CustomInput autocomplete="off" ref={newLastNameRef} defaultValue={props.curLady.LastName} placeholder="Last name" accent="#FFF" theme="dark" type="text" style={{ width: "100%", marginBottom: '25px' }} />
          <CustomInput autocomplete="off" ref={newDateOfBirthRef} defaultValue={props.curLady.DateOfBirth} placeholder="Date of birth" accent="#FFF" theme="dark" type="date" style={{ width: "100%", marginBottom: '25px' }} />
          <CustomInput autocomplete="off" ref={newWeightRef} defaultValue={props.curLady.Weight} placeholder="Weight" accent="#FFF" theme="dark" type="number" style={{ width: "100%", marginBottom: '25px' }} />
          <CustomInput autocomplete="off" ref={newHeightRef} defaultValue={props.curLady.Height} placeholder="Height" accent="#FFF" theme="dark" type="number" style={{ width: "100%", marginBottom: '25px' }} />
          <CustomInput autocomplete="off" ref={newProfesionRef} defaultValue={props.curLady.Profesion} placeholder="Profesion" accent="#FFF" theme="dark" type="text" style={{ width: "100%", marginBottom: '25px' }} />
          <CustomInput autocomplete="off" ref={newSmokesCigarettesRef} placeholder="Smokes cigarettes" accent="#FFF" theme="dark" id='ladie-checkbox' type="checkbox" style={{ width: "100%", marginBottom: '25px' }} />
          {/* <CustomInput autocomplete="off" ref={newIsPremiumLadyRef} placeholder="Is Premium Lady" accent="#FFF" theme="dark" id='ladie-checkbox' type="checkbox" style={{ width: "100%", marginBottom: '25px' }} /> */}
          <CustomInput autocomplete="off" ref={newConsumesAlcoholRef} placeholder="Consumes alcohol" accent="#FFF" theme="dark" id='ladie-checkbox' type="checkbox" style={{ width: "100%", marginBottom: '25px' }} />
          <CustomInput autocomplete="off" ref={newIsVerifiedRef} placeholder="Verified Profile" accent="#FFF" theme="dark" id='ladie-checkbox' type="checkbox" style={{ width: "100%", marginBottom: '25px' }} />
          <CustomInput autocomplete="off" ref={newZodiacSignRef} defaultValue={props.curLady.ZodiacSign} placeholder="Zodiac sign" accent="#FFF" theme="dark" type="text" style={{ width: "100%", marginBottom: '25px' }} />
          <CustomInput autocomplete="off" ref={newReligionRef} defaultValue={props.curLady.Religion} placeholder="Religion" accent="#FFF" theme="dark" type="text" style={{ width: "100%", marginBottom: '25px' }} />
          <CustomInput autocomplete="off" ref={newKidsRef} defaultValue={props.curLady.Kids} placeholder="Kids" accent="#FFF" theme="dark" type="number" style={{ width: "100%", marginBottom: '25px' }} />
          <CustomInput autocomplete="off" ref={newMaritalStatusRef} defaultValue={props.curLady.MaritalStatus} placeholder="Marital status" accent="#FFF" theme="dark" type="text" style={{ width: "100%", marginBottom: '25px' }} />
          <CustomInput autocomplete="off" ref={newCountryRef} defaultValue={props.curLady.Country} placeholder="Country" accent="#FFF" theme="dark" type="text" style={{ width: "100%", marginBottom: '25px' }} />
          <CustomInput autocomplete="off" ref={newHairColorRef} defaultValue={props.curLady.HairColor} placeholder="Marital status" accent="#FFF" theme="dark" type="text" style={{ width: "100%", marginBottom: '25px' }} />
          <CustomInput autocomplete="off" ref={newCityRef} defaultValue={props.curLady.City} placeholder="City" accent="#FFF" theme="dark" type="text" style={{ width: "100%", marginBottom: '25px' }} />
          <CustomInput autocomplete="off" ref={newBioRef} defaultValue={props.curLady.Bio} placeholder="Bio" accent="#FFF" theme="dark" type="text" style={{ width: "100%", marginBottom: '25px' }} />
          {/* <br /> */}
          {/* <span id='languages-span'>
            {
              newLanguages.map((item) => {
                return <p onClick={() => {
                  setNewLanguages(prev => {
                    return prev.filter(i => {return i !== item});
                  });
                }}>{item}</p>
              })
            }
          </span> */}
          {/* <span id='interests-span'>
            {
              newInterests.map((item) => {
                return <p onClick={() => {
                  setNewInterests(prev => {
                    return prev.filter(i => {return i !== item});
                  });
                }}>{item}</p>
              })
            }
          </span> */}
          {/* <CustomInput
            ref={newLanguagesRef}
            autocomplete="off"
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                setNewLanguages(prev => {
                  return [
                    ...prev,
                    newLanguagesRef.current.value
                  ]
                });
                setTimeout(() => {
                  newLanguagesRef.current.value = '';
                }, 50);
              }
            }}
            placeholder="Speaking languages"
            accent="#FFF"
            theme="dark"
            type="text"
            style={{ width: "100%", marginBottom: '25px' }}
          /> */}
          {/* <CustomInput
            ref={newInterestsRef}
            autocomplete="off"
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                setNewInterests(prev => {
                  return [
                    ...prev,
                    newInterestsRef.current.value
                  ]
                });
                setTimeout(() => {
                  newInterestsRef.current.value = '';
                }, 50);
              }
            }}
            placeholder="Interests"
            accent="#FFF"
            theme="dark"
            type="text"
            style={{ width: "100%", marginBottom: '25px' }}
          /> */}
          <div id='edit-photo-btns'>
            <Button value='UPLOAD PHOTOS' accent='rgb(10, 15, 36)' className='addNewLadieBtn' onClick={(e) => openPhotosModal(e, true, props.curLady)} />
            <Button value='VIEW PHOTOS' accent='rgb(10, 15, 36)' className='addNewLadieBtn' onClick={(e) => animateBox(e, <MorePhotos curLady={props.curLady} isEdit={true} images={props.curLady.Images} />)} />
          </div>
          <Button value='CHANGE DOMAIN' accent='rgb(10, 15, 36)' className='addNewLadieBtn' onClick={(e) => openDomainsModal(e, true, setNewSelectedDomain)} />
        </div>
        <div id='gender-select'>
          <div style={{ border: newGender === 'Male' ? '1px solid white' : '1px solid rgb(46, 76, 110)', backgroundColor: 'rgb(10, 15, 36)' }} onClick={() => setNewGender('Male')}><p>Male</p></div>
          <div style={{ border: newGender === 'Female' ? '1px solid white' : '1px solid rgb(46, 76, 110)', backgroundColor: 'rgb(10, 15, 36)' }} onClick={() => setNewGender('Female')}><p>Female</p></div>
          <div style={{ border: newGender === 'Other' ? '1px solid white' : '1px solid rgb(46, 76, 110)', backgroundColor: 'rgb(10, 15, 36)' }} onClick={() => setNewGender('Other')}><p>Other</p></div>
        </div>
        <div id="controles" style={{ display: !spinner ? 'flex' : 'none' }}>
          <Button value='Cancel' accent='transparent' style={{ borderBottom: '1px solid #e53333', borderRadius: '0px', color: '#e53333' }} onClick={props.onClose} />
          <Button value='Save' accent='transparent' style={{ borderBottom: '1px solid #00A3FF', borderRadius: '0px', color: '#00A3FF' }} onClick={() => { saveLady(props.curLady.ID, props.onClose) }} />
        </div>
        <div style={{ display: spinner ? 'flex' : 'none', flexDirection: 'row', justifyContent: 'flex-end' }}>
          <Spinner color='white' align='right' width='32px' />
        </div>
        <p id="msg" ref={editMsgRef}></p>
      </div>
    </div>
  }

  return (
    <div className='route__ladies'>
      {
        props.newLadieModal && <div className='route__ladies__addNewModal'>
          <div id='box'>
            <h1>Add new lady</h1>
            <div id='gridbox'>
              <CustomInput ref={firstNameRef} autocomplete="off" placeholder="First name" accent="#FFF" theme="dark" type="text" style={{ width: "100%", marginBottom: '10px' }} />
              <CustomInput ref={lastNameRef} autocomplete="off" placeholder="Last name" accent="#FFF" theme="dark" type="text" style={{ width: "100%", marginBottom: '10px' }} />
              <CustomInput ref={dateOfBirthRef} autocomplete="off" placeholder="Date of birth" accent="#FFF" theme="dark" type="date" style={{ width: "100%", marginBottom: '10px' }} />
              <CustomInput ref={weightRef} autocomplete="off" placeholder="Weight" accent="#FFF" theme="dark" type="number" style={{ width: "100%", marginBottom: '10px' }} />
              <CustomInput ref={heightRef} autocomplete="off" placeholder="Height" accent="#FFF" theme="dark" type="number" style={{ width: "100%", marginBottom: '10px' }} />
              <CustomInput ref={profesionRef} autocomplete="off" placeholder="Profesion" accent="#FFF" theme="dark" type="text" style={{ width: "100%", marginBottom: '10px' }} />
              <CustomInput ref={cigarettesRef} autocomplete="off" placeholder="Smokes cigarettes" accent="#FFF" theme="dark" id='ladie-checkbox' type="checkbox" style={{ width: "100%", marginBottom: '10px' }} />
              <CustomInput ref={isPremiumLadyRef} autocomplete="off" placeholder="Is Premium Lady" accent="#FFF" theme="dark" id='ladie-checkbox' type="checkbox" style={{ width: "100%", marginBottom: '10px' }} />
              <CustomInput ref={alcoholRef} autocomplete="off" placeholder="Consumes alcohol" accent="#FFF" theme="dark" id='ladie-checkbox' type="checkbox" style={{ width: "100%", marginBottom: '10px' }} />
              <CustomInput ref={isVerifiedRef} autocomplete="off" placeholder="Is Lady Verified" accent="#FFF" theme="dark" id='ladie-checkbox' type="checkbox" style={{ width: "100%", marginBottom: '10px' }} />
              <CustomInput ref={zodiacRef} autocomplete="off" placeholder="Zodiac sign" accent="#FFF" theme="dark" type="text" style={{ width: "100%", marginBottom: '10px' }} />
              <CustomInput ref={religionRef} autocomplete="off" placeholder="Religion" accent="#FFF" theme="dark" type="text" style={{ width: "100%", marginBottom: '10px' }} />
              <CustomInput ref={kidsRef} autocomplete="off" placeholder="Kids" accent="#FFF" theme="dark" type="number" style={{ width: "100%", marginBottom: '10px' }} />
              <CustomInput ref={maritalStatusRef} autocomplete="off" placeholder="Marital status" accent="#FFF" theme="dark" type="text" style={{ width: "100%", marginBottom: '10px' }} />
              <Dropdown theme={'dark'} data={countries.map(c => {
                return { name: c.name, value: c.code }
              })} selected={countries.indexOf(countries.find(c => c.code.toLowerCase() === country.toLowerCase()))} onChange={e => setCountry(e?.value)} />
              <CustomInput ref={hairColorRef} autocomplete="off" placeholder="Hair color" accent="#FFF" theme="dark" type="text" style={{ width: "100%", marginBottom: '10px' }} />
              <CustomInput ref={cityRef} autocomplete="off" placeholder="City" accent="#FFF" theme="dark" type="text" style={{ width: "100%", marginBottom: '10px' }} />
              <CustomInput ref={bioRef} autocomplete="off" placeholder="Bio" accent="#FFF" theme="dark" type="text" style={{ width: "100%", marginBottom: '10px' }} />
              {/* <br /> */}
              <span id='languages-span'>
                {
                  languages.map((item) => {
                    return <p>{item}</p>
                  })
                }
              </span>
              <span id='interests-span'>
                {
                  interests.map((item) => {
                    return <p>{item}</p>
                  })
                }
              </span>
              <CustomInput
                ref={languagesRef}
                autocomplete="off"
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    setLanguages(prev => {
                      return [
                        ...prev,
                        languagesRef.current.value
                      ]
                    });
                    setTimeout(() => {
                      languagesRef.current.value = '';
                    }, 50);
                  }
                }}
                placeholder="Speaking languages"
                accent="#FFF"
                theme="dark"
                type="text"
                style={{ width: "100%"}}
              />
              {/* <CustomInput
                ref={interestsRef}
                autocomplete="off"
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    setInterests(prev => {
                      return [
                        ...prev,
                        interestsRef.current.value
                      ]
                    });
                    setTimeout(() => {
                      interestsRef.current.value = '';
                    }, 50);
                  }
                }}
                placeholder="Interests"
                accent="#FFF"
                theme="dark"
                type="text"
                style={{ width: "100%" }}
              /> */}
              <div style={{gridColumn: 'span 2'}} className="lookingForWrap">
                {Object.values(lookingFor).map(lf => {
                  return <div className={`lookingForWrap__item ${ladyLookingFor.includes(lf) ? "lookingForWrap__item--active" : ""}`} onClick={() => {
                    setLadyLookingFor(elf => {
                      if (elf.includes(lf)) {
                        return [...elf.filter(elfTmp => elfTmp !== lf)];
                      } else {
                        return [...elf, lf];
                      };
                    })
                  }}>{lf}</div>
                })}
              </div>

              <Button value='UPLOAD PHOTOS' accent='rgb(10, 15, 36)' className='addNewLadieBtn' onClick={(e) => openPhotosModal(e, false)} />
              <Button value={selectedDomain !== null ? selectedDomain.URL : 'SELECT DOMAIN'} accent='rgb(10, 15, 36)' className='addNewLadieBtn' onClick={(e) => openDomainsModal(e, false)} />
            </div>
            <div id='gender-select'>
              <div style={{ border: gender === 'Male' ? '1px solid white' : '1px solid rgb(46, 76, 110)', backgroundColor: 'rgb(10, 15, 36)' }} onClick={() => setGender('Male')}><p>Male</p></div>
              <div style={{ border: gender === 'Female' ? '1px solid white' : '1px solid rgb(46, 76, 110)', backgroundColor: 'rgb(10, 15, 36)' }} onClick={() => setGender('Female')}><p>Female</p></div>
              <div style={{ border: gender === 'Other' ? '1px solid white' : '1px solid rgb(46, 76, 110)', backgroundColor: 'rgb(10, 15, 36)' }} onClick={() => setGender('Other')}><p>Other</p></div>
            </div>
            <div id="controles" style={{ display: !spinner ? 'flex' : 'none' }}>
              <Button value='Cancel' accent='transparent' style={{ borderBottom: '1px solid #e53333', borderRadius: '0px', color: '#e53333' }} onClick={() => props.setNewLadieModal(false)} />
              <Button value='Add' accent='transparent' style={{ borderBottom: '1px solid #00A3FF', borderRadius: '0px', color: '#00A3FF' }} onClick={() => { addLady(props.setNewLadieModal) }} />
            </div>
            <div style={{ display: spinner ? 'flex' : 'none', flexDirection: 'row', justifyContent: 'flex-end' }}>
              <Spinner color='white' align='right' width='32px' />
            </div>
            <p id="msg" ref={msgRef}></p>
          </div>
        </div>
      }
      <DataSorter dropdownBackground='#000' dropdownStyle={{backgroundColor: '#000'}} sorters={[
        {name: 'Online', value: 'Online'},
        {name: 'Is Premium?', value: 'IsPremiumLady'},
        {name: 'Date Of Birth', value: 'DateOfBirth'},
        {name: 'First Name', value: 'FirstName'},
        {name: 'Last Name', value: 'LastName'},
        {name: 'Wight', value: 'Weight'},
        {name: 'Height', value: 'Height'},
      ]} setOrders={setOrders} />
      <FilteredCustomTable
        id='table'
        accent='#000'
        dropdownBackground='#000'
        inputBottomBorderColor='#FFF'
        placeholderStyle={{backgroundColor: '#000'}}
        theme='dark'
        filterHeadStyle={{backgroundColor: 'rgba(25, 35, 67, 0.2)'}}
        tableHeadStyle={{backgroundColor: 'rgba(25, 35, 67, 0.2)'}}
        buttonStyle={{border: '1px solid #FFF', backgroundColor: '#000'}}
        // style={{colors: index % 2 === 0 ? '#1923438e' : '#192343'}}
        style={{colors: '#000'}}
        color={'#000'}
        headers={['ID', 'Name', 'Date of birth', 'Weight', 'Height', 'Profesion', 'Zodiac sign', 'Updated at']}
        filters={[
          { name: "ID", friendlyName: "ID", type: "string" },
          { name: "FirstName", friendlyName: "First name", type: "string" },
          { name: "LastName", friendlyName: "Last name", type: "string" },
          { name: "DateOfBirth", friendlyName: "Date of birth", type: "date" },
          { name: "Weight", friendlyName: "Weight", type: "number" },
          { name: "Height", friendlyName: "Height", type: "number" },
          { name: "updatedAt", friendlyName: "Updated at", type: "date" },
        ]}
        filterCB={f => setFilters(f)}
        data={ladies.data?.length > 0 ? addLadiesToTable() : [[{ keyID: 'noData', type: 'custom', data: <p>No data to display</p> }]]}
      />
      <br></br>
      <div style={{ display: canPaginate ? 'block' : 'none' }} ref={scrollTriggerRef}></div>
      <Spinner style={{ display: loadingData ? 'block' : 'none', width: "64px", height: "64px" }} color="white" align="center" />
    </div>
  )
}
