const initialState = [];

export default (state = initialState, action) => {
    switch (action.type) {
        case "ROOMS_ADD":
            const foundRoom = (state ?? []).find(tmp => tmp.ID === action.payload.ID);
            if (foundRoom) {
                return [
                    ...(state ?? []).filter(tmp => tmp.ID < action.payload.ID),
                    action.payload,
                    ...(state ?? []).filter(tmp => tmp.ID > action.payload.ID)
                ]
            } else {
                return [
                    ...(state ?? []),
                    action.payload
                ];
            };
        case "ROOMS_REMOVE":
            return (state ?? []).filter(item => item.ID !== action.payload);
        case "ROOMS_BULK_ADD": return [...(state ?? []) ?? [], ...action.payload];
        case "ROOMS_ADD_FRONT": {
            const foundRoom = (state ?? []).find(tmp => tmp?.ID === action.payload.ID);
            if (foundRoom) {
                return state;
            } else {
                return [
                    action.payload,
                    ...state,

                ]
            }
        }
        case "ROOMS_CLEAN": return [];
        case "ROOMS_UPDATE_FRONT": {
            return state.map(element => {
                if(element.ID===action.payload.ID){
                    element.Participants=action.payload.Participants.map((item)=>{
                        return item.replace('user:', '')
                    });
                };
                return element;
            });
        }
        case "ROOMS_UPDATE_NAME": {
            return state.map(element => {
                if(element.ID===action.payload.ID){
                    element.ChatRoomName=action.payload.ChatRoomName
                }
                return element;
            });
        }
        case "ROOMS_UPDATE_MODERATION": {
            return state.map(element => {
                if(element.ID===action.payload.ID){
                    element.ModerationEnabled=action.payload.ModerationEnabled
                }
                return element;
            });
        }
        case "ROOMS_UPDATE_USERS": {
            return state.map(element => {
                let temp=[]
                if(element.ID===action.payload.ID){
                    for(let i =0; i< JSON.parse(action.payload.Participants).length; i++){
                        temp.push(JSON.parse(action.payload.Participants)[i].replace('user:',''))
                    }
                    element.Participants=temp
                };
                return element;
            });
        }
        case "ROOMS_INCREMENT_UNREAD":
            return state.map(element => {
                if (element.ID === action.payload) {
                    element.UnreadCount += 1;
                };
                return element;
            });
        case "ROOMS_CLEAR_UNREAD":
            return state.map(element => {
                if (element.ID === action.payload) {
                    element.UnreadCount = 0;
                };
                return element;
            });
        case "ROOMS_MOVE_TO_TOP":
            return state.sort((a, b) => {
                if (b.ID === Number(action.payload)) return 1;
                return -1;
            });
        case "ROOMS_UPDATE_RESPONSIBLE_PERSON":
            return (state ?? []).filter(s => {
                if (s.ID === action.payload.roomID) {
                    return false;
                };
                return true;
            }); 
        default: return state;
    };
};