import { Socket, io } from "socket.io-client";
import * as backendModule from "./backendModule";
import * as chatUsersAction from "../actions/chatUsersAction";
import * as chatRoomsAction from '../actions/chatRoomsAction';
import * as chatAction from '../actions/chatAction'
import * as timestampAction from '../actions/timestampAction';

import { createNewNotification } from "./notificationsModule";

/** @type {Socket<DefaultEventsMap, DefaultEventsMap>} */
let socket = null;
let curTimeout = null;

export const init = () => {
    if (socket) return;

    clearTimeout(curTimeout);
    curTimeout = setTimeout(() => {
        socket = io(backendModule.backendURL, {
            transports: ["websocket"],
            withCredentials: true,
            secure: true
        });

        socket.on("getAllUsers", event_getAllUsers);
        socket.on('getAllChatRooms', event_getAllChatRooms);
        socket.on("userTick", event_userTick);
        socket.on("createChatRoom", event__createChatRoom);
        socket.on("createChatRoomCpanel", event__createChatRoomCpanel);
        socket.on("createChatRoomSwitch", event__createChatRoomSwitch);
        socket.on('getAllChats', event__getAllChats);
        socket.on('receiveMessage', event__receiveMessage);
        socket.on('receiveMessageNotification', event__receiveMessageNotification);
        socket.on('receiveMessageNotificationClear', event__receiveMessageNotificationClear);
        socket.on('forceDataUpdate', event__forceDataUpdate);
        socket.on('paginateChat', event__paginateChat)
        socket.on('removeChatRoom', event__removeChatRoom)
        socket.on('changeChatRoomName', event__changeChatRoomName);
        socket.on("pingResponsiblePersonChanged", event__pingResponsiblePersonChanged);
        socket.on('changeModerationEnabled', event__changeModerationEnabled);
        
        socket.on("connect", () => {
            curTimeout = setTimeout(() => {
                //OVA 3 EMITA UZROKUJU CRASHANJE BACKENDA
                socket.emit("getAllUsers");
                socket.emit('getAllChatRooms');
                socket.emit('getAllChats');
            }, 200);
        });
    }, 200)
};

export const on = (name, cb) => {
    if (!socket) return setTimeout(() => {
        if (!socket) return;
        socket.on(name, cb);
    }, 2000);
    socket.on(name, cb);
};

export const off = (name, cb) => {
    if (!socket) return;
    socket.off(name, cb);
};

export const disconnect = () => {
    if (socket) {
        clearTimeout(curTimeout);
        socket?.removeAllListeners();
        socket?.disconnect();
        socket = null;
        backendModule.getStore().dispatch(chatUsersAction.cleanUsers());
        backendModule.getStore().dispatch(chatRoomsAction.cleanRooms());
    };
};

export const getSocket = () => socket;

export const internal__createChatRoom = (ChatRoomName, Participants) => {
    if (!socket) return;
    socket.emit('createChatRoom', {
        ChatRoomName, Participants
    });
};
export const internal__sendMessage = (ID, From, Type, Content) => {
    if (!socket) return;
    socket.emit('sendChatMessage', {
        ChatRoomID: ID, From: null, FromUserID: From, Type, Content
    });
};
export const internal__changeChatRoomName = (RoomID, ChatRoomName) => {
    if (!socket) return;
    socket.emit('changeChatRoomName', {
        ChatRoomID: RoomID, ChatRoomName
    });
};


export const internal__changeModerationEnabled = (RoomID, ModerationEnabled) => {
    if (!socket) return;
    socket.emit('changeModerationEnabled', {
        ChatRoomID: RoomID, ModerationEnabled
    });
};

export const internal__paginateChat = (ChatRoomID, ChatID) => {
    if (!socket) return;
    socket.emit('paginateChat', {
        ChatRoomID, ChatID
    });
    let cb = () => {
        backendModule.getStore().dispatch(chatAction.chatRemoveSpinner(ChatRoomID, ChatID));
        socket.off("paginateChat", cb);
    };
    socket.on("paginateChat", cb);
};
export const internal__checkAgentActivity = (UserID, UserStatus) => {
    if (!socket) return;
    socket.emit('checkAgentActivity', {
        UserID, UserStatus
    });
};
export const internal__clearChatRoomNotifications = roomID => {
    if (!socket) return;
    socket.emit("receiveMessageNotificationClear", roomID);
};

const event_getAllUsers = (users) => {
    users = backendModule.parseData(users);
    backendModule.getStore().dispatch(chatUsersAction.cleanUsers());
    backendModule.getStore().dispatch(chatUsersAction.bulkAddUsers(users));
};

const event_userTick = user => {
    user = backendModule.parseData(user);
    backendModule.getStore().dispatch(chatUsersAction.addUser(
        user.ID,
        user.FirstName,
        user.LastName,
        user.Image,
        user.LastSeen,
        user.Roles
    ));
};
const event_getAllChatRooms = (rooms) => {
    console.log("called meeee", rooms)
    rooms = backendModule.parseData(rooms);
    // backendModule.getStore().dispatch(chatRoomsAction.cleanRooms());
    backendModule.getStore().dispatch(chatRoomsAction.bulkAddRooms(rooms));
};

const event__createChatRoomSwitch = (param) => {
    param = backendModule.parseData(param);
    backendModule.getStore().dispatch(chatRoomsAction.addFrontRoom(param));
};
const event__createChatRoom = (param) => {
    param = backendModule.parseData(param);
    backendModule.getStore().dispatch(chatRoomsAction.addFrontRoom(param));
};
const event__createChatRoomCpanel = (param) => {
    param = backendModule.parseData(param);
    backendModule.getStore().dispatch(chatRoomsAction.addFrontRoom(param));
};
const event__getAllChats = (param) => {
    param = backendModule.parseData(param);
    backendModule.getStore().dispatch(chatAction.bulkAddChat(param));
};
const event__receiveMessage = (param) => {
    param = backendModule.parseData(param);
    backendModule.getStore().dispatch(chatAction.addChat(param));
    for (let key of Object.keys(param)) {
        backendModule.getStore().dispatch(chatRoomsAction.moveRoomToTop(key));
        if (Array.isArray(param[key])) {
            for (let item of param[key]) {
                createNewNotification(item.From, item.Type, item.Content, key)
                    .then(() => null)
                    .catch(console.warn);
            };
        };
    };
};
const event__receiveMessageNotification = (roomID) => {
    roomID = backendModule.parseData(roomID);
    backendModule.getStore().dispatch(chatRoomsAction.incrementUnreadCount(roomID));
};
const event__receiveMessageNotificationClear = roomID => {
    roomID = backendModule.parseData(roomID);
    backendModule.getStore().dispatch(chatRoomsAction.clearUnreadCount(roomID));
};
const event__forceDataUpdate = (param) => {
    backendModule.getStore().dispatch(timestampAction.setTimestamp());
};
const event__paginateChat = (param) => {
    param = backendModule.parseData(param);
    backendModule.getStore().dispatch(chatAction.addChat(param));
};
const event__changeChatRoomName = (param) => {
    param = backendModule.parseData(param);
    backendModule.getStore().dispatch(chatRoomsAction.updateChatRoomName(param));
};
const event__changeModerationEnabled = (param) => {
    param = backendModule.parseData(param);
    backendModule.getStore().dispatch(chatRoomsAction.updateModerationEnabled(param));
};
const event__removeChatRoom = (param) => {
    param = backendModule.parseData(param);
    backendModule.getStore().dispatch(chatRoomsAction.removeRoom(param.ID));
};
const event__pingResponsiblePersonChanged = param => {
    param = backendModule.parseData(param);
    let curID = backendModule.getStore().getState().user?.UserInfo?.ID ?? null;
    if (!param.UserID) {
        return;
    } else {
        if (curID !== param.UserID) {
            backendModule.getStore().dispatch(chatRoomsAction.updateResponsiblePerson(param.ChatRoomID, param.UserID));
        };
    };
};