import React from 'react';
import "./index.scss";

import { FilteredCustomTable } from "../../components/customComponents/Table";
import Button from '../../components/customComponents/Button';
import Spinner from '../../components/customComponents/Spinner';
import DataSorter from "../../components/customComponents/DataSorter";

import * as backendModule from "../../modules/backendModule";
import axios, { all } from "axios";
import moment from 'moment';
import { animateBox } from '../../modules/componentAnimation';
import CustomInput from '../../components/customComponents/CustomInput';

export default function Terces(props) {
  const [filters, setFilters] = React.useState([]);
  const [orders, setOrders] = React.useState([]);
  const [secondaryUsers, setSecondaryUsers] = React.useState([]);

  const getAllSecondaryUsers = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/terces/getAllUsers`,
      data: {
        offset: 0,
        filters: filters,
        orders: orders,
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === 'ok') {
        setSecondaryUsers(res.data);
      }
      else {
      }
    }).catch((err) => {
      console.log("ERROR ", err)
    });
  }


  React.useEffect(() => {
    getAllSecondaryUsers();
  }, [filters, orders]);

  const addSecondaryUsersToTable = () => {
    if (secondaryUsers.data) {
      return secondaryUsers?.data?.map((item) => {
        return [
          { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.ID}</p></div> },
          { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Email}</p></div> },
          { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Username}</p></div> },
          { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center', background: JSON.parse(item.Flags).isEmailVerified ? 'green' : 'red', width: 'fit-content', marginLeft: 'auto', marginRight: 'auto', padding: '5px', borderRadius: '10px' }}>{JSON.parse(item.Flags).isEmailVerified ? 'YES' : 'NO'}</p></div> },
          {
            keyID: String(item.ID), type: "groupNewline", group: [
              { keyID: String(item.ID), type: "custom", data: <div id='table-country'><p>Country:</p> <strong>{item.Country !== '' ? item.Country : '-ND-'}</strong> <p>Date created</p><strong>{(new Date(item.createdAt).toLocaleString())}</strong> <p>Last Login</p><strong>{(new Date(item.LastLogin).toLocaleString())}</strong></div> },
            ]
          },
        ].filter(t => t);
      });
    }
  }
  

  return (
    <div className='route__secondaryUsers'>
      <DataSorter sorters={[
        {name: 'Credits', value: 'Credits'},
        {name: 'First Name', value: 'FirstName'},
        {name: 'Last Name', value: 'LastName'},
        {name: 'Birthday', value: 'Birthday'},
        {name: 'Country', value: 'Country'},
        {name: 'Last Login', value: 'LastLogin'},
        {name: 'Level', value: 'Level'},
      ]} setOrders={setOrders} />
      <FilteredCustomTable
        id='table'
        accent='#000'
        dropdownBackground='#000'
        inputBottomBorderColor='#FFF'
        placeholderStyle={{backgroundColor: '#000'}}
        theme='dark'
        filterHeadStyle={{backgroundColor: 'rgba(25, 35, 67, 0.2)'}}
        tableHeadStyle={{backgroundColor: 'rgba(25, 35, 67, 0.2)'}}
        buttonStyle={{border: '1px solid #FFF', backgroundColor: '#000'}}
        // style={{colors: index % 2 === 0 ? '#1923438e' : '#192343'}}
        style={{colors: '#000'}}
        color={'#000'}
        headers={['ID', 'Email', 'Username', 'Verified']}
        filters={[
          { name: "ID", friendlyName: "ID", type: "string" },
          { name: "Username", friendlyName: "Username", type: "string" },
          { name: "Email", friendlyName: "Email", type: "string" },
          { name: "Country", friendlyName: "Country", type: "string" },
          { name: "createdAt", friendlyName: "Created date", type: "date" },
          { name: "LastLogin", friendlyName: "Last login date", type: "date" }
        ]}
        filterCB={f => setFilters(f)}
        data={secondaryUsers.data?.length > 0 ? addSecondaryUsersToTable() : [[{ keyID: 'noData', type: 'custom', data: <p>No data to display</p> }]]}
      />
    </div>
  )
}


