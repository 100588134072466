import React from 'react';
import "./index.scss";

import * as backendModule from "../../modules/backendModule";
import axios, { all } from "axios";
import moment from 'moment';

import CustomInput from '../../components/customComponents/CustomInput';
import Button from '../../components/customComponents/Button';
import Spinner from '../../components/customComponents/Spinner';
import { FilteredCustomTable } from "../../components/customComponents/Table";

import { animateBox } from "../../modules/componentAnimation";

export default function EmailServers(props) {
    const [filters, setFilters] = React.useState([]);
    const [spinner, setSpinner] = React.useState(false);

    const getAllEmailServers = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/emailServer/getAllEmailServers`,
            data: {
                filters: filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                props.setEmailServers(res.data);
            }
            else {

            }
        }).catch(() => {

        });
    }

    React.useEffect(() => {
        getAllEmailServers();
    }, [filters]);

    const addEmailServersToTable = () => {
        if (props.emailServers.data) {
            return props.emailServers?.data?.map((item) => {
                return [
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.DomainID}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Name}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Host}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Port}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Username}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center', background: item.isSSL ? 'green' : 'red', width: 'fit-content', marginLeft: 'auto', marginRight: 'auto', padding: '5px', borderRadius: '10px' }}>{item.isSSL ? 'YES' : 'NO'}</p></div> },
                    {
                        keyID: String(item.ID), type: "groupNewline", group: [
                            {
                                keyID: String(item.ID), type: "button", text: "Delete", triggerDropdown: true, triggerData: e => {
                                    return (<div style={{ display: 'flex', flexDirection: 'column', justfyContent: 'center', alignItems: 'center' }}>
                                        <p style={{ color: 'white' }}>Are you sure?</p>
                                        <br></br>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Button style={{ display: spinner ? 'none' : 'block', marginRight: '10px' }} accent='rgb(72, 82, 92)' value='YES' onClick={() => deleteEmailServer(item.ID)} />
                                            <Button style={{ display: spinner ? 'none' : 'block' }} accent='rgb(72, 82, 92)' value='NO' onClick={c => { e() }} />
                                            <div style={{ display: spinner ? 'block' : 'none' }}><Spinner color='white' width='32px' /></div>
                                        </div>
                                    </div>);
                                },
                            },
                            {
                                keyID: String(item.ID), type: "button", text: "Edit", triggerDropdown: true, onClick: e => {
                                    animateBox(e, <EditModal curServer={
                                        {
                                            ID: item.ID,
                                            Name: item.Name,
                                            Host: item.Host,
                                            Port: item.Port,
                                            Username: item.Username,
                                            IsSSL: item.isSSL,
                                            DomainID: item.DomainID
                                        }
                                    } />)
                                }
                            }
                        ]
                    },
                ];
            });
        }
    }

    const deleteEmailServer = (id) => {
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/emailServer/removeEmailServer`,
            data: {
                ID: id
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                getAllEmailServers();
            }
            else {

            }
        }).catch(() => {

        }).finally(() => {
            setSpinner(false);
        });
    }

    const EditModal = (props) => {
        const msgRef = React.useRef(null);
        const newNameRef = React.useRef(null);
        const newHostRef = React.useRef(null);
        const newPortRef = React.useRef(null);
        const newIsSSLcheckboxRef = React.useRef(null);
        const newUsernameRef = React.useRef(null);
        const [newSelectedDomain, setNewSelectedDomain] = React.useState(null);

        React.useEffect(() => {
            if(props.curServer.IsSSL == true){
                newIsSSLcheckboxRef.current.checked = true;
            }
            else{
                newIsSSLcheckboxRef.current.checked = false;
            }
        }, [props.curServer.IsSSL]);

        const openDomainsModal = (e, isEdit, setNewSelectedDomain) => {
            animateBox(e, <SelectDomainModal isEdit={isEdit} setNewSelectedDomain={setNewSelectedDomain} />);
        }

        const SelectDomainModal = (props) => {
            const [filters, setFilters] = React.useState(false);
            const [domains, setDomains] = React.useState(false);

            const getAllDomains = () => {
                axios({
                    method: "POST",
                    url: `${backendModule.backendURL}/domains/getAllDomains`,
                    data: {
                        filters: filters
                    },
                    ...backendModule.axiosConfig
                }).then(res => {
                    if (res.data.status === 'ok') {
                        setDomains(res.data);
                    }
                    else {
                        setDomains('GET_ALL_DOMAINS_ERROR');
                    }
                }).catch(() => {
                    setDomains('GET_ALL_DOMAINS_ERROR');
                });
            }

            React.useEffect(() => {
                getAllDomains();
            }, [filters]);

            const addDomainsToTable = () => {
                if (domains.data) {
                    return domains?.data?.map((item) => {
                        return [
                            { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.ID}</p></div> },
                            { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Domain}</p></div> },
                            { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.WebsiteName}</p></div> },
                            { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center', background: item.forOffer ? 'green' : 'red', width: 'fit-content', marginLeft: 'auto', marginRight: 'auto', padding: '5px', borderRadius: '10px' }}>{item.forOffer ? 'YES' : 'NO'}</p></div> },
                            { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{moment(item.updatedAt).format('DD.MM.YYYY.')}</p></div> },
                            {
                                keyID: String(item.ID), type: "groupNewline", group: [
                                    {
                                        keyID: String(item.ID), type: "button", text: "Select", triggerDropdown: true, onClick: e => {
                                            setNewSelectedDomain({ ID: item.ID, URL: item.Domain });
                                            props.onClose();
                                        }
                                    }
                                ]
                            }
                        ];
                    });
                }
            }

            return <div className='route__emailServers__addNewModal__selectDomainModal'>
                <div id='box'>
                    <div id='table'>
                        <FilteredCustomTable
                            id='table'
                            accent='#48525C'
                            theme='dark'
                            headers={['ID', 'Domain', 'Website name', 'For offer', 'Created at']}
                            filters={[
                                { name: "ID", friendlyName: "ID", type: "number" },
                                { name: "Domain", friendlyName: "Domain", type: "string" },
                                { name: "WebsiteName", friendlyName: "Website name", type: "string" },
                                { name: "updatedAt", friendlyName: "Date", type: "date" },
                            ]}
                            filterCB={f => setFilters(f)}
                            data={domains.data?.length > 0 ? addDomainsToTable() : [[{ keyID: 'noData', type: 'custom', data: <p>No data to display</p> }]]}
                        />
                    </div>
                    <div id="controles">
                        <Button value='Cancel' accent='transparent' style={{ borderBottom: '1px solid #e53333', borderRadius: '0px', color: '#e53333' }} onClick={props.onClose} />
                    </div>
                </div>
            </div>
        }

        const saveEmailServer = (close) => {
            if(
                !newNameRef.current.value ||
                !newHostRef.current.value ||
                !newPortRef.current.value ||
                !newUsernameRef.current.value
            ){
                return msgRef.current.innerText = 'Info missing';
            }

            setSpinner(true);
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/emailServer/editEmailServer`,
                data: {
                    ID: props.curServer.ID,
                    Name: newNameRef.current.value,
                    DomainID: newSelectedDomain !== null ? newSelectedDomain.ID : props.curServer.DomainID,
                    Host: newHostRef.current.value,
                    Port: newPortRef.current.value,
                    Username: newUsernameRef.current.value,
                    isSSL: newIsSSLcheckboxRef.current.checked ? true : false
                },
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === 'ok') {
                    getAllEmailServers();
                    close();
                }
                else {

                }
            }).catch(() => {

            }).finally(() => {
                setSpinner(false);
            });
        }

        return <div className='route__emailServers__editModal'>
            <div id='box'>
                <h1>Edit {props.curServer.Name}</h1>

                <CustomInput autocomplete="off" defaultValue={props.curServer.Name} placeholder="Name" ref={newNameRef} accent="#FFF" theme="dark" type="text" style={{ width: "100%", marginBottom: '25px' }} />
                <CustomInput autocomplete="off" defaultValue={props.curServer.Host} placeholder="Host" ref={newHostRef} accent="#FFF" theme="dark" type="text" style={{ width: "100%", marginBottom: '25px' }} />
                <CustomInput autocomplete="off" defaultValue={props.curServer.Port} placeholder="Port" ref={newPortRef} accent="#FFF" theme="dark" type="number" style={{ width: "100%", marginBottom: '25px' }} />
                <CustomInput autocomplete="off" defaultValue={props.curServer.Username} placeholder="Username" ref={newUsernameRef} accent="#FFF" theme="dark" type="text" style={{ width: "100%", marginBottom: '25px' }} />
                <CustomInput autocomplete="off" placeholder="SSL" ref={newIsSSLcheckboxRef} accent="#FFF" theme="dark" type="checkbox" style={{ width: "100%", marginBottom: '25px' }} />
                <Button value={newSelectedDomain === null ? 'CHANGE DOMAIN' : newSelectedDomain.URL} accent='rgb(72, 82, 92)' className='selectDomainButton' onClick={(e) => openDomainsModal(e, false)} />

                <div id="controles" style={{ display: !spinner ? 'flex' : 'none' }}>
                    <Button value='Cancel' accent='transparent' style={{ borderBottom: '1px solid #e53333', borderRadius: '0px', color: '#e53333' }} onClick={props.onClose} />
                    <Button value='Save' accent='transparent' style={{ borderBottom: '1px solid #00A3FF', borderRadius: '0px', color: '#00A3FF' }} onClick={() => saveEmailServer(props.onClose)} />
                </div>
                <div style={{ display: spinner ? 'flex' : 'none', flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <Spinner color='white' align='right' width='32px' />
                </div>
                <p id="msg" ref={msgRef}></p>
            </div>
        </div>
    }

    return (
        <div className='route__emailServers'>
            <FilteredCustomTable
                id='table'
                accent='#000'
                theme='dark'
                filterHeadStyle={{backgroundColor: 'rgba(25, 35, 67, 0.2)'}}
                tableHeadStyle={{backgroundColor: 'rgba(25, 35, 67, 0.2)'}}
                headers={['Domain ID', 'Name', 'Host', 'Port', 'Username', 'SSL']}
                filters={[
                    { name: "DomainID", friendlyName: "Domain ID", type: "string" },
                    { name: "Username", friendlyName: "Username", type: "string" },
                    { name: "Host", friendlyName: "Host", type: "string" },
                    { name: "Port", friendlyName: "Port", type: "number" },
                    { name: "isSSL", friendlyName: "SSL", type: "boolean" },
                ]}
                filterCB={f => setFilters(f)}
                data={props.emailServers.data?.length > 0 ? addEmailServersToTable() : [[{ keyID: 'noData', type: 'custom', data: <p>No data to display</p> }]]}
            />
        </div>
    )
}

const AddNewModal = (props) => {
    const [selectedDomain, setSelectedDomain] = React.useState(null);
    const nameRef = React.useRef(null);
    const hostRef = React.useRef(null);
    const portRef = React.useRef(null);
    const usernameRef = React.useRef(null);
    const passwordRef = React.useRef(null);
    const isSSLcheckboxRef = React.useRef(null);

    const msgRef = React.useRef(null);
    const [spinner, setSpinner] = React.useState(false);

    const openDomainsModal = (e, isEdit, setNewSelectedDomain) => {
        animateBox(e, <SelectDomainModal isEdit={isEdit} setNewSelectedDomain={setNewSelectedDomain} />);
    }

    const SelectDomainModal = (props) => {
        const [filters, setFilters] = React.useState(false);
        const [domains, setDomains] = React.useState(false);

        const getAllDomains = () => {
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/domains/getAllDomains`,
                data: {
                    filters: filters
                },
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === 'ok') {
                    setDomains(res.data);
                }
                else {
                    setDomains('GET_ALL_DOMAINS_ERROR');
                }
            }).catch(() => {
                setDomains('GET_ALL_DOMAINS_ERROR');
            });
        }

        React.useEffect(() => {
            getAllDomains();
        }, [filters]);

        const addDomainsToTable = () => {
            if (domains.data) {
                return domains?.data?.map((item) => {
                    return [
                        { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.ID}</p></div> },
                        { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Domain}</p></div> },
                        { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.WebsiteName}</p></div> },
                        { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center', background: item.forOffer ? 'green' : 'red', width: 'fit-content', marginLeft: 'auto', marginRight: 'auto', padding: '5px', borderRadius: '10px' }}>{item.forOffer ? 'YES' : 'NO'}</p></div> },
                        { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{moment(item.updatedAt).format('DD.MM.YYYY.')}</p></div> },
                        {
                            keyID: String(item.ID), type: "groupNewline", group: [
                                {
                                    keyID: String(item.ID), type: "button", text: "Select", triggerDropdown: true, onClick: e => {
                                        setSelectedDomain({ ID: item.ID, URL: item.Domain });
                                        props.onClose();
                                    }
                                }
                            ]
                        }
                    ];
                });
            }
        }

        return <div className='route__emailServers__addNewModal__selectDomainModal'>
            <div id='box'>
                <div id='table'>
                    <FilteredCustomTable
                        id='table'
                        accent='#48525C'
                        theme='dark'
                        headers={['ID', 'Domain', 'Website name', 'For offer', 'Created at']}
                        filters={[
                            { name: "ID", friendlyName: "ID", type: "number" },
                            { name: "Domain", friendlyName: "Domain", type: "string" },
                            { name: "WebsiteName", friendlyName: "Website name", type: "string" },
                            { name: "updatedAt", friendlyName: "Date", type: "date" },
                        ]}
                        filterCB={f => setFilters(f)}
                        data={domains.data?.length > 0 ? addDomainsToTable() : [[{ keyID: 'noData', type: 'custom', data: <p>No data to display</p> }]]}
                    />
                </div>
                <div id="controles">
                    <Button value='Cancel' accent='transparent' style={{ borderBottom: '1px solid #e53333', borderRadius: '0px', color: '#e53333' }} onClick={props.onClose} />
                </div>
            </div>
        </div>
    }

    const getAllEmailServers = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/emailServer/getAllEmailServers`,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                props.setEmailServers(res.data);
            }
            else {

            }
        }).catch(() => {

        });
    }

    const addEmailServer = (close) => {
        if(!nameRef.current.value){
            return msgRef.current.innerText = 'Name missing!';
        }

        if(!hostRef.current.value || !portRef.current.value){
            return msgRef.current.innerText = 'Host or port missing!';
        }

        if(!usernameRef.current.value || !passwordRef.current.value){
            return msgRef.current.innerText = 'Username or password missing!';
        }

        if(!selectedDomain){
            return msgRef.current.innerText = 'Please select domain!';
        }

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/emailServer/addEmailServer`,
            data: {
                Name: nameRef.current.value,
                Host: hostRef.current.value,
                Port: portRef.current.value,
                Username: usernameRef.current.value,
                Password: passwordRef.current.value,
                isSSL: isSSLcheckboxRef.current.checked ? true : false,
                DomainID: selectedDomain.ID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                getAllEmailServers();
                close();
            }
            else {
                msgRef.current.innerText = res.data.data;
            }
        }).catch(() => {
            msgRef.current.innerText = 'Something went wrong';
        });
    }

    return <div className='route__emailServers__addNewModal'>
        <div id='box'>
            <h1>Add new email server</h1>

            <CustomInput autocomplete="off" placeholder="Name" ref={nameRef} accent="#FFF" theme="dark" type="text" style={{ width: "100%", marginBottom: '25px' }} />
            <CustomInput autocomplete="off" placeholder="Host" ref={hostRef} accent="#FFF" theme="dark" type="text" style={{ width: "100%", marginBottom: '25px' }} />
            <CustomInput autocomplete="off" placeholder="Port" ref={portRef} accent="#FFF" theme="dark" type="number" style={{ width: "100%", marginBottom: '25px' }} />
            <CustomInput autocomplete="off" placeholder="Username" ref={usernameRef} accent="#FFF" theme="dark" type="text" style={{ width: "100%", marginBottom: '25px' }} />
            <CustomInput autocomplete="off" placeholder="Password" ref={passwordRef} accent="#FFF" theme="dark" type="password" style={{ width: "100%", marginBottom: '25px' }} />
            <CustomInput autocomplete="off" placeholder="SSL" ref={isSSLcheckboxRef} accent="#FFF" theme="dark" type="checkbox" style={{ width: "100%", marginBottom: '25px' }} />
            <Button value={selectedDomain === null ? 'SELECT DOMAIN' : selectedDomain.URL} accent='rgb(72, 82, 92)' className='selectDomainButton' onClick={(e) => openDomainsModal(e, false)} />

            <div id="controles" style={{ display: !spinner ? 'flex' : 'none' }}>
                <Button value='Cancel' accent='transparent' style={{ borderBottom: '1px solid #e53333', borderRadius: '0px', color: '#e53333' }} onClick={props.onClose} />
                <Button value='Add' accent='transparent' style={{ borderBottom: '1px solid #00A3FF', borderRadius: '0px', color: '#00A3FF' }} onClick={() => addEmailServer(props.onClose)} />
            </div>
            <div style={{ display: spinner ? 'flex' : 'none', flexDirection: 'row', justifyContent: 'flex-end' }}>
                <Spinner color='white' align='right' width='32px' />
            </div>
            <p id="msg" ref={msgRef}></p>
        </div>
    </div>
}

export { AddNewModal }