import React from "react";
import "./index.scss";

import axios from "axios";
import { useSelector } from "react-redux";
import * as backendModule from "../../modules/backendModule";

import useDefer from "../../modules/hooks/useDefer";
import useOnScreen from "../../modules/hooks/useOnScreen";

import { FilteredCustomTable } from "../../components/customComponents/Table";
import Spinner from "../../components/customComponents/Spinner";
import DataSorter from "../../components/customComponents/DataSorter";

const TransactionHistory = () => {
    const [data, setData] = React.useState();
    const [canPaginate, setCanPaginate] = React.useState(false);
    const [spinner, setSpinner] = React.useState(false);
    const [filters, setFilters] = React.useState([]);
    const [orders, setOrders] = React.useState([]);

    const curOnScreen = useOnScreen();
    const curDefer = useDefer();

    const currentTimestampRef = React.useRef();

    const paymentStatusCodesSelector = useSelector(state => state?.user?.PaymentStatusCodes ?? {});

    const getData = (ts) => {
        if (canPaginate) setCanPaginate(false);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/payment/getAllTransactions`,
            data: {
                offset: 0,
                limit: 20,
                filters,
                orders
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (ts !== currentTimestampRef.current) return;

            setData(res.data);
            if (res.data.status === "ok") {
                if (res.data.data.length === 20 && !canPaginate) setCanPaginate(true);
            };
        }).catch(() => {
            setData({status: "error", data: "SERVER_ERROR"});
        });
    };

    const continueData = (ts) => {
        if (canPaginate) setCanPaginate(false);

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/payment/getAllTransactions`,
            data: {
                offset: 0,
                limit: 20,
                filters: [
                    ...filters,
                    {name: "ID", op: "notIn", value: data.data.map(d => d.ID)}
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (ts !== currentTimestampRef.current) return;

            if (res.data.status === "ok") {
                if (res.data.data.length === 20 && !canPaginate) setCanPaginate(true);
                setData(d => {
                    return {
                        ...d,
                        data: [
                            ...d.data,
                            ...res.data.data
                        ]
                    };
                });
            };
        }).catch(() => {
            setData({status: "error", data: "SERVER_ERROR"});
        }).finally(() => {
            setSpinner(false);
        });
    };

    React.useEffect(() => {
        curDefer(() => {
            if (!canPaginate) return;
            if (!curOnScreen.isIntersecting) return;
    
            try {
                curOnScreen.observer.unobserve(curOnScreen.measureRef.current);
            } catch {};

            const ts = Date.now();
            currentTimestampRef.current = ts;

            continueData(ts);
        }, 500);
    }, [curOnScreen.isIntersecting, canPaginate, data]);

    React.useEffect(() => {
        let ts = Date.now();
        currentTimestampRef.current = ts;
        getData(ts);
    }, [filters, orders]);

    return <div className="route__transactionHistory">
        <DataSorter sorters={[
            {name: 'Amount', value:'PaymentAmount'},
            {name: 'Status', value:'PaymentStatus'},
            {name: 'Quantity', value:'PaymentQuantity'},
            {name: 'Created at', value:'createdAt'},
            {name: 'Updated at', value:'updatedAt'}
        ]} setOrders={setOrders} />
        <FilteredCustomTable
            filterCB={c => setFilters(c)}
            id='table'
            accent='#000'
            dropdownBackground='#000'
            inputBottomBorderColor='#FFF'
            placeholderStyle={{backgroundColor: '#000'}}
            theme='dark'
            filterHeadStyle={{backgroundColor: 'rgba(25, 35, 67, 0.2)'}}
            tableHeadStyle={{backgroundColor: 'rgba(25, 35, 67, 0.2)'}}
            buttonStyle={{border: '1px solid #FFF', backgroundColor: '#000'}}
            // style={{colors: index % 2 === 0 ? '#1923438e' : '#192343'}}
            style={{colors: '#000'}}
            color={'#000'}
            headers={["ID", "Type", "Amount", "Quantity", "Total", "Status", "Date"]}
            filters={[
                {name: "ID", friendlyName: "ID", type: "string"},
                {name: "PaymentID", friendlyName: "Payment ID (integration)", type: "string"},
                {name: "UserID", friendlyName: "User ID", type: "string"},
                {name: "DomainID", friendlyName: "Domain ID", type: "string"},
                {name: "PaymentArticleType", friendlyName: "Article type", type: "custom", varType: "string", data: [
                    {text: "Package", value: "package"},
                    {text: "Gift card", value: "gift card"}
                ]},
                {name: "PaymentArticleID", friendlyName: "Article ID", type: "string"},
                {name: "PaymentAmount", friendlyName: "Amount", type: "number"},
                {name: "PaymentQuantity", friendlyName: "Quantity", type: "number"},
                {name: "PaymentCurrency", friendlyName: "Currency", type: "string"},
                {name: "PaymentProvider", friendlyName: "Payment provider", type: "string"},
                {name: "PaymentStatus", friendlyName: "Payment status", type: "custom", varType: "number", data: Object.keys(paymentStatusCodesSelector).map(key => {
                    return {text: paymentStatusCodesSelector[key], value: key}
                })},
                {name: "AdditionalData:by-request-sn", friendlyName: "Fibonatix ID (BRS)", type: "string"}
            ]}
            data={(()=>{
                let out = [];

                if (!data) return [[{keyID: "noData-spinner", type: "spinner"}]];
                if (data.status === "error") return [[{keyID: "noData-error", type: "text", text: "Error while fetching data"}]];

                for (let item of data.data) {
                    out.push([
                        {keyID: item.ID, type: "text", text: item.ID},
                        {keyID: item.ID, type: "text", text: item.PaymentArticleType},
                        {keyID: item.ID, type: "text", text: item.PaymentAmount + ` ${item.PaymentCurrency}`},
                        {keyID: item.ID, type: "text", text: item.PaymentQuantity},
                        {keyID: item.ID, type: "text", text: `${Number(item.PaymentAmount) * Number(item.PaymentQuantity)} ${item.PaymentCurrency}`},
                        {keyID: item.ID, type: "text", text: paymentStatusCodesSelector[item.PaymentStatus], style:{color: (()=>{
                            if (item.PaymentStatus >= 100) return "#3df73d";
                            if (item.PaymentStatus >= 90) return "#ff7d7d";
                            return "yellow";
                        })()}},
                        {keyID: item.ID, type: "text", text: (new Date(item.createdAt).toLocaleString())},
                        {keyID: item.ID, type: "groupNewline", group: [
                            {keyID: item.ID, type: "custom", data: <TransactionHistoryItem key={item.ID} item={item} />}
                        ]}
                    ])
                };

                if (spinner) out.push([{keyID: "data-spinner", type: "spinner"}]);
                if (out.length === 0) out.push([{keyID: "noData-noData", type: "text", text: "Nothing to show..."}]);

                return out;
            })()}
        />
        {(canPaginate && data?.status === "ok") && <div style={{width: "1px", height: "1px"}} ref={curOnScreen.measureRef}></div>}
    </div>
};

const TransactionHistoryItem = props => {
    const [articleData, setArticleData] = React.useState();

    const getGiftCard = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/giftCards/getAllGiftCardsCpanel`,
            data: {
                limit: 1,
                offset: 0,
                filters: [
                    {name: "ID", op: "eq", value: props.item.PaymentArticleID}
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (res.data.data.length !== 1) return setArticleData({status: "error", data: "SERVER_ERROR"});
                setArticleData({status: "ok", data: {
                    Name: res.data.data[0].Name,
                    Type: res.data.data[0].Color
                }});
            } else {
                setArticleData(res.data);
            };
        }).catch(() => {
            setArticleData({status: "error", data: "SERVER_ERROR"});
        });
    };

    const getPackage = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/packages/getAllPackages`,
            data: {
                limit: 1,
                offset: 0,
                filters: [
                    {name: "ID", op: "eq", value: props.item.PaymentArticleID}
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (res.data.data.length !== 1) return setArticleData({status: "error", data: "SERVER_ERROR"});

                return setArticleData({status: "ok", data: {
                    Name: res.data.data[0].Name,
                    Type: res.data.data[0].PackageType
                }});
            } else {
                setArticleData(res.data);
            };
        }).catch(() => {
            setArticleData({status: "error", data: "SERVER_ERROR"});
        });
    };

    const prepareAdditionalData = ad => {
        let tmp = {};
        try {
            tmp = JSON.parse(ad);
        } catch {
            tmp = {};
        };

        let out = [];

        for (let key of Object.keys(tmp)) {
            out.push(<p>Additional-{key}</p>);
            out.push(<strong>{tmp[key]}</strong>)
        };

        return out;
    };

    React.useEffect(() => {
        switch (props.item.PaymentArticleType) {
            case "gift card": return getGiftCard();
            case "package": return getPackage();
            default: break;
        };
    }, [props.item.ID]);

    return <div className="route__transactionHistory__item">
        {articleData ? <>
            {articleData.status === "ok" ? <>
                <div className="route__transactionHistory__item__pills">
                    <p>Name:</p>
                    <strong>{articleData.data.Name}</strong>

                    <p>Type:</p>
                    <strong>{articleData.data.Type}</strong>

                    <p>UserID</p>
                    <strong>{props.item.UserID}</strong>

                    <p>DomainID</p>
                    <strong>{props.item.DomainID}</strong>

                    <p>Payment provider</p>
                    <strong>{props.item.PaymentProvider}</strong>

                    {prepareAdditionalData(props.item.AdditionalData)}
                </div>
            </> : <p>Can't get fetch data!</p>}
        </> : <Spinner style={{width: "24px", height: "24px"}} color="white" />}
    </div>
};

export default TransactionHistory;