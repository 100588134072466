import React from "react";
import "./index.scss";

import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import * as backendModule from "../../modules/backendModule";
import * as timestmapActions from "../../actions/timestampAction";

import useDefer from "../../modules/hooks/useDefer";
import useOnScreen from "../../modules/hooks/useOnScreen";

import Button from "../../components/customComponents/Button";
import CustomInput from "../../components/customComponents/CustomInput";
import { FilteredCustomTable } from "../../components/customComponents/Table";
import Dropdown from "../../components/customComponents/Dropdown";
import Spinner from "../../components/customComponents/Spinner";
import DataSorter from "../../components/customComponents/DataSorter";
import moment from 'moment';
import { animateBox } from "../../modules/componentAnimation";

const Banners = () => {
  const [data, setData] = React.useState();
  const [canPaginate, setCanPaginate] = React.useState(false);
  const [spinner, setSpinner] = React.useState(false);
  const [filters, setFilters] = React.useState([]);
  const [orders, setOrders] = React.useState([]);

  const curOnScreen = useOnScreen();
  const curDefer = useDefer();
  const timestampSelector = useSelector(state => state?.timestamp ?? null);

  const currentTimestampRef = React.useRef();

  const getAllBanners = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/banners/getAllBannersCpanel`,
      data: {
        filters: filters,
        orders: orders
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === 'ok') {
        setData(res.data);
      }
    }).catch(() => {
    });
  }

  const continueData = (ts) => {
    if (canPaginate) setCanPaginate(false);

    setSpinner(true);
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/banners/getAllBannersCpanel`,
      data: {
        offset: 0,
        limit: 20,
        filters: [
          ...filters,
          {name: "ID", op: "notIn", value: data.data.map(d => d.ID)}
        ]
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (ts !== currentTimestampRef.current) return;

      if (res.data.status === "ok") {
        if (res.data.data.length === 20 && !canPaginate) setCanPaginate(true);
        setData(d => {
          return {
            ...d,
            data: [
              ...d.data,
              ...res.data.data
            ]
          };
        });
      };
    }).catch(() => {
      setData({status: "error", data: "SERVER_ERROR"});
    }).finally(() => {
      setSpinner(false);
    });
  };

  React.useEffect(() => {
    curDefer(() => {
      if (!canPaginate) return;
      if (!curOnScreen.isIntersecting) return;

      try {
        curOnScreen.observer.unobserve(curOnScreen.measureRef.current);
      } catch {};

      const ts = Date.now();
      currentTimestampRef.current = ts;

      continueData(ts);
    }, 500);
  }, [curOnScreen.isIntersecting, canPaginate, data]);

  React.useEffect(() => {
    let ts = Date.now();
    currentTimestampRef.current = ts;
    getAllBanners(ts);
  }, [filters, timestampSelector, orders]);

  return (
    <div className='route__banners'>
      <DataSorter sorters={[
        {name: 'Price', value:'Price'},
        {name: 'Type', value:'Type'},
        {name: 'Created at', value:'createdAt'},
        {name: 'Updated at', value:'updatedAt'}
      ]} setOrders={setOrders} />
      <FilteredCustomTable
        id='table'
        accent='#48525C'
        theme='dark'
        headers={['ID', 'Price', 'Image', 'Type', 'Domain ID', 'Updated at']}
        filters={[
          { name: "ID", friendlyName: "ID", type: "string" },
          { name: "Price", friendlyName: "Price", type: "number" },
          { name: "Image", friendlyName: "Image", type: "string" },
          { name: "DomainID", friendlyName: "Domain ID", type: "string" },
          { name: "updatedAt", friendlyName: "Updated at", type: "string" }
        ]}
        filterCB={f => setFilters(f)}
        data={(()=>{
          let out = [];

          if (!data) return [[{keyID: "noData-spinner", type: "spinner"}]];
          if (data.status === "error") return [[{keyID: "noData-error", type: "text", text: "Error while fetching data"}]];

          for (let item of data.data) {
            out.push([
              { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.ID}</p></div> },
              { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Price}</p></div> },
              { keyID: String(item.ID), type: "custom", data: <div className='view__banners__image'><a href={`${item.Image}`} target='_blank' style={{ textAlign: 'center', width:'100%'}}>View Image</a></div> },
              { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Type}</p></div> },
              { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.DomainID}</p></div> },
              { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{moment(item.updatedAt).format('DD.MM.YYYY.')}</p></div> },
              {keyID: item.ID, type: "groupNewline", group: [
                {keyID: item.ID, type: "button", text: "Delete", triggerDropdown: true, triggerData: c => <RemoveBanner ID={item.ID} onClose={c} onChange={() => {
                  let ts = Date.now();
                  currentTimestampRef.current = ts;
                  getAllBanners(ts);
                }} />},
                {keyID: item.ID, type: "button", text: "Edit", triggerDropdown: true, triggerData: c => <EditModal curBanner={
                  {
                    ID: item.ID,
                    Price: item.Price,
                    Image: item.Image,
                    DomainID: item.DomainID,
                  }
                } ID={item.ID} onClose={c} onChange={() => {
                  let ts = Date.now();
                  currentTimestampRef.current = ts;
                  getAllBanners(ts);
                }} />}
              ]}
            ])
          };

          if (spinner) out.push([{keyID: "data-spinner", type: "spinner"}]);
          if (out.length === 0) out.push([{keyID: "noData-noData", type: "text", text: "Nothing to show..."}]);

          return out;
        })()}
      />
      {canPaginate && <div ref={curOnScreen.measureRef} style={{ width: '1px', height: '1px', opacity: '0' }}></div>}
    </div>
  )
};

const RemoveBanner = props => {
  const [spinner, setSpinner] = React.useState(false);
  const [infoP, setInfoP] = React.useState("");

  const deleteBanner = () => {
    setSpinner(true);
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/banners/removeBanner`,
      data: {
        ID: props.ID
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        props.onClose().then(() => {
          props.onChange();
        })
      } else {
        setInfoP("There was an error fetching the data..");
      };
    }).catch(() => {
      setInfoP("Server timed out");
    }).finally(() => {
      setSpinner(false);
    });
  }

  if (spinner) return <Spinner color="white" />
  if (infoP) return <>
    {infoP}
    <Button style={{  }} accent='rgb(72, 82, 92)' value='Close' onClick={props.onClose} />
  </>;

  return <div className="route__buildABitch__removeBitch">
    <p>Are you sure?</p>
    <Button style={{ marginRight: '10px' }} accent='rgb(72, 82, 92)' value='YES' onClick={() => deleteBanner()} />
    <Button style={{  }} accent='rgb(72, 82, 92)' value='NO' onClick={props.onClose} />
  </div>
};

const AddBanner = props => {
  const [spinner, setSpinner] = React.useState(false);
  const [infoP, setInfoP] = React.useState("");

  const [selectedDomain, setSelectedDomain] = React.useState(null);
  const [selectedImage, setSelectedImage] = React.useState(null);
  const msgRef = React.useRef(null);
  const [type, setType] = React.useState(false);

  const priceRef = React.useRef(null);

  const curDispatch = useDispatch();

  const addBanner = (close) => {
    if(spinner) return;
    setSpinner(true)
    setInfoP("");

    if(!priceRef.current.value){
      setSpinner(false);
      return msgRef.current.innerText = 'Price missing!';
    }
    if(!type){
      setSpinner(false);
      return msgRef.current.innerText = 'Type missing!';
    }
    if(!selectedDomain){
      setSpinner(false);
      return msgRef.current.innerText = 'Domain missing!';
    }

    let imgFile = new FormData();
    imgFile.append('image', selectedImage);
    imgFile.append('ImageName', selectedImage.name);
    imgFile.append('tag', 'logo-img');
    
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/images/uploadImage`,
      data: imgFile,
      ...backendModule.axiosConfig,
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then(imgres => {
      axios({
        method: "POST",
        url: `${backendModule.backendURL}/banners/addBanner`,
        data: {
          Price: priceRef.current.value,
          Image: imgres.data.data,
          DomainID: selectedDomain.ID,
          Type: type,
        },
        ...backendModule.axiosConfig
      }).then(res => {
        if (res.data.status === "ok") {
          curDispatch(timestmapActions.setTimestamp());
          props.onClose();
        } else {
          setInfoP("Error while fetching the data..");
        }
      }).catch(() => {
        setInfoP("Something went wrong..");
      }).finally(() => {
        setSpinner(false);
      });
    }).catch(() => {

    });
  }

  return <div className='route__banners__addNewModal'>
    <div id='box'>
      <h1>Add new banner</h1>

      <CustomInput autocomplete="off" placeholder="Price" ref={priceRef} accent="#FFF" theme="dark" type="number" style={{ width: "100%", marginBottom: '25px' }} />
      <input
        id="img-input"
        type='file'
        name='websitelogo'
        onChange={
          (event) => {
            setSelectedImage(event.target.files[0]);
          }
        }
      />
      <Dropdown inlinePlaceholder='Select Banner Type' theme='dark' style={{marginBottom:'25px'}} data={[
          {name: 'Image', value: 'Image'},
          {name: 'GIF', value: 'GIF'},
          {name: 'Border', value: 'Border'},
        ].map(item => {
        return {name: item.name, value: item.value}
      })} onChange={e => {setType(e.value)}}/>
      <Button value={selectedDomain === null ? 'SELECT DOMAIN' : selectedDomain.URL} accent='rgb(72, 82, 92)' className='selectDomainButton' onClick={(e) => animateBox(e, <SelectDomainModal setSelectedDomain={setSelectedDomain}/>)} />

      <div id="controles" style={{ display: !spinner ? 'flex' : 'none' }}>
        <Button value='Cancel' accent='transparent' style={{ borderBottom: '1px solid #e53333', borderRadius: '0px', color: '#e53333' }} onClick={props.onClose} />
        <Button value='Add' accent='transparent' style={{ borderBottom: '1px solid #00A3FF', borderRadius: '0px', color: '#00A3FF' }} onClick={() => addBanner(props.onClose)} />
      </div>
      <div style={{ display: spinner ? 'flex' : 'none', flexDirection: 'row', justifyContent: 'flex-end' }}>
        <Spinner color='white' align='right' width='32px' />
      </div>
      <p id="msg" ref={msgRef}></p>
    </div>
  </div>
};

const EditModal = (props) => {
  const [spinner, setSpinner] = React.useState(false);
  const [infoP, setInfoP] = React.useState("");
  const newPriceRef = React.useRef(null);
  const newImageRef = React.useRef(null);
  const [selectedDomain, setSelectedDomain] = React.useState(null);
  const msgRef = React.useRef(null);
  const [selectedImage, setSelectedImage] = React.useState(null);
  const [type, setType] = React.useState(false);

  const saveBanner = (close) => {
    setSpinner(true);

    axios({
      method: "POST",
      url: `${backendModule.backendURL}/banners/editBanner`,
      data: {
        ID: props.curBanner.ID,
        Price: newPriceRef.current.value ? newPriceRef.current.value : props.curBanner.Price,
        Type: type,
        Image: selectedImage?.name ? selectedImage?.name : props.curBanner.Image,
        DomainID: selectedDomain ? selectedDomain.ID : props.curBanner.DomainID,
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === 'ok') {
        // getAllBanners();
        props.onChange();
        close();
      }
      else {
        msgRef.current.innerText = 'Something went wrong';
      }
    }).catch(() => {
      msgRef.current.innerText = 'Something went wrong';
    }).finally(() => {
      setSpinner(false);
    });
  }

  return <div className='route__banners__editModal'>
    <div id='box'>
      <h1>Edit {props.curBanner.Name}</h1>

      <CustomInput autocomplete="off" placeholder="Price" ref={newPriceRef} defaultValue={props.curBanner.Price} accent="#FFF" theme="dark" type="number" style={{ width: "100%", marginBottom: '25px' }} />
      <input
        id="img-input"
        type='file'
        name='websitelogo'
        onChange={
          (event) => {
            setSelectedImage(event.target.files[0]);
          }
        }
      />
      <Dropdown inlinePlaceholder='Select Banner Type' theme='dark' style={{marginBottom:'25px'}} data={[
          {name: 'Image', value: 'Image'},
          {name: 'GIF', value: 'GIF'},
          {name: 'Border', value: 'Border'},
        ].map(item => {
        return {name: item.name, value: item.value}
      })} onChange={e => {setType(e.value)}}/>
      <p id="img-p">{selectedImage?.name}</p>
      <Button value={selectedDomain === null ? 'CHANGE DOMAIN' : selectedDomain.URL} accent='rgb(72, 82, 92)' className='selectDomainButton' onClick={(e) =>  animateBox(e, <SelectDomainModal setSelectedDomain={setSelectedDomain}/>)} />

      <div id="controles" style={{ display: !spinner ? 'flex' : 'none' }}>
        <Button value='Cancel' accent='transparent' style={{ borderBottom: '1px solid #e53333', borderRadius: '0px', color: '#e53333' }} onClick={props.onClose} />
        <Button value='Save' accent='transparent' style={{ borderBottom: '1px solid #00A3FF', borderRadius: '0px', color: '#00A3FF' }} onClick={() => saveBanner(props.onClose)} />
      </div>
      <div style={{ display: spinner ? 'flex' : 'none', flexDirection: 'row', justifyContent: 'flex-end' }}>
        <Spinner color='white' align='right' width='32px' />
      </div>
      <p id="msg" ref={msgRef}></p>
    </div>
  </div>
}

const SelectDomainModal = (props) => {
  const [filters, setFilters] = React.useState(false);
  const [domains, setDomains] = React.useState(false);

  const getAllDomains = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/domains/getAllDomains`,
      data: {
        filters: filters
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === 'ok') {
        setDomains(res.data);
      }
      else {
        setDomains('GET_ALL_DOMAINS_ERROR');
      }
    }).catch(() => {
      setDomains('GET_ALL_DOMAINS_ERROR');
    });
  }

  React.useEffect(() => {
    getAllDomains();
  }, [filters]);

  const addDomainsToTable = () => {
    if (domains.data) {
      return domains?.data?.map((item) => {
        return [
          { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.ID}</p></div> },
          { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Domain}</p></div> },
          { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.WebsiteName}</p></div> },
          { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center', background: item.forOffer ? 'green' : 'red', width: 'fit-content', marginLeft: 'auto', marginRight: 'auto', padding: '5px', borderRadius: '10px' }}>{item.forOffer ? 'YES' : 'NO'}</p></div> },
          { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{moment(item.updatedAt).format('DD.MM.YYYY.')}</p></div> },
          {
            keyID: String(item.ID), type: "groupNewline", group: [
              {
                keyID: String(item.ID), type: "button", text: "Select", triggerDropdown: true, onClick: e => {
                  props.setSelectedDomain({ ID: item.ID, URL: item.Domain });
                  props.onClose();
                }
              }
            ]
          }
        ];
      });
    }
  }

  return <div className='route__emailServers__addNewModal__selectDomainModal'>
    <div id='box'>
      <div id='table'>
        <FilteredCustomTable
          id='table'
          accent='#48525C'
          theme='dark'
          headers={['ID', 'Domain', 'Website name', 'For offer', 'Created at']}
          filters={[
            { name: "ID", friendlyName: "ID", type: "number" },
            { name: "Domain", friendlyName: "Domain", type: "string" },
            { name: "WebsiteName", friendlyName: "Website name", type: "string" },
            { name: "updatedAt", friendlyName: "Date", type: "date" },
          ]}
          filterCB={f => setFilters(f)}
          data={domains.data?.length > 0 ? addDomainsToTable() : [[{ keyID: 'noData', type: 'custom', data: <p>No data to display</p> }]]}
        />
      </div>
      <div id="controles">
        <Button value='Cancel' accent='transparent' style={{ borderBottom: '1px solid #e53333', borderRadius: '0px', color: '#e53333' }} onClick={props.onClose} />
      </div>
    </div>
  </div>
}





export default Banners;
export {AddBanner as AddNewBannerModal};