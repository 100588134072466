import React from 'react';
import "./index.scss";

import * as socketModule from "../../../../modules/socketModule";
import * as backendModule from "../../../../modules/backendModule";
import * as chatActions from "../../../../actions/chatAction";

import { useDispatch, useSelector } from 'react-redux';

export default function ContentSendMessage(props) {

  const chatRoomsSelector = useSelector(state => state?.chatRooms ?? []);
  const [moderation, setModeration] = React.useState(true);


  const messageRef = React.useRef(null);
  const chatDispatch = useDispatch();
  const curUserSelector = useSelector(state => state?.userData?.userData?.UserInfo ?? {});

  React.useEffect(() => {
    if (!chatRoomsSelector) return;
    for (let room of chatRoomsSelector) {
      if (room.ID === props.activeChat.ChatRoomID) {
        setModeration(room.ModerationEnabled);
      }
    }

  }, [chatRoomsSelector])

  const sendMessage = () => {
    if (!messageRef.current.value || !props.activeChat?.ChatRoomID || !props.activeChat?.AgentID) {
      return;
    }
    else {
      socketModule.internal__sendMessage(props.activeChat?.ChatRoomID, props.activeChat?.AgentID, 'text', String(messageRef.current.value).trim());
      setTimeout(() => {
        messageRef.current.value = '';
      }, 100);
    }
  }

  const sendFile = (files) => {
    if (!files) return;

    const curChat = backendModule.getStore()?.getState()?.chatRooms?.find(t => t.ID === props.activeChat?.ChatRoomID);
    if (!curChat) return;

    let lastMessageID = backendModule.getStore()?.getState()?.chats?.[curChat.ID];
    if (lastMessageID && lastMessageID[lastMessageID.length - 1]) {
      lastMessageID = lastMessageID[lastMessageID.length - 1].ID;
    } else {
      lastMessageID = 1;
    };
    let curID = lastMessageID;

    for (let file of files) {
      let roundNumber = `${Math.floor(Math.random() * 500000)}-${Date.now()}`;
      curID += 0.00001;
      chatDispatch(chatActions.addChat({
        [curChat.ID]: [{
          ID: curID,
          rnd: roundNumber,
          From: curUserSelector.ID,
          Type: 'image',
          Content: null
        }]
      }));
      socketModule.internal__sendMessage(curChat.ID, props.activeChat?.AgentID, 'image', {
        file: file,
        name: file.name,
        rnd: roundNumber
      });
    };
  };

  const sendVideo = (files) => {
    if (!files) return;

    const curChat = backendModule.getStore()?.getState()?.chatRooms?.find(t => t.ID === props.activeChat?.ChatRoomID);
    if (!curChat) return;

    let lastMessageID = backendModule.getStore()?.getState()?.chats?.[curChat.ID];
    if (lastMessageID && lastMessageID[lastMessageID.length - 1]) {
      lastMessageID = lastMessageID[lastMessageID.length - 1].ID;
    } else {
      lastMessageID = 1;
    };
    let curID = lastMessageID;

    for (let file of files) {
      let roundNumber = `${Math.floor(Math.random() * 500000)}-${Date.now()}`;
      curID += 0.00001;
      chatDispatch(chatActions.addChat({
        [curChat.ID]: [{
          ID: curID,
          rnd: roundNumber,
          From: curUserSelector.ID,
          Type: 'video',
          Content: null
        }]
      }));
      socketModule.internal__sendMessage(curChat.ID, props.activeChat?.AgentID, 'video', {
        file: file,
        name: file.name,
        rnd: roundNumber
      });
    };
  };

  const disableModeration = () => {
    socketModule.internal__changeModerationEnabled(props.activeChat?.ChatRoomID, false);
  }

  const enableModeration = () => {
    socketModule.internal__changeModerationEnabled(props.activeChat?.ChatRoomID, true);
  }

  return (
    <div style={{ display: !props.activeChat?.ChatRoomID ? 'none' : '' }} className='component__contentSendMessage'>
      {moderation ? <>
        <button onClick={() => disableModeration()} style={{
          ...props?.buttonStyle,
          width: '300px'
        }} >Disable moderation service</button>
      </> : <>
        <img src='images/attachIcon.png' alt='' onClick={(e) => {
          let input = e.target.parentNode.querySelector("input[data-type=image]");
          if (input) input.click();
        }} />
        <img src='images/cpanel__banners-icon.svg' alt='' onClick={(e) => {
          let input = e.target.parentNode.querySelector("input[data-type=video]");
          if (input) input.click();
        }} />
        <input data-type="image" style={{ display: 'none' }} type="file" accept="image/*" multiple='true' onChange={e => {
          if (e.target.files.length > 0) {
            sendFile(e.target.files);
          };
        }} />
        <input data-type="video" style={{ display: 'none' }} type="file" accept="video/*" multiple='true' onChange={e => {
          if (e.target.files.length > 0) {
            sendVideo(e.target.files);
          };
        }} />
        <textarea ref={messageRef} onKeyPress={(e) => { if (e.key === 'Enter') sendMessage() }} type='text' alt='' />
        <button onClick={() => sendMessage()} >Send</button>
        <button style={{width: '300px'}} onClick={() => enableModeration()} >Enable Moderation</button>
      </>}
    </div>
  )
}
