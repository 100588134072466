import React from "react";
import "./index.scss";

import axios from "axios";
import moment from "moment";
import * as backendModule from "../../modules/backendModule";
import useOnScreen from "../../modules/hooks/useOnScreen";
import useDefer from "../../modules/hooks/useDefer";
import { lookingFor } from "../../modules/miscModule";

import Spinner from "../../components/customComponents/Spinner";
import FilterPanel from "../../components/customComponents/FilterPanelNew";
import Dropdown from "../../components/customComponents/Dropdown";

import { FilteredCustomTable } from "../../components/customComponents/Table2";
import { RetentionRateTable } from "../../components/customComponents/RetentionRateTable";

import { animateBox } from "../../modules/componentAnimation";

const GeneralStatistics = () => {
  const [spinner, setSpinner] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [filters, setFilters] = React.useState([]);
  const [data, setData] = React.useState([]);

  const [orders, setOrders] = React.useState();

  const [startDate, setStartDate] = React.useState('');
  const [startRetentionDate, setStartRetentionDate] = React.useState('');
  const [endDate, setEndDate] = React.useState('');
  const [endRetentionDate, setEndRetentionDate] = React.useState('');

  const [dataChurnRetention, setDataChurnRetention] = React.useState([]);
  const [isCustom, setIsCustom] = React.useState(false);
  const [selectedOption, setSelectedOption] = React.useState('');

  const [selectedAmount, setSelectedAmount] = React.useState(6);


  const getData = () => {

    axios({
      method: "POST",
      url: `${backendModule.backendURL}/generalStatistics/getGeneralStatistics`,
      data: {
        filters,
      },
      ...backendModule.axiosConfig
    }).then(res => {
      setData(res.data.data);
    }).catch((err) => {
      console.log("error while getting general statistics ", err)
    });
  };

  const addMoreData = () => {

    if(!startDate || !endDate) return;

    axios({
      method: "POST",
      url: `${backendModule.backendURL}/generalStatistics/getMoreStatistics`,
      data: {
        filters,
        startDate,
        endDate,
      },
      ...backendModule.axiosConfig
    }).then(res => {
      setData(res.data.data)
    }).catch((err) => {
      console.log("error while getting general statistics ", err)
    });
  }

  const getChurnRetentionData = () => {

    axios({
      method: "POST",
      url: `${backendModule.backendURL}/generalStatistics/getRetentionAndChurnRate`,
      data: {
        filters,
        selectedAmount: selectedAmount ? selectedAmount : 2
      },
      ...backendModule.axiosConfig
    }).then(res => {
      console.log("-----", res.data.data)
      setDataChurnRetention(res.data.data);
    }).catch((err) => {
      console.log("error while getting general statistics ", err)
    });
  };

  const getMoreChurnRetentionData = () => {

    if(!selectedAmount) return;

    axios({
      method: "POST",
      url: `${backendModule.backendURL}/generalStatistics/getMoreRetentionAndChurnRate`,
      data: {
        filters,
        startRetentionDate: startRetentionDate ? startRetentionDate : null,
        endRetentionDate: endRetentionDate ? endRetentionDate : null,
        selectedOption: selectedOption ? selectedOption : null,
        selectedAmount: selectedAmount ? selectedAmount : 6
      },
      ...backendModule.axiosConfig
    }).then(res => {
      console.log("!!!!!", res.data.data)
      setDataChurnRetention(res.data.data);
    }).catch((err) => {
      console.log("error while getting general statistics ", err)
    });
  };

  const orderData = (d, headers) => {
    if (!orders) return d;

    let headerIndex = headers.indexOf(orders.name);

    if (headerIndex === -1) return d;

    let asc = orders.order === "asc" ? 1 : -1;
    let desc = orders.order === "asc" ? -1 : 1;

    return [...d].sort((a, b) => {
      let a1 = a[headerIndex];
      let b1 = b[headerIndex];

      if (!a1 || !b1) return 0;

      if (Number(a1?.text) && Number(b1?.text)) {
        return Number(a1?.text) > Number(b1?.text) ? asc : desc;
      } else {
        return a1?.text > b1?.text ? asc : desc;
      };
    });
  };

  const handleCheckboxChange = () => {
    setIsCustom(!isCustom);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setStartRetentionDate(null);
    setEndRetentionDate(null);
  };

  const handleAmountChange = (e) => {
    setSelectedAmount(e.target.value);
  };
  

  React.useEffect(() => {
    getData();
    getChurnRetentionData();
  }, [filters]);

  React.useEffect(() => {
    addMoreData();
  }, [startDate, endDate]);

  React.useEffect(() => {
    getMoreChurnRetentionData();
  }, [startRetentionDate, endRetentionDate, selectedAmount, selectedOption]);


  return <div className="route__generalStatistics">

    <div className="date-picker-container">
      <label htmlFor="start-date">Start Date:</label>
      <input
        className="styled-input"
        type="date"
        id="start-date"
        name="start-date"
        value={startDate}
        onChange={(e) => setStartDate(e.target.value)}
      />

      <label htmlFor="end-date">End Date:</label>
      <input
        className="styled-input"
        type="date"
        id="end-date"
        name="end-date"
        value={endDate}
        onChange={(e) => setEndDate(e.target.value)}
      />
    </div>

    <FilteredCustomTable
      filterCB={c => setFilters(c)}
      id='table'
      accent='#000'
      dropdownBackground='#000'
      inputBottomBorderColor='#FFF'
      placeholderStyle={{ backgroundColor: '#000' }}
      theme='dark'
      filterHeadStyle={{ backgroundColor: 'rgba(25, 35, 67, 0.2)' }}
      tableHeadStyle={{ backgroundColor: 'rgba(25, 35, 67, 0.2)' }}
      buttonStyle={{ border: '1px solid #FFF', backgroundColor: '#000' }}
      // style={{colors: index % 2 === 0 ? '#1923438e' : '#192343'}}
      customColumns={["auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", '50px']}
      orderCB={o => setOrders(o)}
      headers={["Timelapse", "Clicks", "SOI", "CR(SOI)", "DOI", "CR(DOI)", "Daily Users", "Avg. Active Time", "Unique Buyers", "Total Revenue"]}
      data={(() => {
        let out = [];

        if (!data) return [[{ keyID: "noData-spinner", type: "spinner" }]];
        if (data.status === "error") return [[{ keyID: "noData-error", type: "text", text: "Error while fetching data" }]];

        for (let item of data) {
          out.push([
            { keyID: item.ID, type: "text", text: item.Timelapse },
            { keyID: item.ID, type: "text", text: item.Clicks },
            { keyID: item.ID, type: "text", text: item.SOI },
            { keyID: item.ID, type: "text", text: `${item.CRSOI}%` },
            { keyID: item.ID, type: "text", text: item.DOI },
            { keyID: item.ID, type: "text", text: `${item.CRDOI}%` },
            { keyID: item.ID, type: "text", text: item.DailyUsers },
            { keyID: item.ID, type: "text", text: item.AvgActiveTime },
            { keyID: item.ID, type: "text", text: item.UniqueBuyers },
            { keyID: item.ID, type: "text", text: item.TotalRevenue },
            { keyID: item.ID, type: "button", text: 'x', style: {minWidth: '0px', padding: '0px 20px'}, onClick: () => {
              setData(d => d.filter(dd => dd.Timelapse !== item.Timelapse))
            } }
          ])
        };

        out = orderData(out, ["Timelapse", "Clicks", "SOI", "CR(SOI)", "DOI", "CR(DOI)", "Daily Users", "Avg. Active Time", "Unique Buyers", "Total Revenue"])
        if (spinner) out.push([{ keyID: "data-spinner", type: "spinner" }]);
        if (out.length === 0) out.push([{ keyID: "noData-noData", type: "text", text: "Nothing to show..." }]);

        return out;
      })()}
    />

    <p className="subheader" style={{margin: '20px 0'}}>Retention Rate</p>

    <div className="monthSelectionContainer">
      <div className="date-picker-container" style={{ paddingBottom: '30px' }}>
        <div className="date-picker-container-checkbox">
          <p>Custom</p>
          <input type="checkbox" checked={isCustom} onChange={handleCheckboxChange} />
        </div>

        {isCustom ? (
          <>
            <label htmlFor="start-date">Start Date:</label>
            <input
              className="styled-input"
              type="date"
              id="start-date"
              name="start-date"
              value={startRetentionDate}
              onChange={(e) => {setStartRetentionDate(e.target.value); setSelectedOption(null)}}
            />

            <label htmlFor="end-date">End Date:</label>
            <input
              className="styled-input"
              type="date"
              id="end-date"
              name="end-date"
              value={endRetentionDate}
              onChange={(e) => {setEndRetentionDate(e.target.value); setSelectedOption(null)}}
            />
          </>
        ) : (
          <>
            <div className="options-container">
              <div className={`option ${selectedOption === 'week' ? 'selected' : ''}`} onClick={() => handleOptionClick('week')}>Week</div>
              <div className={`option ${selectedOption === 'month' ? 'selected' : ''}`} onClick={() => handleOptionClick('month')}>Month</div>
              <div className={`option ${selectedOption === 'quarter' ? 'selected' : ''}`} onClick={() => handleOptionClick('quarter')}>Quarter</div>
              <div className={`option ${selectedOption === 'year' ? 'selected' : ''}`} onClick={() => handleOptionClick('year')}>Year</div>
            </div>
            <p style={{marginRight: '-8px'}}>Amount:</p>
            <select value={selectedAmount} onChange={handleAmountChange} className="styled-select">
              {Array.from({ length: 11 }, (_, i) => i + 1).map((num) => (
                <option key={num} value={num}>
                  {num}
                </option>
              ))}
            </select>
          </>
        )}
        
      </div>
    </div>
    
    
    {dataChurnRetention && <RetentionRateTable data={dataChurnRetention} />}


  </div>
};




export default GeneralStatistics