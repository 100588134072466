import React from 'react';
import "./index.scss";

import { FilteredCustomTable } from "../../components/customComponents/Table";
import Button from '../../components/customComponents/Button';
import Spinner from '../../components/customComponents/Spinner';

import * as backendModule from "../../modules/backendModule";
import axios, { all } from "axios";
import moment from 'moment';
import { animateBox } from '../../modules/componentAnimation';
import * as socketModule from "../../modules/socketModule";

export default function Stream(props) {
    const [data, setData] = React.useState();
    const [messages, setMessages] = React.useState();
    const endMsgRef = React.useRef()
    const getData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/streamers/getUserStreamInfo`,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                setData(res.data.data)
            }
            else {

            }
        }).catch(() => {

        });
    }

    React.useEffect(() => {
        getData()
    }, []);
    const getMessages = () => {
        if (!data) return;
        if (!socketModule.getSocket()) {
            setTimeout(() => {
                getMessages()
            }, 500)
            return;
        }
        let ID = data.ID;
        socketModule.getSocket().emit("stream_connect", data?.ID);

        const newMessageHandler = (msg) => {
            setMessages(msgs => {
                let final = msgs;
                while (final.length > 200) final.shift();
                return [
                    ...final,
                    msg
                ];
            });
        };
        const allChatsHandler = (msgs) => {
            setMessages(msgs);

            socketModule.off("stream_receiveMessage", newMessageHandler);
            socketModule.on("stream_receiveMessage", newMessageHandler);
        };

        socketModule.on("stream_getAllChats", allChatsHandler);
        socketModule.getSocket().emit("stream_getAllChats", data?.ID);

        return () => {
            if (!socketModule.getSocket()) return;
            socketModule.off("stream_getAllChats", allChatsHandler);
            socketModule.off("stream_receiveMessage", newMessageHandler)
            socketModule.getSocket().emit("stream_disconnect", data?.ID);
        };
    }
    React.useEffect(() => {
        setTimeout(() => {
            getMessages()
        }, 500)

    }, [data]);
    const stringToColour = function (username) {
        function lightenColor(hex) {
            var color = hexToRgb(hex);
            color.r += (255 - color.r) * 0.5;
            color.g += (255 - color.g) * 0.5;
            color.b += (255 - color.b) * 0.5;
            return `rgb(${color.r}, ${color.g}, ${color.b})`;
        }
        function hexToRgb(hex) {
            var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
            return result ? {
                r: parseInt(result[1], 16),
                g: parseInt(result[2], 16),
                b: parseInt(result[3], 16)
            } : null;
        }
        var hash = 0;
        for (var i = 0; i < username.length; i++) {
            hash = username.charCodeAt(i) + ((hash << 5) - hash);
        }
        var color = '#';
        for (var i = 0; i < 3; i++) {
            var value = (hash >> (i * 8)) & 0xFF;
            color += ('00' + value.toString(16)).substr(-2);
        }
        return lightenColor(color);
    }

    const scrollToBottom = () => {
        endMsgRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    React.useEffect(() => {
        scrollToBottom()
    }, [messages]);

    return <div className='route__stream'>
        <p>Stream key: {data?.StreamKey}</p>
        <p>Stream active: {data?.StreamStarted ? 'Yes' : 'No'}</p>
        <div className='route__stream__chat'>

            <div className="route__stream__chat__content">
                {messages && messages.map((item, index) => {

                    return <div key={`stream-messagesWindow-message-${item.ID}`}>
                        {item.Type === 'text' ? <p >
                            {moment(item.Date).format('hh:mm')} &nbsp;
                            <span style={{ color: stringToColour(item.From) }}> {item.From}: </span> {item.Content}
                        </p> : <>
                            <p >
                                {moment(item.Date).format('hh:mm')} &nbsp;
                                <span style={{ color: stringToColour(item.From) }}>{item.From}: </span></p>
                            <div className="route__contentChat__message__right__gift route__stream__chat__content__imageContainer">
                                <img alt="" src={JSON.parse(item.Content).Image} />
                            </div>

                        </>}

                    </div>
                })}
                <div ref={endMsgRef}></div>
            </div>

        </div>
    </div>
}
