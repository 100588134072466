import React from 'react';
import "./index.scss";

import * as backendModule from "../../modules/backendModule";
import axios from "axios";
import moment from 'moment';

import Spinner from '../../components/customComponents/Spinner';
import Dropdown from '../../components/customComponents/Dropdown';
import UserStats from './UserStats';



const Analitycs = () => {
    const [filters, setFilters] = React.useState();
    const [users, setUsers] = React.useState();
    const [topUsers, setTopUsers] = React.useState();
    const [totalUsers, setTotalUsers] = React.useState(0);
    const [countryCoordinates, setCountryCoordinates] = React.useState([{ name: 'All countries', value: null }])
    const [selectedCountry, setSelectedCountry] = React.useState();
    const [date, setDate] = React.useState([{ name: "createdAt", op: "deq", value: new Date().getTime() }]);
    const [active, setActive] = React.useState(1);
    const [totalData, setTotalData] = React.useState([]);
    const [spinner, setSpinner] = React.useState(false);
    const [activeTab, setActiveTab] = React.useState('messages');
    const [countries, setCountries] = React.useState([]);
    const [countriesMessages, setCountriesMessages] = React.useState([]);
    const [secondaryUsers, setSecondaryUsers] = React.useState([]);
    const [totalAge, setTotalAge] = React.useState(0);
    let tmp = [];
    let tmpMessages = [];

    React.useEffect(() => {
        axios.get('/countryCoordinates.json').then(res => {
            setCountryCoordinates({ ALL: { name: 'All countries' }, ...res.data });
        }).catch(() => { }).finally(() => {

        });
    }, []);

    const getData = () => {
        setSpinner(true)
        let tmpData = { users: 0, sent: 0, received: 0, countries: 0, countriesList: [] }
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/getAllSecondaryUsers`,
            data: {
                filters: [
                    selectedCountry ? {
                        name: "Country",
                        op: "eq",
                        value: selectedCountry
                    } : null,
                ],
                limit: 500000,
                messageStatsDate: activeTab === 'messages' ? date : '',
                messageStats: true
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                setUsers({ status: res.data.status, data: res.data.data.filter(usr => !String(usr.Username).startsWith('--internal')).sort((a, b) => (a.messageStatsDate.sentMessages < b.messageStatsDate.sentMessages ? 1 : -1)) });
                res.data.data.forEach(item => {
                    if(!String(item.Username).startsWith('--internal')){
                        tmpData.users++;
                        tmpData.sent += item.messageStatsDate.sentMessages;
                        tmpData.received += item.messageStatsDate.receivedMessages;
                        if (!tmpData.countriesList.includes(item.Country)) {
                            tmpData.countriesList.push(item.Country)
                            tmpData.countries++
                        }
                    }
                })
                setTotalData(tmpData)
                setSpinner(false)
            }
            else {

            }

        }).catch(() => {

        });

    }

    const getAllSecondaryUsers = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/getAllSecondaryUsers`,
            data: {
                limit: 500000,
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                setSecondaryUsers(res.data);
            }
            else {

            }
        }).catch(() => {

        });
    }

    React.useEffect(() => {
        getData();
    }, [selectedCountry, date]);

    React.useEffect(() => {
        getAllSecondaryUsers();
    }, []);

    React.useEffect(() => {
        secondaryUsers.data?.map((item, index) => {
            let counter = 0;
            if(!String(item.Username).startsWith('--internal')){
                tmp.forEach(countries => {
                    if (countries.Country === item.Country) {
                        counter++;
                    }
                });
                if (counter === 0) {
                    tmp.push({ Country: item.Country, Users: 0, AgeSum: 0 });
                }
                setCountries(tmp);
            }
            if(!String(item.Username).startsWith('--internal')){
                setTotalAge(prev => prev + Number(moment().year()) - Number(moment(item.Birthday).year()));
            }
        });
        setTotalUsers(secondaryUsers.data?.filter(u=> !String(u.Username).startsWith('--internal')).length);
    }, [secondaryUsers]);

    React.useEffect(() => {
        if (countries.length > 0) {
            countries.map((country, index) => {
                secondaryUsers.data?.map((user) => {
                    if(!String(user.Username).startsWith('--internal'))
                    if (country.Country === user.Country) {
                        country.Users++;
                        country.AgeSum += Number(moment().year()) - Number(moment(user.Birthday).year());
                    }
                });
            });
            countries.sort((c1, c2) => (c1.Users < c2.Users) ? 1 : (c1.Users > c2.Users) ? -1 : 0);
        }
    }, [countries]);

    React.useEffect(() => {
        users?.data?.map((item) => {
            let counter = 0;
            tmpMessages.forEach(countries => {
                if (countries.Country === item.Country) {
                    counter++;
                }
            });
            if (counter === 0) {
                tmpMessages.push({ Country: item.Country, Users: 0, Messages: 0 });
            }
            setCountriesMessages(tmpMessages);
        });
        setTopUsers(users?.data?.sort((c1, c2) => (c1.messageStats.sentMessages < c2.messageStats.sentMessages) ? 1 : (c1.messageStats.sentMessages > c2.messageStats.sentMessages) ? -1 : 0))
    }, [users]);

    React.useEffect(() => {
        if (countriesMessages.length > 0) {
            countriesMessages.map((country, index) => {
                users.data?.map((user) => {
                    if (country.Country === user.Country) {
                        country.Users++;
                        country.Messages = Number(country.Messages) + Number(user.messageStats.sentMessages);
                    }
                });
            });
            countriesMessages.sort((c1, c2) => (c1.Messages < c2.Messages) ? 1 : (c1.Messages > c2.Messages) ? -1 : 0);
        }
    }, [countriesMessages]);

    return <div className='route__analitycs'>
        <div className='route__analitycs__header'>
            <span onClick={() => setActiveTab('messages')} style={{ background: activeTab === 'messages' ? '#2E4C6E' : '' }}><p>Messages table</p></span>
            <span onClick={() => setActiveTab('details')} style={{ background: activeTab === 'details' ? '#2E4C6E' : '', display: spinner ? 'none' : 'block' }}><p>Details</p></span>
            <span onClick={() => setActiveTab('user-stats')} style={{ background: activeTab === 'user-stats' ? '#2E4C6E' : '', display: spinner ? 'none' : 'block' }}><p>User stats</p></span>
        </div>

        { activeTab === 'messages' && <div>
            <div className='route__analitycs__filters'>
                <div>
                    <button style={{ backgroundColor: active === 1 ? '#2E4C6E' : '#06234c' }} onClick={
                        () => { setDate([{ name: "createdAt", op: "deq", value: moment(new Date()).toDate().getTime() }]); setActive(1) }
                    }>Today</button>
                    <button style={{ backgroundColor: active === 2 ? '#2E4C6E' : '#06234c' }} onClick={
                        () => { setDate([{ name: "createdAt", op: "deq", value: moment(new Date().getTime() - 1 * 24 * 60 * 60 * 1000).toDate().getTime() }]); setActive(2) }
                    }>Yesterday</button>
                    <button style={{ backgroundColor: active === 3 ? '#2E4C6E' : '#06234c' }} onClick={() => {
                        setDate([{ name: "createdAt", op: "dgeq", value: new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000).toISOString() }]);
                        setActive(3)
                    }}>Last 7 days</button>
                    <button style={{ backgroundColor: active === 4 ? '#2E4C6E' : '#06234c' }} onClick={() => {
                        setDate([{ name: "createdAt", op: "dgeq", value: new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000).toISOString() }]);
                        setActive(4)
                    }}>Last 30 days</button>

                </div>
                {countryCoordinates && <Dropdown placeholder='Select country' style={{borderColor: '#2E4C6E', backgroundColor: '#06234c'}} theme='dark' 
                placeholderStyle={{backgroundColor: '#06234c', color:'#FFF', padding: '4px', width: 'calc(100% + 2px)', left: '-1px', top:'-5px', textAlign: 'center', 
                border: '1px solid #2E4C6E', borderTopLeftRadius: '5px', borderTopRightRadius: '5px'}}
                dropdownStyle={{backgroundColor: '#06234c', color: '#FFF', borderRadius:'5px'}}
                inputStyle={{backgroundColor: '#06234c', borderBottom: '1px solid #2E4C6E'}}
                data={Object.keys(countryCoordinates).map(country => {
                    if (country === 'ALL') return { name: countryCoordinates[country].name, value: null }
                    return { name: countryCoordinates[country].name, value: country }
                })} onChange={e => setSelectedCountry(e.value)} />}

            </div>
            {!spinner ? <div className='route__analitycs__table'>
                <div className='route__analitycs__table__head'>
                    <p>Username</p>
                    <p>Send messages</p>
                    <p>Recived messages</p>
                    <p>Country</p>
                </div>
                <div className='route__analitycs__table__total'>
                    <p>Total users:<br /> {totalData.users}</p>
                    <p>{totalData.sent}</p>
                    <p>{totalData.received}</p>
                    <p>Total countries <br /> {totalData.countries}</p>
                </div>
                {users && users.data.map((item, index) => {
                    return <div className='route__analitycs__table__content' key={index} style={{
                        backgroundColor: index % 2 === 0 ? 'rgb(25, 35, 67)' : 'rgba(25, 35, 67, 0.50)',
                        color: moment(item.createdAt).add(30, "minutes").isBefore(moment()) ? "yellow" : ""
                    }}>
                        <p>{item.Username}</p>
                        <p>{item.messageStatsDate.sentMessages}</p>
                        <p>{item.messageStatsDate.receivedMessages}</p>
                        <p>{item.Country}</p>
                    </div>
                })}
            </div> : <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '30px' }} ><Spinner /></div>}
        </div>}
        {activeTab === 'details' && <div className='route__analitycs__details'>
            {!spinner && totalUsers !== null && <div className='route__analitycs__details__wrapper'>
                <div className='route__analitycs__details__wrapper__block'>
                    <h1>SALES</h1>
                </div>
                <div className='route__analitycs__details__wrapper__block'>
                    <h1>TOP COUNTRIES</h1>
                    {
                        countries?.map((item) => {
                            return <span className='route__analitycs__details__wrapper__block__item'><strong>Country: {item.Country !== '' ? item.Country : '-ND-'}</strong><p>Users: {item.Users}</p></span>
                        })
                    }
                </div>
                <div className='route__analitycs__details__wrapper__block'>
                    <h1>TOP USERS</h1>
                    {
                        topUsers?.map((item) => {
                            return <span className='route__analitycs__details__wrapper__block__item'><strong>Username: {item.Username}</strong><p>Messages sent: {item.messageStats.sentMessages}</p></span>
                        })
                    }
                </div>
                <div className='route__analitycs__details__wrapper__block'>
                    <h1>AVG. AGE BY COUNTRY</h1>
                    {
                        countries?.map((item) => {
                            return <span className='route__analitycs__details__wrapper__block__item'><strong>Country: {item.Country !== '' ? item.Country : '-ND-'}</strong><p>Avg. age: {Number(item.AgeSum) / Number(item.Users)}</p></span>
                        })
                    }
                </div>
                <div className='route__analitycs__details__wrapper__block'>
                    <h1>OTHER</h1>
                    <span className='route__analitycs__details__wrapper__block__item'>
                        <strong>Avg. age:</strong> {Number(totalAge / totalUsers).toFixed(2)}
                    </span>
                    <span className='route__analitycs__details__wrapper__block__item'>
                        <strong>Most messages sent:</strong> {countriesMessages[0]?.Country !== '' ? countriesMessages[0]?.Country : '-ND-'} / {countriesMessages[0]?.Messages}
                    </span>
                </div>
            </div>}
            {spinner && totalUsers === null && <Spinner color='#00a3ff' align='center' />}
        </div>}

        {activeTab === "user-stats" && <UserStats />}
    </div>

}

export default Analitycs;