import React from 'react';
import "./index.scss";

import CustomInput from '../../components/customComponents/CustomInput';
import Button from '../../components/customComponents/Button';
import Spinner from '../../components/customComponents/Spinner';
import { FilteredCustomTable } from "../../components/customComponents/Table";
import DataSorter from "../../components/customComponents/DataSorter";

import * as backendModule from "../../modules/backendModule";
import axios, { all } from "axios";
import moment from 'moment';

import Dropdown from '../../components/customComponents/Dropdown';

import { animateBox } from "../../modules/componentAnimation";

export default function Boosts(props) {
    const [filters, setFilters] = React.useState([]);
    const [spinner, setSpinner] = React.useState(false);
    const [orders, setOrders] = React.useState([]);

    const getAllBoosts = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/boosts/getAllBoostsCpanel`,
            data: {
                filters: filters,
                orders: orders
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                props.setBoosts(res.data);
            }
            else {

            }
        }).catch(() => {

        });
    }

    React.useEffect(() => {
        getAllBoosts();
    }, [filters, orders]);

    const deleteBoost = (id) => {
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/boosts/removeBoost`,
            data: {
                ID: id
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                getAllBoosts();
            }
            else {

            }
        }).catch(() => {

        }).finally(() => {
            setSpinner(false);
        });
    }

    const addBoostsToTable = () => {
        if (props.boosts.data) {
            return props.boosts?.data?.map((item) => {
                return [
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.ID}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Price}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Amount}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.DomainID}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{moment(item.updatedAt).format('DD.MM.YYYY.')}</p></div> },
                    {
                        keyID: String(item.ID), type: "groupNewline", group: [
                            {
                                keyID: String(item.ID), type: "button", text: "Delete", triggerDropdown: true, triggerData: e => {
                                    return (<div style={{ display: 'flex', flexDirection: 'column', justfyContent: 'center', alignItems: 'center' }}>
                                        <p style={{ color: 'white' }}>Are you sure?</p>
                                        <br></br>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Button style={{ display: spinner ? 'none' : 'block', marginRight: '10px' }} accent='rgb(72, 82, 92)' value='YES' onClick={() => deleteBoost(item.ID)} />
                                            <Button style={{ display: spinner ? 'none' : 'block' }} accent='rgb(72, 82, 92)' value='NO' onClick={c => { e() }} />
                                            <div style={{ display: spinner ? 'block' : 'none' }}><Spinner color='white' width='32px' /></div>
                                        </div>
                                    </div>);
                                },
                            },
                            {
                                keyID: String(item.ID), type: "button", text: "Edit", triggerDropdown: true, onClick: e => {
                                    animateBox(e, <EditModal curBoost={
                                        {
                                            ID: item.ID,
                                            Price: item.Price,
                                            Amount: item.Amount,
                                            DomainID: item.DomainID,
                                        }
                                    } />)
                                }
                            }
                        ]
                    }
                ];
            });
        }
    }

    const EditModal = (props) => {
        const newPriceRef = React.useRef(null);
        const newAmountRef = React.useRef(null);
        const [newSelectedDomain, setNewSelectedDomain] = React.useState(null);
        const msgRef = React.useRef(null);

        const openDomainsModal = (e, isEdit, setNewSelectedDomain) => {
            animateBox(e, <SelectDomainModal isEdit={isEdit} setNewSelectedDomain={setNewSelectedDomain} />);
        }

        const SelectDomainModal = (props) => {
            const [filters, setFilters] = React.useState(false);
            const [domains, setDomains] = React.useState(false);

            const getAllDomains = () => {
                axios({
                    method: "POST",
                    url: `${backendModule.backendURL}/domains/getAllDomains`,
                    data: {
                        filters: filters
                    },
                    ...backendModule.axiosConfig
                }).then(res => {
                    if (res.data.status === 'ok') {
                        setDomains(res.data);
                    }
                    else {
                        setDomains('GET_ALL_DOMAINS_ERROR');
                    }
                }).catch(() => {
                    setDomains('GET_ALL_DOMAINS_ERROR');
                });
            }

            React.useEffect(() => {
                getAllDomains();
            }, [filters]);

            const addDomainsToTable = () => {
                if (domains.data) {
                    return domains?.data?.map((item) => {
                        return [
                            { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.ID}</p></div> },
                            { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Domain}</p></div> },
                            { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.WebsiteName}</p></div> },
                            { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center', background: item.forOffer ? 'green' : 'red', width: 'fit-content', marginLeft: 'auto', marginRight: 'auto', padding: '5px', borderRadius: '10px' }}>{item.forOffer ? 'YES' : 'NO'}</p></div> },
                            { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{moment(item.updatedAt).format('DD.MM.YYYY.')}</p></div> },
                            {
                                keyID: String(item.ID), type: "groupNewline", group: [
                                    {
                                        keyID: String(item.ID), type: "button", text: "Select", triggerDropdown: true, onClick: e => {
                                            setNewSelectedDomain({ ID: item.ID, URL: item.Domain });
                                            props.onClose();
                                        }
                                    }
                                ]
                            }
                        ];
                    });
                }
            }

            return <div className='route__emailServers__addNewModal__selectDomainModal'>
                <div id='box'>
                    <div id='table'>
                        <FilteredCustomTable
                            id='table'
                            accent='#48525C'
                            theme='dark'
                            headers={['ID', 'Domain', 'Website name', 'For offer', 'Created at']}
                            filters={[
                                { name: "ID", friendlyName: "ID", type: "number" },
                                { name: "Domain", friendlyName: "Domain", type: "string" },
                                { name: "WebsiteName", friendlyName: "Website name", type: "string" },
                                { name: "updatedAt", friendlyName: "Date", type: "date" },
                            ]}
                            filterCB={f => setFilters(f)}
                            data={domains.data?.length > 0 ? addDomainsToTable() : [[{ keyID: 'noData', type: 'custom', data: <p>No data to display</p> }]]}
                        />
                    </div>
                    <div id="controles">
                        <Button value='Cancel' accent='transparent' style={{ borderBottom: '1px solid #e53333', borderRadius: '0px', color: '#e53333' }} onClick={props.onClose} />
                    </div>
                </div>
            </div>
        }

        const saveBoost = (close) => {
            setSpinner(true);

            axios({
                method: "POST",
                url: `${backendModule.backendURL}/boosts/editBoost`,
                data: {
                    ID: props.curBoost.ID,
                    Price: newPriceRef.current.value ? newPriceRef.current.value : props.curBoost.Price,
                    Amount: newAmountRef.current.value ? newAmountRef.current.value : props.curBoost.Amount,
                    DomainID: newSelectedDomain ? newSelectedDomain.ID : props.curBoost.DomainID,
                },
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === 'ok') {
                    getAllBoosts();
                    close();
                }
                else {
                    msgRef.current.innerText = 'Something went wrong';
                }
            }).catch(() => {
                msgRef.current.innerText = 'Something went wrong';
            }).finally(() => {
                setSpinner(false);
            });
        }

        return <div className='route__boosts__editModal'>
            <div id='box'>
                <h1>Edit {props.curBoost.Name}</h1>

                <CustomInput autocomplete="off" placeholder="Price" ref={newPriceRef} defaultValue={props.curBoost.Price} accent="#FFF" theme="dark" type="number" style={{ width: "100%", marginBottom: '25px' }} />
                <CustomInput autocomplete="off" placeholder="Amount" ref={newAmountRef} defaultValue={props.curBoost.Amount} accent="#FFF" theme="dark" type="number" style={{ width: "100%", marginBottom: '25px' }} />
                <Button value={newSelectedDomain === null ? 'CHANGE DOMAIN' : newSelectedDomain.URL} accent='rgb(72, 82, 92)' className='selectDomainButton' onClick={(e) => openDomainsModal(e, false)} />

                <div id="controles" style={{ display: !spinner ? 'flex' : 'none' }}>
                    <Button value='Cancel' accent='transparent' style={{ borderBottom: '1px solid #e53333', borderRadius: '0px', color: '#e53333' }} onClick={props.onClose} />
                    <Button value='Save' accent='transparent' style={{ borderBottom: '1px solid #00A3FF', borderRadius: '0px', color: '#00A3FF' }} onClick={() => saveBoost(props.onClose)} />
                </div>
                <div style={{ display: spinner ? 'flex' : 'none', flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <Spinner color='white' align='right' width='32px' />
                </div>
                <p id="msg" ref={msgRef}></p>
            </div>
        </div>
    }

    return (
        <div className='route__boosts'>
            <DataSorter sorters={[
                {name: 'Price', value:'Price'},
                {name: 'Amount', value:'Amount'},
                {name: 'Created at', value:'createdAt'},
                {name: 'Updated at', value:'updatedAt'}
            ]} setOrders={setOrders} />
            <FilteredCustomTable
                id='table'
                accent='#48525C'
                theme='dark'
                headers={['ID', 'Price', 'Amount', 'Domain ID', 'Updated at']}
                filters={[
                    { name: "ID", friendlyName: "ID", type: "string" },
                    { name: "Price", friendlyName: "Price", type: "number" },
                    { name: "Amount", friendlyName: "Amount", type: "string" },
                    { name: "DomainID", friendlyName: "Domain ID", type: "string" },
                    { name: "updatedAt", friendlyName: "Updated at", type: "string" }
                ]}
                filterCB={f => setFilters(f)}
                data={props.boosts.data?.length > 0 ? addBoostsToTable() : [[{ keyID: 'noData', type: 'custom', data: <p>No data to display</p> }]]}
            />
        </div>
    )
}

const AddNewBoostModal = (props) => {
    const [spinner, setSpinner] = React.useState(false);
    const [selectedDomain, setSelectedDomain] = React.useState(null);
    const msgRef = React.useRef(null);

    const priceRef = React.useRef(null);
    const amountRef = React.useRef(null);

    const getAllBoosts = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/boosts/getAllBoostsCpanel`,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                props.setBoosts(res.data);
            }
            else {

            }
        }).catch(() => {

        });
    }

    React.useEffect(() => {
        getAllBoosts();
    }, []);

    const openDomainsModal = (e, isEdit, setNewSelectedDomain) => {
        animateBox(e, <SelectDomainModal isEdit={isEdit} setNewSelectedDomain={setNewSelectedDomain} />);
    }

    const SelectDomainModal = (props) => {
        const [filters, setFilters] = React.useState(false);
        const [domains, setDomains] = React.useState(false);
        

        const getAllDomains = () => {
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/domains/getAllDomains`,
                data: {
                    filters: filters
                },
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === 'ok') {
                    setDomains(res.data);
                }
                else {
                    setDomains('GET_ALL_DOMAINS_ERROR');
                }
            }).catch(() => {
                setDomains('GET_ALL_DOMAINS_ERROR');
            });
        }

        React.useEffect(() => {
            getAllDomains();
        }, [filters]);

        const addDomainsToTable = () => {
            if (domains.data) {
                return domains?.data?.map((item) => {
                    return [
                        { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.ID}</p></div> },
                        { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Domain}</p></div> },
                        { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.WebsiteName}</p></div> },
                        { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center', background: item.forOffer ? 'green' : 'red', width: 'fit-content', marginLeft: 'auto', marginRight: 'auto', padding: '5px', borderRadius: '10px' }}>{item.forOffer ? 'YES' : 'NO'}</p></div> },
                        { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{moment(item.updatedAt).format('DD.MM.YYYY.')}</p></div> },
                        {
                            keyID: String(item.ID), type: "groupNewline", group: [
                                {
                                    keyID: String(item.ID), type: "button", text: "Select", triggerDropdown: true, onClick: e => {
                                        setSelectedDomain({ ID: item.ID, URL: item.Domain });
                                        props.onClose();
                                    }
                                }
                            ]
                        }
                    ];
                });
            }
        }

        return <div className='route__emailServers__addNewModal__selectDomainModal'>
            <div id='box'>
                <div id='table'>
                    <FilteredCustomTable
                        id='table'
                        accent='#48525C'
                        theme='dark'
                        headers={['ID', 'Domain', 'Website name', 'For offer', 'Created at']}
                        filters={[
                            { name: "ID", friendlyName: "ID", type: "number" },
                            { name: "Domain", friendlyName: "Domain", type: "string" },
                            { name: "WebsiteName", friendlyName: "Website name", type: "string" },
                            { name: "updatedAt", friendlyName: "Date", type: "date" },
                        ]}
                        filterCB={f => setFilters(f)}
                        data={domains.data?.length > 0 ? addDomainsToTable() : [[{ keyID: 'noData', type: 'custom', data: <p>No data to display</p> }]]}
                    />
                </div>
                <div id="controles">
                    <Button value='Cancel' accent='transparent' style={{ borderBottom: '1px solid #e53333', borderRadius: '0px', color: '#e53333' }} onClick={props.onClose} />
                </div>
            </div>
        </div>
    }

    const addPackage = (close) => {
        setSpinner(true);

        if(!priceRef.current.value){
            setSpinner(false);
            return msgRef.current.innerText = 'Price missing!';
        }
        if(!selectedDomain){
            setSpinner(false);
            return msgRef.current.innerText = 'Domain missing!';
        }

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/boosts/addBoost`,
            data: {
                Price: priceRef.current.value,
                Amount: amountRef.current.value,
                DomainID: selectedDomain.ID,
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                getAllBoosts();
                close();
            }
            else {
                msgRef.current.innerText = 'Something went wrong';
            }
        }).catch(() => {
            msgRef.current.innerText = 'Something went wrong';
        }).finally(() => {
            setSpinner(false);
        });


    }

    return <div className='route__boosts__addNewModal'>
        <div id='box'>
            <h1>Add new boost</h1>

            <CustomInput autocomplete="off" placeholder="Price" ref={priceRef} accent="#FFF" theme="dark" type="number" style={{ width: "100%", marginBottom: '25px' }} />
            <CustomInput autocomplete="off" placeholder="Amount" ref={amountRef} accent="#FFF" theme="dark" type="number" style={{ width: "100%", marginBottom: '25px' }} />
            <Button value={selectedDomain === null ? 'SELECT DOMAIN' : selectedDomain.URL} accent='rgb(72, 82, 92)' className='selectDomainButton' onClick={(e) => openDomainsModal(e, false)} />

            <div id="controles" style={{ display: !spinner ? 'flex' : 'none' }}>
                <Button value='Cancel' accent='transparent' style={{ borderBottom: '1px solid #e53333', borderRadius: '0px', color: '#e53333' }} onClick={props.onClose} />
                <Button value='Add' accent='transparent' style={{ borderBottom: '1px solid #00A3FF', borderRadius: '0px', color: '#00A3FF' }} onClick={() => addPackage(props.onClose)} />
            </div>
            <div style={{ display: spinner ? 'flex' : 'none', flexDirection: 'row', justifyContent: 'flex-end' }}>
                <Spinner color='white' align='right' width='32px' />
            </div>
            <p id="msg" ref={msgRef}></p>
        </div>
    </div>
}

export { AddNewBoostModal }