import React from 'react';
import "./index.scss";

import CustomInput from '../../components/customComponents/CustomInput';
import Button from '../../components/customComponents/Button';
import Spinner from '../../components/customComponents/Spinner';
import { FilteredCustomTable } from "../../components/customComponents/Table";
import DataSorter from "../../components/customComponents/DataSorter";

import * as backendModule from "../../modules/backendModule";
import axios, { all } from "axios";
import moment from 'moment';

import Dropdown from '../../components/customComponents/Dropdown';

import { animateBox } from "../../modules/componentAnimation";

export default function DeletedUsers(props) {
  const [filters, setFilters] = React.useState([]);
  const [orders, setOrders] = React.useState([]);

  const [users, setUsers] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [hasMoreData, setHasMoreData] = React.useState(true);
  const observerRef = React.useRef(null);

  const getAllDeletedUsers = () => {
    setIsLoading(true);
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/deletedUsers/getAllDeletedUsers`,
      data: {
        filters: filters,
        orders: orders
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === 'ok') {
        setUsers(res.data.data);
        setHasMoreData(res.data.data.length)
      }
      else {
      }
    }).catch((err) => {
      console.log("ERROR while getting all deletion surveys ::", err)
    }).finally(() => {
      setIsLoading(false)
    });
  }

  const getMoreDeletedUsers = () => {
    if (!isLoading && hasMoreData) {
      setIsLoading(true);
      axios({
        method: "POST",
        url: `${backendModule.backendURL}/deletedUsers/getAllDeletedUsers`,
        data: {
          // filters: filters ? [...filters, {name: "ID", op: "notIn", value: usersSurveys.length ? usersSurveys.map(elem => elem.ID) : []}] : [{name: "ID", op: "notIn", value: usersSurveys.length ? usersSurveys.map(elem => elem.ID) : []}],
          filters: [...(Array.isArray(filters) ? filters : []), { name: "ID", op: "notIn", value: users.length ? users.map(elem => elem.ID) : []}],
          orders: orders
        },
        ...backendModule.axiosConfig
      }).then(res => {
        if (res.data.status === 'ok') {
          let newData = res.data.data;
          setUsers([...users, ...newData])
          setHasMoreData(res.data.data.length)
        }
        else {
        }
      }).catch((err) => {
        console.log("ERROR while getting all deletion surveys ::", err)
      }).finally(() => {
        setIsLoading(false)
      })
    }
  }

  const removeDeletedUser = (ID) => {
    if(!ID) return;

    axios({
      method: "POST",
      url: `${backendModule.backendURL}/deletedUsers/removeDeletedUser`,
      data: {
        ID: ID
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === 'ok') {
        getAllDeletedUsers();
      }
      else {
      }
    }).catch((err) => {
      console.log("ERROR while deleting survey :::", err)
    })
  }

  const MoreDetailsModal = (props) => {
    const [domainName, setDomainName] = React.useState('');
    
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/domains/getDomainByID`,
      data: {
          ID: props.curUser.DomainID
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === 'ok') {
        setDomainName(res.data.data.Domain)
      }
    }).catch((err) => {
      console.log("ERROR::: ", err)
    });

    return <div className='route__secondaryUsers__moreDetailsModal'>
      <div id='box'>
        <span id='domain-id'><strong>DOMAIN ID:</strong> <p>{props.curUser.DomainID}</p></span>
        <span id='domain-id'><strong>DOMAIN NAME:</strong> <p>{domainName}</p></span>
        <div id='gridbox'>
          <img src={String(props.curUser.Username).startsWith('--internal') ? props.curUser.Image : `${backendModule.backendURL}/staticContent/images/${props.curUser.Image}`} alt='' />
          <div id='top-right'>
            <p><strong>Name:</strong> {props.curUser.Name}</p>
            <p><strong>LastName:</strong> {props.curUser.LastName}</p>
            <p><strong>Username:</strong> {props.curUser.Username}</p>
            <p><strong>Email:</strong> {props.curUser.Email}</p>
          </div>
          <div id='middle-left'>
            <p><strong>Country:</strong> {props.curUser.Country !== '' ? props.curUser.Country : '-ND-'}</p>
            <p><strong>Phone:</strong> {props.curUser.PhoneNumber}</p>
            <p><strong>VIP:</strong> {props.curUser.isVIP ? 'YES' : 'NO'}</p>
            <p><strong>Gender:</strong> {props.curUser.Gender === true ? 'Male' : props.curUser.Gender === false ? 'Female' : 'Other'}</p>
            <p><strong>Last login:</strong> {moment(props.curUser.LastLogin).format('DD.MM.YYYY.')}</p>
            <p><strong>Publisher ID:</strong> {props.curUser.PublisherID}</p>
          </div>
          <div id='middle-right'>
            <p><strong>Birthday:</strong> {moment(props.curUser.Birthday).format('DD.MM.YYYY.')}</p>
            <p><strong>First Purchase:</strong> {props.curUser.firstPurchaseDone ? 'YES' : 'NO'}</p>
            <p><strong>Banned:</strong> {props.curUser.isBanned ? 'YES' : 'NO'}</p>
            <p><strong>Email verified:</strong> {props.curUser.isEmailVerified ? 'YES' : 'NO'}</p>
            <p><strong>Interested in Gender:</strong> {props.curUser.InterestedInGender === true ? 'Male' : props.curUser.InterestedInGender === false ? 'Female' : 'Other'}</p>
            <p><strong>Credits:</strong> {props.curUser.Credits}</p>
            <p><strong>City:</strong> {props.curUser.City}</p>
          </div>
          <div id="controles">
            <Button value='Close' accent='transparent' style={{ borderBottom: '1px solid #e53333', borderRadius: '0px', color: '#e53333' }} onClick={() => props.onClose()} />
          </div>
        </div>
      </div>
    </div>
  }

  React.useEffect(() => {
    getAllDeletedUsers();
  }, [filters, orders]);

  React.useEffect(() => {
    if(!observerRef.current) return;
    if (observerRef.current) {
      const observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && !isLoading && hasMoreData) {
          setTimeout(getMoreDeletedUsers, 300)
        }
      }, { threshold: 1 });

      setTimeout(() => observer.observe(observerRef.current), 300);

      return () => {try{
        observer.unobserve(observerRef.current);
      }catch{

      }}
    }
  }, [hasMoreData, observerRef.current]);


  const addDeletedUsersToTable = () => {
    if (users.length) {
      return users.map((item) => {
        return [
          { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.ID}</p></div> },
          { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.DomainID}</p></div> },
          { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.FirstName}</p></div> },
          { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.LastName}</p></div> },
          { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Username}</p></div> },
          { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Email}</p></div> },
          { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{moment(item.updatedAt).format('DD.MM.YYYY.')}</p></div> },
          {
            keyID: String(item.ID), type: "groupNewline", group: [
              {
                keyID: String(item.ID), type: "button", text: "More details", triggerDropdown: true, onClick: e => {
                  animateBox(e, <MoreDetailsModal curUser={
                    {
                      ID: item.ID,
                      DomainID: item.DomainID,
                      Birthday: item.Birthday,
                      Country: item.Country,
                      Credits: item.Credits,
                      Email: item.Email,
                      Flags: item.Flags,
                      Gender: item.Gender,
                      Image: item.Image,
                      InterestedInGender: item.InterestedInGender,
                      LastLogin: item.LastLogin,
                      LookingFor: item.LookingFor,
                      Name: item.FirstName,
                      LastName: item.LastName,
                      PublisherID: item.PublisherID,
                      Username: item.Username,
                      PhoneNumber: item.PhoneNumber,
                      Credits: item.Credits,
                      City: item.City
                    }
                  } />)
                }
              },
              {
                keyID: String(item.ID),
                type: "button",
                text: "Remove User",
                onClick: e=> { removeDeletedUser(item.ID) }
              }
            ]
          },
          
        ];
      });
    }
  }

  return (
    <div className='route__surveys'>
      <div className='route__surveys__users'>
        <p className='route__surveys__users__headline'>Deleted Users</p>
        <DataSorter sorters={[
          {name: 'FirstName', value:'FirstName'},
          {name: 'LastName', value: 'LastName'},
          {name: 'Username', value: 'Username'},
          {name: 'Email', value: 'Email'},
          {name: 'updatedAt', value:'updatedAt'},
        ]} setOrders={setOrders} />
        <FilteredCustomTable
          id='table'
          accent='#000'
          dropdownBackground='#000'
          inputBottomBorderColor='#FFF'
          placeholderStyle={{backgroundColor: '#000'}}
          filterHeadStyle={{backgroundColor: 'rgba(25, 35, 67, 0.2)'}}
          tableHeadStyle={{backgroundColor: 'rgba(25, 35, 67, 0.2)'}}
          buttonStyle={{border: '1px solid #FFF', backgroundColor: '#000'}}
          theme='dark'
          headers={['ID', 'DomainID', 'FirstName', 'LastName', 'Username', 'Email', 'Updated at']}
          filters={[
            { name: "ID", friendlyName: "ID", type: "string" },
            { name: "DomainID", friendlyName: "DomainID", type: "string" },
            { name: "FirstName", friendlyName: "FirstName", type: "string" },
            { name: "LastName", friendlyName: "LastName", type: "string" },
            { name: "Username", friendlyName: "Username", type: "string" },
            { name: "Email", friendlyName: "Email", type: "string" },
            { name: "updatedAt", friendlyName: "Updated at", type: "date" }
          ]}
          filterCB={f => setFilters(f)}
          data={users.length > 0 ? addDeletedUsersToTable() : [[{ keyID: 'noData', type: 'custom', data: <p>No data to display</p> }]]}
        />
        {!isLoading && hasMoreData && users.length > 0 && (
          <div ref={observerRef}></div>
        )}
      </div>
    </div>
  )
}