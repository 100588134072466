import React from 'react';
import "./index.scss";

import { useSelector } from 'react-redux';

import { useParams } from 'react-router-dom';
import axios from 'axios';

import * as backendModule from "../../modules/backendModule";
import * as socketModule from '../../modules/socketModule';
import Spinner from '../../components/customComponents/Spinner';
import DataSorter from "../../components/customComponents/DataSorter";

export default function PaymentPageStats(props) {

  const [data, setData] = React.useState(null);
  const [filters, setFilters] = React.useState([]);
  const [orders, setOrders] = React.useState([]);

  const [condition, setCondition] = React.useState({ name: 'Condition', value: null })
  const [conditionDropdownOpened, setConditionDropdownOpened] = React.useState(false);

  const [filterName, setFilterName] = React.useState({ name: 'Action', value: 'String' })
  const [filterNameDropdownOpened, setFilterNameDropdownOpened] = React.useState(false);
  const [filterParams, setFilterParams] = React.useState()

  const filterRequestValue = React.useRef();

  const getAllPaymentPageStats = (filterItem, filterReq, filterValue) => {

    axios({
      method: "POST",
      url: `${backendModule.backendURL}/paymentPageStats/getAllPaymentPageStats`,
      data: {
        offset: 0,
        filters: filters,
        orders: orders,
        filterItem: filterItem ? filterItem : null,
        filterValue: filterValue ? filterValue : null,
        filterReq: filterReq ? filterReq : null
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === 'ok') {
        setData(res.data);
        // if (res.data.data.length === 20) {
        //   setTimeout(() => setCanPaginate(true), 1000);
        // }
      }
      else {

      }
    }).catch((err) => {
      console.log("ERROR::", err)
    });
  }

  React.useEffect(() => {
    getAllPaymentPageStats();
  }, [])

  return <div className='route__paymentPageStats'>

    <div className="route__dashboardV2__table__filter" style={{ background: '#080B16', padding: '0px', marginBottom: '20px' }}>
      <div className="route__dashboardV2__table__filter__item" onClick={() => setFilterNameDropdownOpened(d => d = !d)}>
        <p>{filterName.name}</p>
        <div className="route__dashboardV2__table__filter__item__dropdown"
          style={{
            padding: filterNameDropdownOpened ? '10px' : '0px',
            maxHeight: filterNameDropdownOpened ? '1000px' : '0',
            border: filterNameDropdownOpened ? '1px solid #192343' : '0px'
          }}>
          <p onClick={() => setFilterName({ name: 'StepOne', value: 'Number' })}>Step One</p>
          <p onClick={() => setFilterName({ name: 'StepTwo', value: 'Number' })}>Step Two</p>
          <p onClick={() => setFilterName({ name: 'StepThree', value: 'Number' })}>Step Three</p>
          <p onClick={() => setFilterName({ name: 'InitiatedPayment', value: 'Number' })}>Initiated Payment</p>
          <p onClick={() => setFilterName({ name: 'PPS-ID', value: 'String' })}>ID</p>
          <p onClick={() => setFilterName({ name: 'PPS-name', value: 'String' })}>Name</p>
          <p onClick={() => setFilterName({ name: 'PPS-price', value: 'Number' })}>Price</p>
          <p onClick={() => setFilterName({ name: 'PPS-credits', value: 'Number' })}>Credits</p>
          <p onClick={() => setFilterName({ name: 'PPS-bought', value: 'Number' })}>Bought</p>
          <p onClick={() => setFilterName({ name: 'PPS-failed', value: 'Number' })}>Failed</p>
          <p onClick={() => setFilterName({ name: 'PPS-isVisible', value: 'Boolean' })}>Is Visible?</p>
          
        </div>
      </div>

      <div className="route__dashboardV2__table__filter__item" onClick={() => setConditionDropdownOpened(d => d = !d)}>
        <p>{condition.name}</p>
        <div className="route__dashboardV2__table__filter__item__dropdown"
          style={{
            padding: conditionDropdownOpened ? '10px' : '0px',
            maxHeight: conditionDropdownOpened ? '1000px' : '0',
            border: conditionDropdownOpened ? '1px solid #192343' : '0px'
          }}>
          {filterName.value === 'String' ? <p onClick={() => setCondition({ name: 'Equals', value: 'Equals' })}>Equals</p> : null}
          {filterName.value === 'String' ? <p onClick={() => setCondition({ name: 'Contains', value: 'Contains' })}>Contains</p> : null}
          {filterName.value === 'String' ? <p onClick={() => setCondition({ name: 'Starts with', value: 'StartsWith' })}>Starts with</p> : null}
          {filterName.value === 'Number' ? <p onClick={() => setCondition({ name: 'Equals', value: '===' })}>Equals</p> : null}
          {filterName.value === 'Number' ? <p onClick={() => setCondition({ name: 'Greater Than', value: 'GreaterThan' })}>Greater Than</p> : null}
          {filterName.value === 'Number' ? <p onClick={() => setCondition({ name: 'Less Than', value: 'LessThan' })}>Less Than</p> : null}
          {filterName.value === 'Boolean' ? <p onClick={() => setCondition({ name: 'Equals', value: 'True' })}>Equals True</p> : null}
          {filterName.value === 'Boolean' ? <p onClick={() => setCondition({ name: 'Equals', value: 'False' })}>Equals False</p> : null}
        </div>
      </div>

      {filterName.value === 'String' || filterName.value === 'Number' ? <input ref={filterRequestValue} style={{ background: '#080B16' }} onChange={() => { setFilterParams({ filterType: 'string', filterValue: filterRequestValue.current.value }) }}></input> : null}
      {filterName.value === 'Boolean' ? <div className="route__dashboardV2__table__filter__item" ref={filterRequestValue}>
        {condition.value === 'True' ? <p>True</p> : condition.value === 'False' ? <p>False</p> : ''}</div> : null}


      <div className="route__dashboardV2__table__filter__btn" onClick={() => { getAllPaymentPageStats(filterName.name, condition.value, filterParams) }}>Filter</div>
      <div className="route__dashboardV2__table__filter__btn" onClick={() => { getAllPaymentPageStats() }}>Reset</div>

    </div>

    {/* <DataSorter dropdownBackground='#000' dropdownStyle={{ backgroundColor: '#000' }} sorters={[
      { name: 'Online', value: 'Online' },
      { name: 'Is Premium?', value: 'IsPremiumLady' },
      { name: 'Date Of Birth', value: 'DateOfBirth' },
      { name: 'First Name', value: 'FirstName' },
      { name: 'Last Name', value: 'LastName' },
      { name: 'Wight', value: 'Weight' },
      { name: 'Height', value: 'Height' },
    ]} setOrders={setOrders} /> */}


    {data && data.status === 'ok' && data?.data.length && data.data.map(item => {
      if(item.InitiatedPayment === 0 &&
        item.StepOne === 0 &&
        item.StepTwo === 0 &&
        item.StepThree === 0 &&
        JSON.parse(item?.UniqueBuyers).length === 0 &&
        JSON.parse(item?.BoughtPackages).length === 0) return;
      return <div className='route__paymentPageStats__item'>
        {item && <p className='route__paymentPageStats__item__headline'>{item.WebsiteName} <span>DomainID : {item.DomainID}</span></p>}
        <div className='route__paymentPageStats__item__buycredit'>
          <p>Step 1</p>
          <p>Step 2</p>
          <p>Step 3</p>
          <p>Initiated Payment</p>
          <p>Unique Buyers</p>
          {item && <p>{item.StepOne}</p>}
          {item && <p>{item.StepTwo}</p>}
          {item && <p>{item.StepThree}</p>}
          {item && <p>{item.InitiatedPayment}</p>}
          {item && JSON.parse(item.UniqueBuyers).length ? <p>{JSON.parse(item.UniqueBuyers).length}</p> : <p>0</p>}
        </div>
        {item && <div className='route__paymentPageStats__item__packages'>
          <div className='route__paymentPageStats__item__packages__head'>
            <p style={{ paddingLeft: '40px' }}>ID</p>
            <p>Name</p>
            <p>Price</p>
            <p>Credits</p>
            <p>Failed</p>
            <p>Bought</p>
          </div>
          {item && JSON.parse(item?.BoughtPackages).length && JSON.parse(item?.BoughtPackages).map((bPackage, index) => {
            return <div className='route__paymentPageStats__item__packages__item'  style={{ backgroundColor: index % 2 === 0 ? '#192343' : '#1923438e'}}>
              <p style={{ color: '#FFF' }}><span>{bPackage.ID}</span></p>
              <p style={{ color: '#FFF' }}>{bPackage.name}</p>
              <p style={{ color: '#FFF' }}>{bPackage.price}</p>
              <p style={{ color: '#FFF' }}>{Number(bPackage.credits).toFixed(0)}</p>
              <p style={{ color: '#FFF' }}>{bPackage.failed}</p>
              <p style={{ color: '#FFF' }}>{bPackage.bought}</p>
            </div>
          })}
        </div>}
      </div>
    })}


  </div>
}

