import React from "react";
import "./index.scss";

import axios from "axios";
import moment from "moment";
import * as backendModule from "../../modules/backendModule";
import useOnScreen from "../../modules/hooks/useOnScreen";
import useDefer from "../../modules/hooks/useDefer";
import { lookingFor } from "../../modules/miscModule";

import Spinner from "../../components/customComponents/Spinner";
import FilterPanel from "../../components/customComponents/FilterPanelNew";
import Dropdown from "../../components/customComponents/Dropdown";

import { animateBox } from "../../modules/componentAnimation";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

const Dashboard_v2 = () => {
  const [realtimeUsers, setRealtimeUsers] = React.useState(null);
  const [allDomains, setAllDomains] = React.useState([]);
  const [data, setData] = React.useState();
  const [spinner, setSpinner] = React.useState(true);
  const [error, setError] = React.useState(false);
  const [filters, setFilters] = React.useState([]);

  const prepareFilters = curDate => {
    switch (curDate) {
      case "today": return [{ name: "createdAt", op: "deq", value: moment().toDate().getTime() }];
      case "yesterday": return [{ name: "createdAt", op: "deq", value: moment().add(-1, "days").toDate().getTime() }];
      case "currentMonth": return [
        { name: "createdAt", op: "dgeq", value: moment().startOf("month").toDate().getTime() },
        { name: "createdAt", op: "dleq", value: moment().endOf("month").toDate().getTime() }
      ];
      case "lastMonth": return [
        { name: "createdAt", op: "dgeq", value: moment().add(-1, "month").startOf("month").toDate().getTime() },
        { name: "createdAt", op: "dleq", value: moment().add(-1, "month").endOf("month").toDate().getTime() }
      ];
      default: return [];
    }
  };

  const getAllDomains = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/domains/getAllDomains`,
      data: {
        limit: null,
        offset: 0
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        setAllDomains(res.data.data);
      };
    }).catch(() => null);
  };

  const getKPIData = (key, date) => {
    const valueToScientific = val => {
      val = Number(val);
      if (isNaN(val)) return 0;

      if (val < 1000) return val.toFixed(2);
      if (val < 1000000) return `${(val / 1000).toFixed(2)}k`;
      if (val < 1000000000) return `${(val / 1000000).toFixed(2)}m`;
      if (val < 1000000000000) return `${(val / 1000000000).toFixed(2)}b`;
    };

    let suffix = "";

    if (key === "conversionRate") suffix = "%";
    if (key === "totalRevenue") suffix = "€";

    if (!data) return `NA${suffix ? " " + suffix : ""}`;
    if (error) return `NA${suffix ? " " + suffix : ""}`;

    if (!data[date]) return `NA${suffix ? " " + suffix : ""}`;
    if (data[date][key] === undefined) return `NA${suffix ? " " + suffix : ""}`;
    if (!data[date][key]) return `0${suffix ? " " + suffix : ""}`;

    return `${valueToScientific(data[date][key])}${suffix ? " " + suffix : ""}`
  };

  const getData = async () => {
    setError(false);

    let out = {
      today: null,
      yesterday: null,
      currentMonth: null,
      lastMonth: null
    };

    setSpinner(true);
    for (let key of Object.keys(out)) {
      let tmp = await axios({
        method: "POST",
        url: `${backendModule.backendURL}/statistics/getDashboardStats`,
        data: {
          filters: prepareFilters(key)
        },
        ...backendModule.axiosConfig
      }).then(res => {
        return res.data
      }).catch(() => {
        return { status: "error", data: "SERVER_ERROR" };
      });

      if (tmp.status === "ok") {
        out[key] = tmp.data;
      } else {
        setError(true);
        return;
      };
    };
    setSpinner(false);
    setData(out);
  };

  const getDataFiltered = () => {
    setSpinner(true);
    setError(false);

    axios({
      method: "POST",
      url: `${backendModule.backendURL}/statistics/getDashboardStats`,
      data: {
        filters
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        setData({ "currentMonth": res.data.data });
      } else {
        setError(true);
      };
    }).catch(() => {
      setError(true);
    }).finally(() => {
      setSpinner(false);
    });
  };

  const getRealtimeUsers = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/statistics/getDashboardRealtimeUsers`,
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        setRealtimeUsers({ value: res.data.data, date: Date.now() });
      } else {
        setRealtimeUsers({ value: "?", date: Date.now() });
      };
    }).catch(() => {
      setRealtimeUsers({ value: "?", date: Date.now() });
    });
  };

  React.useEffect(() => {
    if (filters.length === 0) {
      getData();
    } else {
      getDataFiltered();
    };
  }, [filters]);

  React.useEffect(() => {
    let h = null;

    if (filters.length === 0) {
      h = setTimeout(() => {
        getRealtimeUsers();
      }, realtimeUsers !== null ? 5000 : 0);
    } else {
      if (realtimeUsers !== null) setRealtimeUsers(null);
    };

    return () => clearTimeout(h);
  }, [realtimeUsers, filters]);

  React.useEffect(() => {
    getAllDomains();
  }, []);

  return <div className="route__dashboardV2">
    <div className="route__dashboardV2__head">CupidCoast Stats</div>

    <FilterPanel
      theme="dark"
      accent="rgb(62, 87, 166)"
      style={{color: 'yellow'}}
      filters={[
        { name: "createdAt", friendlyName: "Date", type: "date" },
        {
          name: "DomainID", friendlyName: "Domain", type: "custom", varType: "string", data: allDomains.map(d => {
            return { text: d.Domain, value: d.ID };
          })
        }
      ]}
      filterCB={f => {
        setSpinner(true);
        setData();
        setFilters(f);
      }}
    />
    <p style={{ marginBottom: "20px" }}></p>

    <div className={`route__dashboardV2__kpiPanel ${filters.length > 0 ? "route__dashboardV2__kpiPanel--filtered" : ""}`}>
      <Dashboard_v2__kpiPanel
        headline="SoiDoi"
        spinner={spinner}
        error={error}
        data_today={getKPIData("soidoi", "today")}
        data_yesterday={getKPIData("soidoi", "yesterday")}
        data_lastMonth={getKPIData("soidoi", "lastMonth")}
        data_currentMonth={getKPIData("soidoi", "currentMonth")}
        filtered={filters.length > 0}
      />
      <Dashboard_v2__kpiPanel
        headline="Active users"
        spinner={spinner || (realtimeUsers === null && filters.length === 0)}
        error={error}
        data_today={getKPIData("activeUsers", "today")}
        data_yesterday={getKPIData("activeUsers", "yesterday")}
        data_lastMonth={getKPIData("activeUsers", "lastMonth")}
        {...(filters.length === 0 ? {
          data_currentMonth: realtimeUsers?.value,
          realtime: true
        } : {
          data_currentMonth: getKPIData("activeUsers", "currentMonth"),
          realtime: false
        })}
        filtered={filters.length > 0}
      />
      <Dashboard_v2__kpiPanel
        headline="Clicks"
        spinner={spinner}
        error={error}
        data_today={getKPIData("clicks", "today")}
        data_yesterday={getKPIData("clicks", "yesterday")}
        data_lastMonth={getKPIData("clicks", "lastMonth")}
        data_currentMonth={getKPIData("clicks", "currentMonth")}
        filtered={filters.length > 0}
      />
      <Dashboard_v2__kpiPanel
        headline="Conversions"
        spinner={spinner}
        error={error}
        data_today={getKPIData("conversions", "today")}
        data_yesterday={getKPIData("conversions", "yesterday")}
        data_lastMonth={getKPIData("conversions", "lastMonth")}
        data_currentMonth={getKPIData("conversions", "currentMonth")}
        filtered={filters.length > 0}
      />
      <Dashboard_v2__kpiPanel
        headline="Verified users"
        spinner={spinner}
        error={error}
        data_today={getKPIData("verifiedUsers", "today")}
        data_yesterday={getKPIData("verifiedUsers", "yesterday")}
        data_lastMonth={getKPIData("verifiedUsers", "lastMonth")}
        data_currentMonth={getKPIData("verifiedUsers", "currentMonth")}
        filtered={filters.length > 0}
      />
      <Dashboard_v2__kpiPanel
        headline="Conversion rate"
        spinner={spinner}
        error={error}
        data_today={getKPIData("conversionRate", "today")}
        data_yesterday={getKPIData("conversionRate", "yesterday")}
        data_lastMonth={getKPIData("conversionRate", "lastMonth")}
        data_currentMonth={getKPIData("conversionRate", "currentMonth")}
        filtered={filters.length > 0}
      />
      <Dashboard_v2__kpiPanel
        headline="Total revenue"
        spinner={spinner}
        error={error}
        data_today={getKPIData("totalRevenue", "today")}
        data_yesterday={getKPIData("totalRevenue", "yesterday")}
        data_lastMonth={getKPIData("totalRevenue", "lastMonth")}
        data_currentMonth={getKPIData("totalRevenue", "currentMonth")}
        filtered={filters.length > 0}
      />
    </div>

    <Dashboard_v2__chart filters={filters} />
    <Dashboard_v2__table filters={filters} />
  </div>
};

const Dashboard_v2__kpiPanel = props => {

  const calculatePercentage = () => {
    let a1 = props.data_currentMonth;
    let a2 = props.data_lastMonth;

    if (String(a1).includes(" ")) {
      a1 = Number(a1.split(" ").shift());
    };
    if (String(a2).includes(" ")) {
      a2 = Number(a2.split(" ").shift());
    };

    let tmp = ((a1 / a2) - 1) * 100;
    if (isNaN(tmp)) tmp = 0;
    return tmp.toFixed(2);
  };

  const DrawLine = () => <svg width="202" height="2" viewBox="0 0 202 2" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 1H201" stroke="url(#paint0_linear_5_301)" stroke-linecap="round" />
    <defs>
      <linearGradient id="paint0_linear_5_301" x1="201" y1="1" x2="1" y2="1" gradientUnits="userSpaceOnUse">
        <stop stop-color="white" stop-opacity="0" />
        <stop offset="0.489583" stop-color="white" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </linearGradient>
    </defs>
  </svg>;

  return <div className="route__dashboardV2__kpiPanel__kpi">
    <div className={`route__dashboardV2__kpiPanel__kpi__error ${props.error ? "route__dashboardV2__kpiPanel__kpi__error--active" : ""}`}>
      Error while fetching data
    </div>

    <div className="route__dashboardV2__kpiPanel__kpi__head">{props.headline}</div>
    <div className="route__dashboardV2__kpiPanel__kpi__wrap">
      <div className="route__dashboardV2__kpiPanel__kpi__wrap__stats">
        <div className="route__dashboardV2__kpiPanel__kpi__wrap__stats__top">
          <div className="route__dashboardV2__kpiPanel__kpi__wrap__stats__top__left">{props.filtered ? "Filtered result" : (props.realtime ? "Realtime (30mins)" : "Current month")}</div>
          {!props.filtered && <div className={`route__dashboardV2__kpiPanel__kpi__wrap__stats__top__right ${calculatePercentage() > 0 ? "route__dashboardV2__kpiPanel__kpi__wrap__stats__top__right--blue" : ""}`}>
            {(!props.spinner && !props.realtime) && <>
              <img src={`/images/DashboardV2/chart_${calculatePercentage() > 0 ? "up" : "down"}.svg`} />
              <span>{calculatePercentage()} %</span>
            </>}
          </div>}
        </div>
        <div className="route__dashboardV2__kpiPanel__kpi__wrap__stats__bottom">
          {props.spinner ? <Spinner style={{ width: "32px", height: "32px" }} color="#4FB2F3" /> : props.data_currentMonth ?? "?"}
        </div>
      </div>

      {!props.filtered && <div className="route__dashboardV2__kpiPanel__kpi__wrap__info">
        <div className="route__dashboardV2__kpiPanel__kpi__wrap__info__data">
          <div className="route__dashboardV2__kpiPanel__kpi__wrap__info__data__name">Today</div>
          <div className="route__dashboardV2__kpiPanel__kpi__wrap__info__data__value">{props.spinner ? <Spinner color="#4FB2F3" style={{ width: "12px", height: "12px" }} /> : props.data_today}</div>
        </div>

        {DrawLine()}

        <div className="route__dashboardV2__kpiPanel__kpi__wrap__info__data">
          <div className="route__dashboardV2__kpiPanel__kpi__wrap__info__data__name">Yesterday</div>
          <div className="route__dashboardV2__kpiPanel__kpi__wrap__info__data__value">{props.spinner ? <Spinner color="#4FB2F3" style={{ width: "12px", height: "12px" }} /> : props.data_yesterday}</div>
        </div>

        {DrawLine()}

        <div className="route__dashboardV2__kpiPanel__kpi__wrap__info__data">
          <div className="route__dashboardV2__kpiPanel__kpi__wrap__info__data__name">Last month</div>
          <div className="route__dashboardV2__kpiPanel__kpi__wrap__info__data__value">{props.spinner ? <Spinner color="#4FB2F3" style={{ width: "12px", height: "12px" }} /> : props.data_lastMonth}</div>
        </div>
      </div>}
    </div>
  </div>
};

const Dashboard_v2__chart = props => {
  const [data, setData] = React.useState({ labels: [], datasets: [] });
  const [selectedMetrics, setSelectedMetrics] = React.useState(["Clicks", "Conversions"]);
  const [selectedTime, setSelectedTime] = React.useState("Day");

  const dropdownResetRef = React.useRef(() => null);

  const setChartColor = (context, key) => {
    const ctx = context.chart.ctx;
    const gradient = ctx.createLinearGradient(0, 0, 0, 250);
    switch (key) {
      case "Conversions":
        gradient.addColorStop(0, "#E717B3");
        gradient.addColorStop(1, "rgba(17, 24, 47, 0.00)");
        break;
      case "Revenue":
        gradient.addColorStop(0, "#BFF34F");
        gradient.addColorStop(1, "rgba(17, 24, 47, 0.00)");
        break;
      case "Active users":
        gradient.addColorStop(0, "#844FF3");
        gradient.addColorStop(1, "rgba(17, 24, 47, 0.00)");
        break;
      case "Clicks":
      default:
        gradient.addColorStop(0, "#4FB2F3");
        gradient.addColorStop(1, "rgba(17, 24, 47, 0.00)");
        break;
    };
    return gradient;
  };

  const setChartBorderColor = key => {
    switch (key) {
      case "Clicks": return "#4FB2F3";
      case "Conversions": return "#E717B3";
      case "Revenue": return "#BFF34F";
      case "Active users": return "#844FF3";
      default: return "#4FB2F3";
    };
  };

  const allMetrics = [
    "Revenue",
    "Clicks",
    "Conversions",
    "Active users"
  ];

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
  );

  React.useEffect(() => {
    let startDate = moment().add(-14, "days");
    let endDate = moment().endOf("day");

    let finalFilters = [];
    for (let item of (props.filters ?? [])) {
      if (item?.name === "createdAt") {
        if (item.op === "dgeq" || item.op === "dgt") startDate = moment(item.value).startOf("day");
        if (item.op === "dleq" || item.op === "dlt") endDate = moment(item.value).endOf("day");
        if (item.op === "deq") {
          startDate = moment(item.value).startOf("day");
          endDate = moment(item.value).endOf("day");
        };
      } else {
        finalFilters.push(item);
      };
    };
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/statistics/getChartAnalyticsData`,
      data: {
        startDate: startDate.toDate().getTime(),
        endDate: endDate.toDate().getTime(),
        filters: finalFilters,
        selectedTime,
        selectedMetrics
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        setData(res.data.data);
      } else {
        setData({ labels: [], datasets: [] });
      };
    }).catch(() => {
      setData({ labels: [], datasets: [] });
    });

  }, [props.filters, selectedTime, selectedMetrics]);

  return <div className="route__dashboardV2__chart">
    <div className="route__dashboardV2__chart__head">Performance</div>

    <div className="route__dashboardV2__chart__chart">
      <div className="route__dashboardV2__chart__chart__chartHead">
        <div className="route__dashboardV2__chart__chart__chartHead__item">
          <p>Metrics</p>
          <div className="route__dashboardV2__chart__chart__chartHead__item__metrics">
            {selectedMetrics.map(m => <div className="route__dashboardV2__chart__chart__chartHead__item__metrics__metric" onClick={() => {
              setSelectedMetrics(m2 => m2.filter(m2i => m2i !== m));
            }}>{m}</div>)}
          </div>
        </div>
        <div className="route__dashboardV2__chart__chart__chartHead__item">
          {allMetrics.length !== selectedMetrics.length && <Dropdown theme="dark" accent="#4FB2F3" data={allMetrics.filter(f => !selectedMetrics.includes(f)).map(m => {
            return { name: m, value: m };
          })} inlinePlaceholder="Select a metric..." onChange={e => {
            setSelectedMetrics(sm => [...sm, e?.value]);
            dropdownResetRef.current?.();
          }} onReset={e => dropdownResetRef.current = e} />}
          <p>Time</p>
          <Dropdown theme="dark" accent="#4FB2F3" data={[
            { name: "Hour (max 7 days)", value: "Hour" },
            { name: "Day", value: "Day" },
            { name: "Week", value: "Week" },
            { name: "Month", value: "Month" },
          ]} selected={(() => {
            switch (selectedTime) {
              case "Hour": return 0;
              case "Day": return 1;
              case "Week": return 2;
              case "Month": return 3;
              default: return 0;
            };
          })()} onChange={e => setSelectedTime(e?.value)} />
        </div>
      </div>

      <div className="route__dashboardV2__chart__chart__chartItem">
        <Line
          style={{ width: "100%", height: "100%" }}
          options={{
            maintainAspectRatio: false,
            scales: {
              y: {
                ticks: {
                  stepSize: 1
                }
              }
            },
            plugins: {
              legend: {
                position: 'top'
              },
              title: {
                display: false
              },
            }
          }}
          data={{
            labels: data.labels, datasets: Object.keys(data.datasets).map((key) => {
              return {
                fill: true,
                data: data.labels.map(l => {
                  if (!data.datasets[key][l]) return 0;
                  return data.datasets[key][l];
                }),
                label: key,
                borderColor: setChartBorderColor(key),
                backgroundColor: context => setChartColor(context, key)
              };
            })
          }}
        />
      </div>
    </div>
  </div>
};

const Dashboard_v2__table = props => {
  const [data, setData] = React.useState();
  // const [filters, setFilters] = React.useState();
  const [canPaginate, setCanPaginate] = React.useState();
  const [spinner, setSpinner] = React.useState(false);

  const [filterName, setFilterName] = React.useState({ name: 'Name', value: 'String' })
  const [filterNameDropdownOpened, setFilterNameDropdownOpened] = React.useState(false);

  const [condition, setCondition] = React.useState({ name: 'Condition', value: null })
  const [conditionDropdownOpened, setConditionDropdownOpened] = React.useState(false);

  const [filterParams, setFilterParams] = React.useState()

  const filterRequestValue = React.useRef();
  const filterReqValHours = React.useRef();
  const filterReqValMins = React.useRef();
  const filterReqValSecs = React.useRef();

  const curOnScreen = useOnScreen();
  const curDefer = useDefer();


  // TODO implement sorting to the table without pagination!!
  const getData = (filterItem, filterReq, filterValue) => {
    if (canPaginate) setCanPaginate(false);

    axios({
      method: "POST",
      url: `${backendModule.backendURL}/statistics/getDashboardTableAnalyticsData`,
      data: {
        // filters: filters,
        // limit: 20,
        // offset: 0,
        filterItem: filterItem ? filterItem : null,
        filterValue: filterValue ? filterValue : null,
        filterReq: filterReq ? filterReq : null
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        setData(res.data);
        if (res.data.data.length === 20) setCanPaginate(true);
      } else {
        setData({ status: "error", data: "SERVER_ERROR" });
      };
    }).catch(() => {
      setData({ status: "error", data: "SERVER_ERROR" });
    });
  };



  // const continueData = () => {
  //     if (canPaginate) setCanPaginate(false);

  //     setSpinner(true);
  //     axios({
  //         method: "POST",
  //         url: `${backendModule.backendURL}/statistics/getDashboardTableAnalyticsData`,
  //         data: {
  //             filters: props.filters,
  //             limit: 20,
  //             offset: data.data.length
  //         },
  //         ...backendModule.axiosConfig
  //     }).then(res => {
  //         if (res.data.status === "ok") {
  //             setData(d => {
  //                 return {
  //                     ...d,
  //                     data: [
  //                         ...d.data,
  //                         ...res.data.data
  //                     ]
  //                 };
  //             });
  //             if (res.data.data.length === 20) setCanPaginate(true);
  //         };
  //     }).catch(() => {
  //         return null;
  //     }).finally(() => {
  //         setSpinner(false);
  //     });
  // };

  React.useEffect(() => {
    getData();
  }, [props.filters]);


  // React.useEffect(() => {
  //     if (!canPaginate) return;
  //     if (!curOnScreen.isIntersecting) return;

  //     try {
  //         curOnScreen.observer.unobserve(curOnScreen.measureRef.current);
  //     } catch {};

  //     curDefer(continueData, 500);
  // }, [canPaginate, curOnScreen.isIntersecting]);

  return <div className="route__dashboardV2__table">

    <div className="route__dashboardV2__table__head">Analytics</div>

    <div className="route__dashboardV2__table__filter">
      <div className="route__dashboardV2__table__filter__item" onClick={() => setFilterNameDropdownOpened(d => d = !d)}>
        <p>{filterName.name}</p>
        <div className="route__dashboardV2__table__filter__item__dropdown"
          style={{
            padding: filterNameDropdownOpened ? '10px' : '0px',
            maxHeight: filterNameDropdownOpened ? '1000px' : '0',
            border: filterNameDropdownOpened ? '1px solid #192343' : '0px'
          }}>
          <p onClick={() => setFilterName({ name: 'Name', value: 'String' })}>Name</p>
          <p onClick={() => setFilterName({ name: 'TotalRevenue', value: 'Number' })}>TotalRevenue</p>
          <p onClick={() => setFilterName({ name: 'Credits', value: 'Number' })}>Credits</p>
          <p onClick={() => setFilterName({ name: 'RegistrationDate', value: 'Date' })}>RegistrationDate</p>
          <p onClick={() => setFilterName({ name: 'Country', value: 'String' })}>Country</p>
          <p onClick={() => setFilterName({ name: 'LastActiveTime', value: 'Date' })}>LastActiveTime</p>
          <p onClick={() => setFilterName({ name: 'MonthlySubscription', value: 'Boolean' })}>MonthlySubscription</p>
          <p onClick={() => setFilterName({ name: 'PurchasedPackages', value: 'Number' })}>PurchasedPackages</p>
          <p onClick={() => setFilterName({ name: 'AverageActiveTime', value: 'Time' })}>AverageActiveTime</p>
          <p onClick={() => setFilterName({ name: 'TodaysActiveTime', value: 'Time' })}>TodaysActiveTime</p>
          <p onClick={() => setFilterName({ name: 'TotalActiveTime', value: 'Time' })}>TotalActiveTime</p>
          <p onClick={() => setFilterName({ name: 'VerifiedEmail', value: 'Boolean' })}>VerifiedEmail</p>
          <p onClick={() => setFilterName({ name: 'VerifiedNumber', value: 'Boolean' })}>VerifiedNumber</p>
          <p onClick={() => setFilterName({ name: 'VerifiedProfilePicture', value: 'Boolean' })}>VerifiedProfilePicture</p>
          <p onClick={() => setFilterName({ name: 'NumberOfProfilesVisited', value: 'Number' })}>NumberOfProfilesVisited</p>
          <p onClick={() => setFilterName({ name: 'NumberOfChatsStarted', value: 'Number' })}>NumberOfChatsStarted</p>
          <p onClick={() => setFilterName({ name: 'UnsuccessfulTransactions', value: 'Number' })}>UnsuccessfulTransactions</p>
        </div>
      </div>

      <div className="route__dashboardV2__table__filter__item" onClick={() => setConditionDropdownOpened(d => d = !d)}>
        <p>{condition.name}</p>
        <div className="route__dashboardV2__table__filter__item__dropdown"
          style={{
            padding: conditionDropdownOpened ? '10px' : '0px',
            maxHeight: conditionDropdownOpened ? '1000px' : '0',
            border: conditionDropdownOpened ? '1px solid #192343' : '0px'
          }}>
          {filterName.value === 'String' ? <p onClick={() => setCondition({ name: 'Equals', value: 'Equals' })}>Equals</p> : null}
          {filterName.value === 'String' ? <p onClick={() => setCondition({ name: 'Contains', value: 'Contains' })}>Contains</p> : null}
          {filterName.value === 'String' ? <p onClick={() => setCondition({ name: 'Starts with', value: 'StartsWith' })}>Starts with</p> : null}
          {filterName.value === 'Number' ? <p onClick={() => setCondition({ name: 'Equals', value: '===' })}>Equals</p> : null}
          {filterName.value === 'Number' ? <p onClick={() => setCondition({ name: 'Greater Than', value: 'GreaterThan' })}>Greater Than</p> : null}
          {filterName.value === 'Number' ? <p onClick={() => setCondition({ name: 'Less Than', value: 'LessThan' })}>Less Than</p> : null}
          {filterName.value === 'Boolean' ? <p onClick={() => setCondition({ name: 'Equals', value: 'True' })}>Equals True</p> : null}
          {filterName.value === 'Boolean' ? <p onClick={() => setCondition({ name: 'Equals', value: 'False' })}>Equals False</p> : null}
          {filterName.value === 'Date' ? <p onClick={() => setCondition({ name: 'Greater Than', value: 'DateGreaterThan' })}>Greater Than</p> : null}
          {filterName.value === 'Date' ? <p onClick={() => setCondition({ name: 'Less Than', value: 'DateLessThan' })}>Less Than</p> : null}
          {filterName.value === 'Date' ? <p onClick={() => setCondition({ name: 'Equals', value: 'DateEquals' })}>Equals</p> : null}
          {filterName.value === 'Time' ? <p onClick={() => setCondition({ name: 'Equals', value: 'TimeEquals' })}>Equals</p> : null}
          {filterName.value === 'Time' ? <p onClick={() => setCondition({ name: 'Greater Than', value: 'TimeGreaterThan' })}>Greater Than</p> : null}
          {filterName.value === 'Time' ? <p onClick={() => setCondition({ name: 'Less Than', value: 'TimeLessThan' })}>Less Than</p> : null}
        </div>
      </div>

      {filterName.value === 'Date' ? <input type="date" ref={filterRequestValue} onChange={() => { setFilterParams({ filterType: 'date', filterValue: filterRequestValue.current.value }) }}></input> : null}
      {filterName.value === 'Time' ? <div className="route__dashboardV2__table__filter__timeBox">
        <input ref={filterReqValHours} type="number" placeholder='hh' className="route__dashboardV2__table__filter__timeBox__hour"
          onChange={() => { setFilterParams({ filterType: 'time', filterValue: `${filterReqValHours.current.value}:${filterReqValMins.current.value}:${filterReqValSecs.current.value}` }) }} />
        <input ref={filterReqValMins} type="number" placeholder='mm' className="route__dashboardV2__table__filter__timeBox__min" maxLength={2}
          onChange={() => { setFilterParams({ filterType: 'time', filterValue: `${filterReqValHours.current.value}:${filterReqValMins.current.value}:${filterReqValSecs.current.value}` }) }} />
        <input ref={filterReqValSecs} type="number" placeholder='ss' className="route__dashboardV2__table__filter__timeBox__sec" maxLength={2}
          onChange={() => { setFilterParams({ filterType: 'time', filterValue: `${filterReqValHours.current.value}:${filterReqValMins.current.value}:${filterReqValSecs.current.value}` }) }} />
      </div> : null}
      {filterName.value === 'String' || filterName.value === 'Number' ? <input ref={filterRequestValue} onChange={() => { setFilterParams({ filterType: 'string', filterValue: filterRequestValue.current.value }) }}></input> : null}
      {filterName.value === 'Boolean' ? <div className="route__dashboardV2__table__filter__item" ref={filterRequestValue}>
        {condition.value === 'True' ? <p>True</p> : condition.value === 'False' ? <p>False</p> : ''}</div> : null}


      <div className="route__dashboardV2__table__filter__btn" onClick={() => { getData(filterName.name, condition.value, filterParams) }}>Filter</div>
      <div className="route__dashboardV2__table__filter__btn" onClick={() => { getData() }}>Reset</div>

      <div className="route__dashboardV2__table__filter__btn">Total Results : {data?.data && data.data.length}</div>

    </div>



    <div className="route__dashboardV2__table__content">
      <div className="route__dashboardV2__table__content__table">
        <div className="route__dashboardV2__table__content__table__row route__dashboardV2__table__content__table__row--head">
          <div className="route__dashboardV2__table__content__table__row__column route__dashboardV2__table__content__table__row__column__name">Name</div>
          <div className="route__dashboardV2__table__content__table__row__column">Total revenue</div>
          <div className="route__dashboardV2__table__content__table__row__column">Credits</div>
          <div className="route__dashboardV2__table__content__table__row__column">Registration date</div>
          <div className="route__dashboardV2__table__content__table__row__column">Country</div>
          <div className="route__dashboardV2__table__content__table__row__column">Last active time</div>
          <div className="route__dashboardV2__table__content__table__row__column">Monthly subscription</div>
          <div className="route__dashboardV2__table__content__table__row__column">Purchased packages</div>
          <div className="route__dashboardV2__table__content__table__row__column">Average active time</div>
          <div className="route__dashboardV2__table__content__table__row__column">Todays active time</div>
          <div className="route__dashboardV2__table__content__table__row__column">Total active time</div>
          <div className="route__dashboardV2__table__content__table__row__column">Verified e-mail</div>
          <div className="route__dashboardV2__table__content__table__row__column">Verified number</div>
          <div className="route__dashboardV2__table__content__table__row__column">Verified profile picture</div>
          <div className="route__dashboardV2__table__content__table__row__column">Number of profiles visited</div>
          <div className="route__dashboardV2__table__content__table__row__column">Unsucessful transactions</div>
        </div>

        {data ? <>
          {data.status === "ok" ? (data.data.length > 0 ? data.data.map((item, itemIndex) => {
            return <div className="route__dashboardV2__table__content__table__row" ref={(itemIndex === data.data.length - 1 && !spinner) ? curOnScreen.measureRef : null}
              onClick={(e) => animateBox(e, <SecondaryUserMoreInfoModal ID={item.ID} />)}>
              <div className="route__dashboardV2__table__content__table__row__column route__dashboardV2__table__content__table__row__column__name">{item.Username ?? item.Username}</div>
              <div className="route__dashboardV2__table__content__table__row__column">{Number(item.TotalRevenue).toFixed(2)} €</div>
              <div className="route__dashboardV2__table__content__table__row__column">{Number(item.Credits).toFixed(0)}</div>
              <div className="route__dashboardV2__table__content__table__row__column">{(new Date(item.RegistrationDate)).toLocaleString()}</div>
              <div className="route__dashboardV2__table__content__table__row__column">{item.Country}</div>
              <div className="route__dashboardV2__table__content__table__row__column">{(new Date(item.LastActiveTime)).toLocaleString()}</div>
              <div className="route__dashboardV2__table__content__table__row__column">{item.MonthlySubscription ? "Yes" : "No"}</div>
              <div className="route__dashboardV2__table__content__table__row__column">{item.PurchasedPackages}</div>
              <div className="route__dashboardV2__table__content__table__row__column">{item.AverageActiveTime}</div>
              <div className="route__dashboardV2__table__content__table__row__column">{item.TodaysActiveTime}</div>
              <div className="route__dashboardV2__table__content__table__row__column">{item.TotalActiveTime}</div>
              <div className="route__dashboardV2__table__content__table__row__column">{item.VerifiedEmail ? "Yes" : "No"}</div>
              <div className="route__dashboardV2__table__content__table__row__column">{item.VerifiedNumber ? "Yes" : "No"}</div>
              <div className="route__dashboardV2__table__content__table__row__column">{item.VerifiedProfilePicture ? "Yes" : "No"}</div>
              <div className="route__dashboardV2__table__content__table__row__column">{item.NumberOfProfilesVisited}</div>
              <div className="route__dashboardV2__table__content__table__row__column">{item.UnsuccessfulTransactions}</div>
            </div>
          }) : <div className="route__dashboardV2__table__content__table__row">
            <div className="route__dashboardV2__table__content__table__row__column">Nothing to show...</div>
          </div>) : <div className="route__dashboardV2__table__content__table__row">
            <div className="route__dashboardV2__table__content__table__row__column">There was an error while fetching data</div>
          </div>}
        </> : <div className="route__dashboardV2__table__content__table__row">
          <div className="route__dashboardV2__table__content__table__row__column">
            <Spinner color="#4FB2F3" style={{ width: "64px", height: "64px" }} />
          </div>
        </div>}

        {spinner && <div className="route__dashboardV2__table__content__table__row">
          <div className="route__dashboardV2__table__content__table__row__column">
            <Spinner color="#4FB2F3" style={{ width: "64px", height: "64px" }} />
          </div>
        </div>}
      </div>
    </div>
  </div>
};

const SecondaryUserMoreInfoModal = props => {

  const [activeTab, setActiveTab] = React.useState('Actions');


  return <div className="route__dashboardV2__usersActions">
    <div className="route__dashboardV2__usersActions__box">

      <img id='close' src='images/close.png' alt='' onClick={() => props.onClose()} />

      <div className="route__dashboardV2__usersActions__box__tabs">
        <p style={{ background: activeTab === 'Actions' ? 'rgb(14, 19, 34)' : '' }} onClick={() => { setActiveTab("Actions") }}>Actions</p>
        <p style={{ background: activeTab === 'Sessions' ? 'rgb(14, 19, 34)' : '' }} onClick={() => { setActiveTab("Sessions") }}>Sessions</p>
        <p style={{ background: activeTab === 'ProfileCompletion' ? 'rgb(14, 19, 34)' : '' }} onClick={() => { setActiveTab("ProfileCompletion") }}>Profile Completion</p>
        <p style={{ background: activeTab === 'Chats' ? 'rgb(14, 19, 34)' : '' }} onClick={() => { setActiveTab("Chats") }}>Chats</p>
      </div>

      {activeTab === 'Actions' && <InfoModalActionsTab ID={props.ID} />}
      {activeTab === 'Sessions' && <InfoModalSessionsTab ID={props.ID} />}
      {activeTab === 'ProfileCompletion' && <InfoModalProfileCompletionTab ID={props.ID} />}
      {activeTab === 'Chats' && <InfoModalChatsTab ID={props.ID} />}

    </div>
  </div>
}

const InfoModalActionsTab = props => {

  const [data, setData] = React.useState([]);
  const [condition, setCondition] = React.useState({ name: 'Condition', value: null })
  const [conditionDropdownOpened, setConditionDropdownOpened] = React.useState(false);

  const [filterName, setFilterName] = React.useState({ name: 'Action', value: 'String' })
  const [requestValue, setRequestValue] = React.useState({ name: 'open', value: 'open' })
  const [filterNameDropdownOpened, setFilterNameDropdownOpened] = React.useState(false);
  const [requestValueDropdownOpened, setRequestValueDropdownOpened] = React.useState(false);

  const filterRequestValue = React.useRef();


  const [filterParams, setFilterParams] = React.useState()

  const getData = (filterItem, filterReq, filterValue) => {

    let finalData;

    if (filterItem && filterReq && filterValue) {
      finalData = {
        SecondaryUserID: props.ID,
        filterItem: filterItem,
        filterReq: filterReq,
        filterValue: filterValue,
      };
    } else {
      finalData = {
        SecondaryUserID: props.ID,
      };
    }

    axios({
      method: "POST",
      url: `${backendModule.backendURL}/usersActions/getActionsBySecondaryUserID`,
      data: finalData,
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === 'ok') {
        setData(res.data.data);
      }

    }).catch((err) => {
      console.log("Failed in creating an action ", err)
    });
  }

  React.useEffect(() => {
    getData();
  }, []);

  return <div className="route__dashboardV2__usersActions__box__container">

    <div className="route__dashboardV2__usersActions__box__container__filter">

      <div className="route__dashboardV2__usersActions__box__container__filter__item" onClick={() => setFilterNameDropdownOpened(d => d = !d)}>
        <p>{filterName.name}</p>
        <div className="route__dashboardV2__usersActions__box__container__filter__item__dropdown"
          style={{
            padding: filterNameDropdownOpened ? '10px' : '0px',
            maxHeight: filterNameDropdownOpened ? '1000px' : '0',
            border: filterNameDropdownOpened ? '1px solid #192343' : '0px'
          }}>
          <p onClick={() => setFilterName({ name: 'Action', value: 'String' })}>Action</p>
          <p onClick={() => setFilterName({ name: 'Value', value: 'String' })}>Value</p>
          <p onClick={() => setFilterName({ name: 'createdAt', value: 'Date' })}>Date</p>
        </div>
      </div>

      <div className="route__dashboardV2__usersActions__box__container__filter__item" onClick={() => setConditionDropdownOpened(d => d = !d)}>
        <p>{condition.name}</p>
        <div className="route__dashboardV2__usersActions__box__container__filter__item__dropdown"
          style={{
            padding: conditionDropdownOpened ? '10px' : '0px',
            maxHeight: conditionDropdownOpened ? '1000px' : '0',
            border: conditionDropdownOpened ? '1px solid #192343' : '0px'
          }}>
          {filterName.value === 'String' ? <p onClick={() => setCondition({ name: 'Equals', value: 'eq' })}>Equals</p> : null}
          {filterName.value === 'Date' ? <p onClick={() => setCondition({ name: 'Greater Than', value: 'gt' })}>Greater Than</p> : null}
          {filterName.value === 'Date' ? <p onClick={() => setCondition({ name: 'Less Than', value: 'lt' })}>Less Than</p> : null}
          {filterName.value === 'Date' ? <p onClick={() => setCondition({ name: 'Equals', value: 'eq' })}>Equals</p> : null}
        </div>
      </div>

      {filterName.value === 'Date' ? <input type="date" ref={filterRequestValue} onChange={() => { setFilterParams({ filterType: 'date', filterValue: filterRequestValue.current.value }) }}></input> : null}
      {filterName.value === 'String' || filterName.value === 'Number' ? <div className="route__dashboardV2__usersActions__box__container__filter__item" onClick={() => setRequestValueDropdownOpened(d => d = !d)}>
        <p>{filterParams?.filterValue ? `${filterParams?.filterValue}` : 'value'}</p>
        <div className="route__dashboardV2__usersActions__box__container__filter__item__dropdown"
          style={{
            padding: requestValueDropdownOpened ? '10px' : '0px',
            maxHeight: requestValueDropdownOpened ? '1000px' : '0',
            border: requestValueDropdownOpened ? '1px solid #192343' : '0px'
          }}>
          {filterName.name === 'Action' && <p onClick={() => setFilterParams({ filterType: 'string', filterValue: 'open' })}>open</p>}
          {filterName.name === 'Action' && <p onClick={() => setFilterParams({ filterType: 'string', filterValue: 'close' })}>close</p>}
          {filterName.name === 'Action' && <p onClick={() => setFilterParams({ filterType: 'string', filterValue: 'set' })}>set</p>}
          {filterName.name === 'Action' && <p onClick={() => setFilterParams({ filterType: 'string', filterValue: 'remove' })}>remove</p>}
          {filterName.name === 'Value' && <p onClick={() => setFilterParams({ filterType: 'string', filterValue: 'browsemembers' })}>browsemembers</p>}
          {filterName.name === 'Value' && <p onClick={() => setFilterParams({ filterType: 'string', filterValue: 'chat' })}>chat</p>}
          {filterName.name === 'Value' && <p onClick={() => setFilterParams({ filterType: 'string', filterValue: 'member' })}>member</p>}
          {filterName.name === 'Value' && <p onClick={() => setFilterParams({ filterType: 'string', filterValue: 'buycredit' })}>buycredit</p>}
          {filterName.name === 'Value' && <p onClick={() => setFilterParams({ filterType: 'string', filterValue: 'chatModal' })}>chatModal</p>}
        </div>
      </div> : null}

      <div className="route__dashboardV2__usersActions__box__container__filter__btn" onClick={() => { getData(filterName.name, condition.value, filterParams) }}>Filter</div>
      <div className="route__dashboardV2__usersActions__box__container__filter__btn" onClick={() => { getData() }}>Reset</div>

      <div className="route__dashboardV2__usersActions__box__container__filter__btn">Total Results : {data && data.length}</div>
    </div>

    <div className="route__dashboardV2__usersActions__box__container__items">
      <div className="route__dashboardV2__usersActions__box__container__items__head">
        <p>Time</p>
        <p>Action</p>
        <p>Value</p>
        <p>Info</p>
        <p>Description</p>
      </div>
      {data && data.length ? data.map(item => {
        let date = item.createdAt.split('T')[0]
        let time = item.createdAt.split('T')[1].split('.')[0]
        return <div className="route__dashboardV2__usersActions__box__container__items--item">
          <p style={{ color: item.Value === 'chat' ? 'rgb(141, 255, 185)' : item.Value === 'buycredit' ? '#FF6161' : item.Info === 'sendEmail' ? '#0594fa' : '#FFF' }}>{date} {time}</p>
          <p style={{ color: item.Value === 'chat' ? 'rgb(141, 255, 185)' : item.Value === 'buycredit' ? '#FF6161' : item.Info === 'sendEmail' ? '#0594fa' : '#FFF' }}>{item.Action}</p>
          <p style={{ color: item.Value === 'chat' ? 'rgb(141, 255, 185)' : item.Value === 'buycredit' ? '#FF6161' : item.Info === 'sendEmail' ? '#0594fa' : '#FFF' }}>{item.Value}</p>
          <p style={{ color: item.Value === 'chat' ? 'rgb(141, 255, 185)' : item.Value === 'buycredit' ? '#FF6161' : item.Info === 'sendEmail' ? '#0594fa' : '#FFF' }}>{item.Info}</p>
          <p style={{ color: item.Value === 'chat' ? 'rgb(141, 255, 185)' : item.Value === 'buycredit' ? '#FF6161' : item.Info === 'sendEmail' ? '#0594fa' : '#FFF' }}>{item.Description}</p>
        </div>

      }) : <div className="route__dashboardV2__usersActions__box__container__items--item"><p>No Data available</p></div>}
    </div>

  </div>
}


const InfoModalSessionsTab = props => {

  const [data, setData] = React.useState([]);
  const [condition, setCondition] = React.useState({ name: 'Condition', value: null })
  const [conditionDropdownOpened, setConditionDropdownOpened] = React.useState(false);

  const [filterName, setFilterName] = React.useState({ name: 'Action', value: 'String' })
  const [filterNameDropdownOpened, setFilterNameDropdownOpened] = React.useState(false);
  const [requestValueDropdownOpened, setRequestValueDropdownOpened] = React.useState(false);

  const filterRequestValue = React.useRef();


  const [filterParams, setFilterParams] = React.useState()

  const getData = (filterItem, filterReq, filterValue) => {

    let finalData;

    if (filterItem && filterReq && filterValue) {
      finalData = {
        SecondaryUserID: props.ID,
        filterItem: filterItem,
        filterReq: filterReq,
        filterValue: filterValue,
      };
    } else {
      finalData = {
        SecondaryUserID: props.ID,
      };
    }

    axios({
      method: "POST",
      url: `${backendModule.backendURL}/usersTimestamps/getSecondaryUserTimestamps`,
      data: finalData,
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === 'ok') {
        setData(res.data.data)
      } else {
        setData({ status: 'ERROR', data: 'Error while fetching data' })
      }
    }).catch((e) => {
      console.log(e)
      return setData({ status: "error", data: "SERVER_ERROR" });
    });
  }

  React.useEffect(() => {
    getData();
  }, []);


  return <div className="route__dashboardV2__usersActions__box__container">


    <div className="route__dashboardV2__usersActions__box__container__filter">

      <div className="route__dashboardV2__usersActions__box__container__filter__item" onClick={() => setFilterNameDropdownOpened(d => d = !d)}>
        <p>{filterName.name}</p>
        <div className="route__dashboardV2__usersActions__box__container__filter__item__dropdown"
          style={{
            padding: filterNameDropdownOpened ? '10px' : '0px',
            maxHeight: filterNameDropdownOpened ? '1000px' : '0',
            border: filterNameDropdownOpened ? '1px solid #192343' : '0px'
          }}>
          <p onClick={() => setFilterName({ name: 'Action', value: 'String' })}>Action</p>
          <p onClick={() => setFilterName({ name: 'Value', value: 'String' })}>Value</p>
          <p onClick={() => setFilterName({ name: 'createdAt', value: 'Date' })}>Date</p>
        </div>
      </div>

      <div className="route__dashboardV2__usersActions__box__container__filter__item" onClick={() => setConditionDropdownOpened(d => d = !d)}>
        <p>{condition.name}</p>
        <div className="route__dashboardV2__usersActions__box__container__filter__item__dropdown"
          style={{
            padding: conditionDropdownOpened ? '10px' : '0px',
            maxHeight: conditionDropdownOpened ? '1000px' : '0',
            border: conditionDropdownOpened ? '1px solid #192343' : '0px'
          }}>
          {filterName.value === 'String' ? <p onClick={() => setCondition({ name: 'Equals', value: 'eq' })}>Equals</p> : null}
          {filterName.value === 'Date' ? <p onClick={() => setCondition({ name: 'Greater Than', value: 'gt' })}>Greater Than</p> : null}
          {filterName.value === 'Date' ? <p onClick={() => setCondition({ name: 'Less Than', value: 'lt' })}>Less Than</p> : null}
          {filterName.value === 'Date' ? <p onClick={() => setCondition({ name: 'Equals', value: 'eq' })}>Equals</p> : null}
        </div>
      </div>

      {filterName.value === 'Date' ? <input type="date" ref={filterRequestValue} onChange={() => { setFilterParams({ filterType: 'date', filterValue: filterRequestValue.current.value }) }}></input> : null}
      {filterName.value === 'String' || filterName.value === 'Number' ? <div className="route__dashboardV2__usersActions__box__container__filter__item" onClick={() => setRequestValueDropdownOpened(d => d = !d)}>
        <p>{filterParams?.filterValue ? `${filterParams?.filterValue}` : 'value'}</p>
        <div className="route__dashboardV2__usersActions__box__container__filter__item__dropdown"
          style={{
            padding: requestValueDropdownOpened ? '10px' : '0px',
            maxHeight: requestValueDropdownOpened ? '1000px' : '0',
            border: requestValueDropdownOpened ? '1px solid #192343' : '0px'
          }}>
          {filterName.name === 'Action' && <p onClick={() => setFilterParams({ filterType: 'string', filterValue: 'open' })}>open</p>}
          {filterName.name === 'Action' && <p onClick={() => setFilterParams({ filterType: 'string', filterValue: 'close' })}>close</p>}
          {filterName.name === 'Action' && <p onClick={() => setFilterParams({ filterType: 'string', filterValue: 'set' })}>set</p>}
          {filterName.name === 'Action' && <p onClick={() => setFilterParams({ filterType: 'string', filterValue: 'remove' })}>remove</p>}
          {filterName.name === 'Value' && <p onClick={() => setFilterParams({ filterType: 'string', filterValue: 'browsemembers' })}>browsemembers</p>}
          {filterName.name === 'Value' && <p onClick={() => setFilterParams({ filterType: 'string', filterValue: 'chat' })}>chat</p>}
          {filterName.name === 'Value' && <p onClick={() => setFilterParams({ filterType: 'string', filterValue: 'member' })}>member</p>}
          {filterName.name === 'Value' && <p onClick={() => setFilterParams({ filterType: 'string', filterValue: 'buycredit' })}>buycredit</p>}
          {filterName.name === 'Value' && <p onClick={() => setFilterParams({ filterType: 'string', filterValue: 'chatModal' })}>chatModal</p>}
        </div>
      </div> : null}

      <div className="route__dashboardV2__usersActions__box__container__filter__btn" onClick={() => { getData(filterName.name, condition.value, filterParams) }}>Filter</div>
      <div className="route__dashboardV2__usersActions__box__container__filter__btn" onClick={() => { getData() }}>Reset</div>

      <div className="route__dashboardV2__usersActions__box__container__filter__btn">Total Results : {data && data.length}</div>
    </div>

    <div className="route__dashboardV2__usersActions__box__container__sessionItems">
      <div className="route__dashboardV2__usersActions__box__container__sessionItems__head">
        <p>Today</p>
        <p>Total</p>
        <p>Average</p>
        <p>DayStreak</p>
        <p>Sessions</p>
        <p>ByDate</p>
      </div>
      {data && <><div className="route__dashboardV2__usersActions__box__container__sessionItems--item">
        {data.TodaysActiveTime && <p>{data.TodaysActiveTime.data}</p>}
        {data.TotalActiveTime && <p>{data.TotalActiveTime.data}</p>}
        {data.AverageActiveTime && <p>{data.AverageActiveTime.data}</p>}
        {data.ActiveDaysStreak && <p>{data.ActiveDaysStreak}</p>}
        {data.ActiveTimeByDate && <p>{data.ActiveTimeByDate.length}</p>}
        {data.ActiveTimeByDate && <div>
          {data.ActiveTimeByDate.map(item => {
            return <p>{item.date} - {item.activeTime}</p>
          })}
        </div>}
      </div>
      <div className="route__dashboardV2__usersActions__box__container__sessionItems__statHead">
        <p>Date</p>
        <p>Starting Time</p>
        <p>Ending Time</p>
        <p>Duration</p>
        <p>Device Info</p>
      </div>
      {data?.Sessions &&  data?.Sessions.length && data?.Sessions.map(item => {
        return <div className="route__dashboardV2__usersActions__box__container__sessionItems__statDate">
          <p className="route__dashboardV2__usersActions__box__container__sessionItems__statDate--date">{item.date}</p>
          <div className="route__dashboardV2__usersActions__box__container__sessionItems__statItem">
            {item.sessions.map(elem => {
              return <div className="route__dashboardV2__usersActions__box__container__sessionItems__statItem--elems">
                <p>{elem.startingTime}</p>
                <p>{elem.endingTime}</p>
                <p>{elem.activeTime}</p>
                <p>{elem.deviceInfo}</p>
              </div>
            })}
          </div>
      </div>
      })}
      
      </>}
    </div>
  </div>
}

const InfoModalProfileCompletionTab = props => {

  const [data, setData] = React.useState([]);

  const getData = () => {

    let finalData;

    finalData = {
      ID: props.ID,
    };

    axios({
      method: "POST",
      url: `${backendModule.backendURL}/users/getSecondaryUsersProfileStats`,
      data: {
        ID: props.ID,
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === 'ok') {

        let completedElements = 0;

        res.data.data.map(obj => {
          for(const key in obj){
            let value = obj[key];
            
            if(value) completedElements += 1;
          }
        })

        let completedPercentage = ((completedElements / res.data.data.length) * 100).toFixed(2);

        setData({stats: res.data.data, percentage: completedPercentage})
      } else {
        setData({ status: 'ERROR', data: 'Error while fetching data' })
      }
    }).catch((e) => {
      console.log(e)
      return setData({ status: "error", data: "SERVER_ERROR" });
    });
  }

  React.useEffect(() => {
    getData();
  }, []);


  return <div className="route__dashboardV2__usersActions__box__container">

    {/* TODO:: ADD AGE OF AN USER */}

    <div className="route__dashboardV2__usersActions__box__container__completionItems">
      {data?.percentage && <p style={{color: data.percentage > 50 ? '#A0D911' : 'rgb(255, 97, 97)'}} className="route__dashboardV2__usersActions__box__container__completionItems__item"><span>Profile Percentage Completed</span><span>{data.percentage}%</span></p>}
      {data && data.stats && data?.stats.length && data.stats.map(obj => {
        for (const key in obj) {
          const value = obj[key];
          return <p className="route__dashboardV2__usersActions__box__container__completionItems__item" 
          style={{color: value ? 'rgb(141, 255, 185)' : 'rgb(255, 97, 97)'}}
          key={key}>
            {String(value).startsWith("http") ? <><span>{key}</span><span>{value ? <a target="_blank" href={`${String(value)}`} >Image</a> : 'null'}</span></> :
            String(key).startsWith("lookingFor") ? <><span>{key}</span><span>{value ? JSON.parse(value).map(lf => `${lookingFor[lf]}, `) : ''}</span></> :
            <><span>{key}</span><span>{value ? String(value) : 'null'}</span></>}
            </p>;
        }
        return null;
      })}
    </div>

  </div>
}

const InfoModalChatsTab = props => {

  const [data, setData] = React.useState([]);

  const getData = () => {

    let finalData;

    finalData = {
      ID: props.ID,
    };

    axios({
      method: "POST",
      url: `${backendModule.backendURL}/chatTexts/getAllChatsByUser`,
      data: {
        UserID: props.ID,
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === 'ok') { 
        setData(res.data.data)
      } else {
        setData({ status: 'ERROR', data: 'Error while fetching data' })
      }
    }).catch((e) => {
      console.log(e)
      return setData({ status: "error", data: "SERVER_ERROR" });
    });
  }

  React.useEffect(() => {
    getData();
  }, []);

  return <div className="route__dashboardV2__usersActions__box__container">

    <div className="route__dashboardV2__usersActions__box__container__chats">
      <div className="route__dashboardV2__usersActions__box__container__chats__head">
        <p>Total Messages : {data && data.length > 0 && <span>{data.length}</span>}</p>
        <p>Last Message : {data && data.length > 0 && <span>{moment(data[0]?.updatedAt).format("DD.MM.YYYY - HH:mm")}</span>}</p>
      </div>

      {data && data.length > 0 && <div className="route__dashboardV2__usersActions__box__container__chats__messages">
        {data.map(elem => {
          return <div className="route__dashboardV2__usersActions__box__container__chats__messages__item">
            <p>{elem.MessageContent}</p>
            <p>At <span>{moment(elem.createdAt).format("DD.MM.YYYY - HH:mm")}</span> sent to <span>{JSON.parse(elem.NotificationFor)}</span></p>
          </div>
        })}
      </div>}
    </div>

  </div>
}


export default Dashboard_v2