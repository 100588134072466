import React from 'react';
import "./index.scss";

import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import Spinner from '../../../../components/customComponents/Spinner';
import * as socketModule from "../../../../modules/socketModule";
import * as chatActions from "../../../../actions/chatAction";
import * as backendModule from "../../../../modules/backendModule";
import { animateBox } from "../../../../modules/componentAnimation";

import ImageModal from "../../../../components/Modals/ImageModal";

let tmpTimeout = null;
export const PaginateSpinner = (props) => {
  let tmpRef = React.useRef();
  let tmpReact = null;

  const curDispatch = useDispatch();

  React.useEffect(() => {
    if (!tmpRef.current);
    tmpRef.current.style.display = null;
  }, [tmpRef.current]);

  React.useEffect(() => {
    if (!tmpRef?.current) return;
    let observer = null;
    clearTimeout(tmpReact);
    tmpReact = setTimeout(() => {
      observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.intersectionRatio > 0) {
            try { observer.unobserve(tmpRef.current); } catch { };
            if (true) {
              clearTimeout(tmpTimeout);
              tmpTimeout = setTimeout(() => {
                try {
                  curDispatch(chatActions.chatRemoveSpinner(props.room, props.chat));
                  socketModule.internal__paginateChat(props.room, props.chat);
                  if (props.onPaginate) props.onPaginate();
                } catch { };
              }, props.timeout ? 1000 : 0);
            };
          } else {
            clearTimeout(tmpTimeout);
          };
        });
      }, { threshold: [1] });
      observer.observe(tmpRef.current);
    }, 500);
    return () => {
      if (tmpRef?.current) {
        try { observer.unobserve(tmpRef.current); } catch { }; 
      };
    };
  }, [tmpRef]);

  return <div ref={tmpRef} className="component__paginateSpinner" style={{ width: "100%" }}>
    <Spinner color="white" align="center" />
  </div>
}

export default function ContentChat(props) {
  const chatsSelector = useSelector(state => state?.chats ?? null);
  const userInfoSelector = useSelector(state => state?.user?.UserInfo ?? {});
  const chatRoomSelector = useSelector(state => state?.chatRooms ?? null);
  const mainRef = React.useRef(null);

  React.useEffect(() => {
    const delayScroll = (delay, animate = false) => {
      setTimeout(() => {
        if (!mainRef.current) return;
        let lastMessage = [...mainRef.current.querySelectorAll(".component__contentChat__message")].pop();
        if (lastMessage) {
          lastMessage.scrollIntoView({ behavior: animate ? "smooth" : "auto" });
        };
      }, delay);
    };

    let handler = message => {
      for (let key of Object.keys(message)) {
        if (key === String(props.activeChat?.ChatRoomID)) {
          delayScroll(0, true);
        };
      };
    };

    socketModule.on("receiveMessage", handler);
    delayScroll(0);

    return () => socketModule.off("receiveMessage", handler);
  }, [props.activeChat, chatsSelector[props.activeChat?.ChatRoomID]?.length]);

  React.useEffect(() => {
    let curRoom = chatRoomSelector.find(cr => cr.ID === props.activeChat?.ChatRoomID);
    if (curRoom.UnreadCount > 0) {
      socketModule.internal__clearChatRoomNotifications(props.activeChat?.ChatRoomID);
    }
  }, [chatRoomSelector]);

  return (
    <div ref={mainRef} className='component__contentChat'>
      {
        chatsSelector[props.activeChat?.ChatRoomID] === null ? <Spinner /> :
          chatsSelector[props.activeChat?.ChatRoomID]?.map((item, index) => {
            if (item.hasSpinner || item.Type === 'preload') {
              return <PaginateSpinner timeout={item.Type === 'preload' ? false : true} key={`chatPaginate-${props.activeChat?.ChatRoomID}`} room={props.activeChat?.ChatRoomID} chat={item.ID} />
            }
            if (item.From !== props.activeChat?.AgentID) {
              if (index > 0 && chatsSelector[props.activeChat?.ChatRoomID][index - 1].From === item.From) {
                if (item.Type === 'text' || item.Type === 'image' || item.Type === "video" || item.Type === "image-blurred" || item.Type === 'sticker'  || item.Type === 'text-hi') {
                  return <div className='component__contentChat__message lady-message'>
                    <div className='component__contentChat__message__right'>
                      {item.Type === 'text' && <p id='personMessage' style={{ marginRight: '0px', marginLeft: 'auto' }} >{item.Content}</p>}
                      {item.Type === 'text-hi' && <p id='personMessage' style={{ marginRight: '0px', marginLeft: 'auto',  backgroundColor:'#6b06c9' }} >{item.Content}</p>}
                      {item.Type === 'image' && <div className='component__contentChat__message__right__img'>
                        <img id='personMessageImage' src={JSON.parse(item.Content)?.file} alt='' onClick={(e) => animateBox({ currentTarget: e.currentTarget.parentNode }, <ImageModal src={JSON.parse(item.Content)?.file} />)} />
                      </div>}
                      {item.Type === 'video' && <div className='component__contentChat__message__right__img'>
                        <video id='personMessageImage' controls={true} src={JSON.parse(item.Content)?.file} alt='' />
                      </div>}
                      {item.Type === "image-blurred" && <BlurredImage content={item.Content} />}
                      {item.Type === 'sticker'  && <div className='component__contentChat__message__right__img ladyImgMessage'>
                        <img id='personLadyMessageImage' style={{ marginTop: '20px' }} src={JSON.parse(item.Content)?.Image} alt='' onClick={(e) => animateBox({ currentTarget: e.currentTarget.parentNode }, <ImageModal src={JSON.parse(item.Content)?.Image} />)} />
                      </div>}
                    </div>
                  </div>
                }
                else if (item.Type === 'text-flert') {
                  let content = JSON.parse(item.Content);
                  return <div className='component__contentChat__message lady-message'>
                    <div className='component__contentChat__message__right'>
                      {item.Type === 'text' && <p id='personMessage' style={{ marginRight: '0px', marginLeft: 'auto' }} >{item.Content}</p>}
                      {item.Type === 'image' && <div className='component__contentChat__message__right__img'>
                        <img id='personMessageImage' src={JSON.parse(item.Content)?.file} alt='' onClick={(e) => animateBox({ currentTarget: e.currentTarget.parentNode }, <ImageModal src={JSON.parse(item.Content)?.file} />)} />
                      </div>}
                      {item.Type === "image-blurred" && <BlurredImage content={item.Content} />}
                      {item.Type === 'sticker'  && <div className='component__contentChat__message__right__img ladyImgMessage'>
                        <img id='personLadyMessageImage' style={{ marginTop: '20px' }} src={JSON.parse(item.Content)?.Image} alt='' onClick={(e) => animateBox({ currentTarget: e.currentTarget.parentNode }, <ImageModal src={JSON.parse(item.Content)?.Image} />)} />
                      </div>}
                    </div>
                  </div>
                }
                else if (item.Type === 'gift') {
                  return <div className='component__contentChat__message lady-message'>
                    <div className='component__contentChat__message__right'>
                      <div  style={{backgroundColor: '#282F37'}}>
                        {item.Type === 'gift' && <p id='personMessage' style={{ marginRight: '0px', marginLeft: 'auto' }} >Dobili ste poklon: {JSON.parse(item.Content).Name}</p>}
                        {item.Type === 'gift' && <div className='component__contentChat__message__right__img'>
                          <img id='personMessageImage' src={JSON.parse(item.Content)?.Image} alt='' onClick={(e) => animateBox({ currentTarget: e.currentTarget.parentNode }, <ImageModal src={JSON.parse(item.Content)?.Image} />)} />
                        </div>}
                      </div>

                    </div>
                  </div>
                }
                
                else if (item.Type === 'spinner') {
                  return <div className='component__contentChat__message spinner'>
                    <Spinner color='#DC21B8' />
                  </div>
                }
              }
              else {
                if (item.Type === 'text' || item.Type === 'text-hi' || item.Type === 'image' || item.Type === "video" || item.Type === "image-blurred" || item.Type === 'sticker') {
                  return <div className='component__contentChat__message lady-message'>
                    <div id='personImage'><img src={`${backendModule.backendURL}/StaticContent/Images/${props.activeChat?.ClientImage}`} alt='' /></div>
                    <div className='component__contentChat__message__right'>
                      <p id='personName'>{props.activeChat?.ClientUsername} <small>{moment(item.Date).format('DD.MM.YYYY.')} at {moment(item.Date).format('hh:mm:ss')}</small></p>
                      {item.Type === 'text' && <p id='personMessage' style={{ marginRight: '0px', marginLeft: 'auto' }} >{item.Content}</p>}
                      {item.Type === 'text-hi' && <p id='personMessage' style={{ marginRight: '0px', marginLeft: 'auto',  backgroundColor:'#6b06c9' }} >{item.Content}</p>}
                      {item.Type === 'image'  && <div className='component__contentChat__message__right__img ladyImgMessage'>
                        <img id='personLadyMessageImage' style={{ marginTop: '20px' }} src={JSON.parse(item.Content)?.file} alt='' onClick={(e) => animateBox({ currentTarget: e.currentTarget.parentNode }, <ImageModal src={JSON.parse(item.Content)?.file} />)} />
                      </div>}
                      {item.Type === "image-blurred" && <BlurredImage content={item.Content} />}
                      {item.Type === 'sticker'  && <div className='component__contentChat__message__right__img ladyImgMessage'>
                        <img id='personLadyMessageImage' style={{ marginTop: '20px' }} src={JSON.parse(item.Content)?.Image} alt='' onClick={(e) => animateBox({ currentTarget: e.currentTarget.parentNode }, <ImageModal src={JSON.parse(item.Content)?.Image} />)} />
                      </div>}
                    </div>
                  </div>
                } 
                else if (item.Type === 'text-flert') {
                  let content = JSON.parse(item.Content);
                  return <div className='component__contentChat__message lady-message'>
                    <div id='personImage'><img src={`${backendModule.backendURL}/StaticContent/Images/${props.activeChat?.ClientImage}`} alt='' /></div>
                    <div className='component__contentChat__message__right'>
                    <p id='personName'>{props.activeChat?.ClientName} <small>{moment(item.Date).format('DD.MM.YYYY.')} at {moment(item.Date).format('hh:mm:ss')}</small></p>
                      {item.Type === 'text' && <p id='personMessage' style={{ marginRight: '0px', marginLeft: 'auto' }} >{item.Content}</p>}
                      {item.Type === 'text-flert' && <p id='personMessage' style={{ marginRight: '0px', marginLeft: 'auto', alignItems: 'center', display: 'flex', backgroundColor:'#6b06c9' }} >
                        <img src={JSON.parse(item.Content)?.imageSrc} style={{marginRight: '20px'}}></img>
                        {JSON.parse(item.Content)?.text}
                      </p>}
                      {item.Type === 'image' && <div className='component__contentChat__message__right__img'>
                        <img id='personMessageImage' src={JSON.parse(item.Content)?.file} alt='' onClick={(e) => animateBox({ currentTarget: e.currentTarget.parentNode }, <ImageModal src={JSON.parse(item.Content)?.file} />)} />
                      </div>}
                      {item.Type === 'video' && <div className='component__contentChat__message__right__img'>
                        <video id='personMessageImage' controls={true} src={JSON.parse(item.Content)?.file} alt='' />
                      </div>}
                      {item.Type === "image-blurred" && <BlurredImage content={item.Content} />}
                      {item.Type === 'sticker'  && <div className='component__contentChat__message__right__img ladyImgMessage'>
                        <img id='personLadyMessageImage' style={{ marginTop: '20px' }} src={JSON.parse(item.Content)?.Image} alt='' onClick={(e) => animateBox({ currentTarget: e.currentTarget.parentNode }, <ImageModal src={JSON.parse(item.Content)?.Image} />)} />
                      </div>}
                    </div>
                  </div>
                }
                else if (item.Type === 'gift') {
                  return <div className='component__contentChat__message lady-message'>
                    <div className='component__contentChat__message__right'>
                      <div  style={{backgroundColor: '#282F37'}}>
                        {item.Type === 'gift' && <p id='personMessage' style={{ marginRight: '0px', marginLeft: 'auto' }} >Dobili ste poklon: {JSON.parse(item.Content).Name}</p>}
                        {item.Type === 'gift' && <div className='component__contentChat__message__right__img'>
                          <img id='personMessageImage' src={JSON.parse(item.Content)?.Image} alt='' onClick={(e) => animateBox({ currentTarget: e.currentTarget.parentNode }, <ImageModal src={JSON.parse(item.Content)?.Image} />)} />
                        </div>}
                      </div>

                    </div>
                  </div>
                }else if (item.Type === 'spinner') {
                  return <div className='component__contentChat__message spinner'>
                    <Spinner color='#DC21B8' />
                  </div>
                }
              }
            }
            else {
              if (index > 0 && chatsSelector[props.activeChat?.ChatRoomID][index - 1].From === item.From) {
                if (item.Type === 'text' || item.Type == 'image' || item.Type === "video" || item.Type === "image-blurred") {
                  return <div style={{ marginLeft: '51px' }} className='component__contentChat__message'>
                    <div className='component__contentChat__message__right'>
                      {item.Type === 'text' && <p style={{ marginTop: '0px' }} id='personMessage'>{item.Content}</p>}
                      {item.Type === 'image' && <div className='component__contentChat__message__right__img'>
                        <img id='personMessageImage' src={JSON.parse(item.Content)?.file} alt='' onClick={(e) => animateBox({ currentTarget: e.currentTarget.parentNode }, <ImageModal src={JSON.parse(item.Content)?.file} />)} />
                      </div>}
                      {item.Type === 'video' && <div className='component__contentChat__message__right__img'>
                        <video id='personMessageImage' controls={true} src={JSON.parse(item.Content)?.file} alt='' />
                      </div>}
                      {item.Type === "image-blurred" && <BlurredImage content={item.Content} />}
                    </div>
                  </div>
                }
                else if (item.Type === 'spinner') {
                  return <div className='component__contentChat__message spinner'>
                    <Spinner color='#DC21B8' />
                  </div>
                }
              }
              else {
                if (item.Type === 'text' || item.Type === 'image' || item.Type === "video" || item.Type === "image-blurred" || item.Type === 'sticker' || item.Type === 'text-hi') {
                  return <div className='component__contentChat__message'>
                    <div id='personImage'><img src={props.activeChat?.AgentImage ? `${props.activeChat?.AgentImage}` : 'images/defUserIcon.png'} alt='' /></div>
                    <div className='component__contentChat__message__right'>
                      <p id='personName'>{props.activeChat?.AgentName} <small>{moment(item.Date).format('DD.MM.YYYY.')} at {moment(item.Date).format('hh:mm:ss')}</small></p>
                      {item.Type === 'text' && <p id='personMessage'>{item.Content}</p>}
                      {item.Type === 'image' && <div className='component__contentChat__message__right__img'>
                        <img id='personMessageImage' src={JSON.parse(item.Content)?.file} alt='' onClick={(e) => animateBox({ currentTarget: e.currentTarget.parentNode }, <ImageModal src={JSON.parse(item.Content)?.file} />)} />
                      </div>}
                      {item.Type === 'video' && <div className='component__contentChat__message__right__img'>
                        <video id='personMessageImage' controls={true} src={JSON.parse(item.Content)?.file} alt='' />
                      </div>}
                      {item.Type === "image-blurred" && <BlurredImage content={item.Content} />}
                      {item.Type === 'sticker'  && <div className='component__contentChat__message__right__img ladyImgMessage'>
                        <img id='personLadyMessageImage' style={{ marginTop: '20px' }} src={JSON.parse(item.Content)?.Image} alt='' onClick={(e) => animateBox({ currentTarget: e.currentTarget.parentNode }, <ImageModal src={JSON.parse(item.Content)?.Image} />)} />
                      </div>}
                    </div>
                  </div>
                }
                if (item.Type === 'text-flert') {
                  let content = JSON.parse(item.Content);
                  return <div className='component__contentChat__message'>
                    <div id='personImage'><img src={userInfoSelector?.Image ? `${backendModule.backendURL}/staticContent/Images/${userInfoSelector.Image}` : 'images/defUserIcon.png'} alt='' /></div>
                    <div className='component__contentChat__message__right'>
                      <p id='personName'>ASDDAA <small>{moment(item.Date).format('DD.MM.YYYY.')} at {moment(item.Date).format('hh:mm:ss')}</small></p>
                      <div className='component__contentChat__message__right__flert'>
                        <img src={content.imageSrc} alt='' />
                        <span>{content.text}</span>
                      </div>
                    </div>
                  </div>
                }
                if (item.Type === 'spinner') {
                  return <div className='component__contentChat__message spinner'>
                    <div id='personImage'><img src={userInfoSelector?.Image ? `${props.activeChat?.AgentImage}` : 'images/defUserIcon.png'} alt='' /></div>
                    <div className='component__contentChat__message__right'>
                      <p id='personName'>{props.activeChat?.AgentName} <small>{moment(item.Date).format('DD.MM.YYYY.')} at {moment(item.Date).format('hh:mm:ss')}</small></p>
                      <Spinner color='#DC21B8' />
                    </div>
                  </div>
                }
              }
            }
          })
      }
    </div>
  )
}


const BlurredImage = props => {
  let content = null;
  try {
    content = JSON.parse(props.content);
  } catch {};

  return <div className='component__contentChat__message__right__img ladyImgMessage ladyImgMessageBlur'>
    <img id='personLadyMessageImage' style={{ marginTop: '20px' }} src={content?.file} alt='' onClick={(e) => animateBox({ currentTarget: e.currentTarget.parentNode }, <ImageModal src={content?.file} />)} />
    <div className='ladyImgMessageBlur__box'>
      <p>Unlock with credits?</p>
      <p>{content.credits}</p>
    </div>
  </div>
};