import React from 'react';
import "./index.scss";

import CustomInput from '../../components/customComponents/CustomInput';
import Button from '../../components/customComponents/Button';
import Spinner from '../../components/customComponents/Spinner';
import { FilteredCustomTable } from "../../components/customComponents/Table";
import DataSorter from "../../components/customComponents/DataSorter";

import * as backendModule from "../../modules/backendModule";
import axios, { all } from "axios";
import moment from 'moment';

import Dropdown from '../../components/customComponents/Dropdown';

import { animateBox } from "../../modules/componentAnimation";
import ImageModal from '../../components/Modals/ImageModal';

export default function StickerCategories(props) {
    const [filters, setFilters] = React.useState([]);
    const [orders, setOrders] = React.useState([]);
    const [spinner, setSpinner] = React.useState(false);

    const getAllStickerCategories = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/stickerCategories/getAllStickerCategoriesCpanel`,
            data: {
                filters: filters,
                orders: orders
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                props.setStickerCategories(res.data);
            }
            else {

            }
        }).catch(() => {

        });
    }

    React.useEffect(() => {
        getAllStickerCategories();
    }, [filters, orders]);

    const deleteStickerCategory = (id) => {
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/stickerCategories/removeStickerCategory`,
            data: {
                ID: id
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                getAllStickerCategories();
            }
            else {

            }
        }).catch(() => {

        }).finally(() => {
            setSpinner(false);
        });
    }

    const addStickerCategoriesToTable = () => {
        if (props.stickerCategories.data) {
            return props.stickerCategories?.data?.map((item) => {
                return [
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.ID}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Name}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Price}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.DomainID}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{moment(item.updatedAt).format('DD.MM.YYYY.')}</p></div> },
                    {
                        keyID: String(item.ID), type: "groupNewline", group: [
                            {
                                keyID: String(item.ID), type: "button", text: "Delete", triggerDropdown: true, triggerData: e => {
                                    return (<div style={{ display: 'flex', flexDirection: 'column', justfyContent: 'center', alignItems: 'center' }}>
                                        <p style={{ color: 'white' }}>Are you sure?</p>
                                        <br></br>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Button style={{ display: spinner ? 'none' : 'block', marginRight: '10px' }} accent='rgb(72, 82, 92)' value='YES' onClick={() => deleteStickerCategory(item.ID)} />
                                            <Button style={{ display: spinner ? 'none' : 'block' }} accent='rgb(72, 82, 92)' value='NO' onClick={c => { e() }} />
                                            <div style={{ display: spinner ? 'block' : 'none' }}><Spinner color='white' width='32px' /></div>
                                        </div>
                                    </div>);
                                },
                            },
                            {
                                keyID: String(item.ID), type: "button", text: "Open image", triggerDropdown: true, onClick: e => {
                                    window.open(`/#/image/${String(item.Image).replace(`${backendModule.backendURL}/staticContent/images/`, '')}`, '_blank');
                                }
                            },
                            {
                                keyID: String(item.ID), type: "button", text: "Edit", triggerDropdown: true, onClick: e => {
                                    animateBox(e, <EditModal curStickerCategory={
                                        {
                                            ID: item.ID,
                                            Name: item.Name,
                                            Price: item.Price,
                                            DomainID: item.DomainID,
                                        }
                                    } />)
                                }
                            }
                        ]
                    }
                ];
            });
        }
    }

    const EditModal = (props) => {
        const newPriceRef = React.useRef(null);
        const [newSelectedDomain, setNewSelectedDomain] = React.useState(null);
        const [newName, setNewName] = React.useState(null);
        const msgRef = React.useRef(null);

        const openDomainsModal = (e, isEdit, setNewSelectedDomain) => {
            animateBox(e, <SelectDomainModal isEdit={isEdit} setNewSelectedDomain={setNewSelectedDomain} />);
        }

        const SelectDomainModal = (props) => {
            const [filters, setFilters] = React.useState(false);
            const [domains, setDomains] = React.useState(false);

            const getAllDomains = () => {
                axios({
                    method: "POST",
                    url: `${backendModule.backendURL}/domains/getAllDomains`,
                    data: {
                        filters: filters
                    },
                    ...backendModule.axiosConfig
                }).then(res => {
                    if (res.data.status === 'ok') {
                        setDomains(res.data);
                    }
                    else {
                        setDomains('GET_ALL_DOMAINS_ERROR');
                    }
                }).catch(() => {
                    setDomains('GET_ALL_DOMAINS_ERROR');
                });
            }

            React.useEffect(() => {
                getAllDomains();
            }, [filters]);

            const addDomainsToTable = () => {
                if (domains.data) {
                    return domains?.data?.map((item) => {
                        return [
                            { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.ID}</p></div> },
                            { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Domain}</p></div> },
                            { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.WebsiteName}</p></div> },
                            { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center', background: item.forOffer ? 'green' : 'red', width: 'fit-content', marginLeft: 'auto', marginRight: 'auto', padding: '5px', borderRadius: '10px' }}>{item.forOffer ? 'YES' : 'NO'}</p></div> },
                            { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{moment(item.updatedAt).format('DD.MM.YYYY.')}</p></div> },
                            {
                                keyID: String(item.ID), type: "groupNewline", group: [
                                    {
                                        keyID: String(item.ID), type: "button", text: "Select", triggerDropdown: true, onClick: e => {
                                            setNewSelectedDomain({ ID: item.ID, URL: item.Domain });
                                            props.onClose();
                                        }
                                    }
                                ]
                            }
                        ];
                    });
                }
            }

            return <div className='route__emailServers__addNewModal__selectDomainModal'>
                <div id='box'>
                    <div id='table'>
                        <FilteredCustomTable
                            id='table'
                            accent='#48525C'
                            theme='dark'
                            headers={['ID', 'Domain', 'Website name', 'For offer', 'Created at']}
                            filters={[
                                { name: "ID", friendlyName: "ID", type: "number" },
                                { name: "Domain", friendlyName: "Domain", type: "string" },
                                { name: "WebsiteName", friendlyName: "Website name", type: "string" },
                                { name: "updatedAt", friendlyName: "Date", type: "date" },
                            ]}
                            filterCB={f => setFilters(f)}
                            data={domains.data?.length > 0 ? addDomainsToTable() : [[{ keyID: 'noData', type: 'custom', data: <p>No data to display</p> }]]}
                        />
                    </div>
                    <div id="controles">
                        <Button value='Cancel' accent='transparent' style={{ borderBottom: '1px solid #e53333', borderRadius: '0px', color: '#e53333' }} onClick={props.onClose} />
                    </div>
                </div>
            </div>
        }

        const saveStickerCategory = (close) => {
            setSpinner(true);

            axios({
                method: "POST",
                url: `${backendModule.backendURL}/stickerCategories/editStickerCategory`,
                data: {
                    ID: props.curStickerCategory.ID,
                    Name: newName ? newName : props.curStickerCategory.Name,
                    Price: newPriceRef.current.value ? newPriceRef.current.value : props.curStickerCategory.Price,
                    DomainID: newSelectedDomain ? newSelectedDomain.ID : props.curStickerCategory.DomainID,
                },
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === 'ok') {
                    getAllStickerCategories();
                    close();
                }
                else {
                    msgRef.current.innerText = 'Something went wrong';
                }
            }).catch(() => {
                msgRef.current.innerText = 'Something went wrong';
            }).finally(() => {
                setSpinner(false);
            });
        }

        return <div className='route__stickers__editModal'>
            <div id='box'>
                <h1>Edit {props.curStickerCategory.Name}</h1>

                <CustomInput autocomplete="off" placeholder="Price" ref={newPriceRef} defaultValue={props.curStickerCategory.Price} accent="#FFF" theme="dark" type="number" style={{ width: "100%", marginBottom: '25px' }} />
                <Dropdown style={{marginBottom: '20px'}} inlinePlaceholder='Select Sticker Category Name' theme='dark' data={[
                    {name: 'Love ', value: 'Love'},
                    {name: 'Cupid ', value: 'Cupid'},
                    {name: 'Accessories ', value: 'Accessories'},
                    {name: 'SweetChat ', value: 'SweetChat'},
                    {name: 'TasteMe ', value: 'TasteMe'},
                    {name: 'DirtyChat ', value: 'DirtyChat'},
                    {name: 'Gadgets', value: 'Gadgets'},
                    {name: 'Toys', value: 'Toys'},
                    {name: 'SimpleDirtyChat', value: 'SimpleDirtyChat'},
                    {name: 'Exotic', value: 'Exotic'},
                    {name: 'EroticChat', value: 'EroticChat'},
                    {name: 'SimpleSticker', value: 'SimpleSticker'},
                ].map(item => {
                return {name: item.name, value: item.value}
                })} onChange={e => {setNewName(e.value)}} />
                <Button value={newSelectedDomain === null ? 'CHANGE DOMAIN' : newSelectedDomain.URL} accent='rgb(72, 82, 92)' className='selectDomainButton' onClick={(e) => openDomainsModal(e, false)} />

                <div id="controles" style={{ display: !spinner ? 'flex' : 'none' }}>
                    <Button value='Cancel' accent='transparent' style={{ borderBottom: '1px solid #e53333', borderRadius: '0px', color: '#e53333' }} onClick={props.onClose} />
                    <Button value='Save' accent='transparent' style={{ borderBottom: '1px solid #00A3FF', borderRadius: '0px', color: '#00A3FF' }} onClick={() => saveStickerCategory(props.onClose)} />
                </div>
                <div style={{ display: spinner ? 'flex' : 'none', flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <Spinner color='white' align='right' width='32px' />
                </div>
                <p id="msg" ref={msgRef}></p>
            </div>
        </div>
    }

    return (
        <div className='route__stickers'>
            <DataSorter sorters={[
                {name: 'Price', value:'Price'},
                {name: 'Name', value:'Name'},
                {name: 'Created at', value:'createdAt'},
                {name: 'Updated at', value:'updatedAt'}
            ]} setOrders={setOrders} />
            <FilteredCustomTable
                id='table'
                accent='#48525C'
                theme='dark'
                headers={['ID', 'Name', 'Price', 'Domain ID', 'Updated at']}
                filters={[
                    { name: "ID", friendlyName: "ID", type: "string" },
                    { name: "Name", friendlyName: "Name", type: "string" },
                    { name: "Price", friendlyName: "Price", type: "number" },
                    { name: "DomainID", friendlyName: "Domain ID", type: "string" },
                    { name: "updatedAt", friendlyName: "Updated at", type: "string" }
                ]}
                filterCB={f => setFilters(f)}
                data={props.stickerCategories.data?.length > 0 ? addStickerCategoriesToTable() : [[{ keyID: 'noData', type: 'custom', data: <p>No data to display</p> }]]}
            />
        </div>
    )
}

const AddNewStickerCategoryModal = (props) => {
    const [spinner, setSpinner] = React.useState(false);
    const [selectedDomain, setSelectedDomain] = React.useState(null);
    const [name, setName] = React.useState(null);
    const [firstImage, setFirstImage] = React.useState(null);
    const [secondImage, setSecondImage] = React.useState(null);
    const [thirdImage, setThirdImage] = React.useState(null);
    const [backgroundImage, setBackgroundImage] = React.useState(null);
    const msgRef = React.useRef(null);

    const priceRef = React.useRef(null);

    const getAllStickerCategories = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/stickerCategories/getAllStickerCategoriesCpanel`,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                props.setStickerCategories(res.data);
            }
            else {

            }
        }).catch(() => {

        });
    }

    React.useEffect(() => {
        getAllStickerCategories();
    }, []);

    const openDomainsModal = (e, isEdit, setNewSelectedDomain) => {
        animateBox(e, <SelectDomainModal isEdit={isEdit} setNewSelectedDomain={setNewSelectedDomain} />);
    }

    const SelectDomainModal = (props) => {
        const [filters, setFilters] = React.useState(false);
        const [domains, setDomains] = React.useState(false);

        const getAllDomains = () => {
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/domains/getAllDomains`,
                data: {
                    filters: filters
                },
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === 'ok') {
                    setDomains(res.data);
                }
                else {
                    setDomains('GET_ALL_DOMAINS_ERROR');
                }
            }).catch(() => {
                setDomains('GET_ALL_DOMAINS_ERROR');
            });
        }

        React.useEffect(() => {
            getAllDomains();
        }, [filters]);

        const addDomainsToTable = () => {
            if (domains.data) {
                return domains?.data?.map((item) => {
                    return [
                        { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.ID}</p></div> },
                        { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Domain}</p></div> },
                        { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.WebsiteName}</p></div> },
                        { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center', background: item.forOffer ? 'green' : 'red', width: 'fit-content', marginLeft: 'auto', marginRight: 'auto', padding: '5px', borderRadius: '10px' }}>{item.forOffer ? 'YES' : 'NO'}</p></div> },
                        { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{moment(item.updatedAt).format('DD.MM.YYYY.')}</p></div> },
                        {
                            keyID: String(item.ID), type: "groupNewline", group: [
                                {
                                    keyID: String(item.ID), type: "button", text: "Select", triggerDropdown: true, onClick: e => {
                                        setSelectedDomain({ ID: item.ID, URL: item.Domain });
                                        props.onClose();
                                    }
                                }
                            ]
                        }
                    ];
                });
            }
        }

        return <div className='route__emailServers__addNewModal__selectDomainModal'>
            <div id='box'>
                <div id='table'>
                    <FilteredCustomTable
                        id='table'
                        accent='#48525C'
                        theme='dark'
                        headers={['ID', 'Domain', 'Website name', 'For offer', 'Created at']}
                        filters={[
                            { name: "ID", friendlyName: "ID", type: "number" },
                            { name: "Domain", friendlyName: "Domain", type: "string" },
                            { name: "WebsiteName", friendlyName: "Website name", type: "string" },
                            { name: "updatedAt", friendlyName: "Date", type: "date" },
                        ]}
                        filterCB={f => setFilters(f)}
                        data={domains.data?.length > 0 ? addDomainsToTable() : [[{ keyID: 'noData', type: 'custom', data: <p>No data to display</p> }]]}
                    />
                </div>
                <div id="controles">
                    <Button value='Cancel' accent='transparent' style={{ borderBottom: '1px solid #e53333', borderRadius: '0px', color: '#e53333' }} onClick={props.onClose} />
                </div>
            </div>
        </div>
    }

    const uploadImage = (selectedImage) => {
        let imgFile = new FormData();
        imgFile.append('image', selectedImage);
        imgFile.append('ImageName', selectedImage.name);
        imgFile.append('tag', 'logo-img');

        return axios({
            method: "POST",
            url: `${backendModule.backendURL}/images/uploadImage`,
            data: imgFile,
            ...backendModule.axiosConfig,
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })
    }

    const addPackage = async(close) => {
        setSpinner(true);

        if(!name){
            setSpinner(false);
            return msgRef.current.innerText = 'Name missing!';
        }

        if(!priceRef.current.value){
            setSpinner(false);
            return msgRef.current.innerText = 'Price missing!';
        }

        if(!selectedDomain){
            setSpinner(false);
            return msgRef.current.innerText = 'Domain missing!';
        }

        if(!firstImage || !secondImage || !thirdImage || !backgroundImage){
            setSpinner(false);
            return msgRef.current.innerText = 'Image missing!';
        }

        let firstImageToProccess = await uploadImage(firstImage);
        let secondImageToProccess = await uploadImage(secondImage);
        let thirdImageToProccess = await uploadImage(thirdImage);
        let backgroundImageToProccess = await uploadImage(backgroundImage);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/stickerCategories/addStickerCategory`,
            data: {
                Name: name,
                Price: priceRef.current.value,
                FirstDisplayImage: firstImageToProccess.data.data,
                SecondDisplayImage: secondImageToProccess.data.data,
                ThirdDisplayImage: thirdImageToProccess.data.data,
                BackgroundImage: backgroundImageToProccess.data.data,
                DomainID: selectedDomain.ID,
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                getAllStickerCategories();
                close();
            }
            else {
                msgRef.current.innerText = 'Something went wrong';
            }
        }).catch(() => {
            msgRef.current.innerText = 'Something went wrong';
        }).finally(() => {
            setSpinner(false);
        });
    }

    return <div className='route__stickers__addNewModal'>
        <div id='box'>
            <h1>Add new Sticker Category</h1>

            <CustomInput autocomplete="off" placeholder="Price" ref={priceRef} accent="#FFF" theme="dark" type="number" style={{ width: "100%", marginBottom: '25px' }} />

            <Dropdown style={{marginBottom: '20px'}} inlinePlaceholder='Select Sticker Category Name' theme='dark' data={[
                {name: 'Love ', value: 'Love'},
                {name: 'Cupid ', value: 'Cupid'},
                {name: 'Accessories ', value: 'Accessories'},
                {name: 'SweetChat ', value: 'SweetChat'},
                {name: 'TasteMe ', value: 'TasteMe'},
                {name: 'DirtyChat ', value: 'DirtyChat'},
                {name: 'Gadgets', value: 'Gadgets'},
                {name: 'Toys', value: 'Toys'},
                {name: 'SimpleDirtyChat', value: 'SimpleDirtyChat'},
                {name: 'Exotic', value: 'Exotic'},
                {name: 'EroticChat', value: 'EroticChat'},
                {name: 'SimpleSticker', value: 'SimpleSticker'},
            ].map(item => {
            return {name: item.name, value: item.value}
            })} onChange={e => {setName(e.value)}} />

            <Button value={selectedDomain === null ? 'SELECT DOMAIN' : selectedDomain.URL} accent='rgb(72, 82, 92)' className='selectDomainButton' onClick={(e) => openDomainsModal(e, false)} />
            <p style={{color: 'white'}}>Select first Image for the card</p>
            <input
                id="img-input"
                type='file'
                name='websitelogo'
                onChange={
                    (event) => {
                        setFirstImage(event.target.files[0]);
                    }
                }
            />
            <p id="img-p">{firstImage?.name}</p>
            <p style={{color: 'white'}}>Select second Image for the card</p>
            <input
                id="img-input"
                type='file'
                name='websitelogo'
                onChange={
                    (event) => {
                        setSecondImage(event.target.files[0]);
                    }
                }
            />
            <p id="img-p">{secondImage?.name}</p>
            <p style={{color: 'white'}}>Select third Image for the card</p>
            <input
                id="img-input"
                type='file'
                name='websitelogo'
                onChange={
                    (event) => {
                        setThirdImage(event.target.files[0]);
                    }
                }
            />
            <p id="img-p">{thirdImage?.name}</p>
            <p style={{color: 'white'}}>Select background Image for the card</p>
            <input
                id="img-input"
                type='file'
                name='websitelogo'
                onChange={
                    (event) => {
                        setBackgroundImage(event.target.files[0]);
                    }
                }
            />
            <p id="img-p">{backgroundImage?.name}</p>
            <div id="controles" style={{ display: !spinner ? 'flex' : 'none' }}>
                <Button value='Cancel' accent='transparent' style={{ borderBottom: '1px solid #e53333', borderRadius: '0px', color: '#e53333' }} onClick={props.onClose} />
                <Button value='Add' accent='transparent' style={{ borderBottom: '1px solid #00A3FF', borderRadius: '0px', color: '#00A3FF' }} onClick={() => addPackage(props.onClose)} />
            </div>
            <div style={{ display: spinner ? 'flex' : 'none', flexDirection: 'row', justifyContent: 'flex-end' }}>
                <Spinner color='white' align='right' width='32px' />
            </div>
            <p id="msg" ref={msgRef}></p>
        </div>
    </div>
}

export { AddNewStickerCategoryModal }