import React from 'react';
import "./index.scss";


import { useDispatch, useSelector } from "react-redux";
import CustomInput from "../../components/customComponents/CustomInput";
import Button from "../../components/customComponents/Button";
import { FilteredCustomTable } from "../../components/customComponents/Table";
import DataSorter from "../../components/customComponents/DataSorter";

import * as backendModule from "../../modules/backendModule";
import axios, { all } from "axios";
import { animateBox } from "../../modules/componentAnimation";

import useDefer from "../../modules/hooks/useDefer";
import useOnScreen from "../../modules/hooks/useOnScreen";
import * as timestmapActions from "../../actions/timestampAction";

import moment from "moment";
import Spinner from '../../components/customComponents/Spinner';
import Dropdown from '../../components/customComponents/Dropdown';

// export default function Domains(props) {
//     const [filters, setFilters] = React.useState([]);
//     const [orders, setOrders] = React.useState([]);
//     const [domains, setDomains] = React.useState([]);

//     const getAllDomains = () => {
//         axios({
//             method: "POST",
//             url: `${backendModule.backendURL}/domains/getAllDomains`,
//             data: {
//                 filters: filters,
//                 orders: orders
//             },
//             ...backendModule.axiosConfig
//         }).then(res => {
//             if (res.data.status === 'ok') {
//                 setDomains(res.data);
//             }
//             else {
//                 setDomains('GET_ALL_DOMAINS_ERROR');
//             }
//         }).catch(() => {
//             setDomains('GET_ALL_DOMAINS_ERROR');
//         });
//     }

//     React.useEffect(() => {
//         getAllDomains();
//     }, [filters, orders]);

//     const deleteDomain = (id) => {
//         axios({
//             method: "POST",
//             url: `${backendModule.backendURL}/domains/removeDomain`,
//             data: {
//                 ID: id
//             },
//             ...backendModule.axiosConfig
//         }).then(res => {
//             getAllDomains();
//         }).catch(() => {

//         });
//     }

//     const addDomainsToTable = () => {
//         if (domains.data) {
//             return domains?.data?.map((item) => {
//                 return [
//                     { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.ID}</p></div> },
//                     { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Domain}</p></div> },
//                     { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.WebsiteName}</p></div> },
//                     { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Currency}</p></div> },
//                     { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center', background: item.forOffer ? 'green' : '#e53333', width: 'fit-content', marginLeft: 'auto', marginRight: 'auto', padding: '5px', borderRadius: '10px' }}>{item.forOffer ? 'YES' : 'NO'}</p></div> },
//                     { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{moment(item.updatedAt).format('DD.MM.YYYY.')}</p></div> },
//                     {
//                         keyID: String(item.ID), type: "groupNewline", group: [
//                             {
//                                 keyID: String(item.ID), type: "button", text: "Delete", triggerDropdown: true, triggerData: e => {
//                                     return (<div style={{ display: 'flex', flexDirection: 'column', justfyContent: 'center', alignItems: 'center' }}>
//                                         <p style={{ color: 'white' }}>Are you sure?</p>
//                                         <br></br>
//                                         <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
//                                             <Button style={{ marginRight: '10px' }} accent='rgb(72, 82, 92)' value='YES' onClick={() => deleteDomain(item.ID)} />
//                                             <Button accent='rgb(72, 82, 92)' value='NO' onClick={c => { e() }} />
//                                         </div>
//                                     </div>);
//                                 },
//                             },
//                             {
//                                 keyID: String(item.ID), type: "button", text: "Open image", triggerDropdown: true, onClick: e => {
//                                     window.open(`/#/image/${String(item.WebsiteLogo).replace(`${backendModule.backendURL}/staticContent/images/`, '')}`, '_blank');
//                                 }
//                             },
//                             {
//                                 keyID: String(item.ID), type: "button", text: "Edit", triggerDropdown: true, onClick: e => {
//                                     animateBox(e, <EditDomain domainForEdit={
//                                         {
//                                             ID: item.ID,
//                                             Domain: item.Domain,
//                                             WebsiteName: item.WebsiteName,
//                                             Currency: item.Currency,
//                                             WebsiteLogo: item.WebsiteLogo,
//                                             ForOffer: item.forOffer,
//                                             PaymentProvider: item.PaymentProvider
//                                         }
//                                     } forOffer={item.forOffer} />);
//                                 }
//                             }
//                         ]
//                     }
//                 ];
//             });
//         }
//     }

//     const domainNameRef = React.useRef(null);
//     const websiteNameRef = React.useRef(null);
//     const currencyRef = React.useRef(null);
//     const domainForOfferCheckboxRef = React.useRef(null);
//     const domainMsgRef = React.useRef(null);
//     const [selectedImage, setSelectedImage] = React.useState(null);
//     const [paymentKeys, setPaymentKeys] = React.useState([]);
//     const [selectedPaymentKey, setSelectedPaymentKey] = React.useState(null);

//     React.useEffect(() => {
//         axios({
//             method: "POST",
//             url: `${backendModule.backendURL}/payment/getPaymentKeys`,
//             ...backendModule.axiosConfig
//         }).then(res => {
//             if (res.data.status == "ok") {
//                 setPaymentKeys(res.data.data);
//             };
//         }).catch(() => null);
//     }, []);

//     const addDomain = async () => {

//         if(!domainNameRef.current.value){
//             return domainMsgRef.current.innerText = 'Domain missing!';
//         }

//         if(!websiteNameRef.current.value){
//             return domainMsgRef.current.innerText = 'Website name missing!';
//         }

//         if (!domainNameRef.current.value.startsWith('https://')) {
//             return domainMsgRef.current.innerText = 'Domain must start with https://';
//         }

//         let finalImage = null;
//         if (selectedImage) {
//             let imgFile = new FormData();
//             imgFile.append('image', selectedImage);
//             imgFile.append('ImageName', selectedImage.name);
//             imgFile.append('tag', 'logo-img');
    
//             finalImage = await axios({
//                 method: "POST",
//                 url: `${backendModule.backendURL}/images/uploadImage`,
//                 data: imgFile,
//                 ...backendModule.axiosConfig,
//                 headers: {
//                     "Content-Type": "multipart/form-data"
//                 }
//             }).then(imgres => {
//                 return imgres.data.status === "ok" ? imgres.data.data : null;
//             }).catch(() => {
//                 return null;
//             });
//         }

//         axios({
//             method: "POST",
//             url: `${backendModule.backendURL}/domains/addDomain`,
//             data: {
//                 Domain: domainNameRef.current.value,
//                 WebsiteName: websiteNameRef.current.value,
//                 Currency: currencyRef.current.value,
//                 WebsiteLogo: finalImage,
//                 forOffer: domainForOfferCheckboxRef.current.checked ? true : false,
//                 PaymentProvider: selectedPaymentKey
//             },
//             ...backendModule.axiosConfig
//         }).then(res => {
//             if (res.data.status === 'ok') {
//                 getAllDomains();
//                 props.setNewDomainModal(false);
//             }
//             else {
//                 domainMsgRef.current.innerText = 'Something went wrong';
//             }
//         }).catch(() => {
//             domainMsgRef.current.innerText = 'Something went wrong';
//         });
//     }

//     const editDomainForOfferCheckboxRef = React.useRef(null);
//     const editDomainMsgRef = React.useRef(null);
//     const newDomainRef = React.useRef(null);
//     const newWebsiteNameRef = React.useRef(null);
//     const newCurrencyRef = React.useRef(null);
//     const [spinner, setSpinner] = React.useState(false);

//     const saveDomain = (id, domain, close, newImage, paymentKey) => {
//         setSpinner(true);

//         if (!newDomainRef.current.value.startsWith('https://')) {
//             return editDomainMsgRef.current.innerText = 'Domain must start with https://';
//         }
//         if (!newImage) {
//             axios({
//                 method: "POST",
//                 url: `${backendModule.backendURL}/domains/editDomain`,
//                 data: {
//                     ID: id,
//                     Domain: newDomainRef.current.value,
//                     WebsiteName: newWebsiteNameRef.current.value,
//                     Currency: newCurrencyRef.current.value,
//                     WebsiteLogo: domain.WebsiteLogo,
//                     forOffer: editDomainForOfferCheckboxRef.current.checked ? true : false,
//                     PaymentProvider: paymentKey
//                 },
//                 ...backendModule.axiosConfig
//             }).then(res => {
//                 if (res.data.status === 'ok') {
//                     getAllDomains();
//                     close();
//                 }
//                 else {
//                     editDomainMsgRef.current.innerText = 'Something went wrong';
//                 }
//             }).catch(() => {
//                 editDomainMsgRef.current.innerText = 'Something went wrong';
//             }).finally(() => {
//                 setSpinner(false);
//             });
//         }
//         else {
//             let imgFile = new FormData();
//             imgFile.append('image', newImage);
//             imgFile.append('ImageName', newImage.name);
//             imgFile.append('tag', 'logo-img');

//             let oldImage = domain.WebsiteLogo.replace(`${backendModule.backendURL}/staticContent/images/`, '');

//             axios({
//                 method: "POST",
//                 url: `${backendModule.backendURL}/images/getAllImages`,
//                 ...backendModule.axiosConfig,
//                 headers: {
//                     "Content-Type": "multipart/form-data"   
//                 }
//             }).then(allimgs => {
//                 allimgs.data.data.map((item) => {
//                     if(String(item.URL).replace(`${backendModule.backendURL}/staticContent/images/`, '') === oldImage){
//                         axios({
//                             method: "POST",
//                             url: `${backendModule.backendURL}/images/deleteImage`,
//                             ...backendModule.axiosConfig,
//                             data: {
//                                 id: item.ID
//                             },
//                             headers: {
//                                 "Content-Type": "multipart/form-data"   
//                             }
//                         }).then(() => {
//                             axios({
//                                 method: "POST",
//                                 url: `${backendModule.backendURL}/images/uploadImage`,
//                                 data: imgFile,
//                                 ...backendModule.axiosConfig,
//                                 headers: {
//                                     "Content-Type": "multipart/form-data"
//                                 }
//                             }).then(imgres => {
//                                 axios({
//                                     method: "POST",
//                                     url: `${backendModule.backendURL}/domains/editDomain`,
//                                     data: {
//                                         ID: id,
//                                         Domain: newDomainRef.current.value,
//                                         WebsiteName: newWebsiteNameRef.current.value,
//                                         Currency: newCurrencyRef.current.value,
//                                         WebsiteLogo: imgres.data.data,
//                                         forOffer: editDomainForOfferCheckboxRef.current.checked ? true : false,
//                                         PaymentProvider: paymentKey
//                                     },
//                                     ...backendModule.axiosConfig
//                                 }).then(res => {
//                                     if (res.data.status === 'ok') {
//                                         getAllDomains();
//                                         close();
//                                     }
//                                     else {
//                                         editDomainMsgRef.current.innerText = 'Something went wrong';
//                                     }
//                                 }).catch(() => {
//                                     editDomainMsgRef.current.innerText = 'Something went wrong';
//                                 });
//                             }).catch(() => {
                
//                             })
//                         }).catch(() => {}).finally(() => {
//                             setSpinner(false);
//                         })
//                     }
//                 });
//             }).catch(() => {});
//         }
//     }

//     const EditDomain = (props) => {
//         const [editSelectedPaymentKey, setEditSelectedPaymentKey] = React.useState(props.PaymentProvider ?? null);

//         React.useEffect(() => {
//             if(props.forOffer){
//                 editDomainForOfferCheckboxRef.current.checked = true;
//             }
//         }, []);

//         const [newSelectedImage, setNewSelectedImage] = React.useState(null);

//         return <div className='route__domains__editDomainModal'>
//             <div style={{display: spinner ? 'block' : 'none'}}><Spinner /></div>
//             <div style={{display: spinner ? 'none' : ''}} id='box'>
//                 <h1>Edit {props.domainForEdit.Domain}</h1>
//                 <CustomInput autocomplete="off" ref={newDomainRef} defaultValue={`https://${props.domainForEdit.Domain}`} placeholder="Domain" accent="#FFF" theme="dark" type="text" style={{ width: "100%", marginBottom: '25px' }} />
//                 <CustomInput autocomplete="off" ref={newWebsiteNameRef} defaultValue={props.domainForEdit.WebsiteName} placeholder="Website name" accent="#FFF" theme="dark" type="text" style={{ width: "100%", marginBottom: '25px' }} />
//                 <CustomInput autocomplete="off" ref={newCurrencyRef} defaultValue={props.domainForEdit.Currency} placeholder="Currency (EUR, USD, CAD ...)" accent="#FFF" theme="dark" type="text" style={{ width: "100%", marginBottom: '25px' }} />
//                 <input
//                     id="img-input"
//                     type='file'
//                     name='websitelogo'
//                     onChange={
//                         (event) => {
//                             setNewSelectedImage(event.target.files[0]);
//                         }
//                     }
//                 />
//                 <p id="img-p">{newSelectedImage?.name}</p>
//                 <span id='checkbox-span'><input type='checkbox' ref={editDomainForOfferCheckboxRef} /><p>For offer</p></span>
//                 <span style={{color: "white", width: "100%"}}>Payment provider</span><Dropdown style={{width: "100%", marginBottom: "20px"}} accent="gray" theme="dark" data={[{name: "None", value: null},...paymentKeys.map(pk => {
//                     return {name: pk, value: pk};
//                 })]} onChange={e => setEditSelectedPaymentKey(e?.value)} selected={(()=>{
//                     if (!props.domainForEdit.PaymentProvider) return -1;
//                     return paymentKeys.indexOf(props.domainForEdit.PaymentProvider)+1;
//                 })()}/>
//                 <div id="controles">
//                     <Button value='Cancel' accent='transparent' style={{borderBottom: '1px solid #e53333', borderRadius: '0px', color: '#e53333'}} onClick={props.onClose} />
//                     <Button value='Save' accent='transparent' style={{borderBottom: '1px solid #00A3FF', borderRadius: '0px', color: '#00A3FF'}} onClick={() => saveDomain(props.domainForEdit.ID, props.domainForEdit, props.onClose, newSelectedImage, editSelectedPaymentKey)} />
//                 </div>
//                 <p id="msg" ref={editDomainMsgRef}></p>
//             </div>
//         </div>
//     }

//     return (
//         <div className="route__domains">
//             {
//                 props.newDomainModal && <div className='route__domains__newDomainModal'>
//                     <div id="box">
//                         <h1>Add new domain</h1>
//                         <CustomInput autocomplete="off" ref={domainNameRef} placeholder="Domain" accent="#FFF" theme="dark" type="text" style={{ width: "100%", marginBottom: '25px' }} />
//                         <CustomInput autocomplete="off" ref={websiteNameRef} placeholder="Website name" accent="#FFF" theme="dark" type="text" style={{ width: "100%", marginBottom: '25px' }} />
//                         <CustomInput autocomplete="off" ref={currencyRef} placeholder="Currency (EUR, USD, CAD ...)" accent="#FFF" theme="dark" type="text" style={{ width: "100%", marginBottom: '25px' }} />
//                         <input
//                             id="img-input"
//                             type='file'
//                             name='websitelogo'
//                             onChange={
//                                 (event) => {
//                                     setSelectedImage(event.target.files[0]);
//                                 }
//                             }
//                         />
//                         <p id="img-p">{selectedImage?.name}</p>
//                         <span id="checkbox-span"><input type='checkbox' ref={domainForOfferCheckboxRef} /> <p>For offer</p></span>
//                         <span style={{color: "white", width: "100%"}}>Payment provider</span><Dropdown style={{width: "100%", marginBottom: "20px"}} accent="gray" theme="dark" data={[{name: "None", value: null},...paymentKeys.map(pk => {
//                             return {name: pk, value: pk};
//                         })]} onChange={e => setSelectedPaymentKey(e?.value)} selected={selectedPaymentKey ? paymentKeys.indexOf(selectedPaymentKey)+1 : -1}/>
//                         <div id="controles">
//                             <Button value='Cancel' accent='transparent' style={{borderBottom: '1px solid #e53333', borderRadius: '0px', color: '#e53333'}} onClick={() => props.setNewDomainModal(false)} />
//                             <Button value='Add' accent='transparent' style={{borderBottom: '1px solid #00A3FF', borderRadius: '0px', color: '#00A3FF'}}  onClick={() => addDomain()} />
//                         </div>
//                         <p id="msg" ref={domainMsgRef}></p>
//                     </div>
//                 </div>
//             }
//             <DataSorter sorters={[
//                 {name: 'Domain', value: 'Domain'},
//                 {name: 'For Offer ?', value:'forOffer'},
//                 {name: 'Website Name', value:'WebsiteName'},
//                 {name: 'Payment Provider', value:'PaymentProvider'},
//                 {name: 'Currency', value:'Currency'}
//             ]} setOrders={setOrders} />
//             <FilteredCustomTable
//                 id='table'
//                 accent='#48525C'
//                 theme='dark'
//                 headers={['ID', 'Domain', 'Website name','Currency', 'For offer', 'Updated at']}
//                 filters={[
//                     { name: "ID", friendlyName: "ID", type: "number" },
//                     { name: "Domain", friendlyName: "Domain", type: "string" },
//                     { name: "WebsiteName", friendlyName: "Website name", type: "string" },
//                     { name: "Currency", friendlyName: "Currency", type: "string" },
//                     { name: "updatedAt", friendlyName: "Date", type: "date" },
//                 ]}
//                 filterCB={f => setFilters(f)}
//                 data={domains.data?.length > 0 ? addDomainsToTable() : [[{ keyID: 'noData', type: 'custom', data: <p>No data to display</p> }]]}
//             />
//         </div>
//     )
// }

const Domains = (props) => {
  const [filters, setFilters] = React.useState([]);
  const [orders, setOrders] = React.useState([]);
  const [domains, setDomains] = React.useState([]);
  const [canPaginate, setCanPaginate] = React.useState(false);
  const [spinner, setSpinner] = React.useState(false);

  const curOnScreen = useOnScreen();
  const curDefer = useDefer();
  const timestampSelector = useSelector(state => state?.timestamp ?? null);

  const currentTimestampRef = React.useRef();

  const getDomains = (ts) => {
    if (canPaginate) setCanPaginate(false);
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/domains/getAllDomains`,
      data: {
        offset: 0,
        limit: 20,
        filters: filters,
        orders: orders
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (ts !== currentTimestampRef.current) return;
      if (res.data.status === 'ok') {
        setDomains(res.data);
        if (res.data.data.length === 20 && !canPaginate) setCanPaginate(true);
      }
      else {
        setDomains({status: 'error', data: null});
      }
    }).catch(() => {
      setDomains({status: 'error', data: null});
    }).finally(() => {
    })
  }

  const continueData = (ts) => {
    if (canPaginate) setCanPaginate(false);

    setSpinner(true);
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/domains/getAllDomains`,
      data: {
        offset: 0,
        limit: 20,
        filters: [
          ...filters,
          {name: "ID", op: "notIn", value: domains.data.map(d => d.ID)}
        ],
        orders: orders
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (ts !== currentTimestampRef.current) return;

      if (res.data.status === "ok") {
        if (res.data.data.length === 20 && !canPaginate) setCanPaginate(true);
        setDomains(d => {
          return {
            ...d,
            data: [
              ...d.data,
              ...res.data.data
            ]
          };
        });
      };
    }).catch(() => {
      setDomains({status: "error", data: "SERVER_ERROR"});
    }).finally(() => {
      setSpinner(false);
    });
  };

  React.useEffect(() => {

    curDefer(() => {
      if (!canPaginate) return;
      if (!curOnScreen.isIntersecting) return;

      try {
        curOnScreen.observer.unobserve(curOnScreen.measureRef.current);
      } catch {};

      const ts = Date.now();
      currentTimestampRef.current = ts;

      continueData(ts);
    }, 500);

    
  }, [curOnScreen.isIntersecting, canPaginate, domains]);

  React.useEffect(() => {
    let ts = Date.now();
    currentTimestampRef.current = ts;
    getDomains(ts);
  }, [filters, timestampSelector, orders]);

  React.useEffect(() => {
    getDomains();
  }, [filters, orders]);

  return <div className="route__domains">

    <DataSorter sorters={[
      {name: 'Domain', value: 'Domain'},
      {name: 'For Offer ?', value:'forOffer'},
      {name: 'Website Name', value:'WebsiteName'},
      {name: 'Payment Provider', value:'PaymentProvider'},
      {name: 'Currency', value:'Currency'}
    ]} setOrders={setOrders} />

    <FilteredCustomTable
      filterCB={c => setFilters(c)}
      filterHeadStyle={{backgroundColor: 'rgba(25, 35, 67, 0.2)'}}
      tableHeadStyle={{backgroundColor: 'rgba(25, 35, 67, 0.2)'}}
      filters={[
        { name: "ID", friendlyName: "ID", type: "number" },
        { name: "Domain", friendlyName: "Domain", type: "string" },
        { name: "WebsiteName", friendlyName: "Website name", type: "string" },
        { name: "Currency", friendlyName: "Currency", type: "string" },
        { name: "updatedAt", friendlyName: "Date", type: "date" },
      ]}
      accent='#000'
      theme='dark'
      headers={["ID", "WebsiteName", "forOffer", "PaymentProvider", "Currency"]}
      data={(()=>{
      let out = [];

      if (!domains.data) return [[{keyID: "noData-spinner", type: "spinner"}]];
      if (domains.status === "error") return [[{keyID: "noData-error", type: "text", text: "Error while fetching data"}]];

      for (let item of domains.data) {
        out.push([
          {keyID: item.ID, type: "text", text: item.ID},
          {keyID: item.ID, type: "text", text: item.WebsiteName},
          {keyID: item.ID, type: "text", text: item.forOffer ? 'YES' : 'NO'},
          {keyID: item.ID, type: "text", text: item.PaymentProvider},
          {keyID: item.ID, type: "text", text: item.Currency},
          {keyID: item.ID, type: "groupNewline", group: [
            {keyID: item.ID, type: "button", text: "Edit", 
              onClick: e => 
                animateBox(e, <EditDomain ID={item.ID} domainForEdit={ 
                  {ID: item.ID,
                  Domain: item.Domain,
                  WebsiteName: item.WebsiteName,
                  Currency: item.Currency,
                  WebsiteLogo: item.WebsiteLogo,
                  ForOffer: item.forOffer,
                  PaymentProvider: item.PaymentProvider}
                } forOffer={item.forOffer}
                onChange={() => {
                  let ts = Date.now();
                  currentTimestampRef.current = ts;
                  getDomains(ts);
                  
                }} />)},
            {keyID: item.ID, type: "button", text: "Delete", triggerDropdown: true, 
              triggerData: c => 
                <DeleteDomain ID={item.ID} onClose={c} onChange={() => {
                  let ts = Date.now();
                  currentTimestampRef.current = ts;
                  getDomains(ts);
                }}/>}
          ]}
        ])
      };

      if (spinner) out.push([{keyID: "data-spinner", type: "spinner"}]);
      if (out.length === 0) out.push([{keyID: "noData-noData", type: "text", text: "Nothing to show..."}]);

      return out;
      })()}
    />
    {canPaginate ? <p style={{ display : canPaginate ? 'block' : 'none' }}>ee</p>: ''}
    {canPaginate ? <div ref={curOnScreen.measureRef} style={{ width: '1px', height: '1px', opacity: '0', display : canPaginate ? 'block' : 'none' }}></div> : ''}
    {/* <div ref={curOnScreen.measureRef} style={{ width: '1px', height: '1px', opacity: '0', display : canPaginate ? 'block' : 'none' }}></div> */}

  </div>
}

const DeleteDomain = (props) => {

  const [spinner, setSpinner] = React.useState(false);
  const [infoP, setInfoP] = React.useState("");

  const deleteDomain = (id) => {
    setInfoP("");
    setSpinner(true);

    axios({
      method: "POST",
      url: `${backendModule.backendURL}/domains/removeDomain`,
      data: {
        ID: id
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        props.onClose().then(() => {
          props.onChange();
        })
      } else {
        setInfoP("Error while deleting the Domain");
      };
    }).catch(() => {
      setInfoP("Server timed out");
    }).finally(() => {
      setSpinner(false);
    });
  }

  if (spinner) return <Spinner color="white" />
  if (infoP) return <>
    {infoP}
    <Button style={{  }} accent='rgb(72, 82, 92)' value='Close' onClick={props.onClose} />
  </>;

  return <div className="route__buildABitch__removeBitch">
    <p>Are you sure?</p>
    <Button style={{ marginRight: '10px' }} accent='rgb(72, 82, 92)' value='YES' onClick={() => deleteDomain(props.ID)} />
    <Button style={{  }} accent='rgb(72, 82, 92)' value='NO' onClick={props.onClose} />
  </div>

}

const EditDomain = (props) => {

  const [editSelectedPaymentKey, setEditSelectedPaymentKey] = React.useState(props.domainForEdit.PaymentProvider ?? null);
  const [spinner, setSpinner] = React.useState(false);
  const [paymentKeys, setPaymentKeys] = React.useState([]);

  const editDomainForOfferCheckboxRef = React.useRef(null);
  const editDomainMsgRef = React.useRef(null);
  const newDomainRef = React.useRef(null);
  const newWebsiteNameRef = React.useRef(null);
  const newCurrencyRef = React.useRef(null);

  React.useEffect(() => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/payment/getPaymentKeys`,
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status == "ok") {
        setPaymentKeys(res.data.data);
      };
    }).catch(() => null);
  }, []);

  React.useEffect(() => {
    if(props.forOffer){
      editDomainForOfferCheckboxRef.current.checked = true;
    }
  }, []);

  const saveDomain = () => {
    if(spinner) return;
    setSpinner(true);

    if (!newDomainRef.current.value.startsWith('https://')) return editDomainMsgRef.current.innerText = 'Domain must start with https://';

    axios({
      method: "POST",
      url: `${backendModule.backendURL}/domains/editDomain`,
      data: {
        ID: props.domainForEdit.ID,
        Domain: newDomainRef.current.value,
        WebsiteName: newWebsiteNameRef.current.value,
        Currency: newCurrencyRef.current.value,
        WebsiteLogo: props.domainForEdit.WebsiteLogo,
        forOffer: editDomainForOfferCheckboxRef.current.checked ? true : false,
        PaymentProvider: editSelectedPaymentKey
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        props.onClose().then(() => {
          props.onChange();
        })
      } else {
        return editDomainMsgRef.current.innerText = 'Error while editing the domain';
      };
    }).catch(() => {
      editDomainMsgRef.current.innerText = 'Something went wrong';
    }).finally(() => {
      setSpinner(false);
    });
  }

  return <div className='route__domains__editDomainModal'>

    <div className="route__domains__editDomainModal__spinner" style={{
      opacity: spinner ? 1 : 0,
      pointerEvents: spinner ? "all" : "none"
    }} onClick={e => spinner && e.stopPropagation()}>
      <Spinner color="white" />
    </div>

    <div style={{display: spinner ? 'block' : ''}} id='box'>

      <h1>Edit {props.domainForEdit.Domain}</h1>

      <CustomInput autocomplete="off" ref={newDomainRef} defaultValue={`https://${props.domainForEdit.Domain}`} placeholder="Domain" 
        accent="#FFF" theme="dark" type="text" style={{ width: "100%", marginBottom: '25px' }} />
      <CustomInput autocomplete="off" ref={newWebsiteNameRef} defaultValue={props.domainForEdit.WebsiteName} placeholder="Website name" 
        accent="#FFF" theme="dark" type="text" style={{ width: "100%", marginBottom: '25px' }} />
      <CustomInput autocomplete="off" ref={newCurrencyRef} defaultValue={props.domainForEdit.Currency} placeholder="Currency (EUR, USD, CAD ...)" 
        accent="#FFF" theme="dark" type="text" style={{ width: "100%", marginBottom: '25px' }} />
      
      <span id='checkbox-span'><input type='checkbox' ref={editDomainForOfferCheckboxRef} /><p>For offer</p></span>
      <span style={{color: "white", width: "100%", fontSize: '16px', textAlign: 'left', marginBottom:'8px'}}>Payment provider</span>
      
      <Dropdown style={{width: "100%", marginBottom: "20px"}} accent="gray" theme="dark" data={[{name: "None", value: null},...paymentKeys.map(pk => {
        return {name: pk, value: pk};
      })]} onChange={e => setEditSelectedPaymentKey(e?.value)} selected={(()=>{
        if (!props.domainForEdit.PaymentProvider) return -1;
        return paymentKeys.indexOf(props.domainForEdit.PaymentProvider)+1;
      })()}/>

      <div id="controles">
        <Button value='Cancel' accent='transparent' style={{borderBottom: '1px solid #e53333', borderRadius: '0px', color: '#e53333'}} 
          onClick={props.onClose} />
        <Button value='Save' accent='transparent' style={{borderBottom: '1px solid #00A3FF', borderRadius: '0px', color: '#00A3FF'}} 
          onClick={() => saveDomain()} />
      </div>

      <p id="msg" ref={editDomainMsgRef}></p>

    </div>
  </div>
}

const AddDomain = (props) => {
  const [spinner, setSpinner] = React.useState(false);
  const domainNameRef = React.useRef(null);
  const websiteNameRef = React.useRef(null);
  const currencyRef = React.useRef(null);
  const domainForOfferCheckboxRef = React.useRef(null);
  const domainMsgRef = React.useRef(null);
  const [paymentKeys, setPaymentKeys] = React.useState([]);
  const [selectedPaymentKey, setSelectedPaymentKey] = React.useState(null);

  const curDispatch = useDispatch();

  React.useEffect(() => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/payment/getPaymentKeys`,
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status == "ok") {
        setPaymentKeys(res.data.data);
      };
    }).catch(() => null);
  }, []);

  const addDomain = async () => {
    if(spinner) return;
    setSpinner(true)

    if(!domainNameRef.current.value) return domainMsgRef.current.innerText = 'Domain missing!';
    if(!websiteNameRef.current.value) return domainMsgRef.current.innerText = 'Website name missing!';
    if (!domainNameRef.current.value.startsWith('https://')) return domainMsgRef.current.innerText = 'Domain must start with https://';

    axios({
      method: "POST",
      url: `${backendModule.backendURL}/domains/addDomain`,
      data: {
        Domain: domainNameRef.current.value,
        WebsiteName: websiteNameRef.current.value,
        Currency: currencyRef.current.value,
        WebsiteLogo: null,
        forOffer: domainForOfferCheckboxRef.current.checked ? true : false,
        PaymentProvider: selectedPaymentKey
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        curDispatch(timestmapActions.setTimestamp());
        props.onClose();
      } else {
        domainMsgRef.current.innerText = "Check your info and try again";
      };
    }).catch(() => {
      domainMsgRef.current.innerText = 'Something went wrong';
    }).finally(() => {
      setSpinner(false)
    })
  }

  return <div className="route__domains">
      <div className='route__domains__newDomainModal'>
        <div id="box">

          <h1>Add new domain</h1>

          <CustomInput autocomplete="off" ref={domainNameRef} placeholder="Domain" accent="#FFF" theme="dark" type="text" 
            style={{ width: "100%", marginBottom: '25px' }} />
          <CustomInput autocomplete="off" ref={websiteNameRef} placeholder="Website name" accent="#FFF" theme="dark" type="text" 
            style={{ width: "100%", marginBottom: '25px' }} />
          <CustomInput autocomplete="off" ref={currencyRef} placeholder="Currency (EUR, USD, CAD ...)" accent="#FFF" theme="dark" type="text" 
            style={{ width: "100%", marginBottom: '25px' }} />
          
          <span id="checkbox-span"><input type='checkbox' ref={domainForOfferCheckboxRef} /> <p>For offer</p></span>
          
          <span style={{color: "white", width: "100%"}}>Payment provider</span>
          <Dropdown style={{width: "100%", marginBottom: "20px"}} accent="gray" theme="dark" 
            data={[{name: "None", value: null},...paymentKeys.map(pk => {
              return {name: pk, value: pk};
          })]} onChange={e => setSelectedPaymentKey(e?.value)} selected={selectedPaymentKey ? paymentKeys.indexOf(selectedPaymentKey)+1 : -1}/>
          
          <div id="controles">
            <Button value='Cancel' accent='transparent' style={{borderBottom: '1px solid #e53333', borderRadius: '0px', color: '#e53333'}} onClick={props.onClose} />
            <Button value='Add' accent='transparent' style={{borderBottom: '1px solid #00A3FF', borderRadius: '0px', color: '#00A3FF'}}  onClick={() => addDomain()} />
          </div>
          
          <p id="msg" ref={domainMsgRef}></p>

        </div>
      </div>
  </div>
}

export default Domains;
export {AddDomain as AddNewDomainModal};