import React from 'react';
import "./index.scss";

export default function ImageModal(props) {
    return (
        <div className='component__imageModal'>
            <div className='component__imageModal__box'>
                <img className='component__imageModal__box__close' src='images/close.png' alt='' onClick={props.onClose} />
                <img className='component__imageModal__box__img' src={props?.src} alt='' />
            </div>
        </div>
    )
}
