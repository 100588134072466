import "./index.scss";
import * as backendModule from "../../modules/backendModule";
import { useSelector } from "react-redux";

import axios from "axios";
import React from "react";

import { animateBox } from "../../modules/componentAnimation";

import { AddNewModal } from "../../routes/EmailServers";
import { AddNewPackageModal } from "../../routes/Packages";
import { AddNewGiftModal } from "../../routes/Gifts";
import { AddNewGiftCardModal } from "../../routes/GiftCards";
import { AddNewBoostModal } from "../../routes/Boosts";
import { AddNewStickerModal } from "../../routes/Stickers";
import { AddNewStickerCategoryModal } from "../../routes/StickerCategories";
import { AddNewBadgeModal } from "../../routes/Badges";
import { AddNewBitchModal } from "../../routes/BuildABitch";
import { AddNewDomainModal } from "../../routes/Domains";
import { AddNewBannerModal } from "../../routes/Banners";

const Header = (props) => {

  const timestampSelector = useSelector(state => state.timestamp);
  const [logged, setLogged] = React.useState(null);
  const [loggedUser, setLoggedUser] = React.useState(null);

  const checkLogin = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/auth/checkLogin`,
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        setLogged(true);
        setLoggedUser(res.data.data.UserInfo.Username);
      }
      else {
        setLogged(false);
      }
    }).catch(() => {
      setLogged(false);
    });
  };

  React.useEffect(() => {
    checkLogin();
  }, [timestampSelector]);

  const addNewUserModalHandler = () => {
    props.setNewUserModal(m => !m);
  }

  // const addNewDomainModalHandler = () => {
  //   props.setNewDomainModal(m => !m);
  // }

  const addNewLadieModalHandler = () => {
    props.setNewLadieModal(m => !m);
  }

  const addNewEmailServerModal = (e) => {
    animateBox(e, <AddNewModal setEmailServers={props.setEmailServers} activeTab={props.activeTab} />);
  }

  const addNewPackageModal = (e) => {
    animateBox(e, <AddNewPackageModal setPackages={props.setPackages} activeTab={props.activeTab} />)
  }

  const addNewGiftModal = (e) => {
    animateBox(e, <AddNewGiftModal setGifts={props.setGifts} activeTab={props.activeTab} />)
  }

  const addNewGiftCardModal = (e) => {
    animateBox(e, <AddNewGiftCardModal setGiftCards={props.setGiftCards} activeTab={props.activeTab} />)
  }

  const addNewBannerModal = (e) => {
    animateBox(e, <AddNewBannerModal />)
  }

  const addNewBoostModal = (e) => {
    animateBox(e, <AddNewBoostModal setBoosts={props.setBoosts} activeTab={props.activeTab} />)
  }

  const addNewStickerModal = (e) => {
    animateBox(e, <AddNewStickerModal setStickers={props.setStickers} setStickerCategories={props.setStickerCategories} activeTab={props.activeTab} />)
  }

  const addNewStickerCategoryModal = (e) => {
    animateBox(e, <AddNewStickerCategoryModal setStickerCategories={props.setStickerCategories} activeTab={props.activeTab} />)
  }

  const addNewBadgeModal = (e) => {
    animateBox(e, <AddNewBadgeModal setBadges={props.setBadges} activeTab={props.activeTab} />)
  }

  const addNewBitchModal = (e) => {
    animateBox(e, <AddNewBitchModal />)
  }

  const addNewDomainModal = (e) => {
    animateBox(e, <AddNewDomainModal />)
  }

  const navbarHandler = () => {
    props.setNavbar(m => !m);
  }

  return <div className="component__header">
    <div>
      <img id="hamburger" src='images/hamburger.svg' alt='' onClick={() => navbarHandler()} />
      <img src='images/logo.png' alt='' />
    </div>
    {logged && <div>
      {props.loggedUser?.UserInfo?.Flags?.isAdmin && <span id='addNew' style={{ display: props.activeTab === 'users' && logged ? 'flex' : 'none' }} 
        onClick={() => addNewUserModalHandler()}><p>Add new</p></span>}
      {props.loggedUser?.UserInfo?.Flags?.isAdmin && <span id='addNew' style={{ display: props.activeTab === 'domains' && logged ? 'flex' : 'none' }} 
        onClick={(e) => addNewDomainModal(e)}><p>Add new</p></span>}
      {props.loggedUser?.UserInfo?.Flags?.isAdmin && <span id='addNew' style={{ display: props.activeTab === 'ladies' && logged ? 'flex' : 'none' }} 
        onClick={() => addNewLadieModalHandler()}><p>Add new</p></span>}
      {props.loggedUser?.UserInfo?.Flags?.isAdmin && <span id='addNew' style={{ display: props.activeTab === 'emailservers' && logged ? 'flex' : 'none' }} 
        onClick={(e) => addNewEmailServerModal(e)}><p>Add new</p></span>}
      {props.loggedUser?.UserInfo?.Flags?.isAdmin && <span id='addNew' style={{ display: props.activeTab === 'packages' && logged ? 'flex' : 'none' }} 
        onClick={(e) => addNewPackageModal(e)}><p>Add new</p></span>}
      {props.loggedUser?.UserInfo?.Flags?.isAdmin && <span id='addNew' style={{ display: props.activeTab === 'gifts' && logged ? 'flex' : 'none' }} 
        onClick={(e) => addNewGiftModal(e)}><p>Add new</p></span>}
      {props.loggedUser?.UserInfo?.Flags?.isAdmin && <span id='addNew' style={{ display: props.activeTab === 'giftcards' && logged ? 'flex' : 'none' }} 
        onClick={(e) => addNewGiftCardModal(e)}><p>Add new</p></span>}
      {props.loggedUser?.UserInfo?.Flags?.isAdmin && <span id='addNew' style={{ display: props.activeTab === 'banners' && logged ? 'flex' : 'none' }} 
        onClick={(e) => addNewBannerModal(e)}><p>Add new</p></span>}
      {props.loggedUser?.UserInfo?.Flags?.isAdmin && <span id='addNew' style={{ display: props.activeTab === 'boosts' && logged ? 'flex' : 'none' }} 
        onClick={(e) => addNewBoostModal(e)}><p>Add new</p></span>}
      {props.loggedUser?.UserInfo?.Flags?.isAdmin && <span id='addNew' style={{ display: props.activeTab === 'stickers' && logged ? 'flex' : 'none' }} 
        onClick={(e) => addNewStickerModal(e)}><p>Add new</p></span>}
      {props.loggedUser?.UserInfo?.Flags?.isAdmin && <span id='addNew' style={{ display: props.activeTab === 'stickerCategories' && logged ? 'flex' : 'none' }} 
        onClick={(e) => addNewStickerCategoryModal(e)}><p>Add new</p></span>}
      {props.loggedUser?.UserInfo?.Flags?.isAdmin && <span id='addNew' style={{ display: props.activeTab === 'badges' && logged ? 'flex' : 'none' }} 
        onClick={(e) => addNewBadgeModal(e)}><p>Add new</p></span>}
      {props.loggedUser?.UserInfo?.Flags?.isAdmin && <span id='addNew' style={{ display: props.activeTab === 'buildABitch' && logged ? 'flex' : 'none' }} 
        onClick={(e) => addNewBitchModal(e)}><p>Add new</p></span>}
    </div>}
  </div>
};

export default Header;