import React from 'react';
import "./index.scss";

import CustomInput from "../../components/customComponents/CustomInput";
import Button from "../../components/customComponents/Button";
import Spinner from '../../components/customComponents/Spinner';
import Dropdown from '../../components/customComponents/Dropdown';
import { FilteredCustomTable } from "../../components/customComponents/Table";
import DataSorter from "../../components/customComponents/DataSorter";

import * as backendModule from "../../modules/backendModule";
import axios from "axios";
import { animateBox } from "../../modules/componentAnimation";

import moment from "moment";

export default function Users(props) {
    const [flags, setFlags] = React.useState([]);
    const getAllFlags = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/getAllFlags`,
            ...backendModule.axiosConfig
        }).then(res => {
            setFlags(res.data);
        }).catch(() => {

        });
    }

    React.useEffect(() => {
        getAllFlags();
    }, []);

    const [users, setUsers] = React.useState([]);
    const [filters, setFilters] = React.useState([]);
    const [orders, setOrders] = React.useState([]);

    const getAllUsers = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/getAllUsersFrontend`,
            data: {
                filters: filters,
                orders: orders
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                setUsers(res.data);
            }
            else {
                setUsers('GET_ALL_USERS_ERROR');
            }
        }).catch(() => {
            setUsers('GET_ALL_USERS_ERROR');
        });
    }

    React.useEffect(() => {
        getAllUsers();
    }, [filters, orders]);

    const firstNameRef = React.useRef(null);
    const lastNameRef = React.useRef(null);
    const usernameRef = React.useRef(null);
    const emailRef = React.useRef(null);
    const passwordRef = React.useRef(null);
    const adminCheckboxRef = React.useRef(null);
    const agentCheckboxRef = React.useRef(null);
    const bannedCheckboxRef = React.useRef(null);
    const streamCheckboxRef = React.useRef(null);
    const msgRef = React.useRef(null);

    const addUser = () => {

        if (
            !firstNameRef.current.value ||
            !lastNameRef.current.value ||
            !usernameRef.current.value ||
            !passwordRef.current.value ||
            !emailRef.current.value
        ) {
            return msgRef.current.innerText = 'Info missing!';
        }
        if (!adminCheckboxRef.current.checked && !agentCheckboxRef.current.checked && !bannedCheckboxRef.current.checked && !streamCheckboxRef.current.checked) {
            return msgRef.current.innerText = 'Select role please';
        }

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/createUser`,
            data: {
                firstName: firstNameRef.current.value,
                lastName: lastNameRef.current.value,
                username: usernameRef.current.value,
                password: passwordRef.current.value,
                email: emailRef.current.value,
                flags: {
                    isMessageAgent: agentCheckboxRef.current.checked ? true : false,
                    isAdmin: adminCheckboxRef.current.checked ? true : false,
                    isBanned: bannedCheckboxRef.current.checked ? true : false,
                    isStreamer: streamCheckboxRef.current.checked ? true : false
                }
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if(res.data.status === 'ok'){
                getAllUsers();
                props.setNewUserModal(false);
            }
            else{
                msgRef.current.innerText = 'Info missing!';
            }
        }).catch(() => {
            msgRef.current.innerText = 'Something went wrong'
        });
    }

    const deleteUser = (id) => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/deleteUser`,
            data: {
                id: id
            },
            ...backendModule.axiosConfig
        }).then(res => {
            getAllUsers();
        }).catch(() => {

        });
    }

    const addUsersToTable = () => {
        if (users.data) {
            return users?.data?.map((item) => {
                return [
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.ID}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.FirstName} {item.LastName}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Username}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Email}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center', background: item.Flags.isAdmin ? 'green' : item.Flags.isBanned ? 'red' : '#3F7CEA', width: 'fit-content', marginLeft: 'auto', marginRight: 'auto', padding: '5px', borderRadius: '10px' }}>{item.Flags.isAdmin ? 'ADMIN' : item.Flags.isMessageAgent ? 'AGENT' : item.Flags.isBanned ? 'BANNED' : 'STREAMER'}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{moment(item.updatedAt).format('DD.MM.YYYY.')}</p></div> },

                    {
                        keyID: String(item.ID), type: "groupNewline", group: [
                            {
                                keyID: String(item.ID), type: "button", text: "Delete", triggerDropdown: true, triggerData: e => {
                                    return (<div style={{ display: 'flex', flexDirection: 'column', justfyContent: 'center', alignItems: 'center' }}>
                                        <p style={{ color: 'white' }}>{props.loggedUser.Username === item.Username ? 'You cant delete yourself' : 'Are you sure?'}</p>
                                        <br></br>
                                        <div style={{ display: props.loggedUser.Username === item.Username ? 'none' : 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Button style={{ marginRight: '10px' }} accent='rgb(72, 82, 92)' value='YES' onClick={() => deleteUser(item.ID)} />
                                            <Button accent='rgb(72, 82, 92)' value='NO' onClick={c => { e() }} />
                                        </div>
                                    </div>);
                                },
                            },
                            {
                                keyID: String(item.ID), type: "button", text: "Edit", triggerDropdown: true, onClick: e => {
                                    animateBox(e, <EditUser userForEdit={
                                        {
                                            ID: item.ID,
                                            FirstName: item.FirstName,
                                            LastName: item.LastName,
                                            Username: item.Username,
                                            Email: item.Email,
                                            isAdmin: item.Flags.isAdmin,
                                            isMessageAgent: item.Flags.isMessageAgent,
                                            isBanned: item.Flags.isBanned,
                                            isStreamer: item.Flags.isStreamer
                                        }
                                    } />);
                                }
                            },
                            item.Flags.isStreamer ? {
                                keyID: String(item.ID), type: "button", text: "See streamer details", triggerDropdown: true, onClick: e => {
                                    animateBox(e, <Streamer data={item} />);
                                }
                            } : {}

                        ]
                    }
                ];
            });
        }
    }

    const EditUser = (props) => {

        const [editUserModal, setEditUserModal] = React.useState(false);
        const editAdminCheckboxRef = React.useRef(null);
        const editAgentCheckboxRef = React.useRef(null);
        const editBannedCheckboxRef = React.useRef(null);
        const editStreamerCheckboxRef = React.useRef(null);
        const newFirstNameRef = React.useRef(null);
        const newLastNameRef = React.useRef(null);
        const newEmailRef = React.useRef(null);
        const editMsgRef = React.useRef(null);

        React.useEffect(() => {
            if (props.userForEdit.isAdmin) {
                editAdminCheckboxRef.current.checked = true;
            }
            if (props.userForEdit.isMessageAgent) {
                editAgentCheckboxRef.current.checked = true;
            }
            if (props.userForEdit.isBanned) {
                editBannedCheckboxRef.current.checked = true;
            }
            if (props.userForEdit.isStreamer) {
                editStreamerCheckboxRef.current.checked = true;
            }
        }, []);

        const saveUser = (id, close) => {
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/users/editUser`,
                data: {
                    id: id,
                    firstName: newFirstNameRef.current.value,
                    lastName: newLastNameRef.current.value,
                    newEmail: newEmailRef.current.value,
                    newFlags: {
                        isAdmin: editAdminCheckboxRef.current.checked ? true : false,
                        isMessageAgent: editAgentCheckboxRef.current.checked ? true : false,
                        isBanned: editBannedCheckboxRef.current.checked ? true : false,
                        isStreamer: editStreamerCheckboxRef.current.checked ? true : false
                    }
                },
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === 'ok') {
                    getAllUsers();
                    close();
                }
                else {
                    editMsgRef.current.innerText = 'Something went wrong!';
                }
            }).catch(() => {
                editMsgRef.current.innerText = 'Something went wrong!';
            });
        }

        return <div className='route__users__editUserModal'>
            <div id="box">
                <h1>Edit user {props.userForEdit.Username}</h1>
                <CustomInput ref={newFirstNameRef} defaultValue={props.userForEdit.FirstName} autocomplete="off" placeholder="First name" accent="#FFF" theme="dark" type="text" style={{ width: "100%", marginBottom: '25px' }} />
                <CustomInput ref={newLastNameRef} defaultValue={props.userForEdit.LastName} autocomplete="off" placeholder="Last name" accent="#FFF" theme="dark" type="text" style={{ width: "100%", marginBottom: '25px' }} />
                <CustomInput ref={newEmailRef} defaultValue={props.userForEdit.Email} autocomplete="off" placeholder="Email" accent="#FFF" theme="dark" type="text" style={{ width: "100%", marginBottom: '25px' }} />
                <div id="roles">
                    <span>
                        <input type='checkbox' ref={editAdminCheckboxRef} />
                        <p>Admin</p>
                    </span>
                    <span>
                        <input type='checkbox' ref={editAgentCheckboxRef} />
                        <p>Agent</p>
                    </span>
                    <span>
                        <input type='checkbox' ref={editBannedCheckboxRef} />
                        <p>Banned</p>
                    </span>
                    <span>
                        <input type='checkbox' ref={editStreamerCheckboxRef} />
                        <p>Streamer</p>
                    </span>
                </div>
                <div id="controles">
                    <Button value='Cancel' accent='transparent' style={{ borderBottom: '1px solid #e53333', borderRadius: '0px', color: '#e53333' }} onClick={props.onClose} />
                    <Button value='Save' accent='transparent' style={{ borderBottom: '1px solid #00A3FF', borderRadius: '0px', color: '#00A3FF' }} onClick={() => saveUser(props.userForEdit.ID, props.onClose)} />
                </div>
                <p id="msg" ref={editMsgRef}></p>
            </div>
        </div>
    }

    return (
        <div className="route__users">
            <div style={{ display: props.newUserModal ? '' : 'none' }} className='route__users__addNewUserModal'>
                <div id="box">
                    <h1>Add new user</h1>
                    <CustomInput autocomplete="off" ref={firstNameRef} placeholder="First name" accent="#FFF" theme="dark" type="text" style={{ width: "100%", marginBottom: '25px' }} />
                    <CustomInput autocomplete="off" ref={lastNameRef} placeholder="Last name" accent="#FFF" theme="dark" type="text" style={{ width: "100%", marginBottom: '25px' }} />
                    <CustomInput autocomplete="off" ref={usernameRef} placeholder="Username" accent="#FFF" theme="dark" type="text" style={{ width: "100%", marginBottom: '25px' }} />
                    <CustomInput autocomplete="off" ref={emailRef} placeholder="Email" accent="#FFF" theme="dark" type="text" style={{ width: "100%", marginBottom: '25px' }} />
                    <CustomInput autocomplete="off" ref={passwordRef} placeholder="Password" accent="#FFF" theme="dark" type="password" style={{ width: "100%", marginBottom: '25px' }} />
                    <div id="roles">
                        {
                            flags?.map((item) => {
                                return <span><input ref={item.name === 'isAdmin' ? adminCheckboxRef : item.name === 'isBanned' ? bannedCheckboxRef : item.name === "isMessageAgent" ? agentCheckboxRef : streamCheckboxRef} type='checkbox' /><p>{item.description}</p></span>
                            })
                        }
                    </div>
                    <div id="controles">
                        <Button value='Cancel' accent='transparent' style={{ borderBottom: '1px solid #e53333', borderRadius: '0px', color: '#e53333' }} onClick={() => props.setNewUserModal(false)} />
                        <Button value='Add' accent='transparent' style={{ borderBottom: '1px solid #00A3FF', borderRadius: '0px', color: '#00A3FF' }} onClick={() => addUser()} />
                    </div>
                    <p id="msg" ref={msgRef}></p>
                </div>
            </div>
            <DataSorter dropdownBackground='#000' dropdownStyle={{backgroundColor: '#000'}} sorters={[
                {name: 'First Name', value: 'FirstName'},
                {name: 'Last Name', value: 'LastName'},
                {name: 'Username', value: 'Username'},
                {name: 'Email', value: 'Email'},
                {name: 'Last Login', value: 'LastLogin'},
            ]} setOrders={setOrders} />
            <FilteredCustomTable
                accent='#000'
                dropdownBackground='#000'
                placeholderStyle={{backgroundColor: '#000'}}
                inputBottomBorderColor='#FFF'
                theme='dark'
                filterHeadStyle={{backgroundColor: 'rgba(25, 35, 67, 0.2)'}}
                tableHeadStyle={{backgroundColor: 'rgba(25, 35, 67, 0.2)'}}
                buttonStyle={{border: '1px solid #FFF', backgroundColor: '#000'}}
                id='table'
                style={{colors: '#000'}}
                color={'#000'}
                headers={['ID', 'Name', 'Username', 'Email', 'Role', 'Updated at']}
                filters={[
                    { name: "FirstName", friendlyName: "Name", type: "string" },
                    { name: "Username", friendlyName: "Username", type: "string" },
                    { name: "Email", friendlyName: "Email", type: "string" },
                    { name: "updatedAt", friendlyName: "Date", type: "date" },
                ]}
                filterCB={f => setFilters(f)}
                data={users.data?.length > 0 ? addUsersToTable() : [[{ keyID: 'noData', type: 'custom', data: <p>No data to display</p> }]]}
            />
        </div>
    )
}


const Streamer = (props) => {
    const [data, setData] = React.useState();
    const [domains, setDomains] = React.useState();
    const [ladies, setLadies] = React.useState();
    const [changeTime, setChangeTime] = React.useState(false)
    const [spinner, setSpinner] = React.useState(false);

    const [curDomain, setCurDomain] = React.useState();
    const [curLady, setCurLady] = React.useState();

    const timeRef = React.useRef();
    const costRef = React.useRef();

    const getStreamerData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/streamers/getStreamInfoForUser`,
            data: {
                UserID: props.data.ID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setData(res.data);
            if (res.data.status === "ok") {
                setCurDomain(res.data.data.DomainID);
                setCurLady(res.data.data.LadyID);
            };
        }).catch(() => null);
    };
    const getDomains = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/domains/getAllDomains`,
            data: {
                limit: null,
                offset: 0,
                filters: [
                    {name: "forOffer", op: "eq", value: true}
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setDomains(res.data);
        }).catch(() => {
            setDomains({status: "error", data: "SERVER_ERROR"});
        });
    };
    const getLadies = (DomainID) => {
        if (!DomainID) return;
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/ladies/getAllLadies2`,
            data: {
                limit: null,
                offset: 0,
                filters: [
                    {name: "DomainID", op: "eq", value: DomainID}
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setLadies(res.data);
        }).catch(() => {
            setLadies({status: "error", data: "SERVER_ERROR"});
        });
    };
    const generateStreamingKey = () => {
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/streamers/createStreamKey`,
            data: {
                UserID: props.data.ID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            getStreamerData()
        }).catch(() => {

        }).finally(() => {
            setSpinner(false);
        });
    }

    const regenerateStreamingKey = () => {
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/streamers/regenStreamKey`,
            data: {
                UserID: props.data.ID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            getStreamerData()
        }).catch(() => {

        }).finally(() => {
            setSpinner(false);
        });
    }

    const setDomain = (domain) => {
        if (domain === curDomain) return;

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/streamers/updateStreamDomain`,
            data: {
                UserID: data?.data?.UserID,
                DomainID: domain
            },
            ...backendModule.axiosConfig
        }).then(() => {
            getStreamerData();
        }).catch(() => null).finally(() => {
            setSpinner(false);
        });
    };
    
    const setLady = (lady) => {
        if (lady === curLady) return;

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/streamers/updateStreamLady`,
            data: {
                UserID: data?.data?.UserID,
                LadyID: lady
            },
            ...backendModule.axiosConfig
        }).then(() => {
            getStreamerData();
        }).catch(() => null).finally(() => {
            setSpinner(false);
        });
    };

    React.useEffect(() => {
        getLadies(curDomain);
    }, [curDomain]);

    React.useEffect(() => {
        getStreamerData();
        getDomains();
    }, [])
    {(!data || !domains) && <Spinner color="white" />}
    {(domains?.status === "error" || ladies?.status === "error") && <div className='StreamerButton'>
        <div className='StreamerButton__wrap'>
            <p>Error while fetching {domains?.status === "error" ? "domains" : "ladies"}</p>
            <button style={{backgroundColor: "#844545"}} onClick={props.onClose}>Close</button>
        </div>
    </div>}
    if (data?.data === "NOT_FOUND") return <div className='StreamerButton'>
        <div className='StreamerButton__wrap'>
            <div className='StreamerButton__wrap__spinner' style={{
                opacity: spinner ? 1 : 0,
                pointerEvents: spinner ? "all" : "none"
            }} onClick={(e) => {
                if (spinner) e.stopPropagation();
            }}>
                <Spinner color="white" />
            </div>
            <p>No streamer data found</p>
            <p>A new stream key needs to be generated first!</p>
            <button onClick={generateStreamingKey}>Generate streaming key</button>
            <button style={{backgroundColor: "#844545"}} onClick={props.onClose}>Close</button>
        </div>
    </div>
    if (data?.status === 'ok') return <div className='StreamerData'>
        <div>
            <div className='StreamerButton__wrap__spinner' style={{
                    opacity: spinner ? 1 : 0,
                    pointerEvents: spinner ? "all" : "none"
                }} onClick={(e) => {
                    if (spinner) e.stopPropagation();
                }}>
                <Spinner color="white" />
            </div>

            <div className='StreamerData__dropdown'>
                <p>Domain</p>
                <Dropdown theme="dark" data={domains?.data?.map(domain => {
                    return {name: `${domain.WebsiteName} (${domain.Domain})`, value: domain.ID}
                })} selected={domains?.data?.indexOf(domains?.data?.find(d => d.ID === curDomain))} inlinePlaceholder="Select a domain" onChange={e => setDomain(e?.value)} />
            </div>

            <div className='StreamerData__dropdown'>
                <p>Lady</p>
                <Dropdown theme="dark" data={ladies?.data?.map(lady => {
                    return {name: `${lady.FirstName} ${lady.LastName}`, value: lady.ID}
                })} selected={ladies?.data?.indexOf(ladies?.data?.find(d => d.ID === curLady))} inlinePlaceholder="Select a lady" onChange={e => setLady(e?.value)} />
            </div>

            <p>Stream key: {data.data.StreamKey}  <button onClick={regenerateStreamingKey}>Regenerate streaming key</button></p>
            <p>Stream active: {data.data.StreamStarted ? "Yes" : "No"}</p>
            <p>Stream cost: {changeTime ? <input ref={costRef} defaultValue={data.data.CreditCost} type="number" /> : data.data.CreditCost}</p>
            <p>Next stream schedule: {changeTime ? <input ref={timeRef} type='datetime-local' defaultValue={data.data.NextStreamScheduled ? moment(data.data.NextStreamScheduled).format("YYYY-MM-DDThh:mm") : null} /> : moment(data.data.NextStreamScheduled).format('DD/MM/YYYY hh:mm')} <button onClick={() => {
                if (changeTime === true) {
                    let finalCost = null;
                    if (costRef.current) {
                        finalCost = Number(costRef.current.value);
                    } else {
                        finalCost = null;
                    };
                    setSpinner(true);
                    axios({
                        method: "POST",
                        url: `${backendModule.backendURL}/streamers/updateStreamSchedule`,
                        data: {
                            UserID: props.data.ID,
                            NextStreamScheduled: moment(timeRef.current.value).toDate().getTime()
                        },
                        ...backendModule.axiosConfig
                    }).then(async res => {
                        if (finalCost) {
                            if (!isNaN(finalCost)) {
                                if (finalCost > 0) {
                                    await axios({
                                        method: "POST",
                                        url: `${backendModule.backendURL}/streamers/updateStreamCost`,
                                        data: {
                                            UserID: props.data.ID,
                                            Cost: finalCost
                                        },
                                        ...backendModule.axiosConfig
                                    }).then(() => null).catch(() => null);
                                };
                            };
                        };
                        getStreamerData()
                    }).catch(() => null).finally(() => {
                        setSpinner(false);
                    });
                }
                setChangeTime(!changeTime)
            }
            }> {!changeTime ? 'Edit' : "Save"}</button>
            {changeTime && <button onClick={() => setChangeTime(t => !t)}>Cancel</button>}
            </p>

            <div id="controles" style={{display: "flex", justifyContent: "space-between"}}>
                <Button value='Close' accent='transparent' style={{ borderBottom: '1px solid #e53333', borderRadius: '0px', color: '#e53333' }} onClick={props.onClose} />
                <Button value='Refresh' accent='transparent' style={{ borderBottom: '1px solid #00A3FF', borderRadius: '0px', color: '#00A3FF' }} onClick={() => getStreamerData()} />
            </div>
        </div>

    </div>
}