import React from "react";
import "./index.scss";
import PropTypes from "prop-types";
import Dropdown from "../Dropdown";
import Spinner from '../Spinner';

export default function DataSorter(props) {

  const [spinner, setSpinner] = React.useState(false);
  const [sortingItem, setSortingItem] = React.useState();
  const [sortingValue, setSortingValue] = React.useState();

  const setValues = () => {
    if(spinner) return;

    setSpinner(true);
    
    props.setOrders([{name: sortingItem, order: sortingValue}])

    setTimeout(() => {
      setSpinner(false);
    }, 1000);
  }

  return <div className="dataSorter">
    <div className="dataSorter__item">
      <Dropdown accent={'dark'} dropdownBackground={props.dropdownBackground ? props.dropdownBackground : '#000'} selected={0} data={props.sorters} style={{border: '1px solid #2E4C6E'}} 
      dropdownStyle={{backgroundColor: '#000', color: '#FFF', borderRadius:'5px'}}
      inputStyle={{backgroundColor: '#080B16', borderBottom: '1px solid #2E4C6E', color: '#FFF'}}
      onChange={e => setSortingItem(e.value)} changeColor={'#000'}/>
    </div>
    <div className="dataSorter__item">
      <Dropdown accent={'dark'} selected={0} dropdownBackground={props.dropdownBackground ? props.dropdownBackground : '#000'} style={{border: '1px solid #2E4C6E'}} 
      dropdownStyle={{backgroundColor: '#000', color: '#FFF', borderRadius:'5px'}}
      inputStyle={{backgroundColor: '#080B16', borderBottom: '1px solid #2E4C6E', color: '#FFF'}}
      data={[{name: 'Descending', value:'desc'}, {name: 'Ascending', value: 'asc'}]} 
      onChange={e => setSortingValue(e.value)} changeColor={'#000'}/>
    </div>
    <div className="dataSorter__btn" onClick={() => setValues()}>
      <p style={{ display: spinner ? 'none' : 'block'}}>Sort</p>
      <div style={{ display: spinner ? 'flex' : 'none', flexDirection: 'row', justifyContent: 'flex-end' }}>
        <Spinner color='white' align='center' style={{width:'32px'}} />
      </div>
    </div>
  </div>
}