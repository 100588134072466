import React from 'react';
import "./index.scss";

import * as backendModule from "../../modules/backendModule";
import axios from "axios";

import CustomInput from "../../components/customComponents/CustomInput";
import Button from "../../components/customComponents/Button";
import Spinner from '../../components/customComponents/Spinner';

import { useDispatch } from 'react-redux';
import * as timestampActions from "../../actions/timestampAction";

import { useNavigate } from "react-router-dom";
import animateModule from "../../modules/animateModule";

export default function Login() {
    const curNavigate = useNavigate();
    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".component__contentWrapper"));

    const curDispatch = useDispatch();
    const [spinner, setSpinner] = React.useState(false);
    const usernameRef = React.useRef(null);
    const passwordRef = React.useRef(null);
    const msgRef = React.useRef(null);

    const handleKeyDown = event => {
        if (event.key === 'Enter') {
            loginHandler();
        }
    };

    const loginHandler = () => {
        let data = {
            username: usernameRef.current.value,
            password: passwordRef.current.value
        };
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/auth/login`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                curDispatch(timestampActions.setTimestamp());
                animateNavigate('/');
            }
            else {
                msgRef.current.innerText = 'Incorrect username or password';
            }
        }).catch(() => {
            msgRef.current.value = "Server is not responding"
        }).finally(() => {
            setSpinner(false);
        });
    }

    return (
        <div className='route__login'>
            <div className='route__login__form' onKeyDown={handleKeyDown}>
                <p>Welcome to Scale-Date</p>
                <CustomInput ref={usernameRef} autocomplete="off" placeholder="Username" accent="#FFF" theme="dark" type="text" style={{ width: "100%", marginBottom: "24px" }} />
                <CustomInput ref={passwordRef} autocomplete="off" placeholder="Password" accent="#FFF" theme="dark" type="password" style={{ width: "100%" }} />
                <Spinner style={{ display: spinner ? 'block' : 'none' }} color='white' />
                <Button style={{ display: !spinner ? 'block' : 'none' }} value='Login' accent='#3F7CEA' className='loginBtn' onClick={() => loginHandler()} />
                <p id='msg' ref={msgRef}></p>
            </div>
        </div>
    )
}
