import React from 'react';
import "./index.scss";

import { FilteredCustomTable } from "../../components/customComponents/Table";
import Button from '../../components/customComponents/Button';
import Spinner from '../../components/customComponents/Spinner';
import DataSorter from "../../components/customComponents/DataSorter";

import * as backendModule from "../../modules/backendModule";
import axios, { all } from "axios";
import moment from 'moment';
import { animateBox } from '../../modules/componentAnimation';
import CustomInput from '../../components/customComponents/CustomInput';

export default function SecondaryUsers(props) {
  const [spinner, setSpinner] = React.useState(false);
  const [filters, setFilters] = React.useState([]);
  const [orders, setOrders] = React.useState([]);
  const [secondaryUsers, setSecondaryUsers] = React.useState([]);
  const [canPaginate, setCanPaginate] = React.useState(false);

  const getAllSecondaryUsers = () => {
    setCanPaginate(false);
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/users/getAllSecondaryUsers`,
      data: {
        offset: 0,
        filters: filters,
        orders: orders,
        messageStats: false
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === 'ok') {
        setSecondaryUsers(res.data);
        if (res.data.data.length === 20) {
          setTimeout(() => setCanPaginate(true), 1000);
        }
      }
      else {

      }
    }).catch(() => {

    });
  }

  const viewUserProfile = (item) => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/domains/getDomainByID`,
      data: {
          ID: item.DomainID
      },
      ...backendModule.axiosConfig
    }).then(res => {
        if (res.data.status === 'ok') {
          window.open(`https://${res.data.data.Domain}/#/viewUser/${item.ID}`)
          // window.open(`http://${res.data.data.Domain}:3000/#/viewUser/${item.ID}`)
        }
        else {

        }
    }).catch(() => {

    });
  }

  const removeUser = (id) => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/users/deleteSecondaryUserAsAdmin`,
      data: {
        id: id
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if(res.data.status === 'ok'){
        getAllSecondaryUsers();
      }
    }).catch(() => {

    });
  }

  React.useEffect(() => {
    getAllSecondaryUsers();
  }, [filters, orders]);

  const scrollTriggerRef = React.useRef(null);
  const [loadingData, setLoadingData] = React.useState(false);

  const paginationData = (isInViewPort) => {
    if (isInViewPort && canPaginate) {
      axios({
        method: "POST",
        url: `${backendModule.backendURL}/users/getAllSecondaryUsers`,
        data: {
          offset: secondaryUsers.data.length,
          filters: filters,
          messageStats: false
        },
        ...backendModule.axiosConfig
      }).then(res => {
        if (res.data.status === "ok") {
          if (res.data.data.length > 0) {
            setLoadingData(true);
            setTimeout(() => setLoadingData(false), 100);
            res.data.data?.map((item) => {
              secondaryUsers.data.push(item);
            });
            setCanPaginate(false);
            setTimeout(() => {
              setCanPaginate(true);
            }, 1000);
          }
        };
      }).catch(() => {
        setSecondaryUsers({ status: 'error', data: 'Data fetch failed' });
      }).finally(() => {
        setSpinner(false);
      });
    }
  }

  const useIsInViewport = () => {
    const [isIntersecting, setIsIntersecting] = React.useState(false);

    const observer = React.useMemo(
      () =>
        new IntersectionObserver(([entry]) =>
          setIsIntersecting(entry.isIntersecting),
        ),
      [],
    );

    React.useEffect(() => {
      observer.observe(scrollTriggerRef.current);
      return () => {
        observer.disconnect();
      };
    }, [scrollTriggerRef, observer]);

    if (isIntersecting) {
      paginationData(isIntersecting);
    }
  }

  useIsInViewport();

  const addSecondaryUsersToTable = () => {
    if (secondaryUsers.data) {
      return secondaryUsers?.data?.map((item) => {
        return [
          { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.ID}</p></div> },
          { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Name}</p></div> },
          { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Username}</p></div> },
          { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Email}</p></div> },
          { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center', background: item.Flags.isVIP ? 'green' : 'red', width: 'fit-content', marginLeft: 'auto', marginRight: 'auto', padding: '5px', borderRadius: '10px' }}>{item.Flags.isVIP ? 'YES' : 'NO'}</p></div> },
          { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center', background: item.Flags.isEmailVerified ? 'green' : 'red', width: 'fit-content', marginLeft: 'auto', marginRight: 'auto', padding: '5px', borderRadius: '10px' }}>{item.Flags.isEmailVerified ? 'YES' : 'NO'}</p></div> },
          {
            keyID: String(item.ID), type: "groupNewline", group: [
              { keyID: String(item.ID), type: "custom", data: <div id='table-country'><p>Country:</p> <strong>{item.Country !== '' ? item.Country : '-ND-'}</strong> <p>Credits:</p> <strong>{item.Credits}</strong> <p>Streamer:</p> <strong>{item.Username.startsWith("--internal-") ? "Not eligible" : (item.Flags.isStreamer ? "Yes" : "No")}</strong><p>Banned:</p><strong>{item.Flags.isBanned ? 'YES' : 'NO'}</strong><p>Date created</p><strong>{(new Date(item.createdAt).toLocaleString())}</strong></div> },
            ]
          },
          {
            keyID: String(item.ID), type: "groupNewline", group: [
              {
                keyID: String(item.ID), type: "button", text: item.Flags.isBanned === true ? 'Unban' : 'Ban', triggerDropdown: true, onClick: e => {
                  axios({
                    method: "POST",
                    url: `${backendModule.backendURL}/users/setSecondaryUserFlags`,
                    data: {
                      ID: item.ID,
                      flags: {
                        isBanned: item.Flags.isBanned ? false : true,
                      }
                    },
                    ...backendModule.axiosConfig
                  }).then(res => {
                    if (res.data.status === 'ok') {
                      getAllSecondaryUsers();
                    }
                    else {

                    }
                  }).catch(() => {

                  });
                }
              },
              {
                keyID: String(item.ID), type: "button", text: "More details", triggerDropdown: true, onClick: e => {
                  animateBox(e, <MoreDetailsModal curUser={
                    {
                      ID: item.ID,
                      DomainID: item.DomainID,
                      Bio: item.Bio,
                      Birthday: item.Birthday,
                      Country: item.Country,
                      Credits: item.Credits,
                      Email: item.Email,
                      Flags: item.Flags,
                      Gender: item.Gender,
                      Image: item.Image,
                      InterestedInGender: item.InterestedInGender,
                      LastLogin: item.LastLogin,
                      LookingFor: item.LookingFor,
                      Name: item.Name,
                      PublisherID: item.PublisherID,
                      Username: item.Username,
                      ZIPCode: item.ZIPCode,
                      PhoneNumber: item.PhoneNumber,
                      Credits: item.Credits,
                      ProfilesVisited: item.ProfilesVisited,
                      City: item.City
                    }
                  } />)
                }
              },
              {
                keyID: String(item.ID),
                type: "button",
                text: "Add credits",
                onClick: e => animateBox(e, <AddCredits data={item} onChange={(nc) => {
                  setSecondaryUsers(su => {
                    return {
                      ...su,
                      data: su.data.map(sud => {
                        if (sud.ID === item.ID) sud.Credits = Number(sud.Credits) + nc;
                        return sud;
                      })
                    }
                  });
                }} />)
              },
              {
                keyID: String(item.ID),
                type: "button",
                text: "View Profile",
                onClick: e=> { viewUserProfile(item) }
              },
              {
                keyID: String(item.ID),
                type: "button",
                text: "Remove User",
                onClick: e=> { removeUser(item.ID) }
              }
            ].filter(t => t)
          }
        ].filter(t => t);
      });
    }
  }

  const MoreDetailsModal = (props) => {
    const [date, setDate] = React.useState([{ name: "createdAt", op: "deq", value: new Date().getTime() }]);
    const [totalData, setTotalData] = React.useState([]);
    const [spinner, setSpinner] = React.useState(true);
    const [domainName, setDomainName] = React.useState('');


    React.useEffect(() => {
      let tmpData = { sent: 0, received: 0 };
      axios({
        method: "POST",
        url: `${backendModule.backendURL}/users/getAllSecondaryUsers`,
        data: {
          filters: [
            { name: 'Username', op: 'eq', value: props.curUser.Username }
          ],
          limit: 1,
          messageStatsDate: date,
          messageStats: true
        },
        ...backendModule.axiosConfig
      }).then(res => {
        if (res.data.status === 'ok') {
          res.data.data.forEach(item => {
            tmpData.sent += item.messageStats.sentMessages;
            tmpData.received += item.messageStats.receivedMessages;
          })
          setTotalData(tmpData);
          setSpinner(false);
        }
      }).catch(() => {

      });
    }, [])

    axios({
      method: "POST",
      url: `${backendModule.backendURL}/domains/getDomainByID`,
      data: {
          ID: props.curUser.DomainID
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === 'ok') {
        setDomainName(res.data.data.Domain)
      }
    }).catch((err) => {
      console.log("ERROR::: ", err)
    });

    return <div className='route__secondaryUsers__moreDetailsModal'>
      <div id='box'>
        <span id='domain-id'><strong>DOMAIN ID:</strong> <p>{props.curUser.DomainID}</p></span>
        <span id='domain-id'><strong>DOMAIN NAME:</strong> <p>{domainName}</p></span>
        <div id='gridbox'>
          <img src={String(props.curUser.Username).startsWith('--internal') ? props.curUser.Image : `${backendModule.backendURL}/staticContent/images/${props.curUser.Image}`} alt='' />
          <div id='top-right'>
            <p><strong>Name:</strong> {props.curUser.Name}</p>
            <p><strong>Bio:</strong> {props.curUser.Bio}</p>
          </div>
          <div id='middle-left'>
            <p><strong>Country:</strong> {props.curUser.Country !== '' ? props.curUser.Country : '-ND-'}</p>
            <p><strong>Email:</strong> {props.curUser.Email}</p>
            <p><strong>Phone:</strong> {props.curUser.PhoneNumber}</p>
            <p><strong>VIP:</strong> {props.curUser.isVIP ? 'YES' : 'NO'}</p>
            <p><strong>Gender:</strong> {props.curUser.Gender === true ? 'Male' : props.curUser.Gender === false ? 'Female' : 'Other'}</p>
            <p><strong>Last login:</strong> {moment(props.curUser.LastLogin).format('DD.MM.YYYY.')}</p>
            <p><strong>Publisher ID:</strong> {props.curUser.PublisherID}</p>
            <p style={{ display: 'flex', gap: '5px' }}><strong>Sent:</strong><Spinner color='white' style={{ display: spinner ? 'block' : 'none', width: '18px', height: '18px' }} /> {totalData?.sent}</p>
            <p style={{ display: 'flex', gap: '5px' }}><strong>Recieved:</strong><Spinner color='white' style={{ display: spinner ? 'block' : 'none', width: '18px', height: '18px' }} /> {totalData?.received}</p>
            <p><strong>Sales:</strong> 0</p>
            <p><strong>Profiles visited:</strong> {props.curUser.ProfilesVisited}</p>
            <p><strong>Favourite package:</strong> null</p>
            <p><strong>Streams:</strong> 0</p>
          </div>
          <div id='middle-right'>
            <p><strong>Birthday:</strong> {moment(props.curUser.Birthday).format('DD.MM.YYYY.')}</p>
            <p><strong>First Purchase:</strong> {props.curUser.firstPurchaseDone ? 'YES' : 'NO'}</p>
            <p><strong>Banned:</strong> {props.curUser.isBanned ? 'YES' : 'NO'}</p>
            <p><strong>Email verified:</strong> {props.curUser.isEmailVerified ? 'YES' : 'NO'}</p>
            <p><strong>Username:</strong> {props.curUser.Username}</p>
            <p><strong>ZIP Code:</strong> {props.curUser.ZIPCode}</p>
            <p><strong>Interested in Gender:</strong> {props.curUser.InterestedInGender === true ? 'Male' : props.curUser.InterestedInGender === false ? 'Female' : 'Other'}</p>
            <p><strong>Credits:</strong> {props.curUser.Credits}</p>
            <span><strong>Looking for:</strong> {props.curUser.LookingFor.map((item) => {
              return <p> - {
                item === 1 ? 'Just Friends' :
                  item === 2 ? 'Casual Sex' :
                    item === 3 ? 'Affairs' :
                      item === 4 ? 'Sugar Daddies' :
                        item === 5 ? 'Cyber Sex' :
                          item === 6 ? 'Sex' :
                            item === 7 ? 'Relationships' :
                              item === 8 ? 'Group Sex' : ''
              }</p>
            })}</span>
            <p><strong>City:</strong> {props.curUser.City}</p>
          </div>
          <div id="controles">
            <Button value='Close' accent='transparent' style={{ borderBottom: '1px solid #e53333', borderRadius: '0px', color: '#e53333' }} onClick={() => props.onClose()} />
          </div>
        </div>
      </div>
    </div>
  }

  return (
    <div className='route__secondaryUsers'>
      <DataSorter sorters={[
        {name: 'Credits', value: 'Credits'},
        {name: 'First Name', value: 'FirstName'},
        {name: 'Last Name', value: 'LastName'},
        {name: 'Birthday', value: 'Birthday'},
        {name: 'Country', value: 'Country'},
        {name: 'Last Login', value: 'LastLogin'},
        {name: 'Level', value: 'Level'},
      ]} setOrders={setOrders} />
      <FilteredCustomTable
        id='table'
        accent='#000'
        dropdownBackground='#000'
        inputBottomBorderColor='#FFF'
        placeholderStyle={{backgroundColor: '#000'}}
        theme='dark'
        filterHeadStyle={{backgroundColor: 'rgba(25, 35, 67, 0.2)'}}
        tableHeadStyle={{backgroundColor: 'rgba(25, 35, 67, 0.2)'}}
        buttonStyle={{border: '1px solid #FFF', backgroundColor: '#000'}}
        // style={{colors: index % 2 === 0 ? '#1923438e' : '#192343'}}
        style={{colors: '#000'}}
        color={'#000'}
        headers={['ID', 'Name', 'Username', 'Email', 'VIP', 'Verified']}
        filters={[
          { name: "ID", friendlyName: "ID", type: "string" },
          { name: "FirstName", friendlyName: "First Name", type: "string" },
          { name: "LastName", friendlyName: "Last Name", type: "string" },
          { name: "Username", friendlyName: "Username", type: "string" },
          { name: "Email", friendlyName: "Email", type: "string" },
          { name: "Country", friendlyName: "Country", type: "string" },
          { name: "createdAt", friendlyName: "Created date", type: "date" },
          { name: "updatedAt", friendlyName: "Last update date", type: "date" },
          { name: "LastLogin", friendlyName: "Last login date", type: "date" }
        ]}
        filterCB={f => setFilters(f)}
        data={secondaryUsers.data?.length > 0 ? addSecondaryUsersToTable() : [[{ keyID: 'noData', type: 'custom', data: <p>No data to display</p> }]]}
      />
      <div style={{ display: canPaginate ? 'block' : 'none' }} ref={scrollTriggerRef}></div>
      <Spinner style={{ display: loadingData ? 'block' : 'none', width: "64px", height: "64px" }} color="white" align="center" />
    </div>
  )
}

const AddCredits = props => {
  const [spinner, setSpinner] = React.useState(false);
  const [infoP, setInfoP] = React.useState("");

  const creditsRef = React.useRef();

  const addCredits = () => {
    setInfoP();

    let curCredits = creditsRef.current.value;
    if (!curCredits) return setInfoP("Credits can't be empty");

    curCredits = Number(curCredits);
    if (isNaN(curCredits)) return setInfoP("Credits must be a number");

    setSpinner(true);
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/users/addCredits`,
      data: {
        ID: props.data.ID,
        Credits: curCredits
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        if (props.onChange) props.onChange(curCredits);
        return props.onClose();
      };
      return setInfoP("There was an error while adding credits");
    }).catch(() => {
      return setInfoP("Server timed out");
    });
  };

  return <div className='route__secondaryUsers__addCredits'>
    <div className='route__secondaryUsers__addCredits__wrap'>
      <div className='route__secondaryUsers__addCredits__wrap__spinner' style={{
        opacity: spinner ? 1 : 0,
        pointerEvents: spinner ? "all" : "none"
      }} onClick={e => spinner && e.stopPropagation()}>
        <Spinner color="white" />
      </div>

      <h3 style={{ marginBottom: "20px" }}>Add credits</h3>
      <CustomInput ref={creditsRef} theme="dark" type="text" placeholder="Credits" style={{ width: "100%" }} />

      <div id="controles" style={{ display: "flex", justifyContent: "space-between" }}>
        <Button value='Close' accent='transparent' style={{ borderBottom: '1px solid #e53333', borderRadius: '0px', color: '#e53333' }} onClick={props.onClose} />
        <Button value='Add' accent='transparent' style={{ borderBottom: '1px solid #00A3FF', borderRadius: '0px', color: '#00A3FF' }} onClick={() => addCredits()} />
      </div>

      {infoP && <p className='route__secondaryUsers__addCredits__wrap__infoP'>{infoP}</p>}
    </div>
  </div>
};