import React, { useImperativeHandle } from "react";
import "./index.scss";
import PropTypes from "prop-types";

import useDefer from "../../../modules/hooks/useDefer";

import CustomButtonSmall from "../Button";
import CustomInput from "../CustomInput";
import Dropdown from "../Dropdown";
import Spinner from "../Spinner";
import Progressbar from "../Progressbar";

import moment from "moment";

import Checkbox from "../Checkbox";

const RetentionRateTable = (props) => {

    const [hovered, setHovered] = React.useState(false);

    const returnGenericData = (length, data, total) => {
        
        const paragraphs = [];
        for (let index = 0; index < length; index++) {
            paragraphs.push(
            <div className="retention-rate-table__body__item__mainPara" 
            style={{backgroundColor: `rgba(25, 35, 67, ${Number(data[index + 1].retentionRate) === 0 ? 0 : Math.max(Math.min(Number(data[index + 1].retentionRate) / 100, 1), 0.2)})`}}>
            {data[index + 1].retentionRate}%
                <p className="retention-rate-table__body__item__mainPara--span" 
                    style={{maxHeight: hovered ? '200px' : '0px', marginTop: hovered ? '4px' : '0px'}}>
                    <span>Total: {total}</span> 
                    <span>Left: {data[index + 1].usersLeft}</span> 
                    <span>Stayed: {data[index + 1].usersStayed}</span>
                </p>
            </div>
            );
        }
        return paragraphs;
    }

    return <div className="retention-rate-table" onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
        <div className="retention-rate-table__header" style={{gridTemplateColumns: `repeat(${props.data.length + 2}, 120px)`}}>
            <p>Signup</p>
            <p>Users</p>
            {props.data.map((element, index) => {
              return <p className="retention-rate-table__header__mainPara">{index + 1}</p>
            })}
        </div>
        <div className="retention-rate-table__body">
            {props.data.map((elem, index) => {

                return <div className="retention-rate-table__body__item" style={{gridTemplateColumns: `repeat(${(props.data.length - index) + 2}, 120px)`}}> 
                    <p>{elem.startOfMonth ? moment(elem.startOfMonth).format('MMM') : moment(elem.start).format('DD/MMM/YYYY')}</p>
                    <p>{elem.totalUsers}</p>
                    {returnGenericData((props.data.length - index), elem, elem.totalUsers)}
                </div>
            })}
        </div>

    </div>
}



export { RetentionRateTable };

/*
FORMAT OF DATA FOR THE COMPONENT :

[
    {
        '1': { usersLeft: 0, usersStayed: 1, retentionRate: 100 },
        '2': { usersLeft: 0, usersStayed: 1, retentionRate: 100 },
        '3': { usersLeft: 1, usersStayed: 0, retentionRate: 0 },
        '4': { usersLeft: 0, usersStayed: 0, retentionRate: 0 },
        '5': { usersLeft: 0, usersStayed: 0, retentionRate: 0 },
        '6': { usersLeft: 0, usersStayed: 0, retentionRate: 0 },
        startOfMonth: 2024-04-30T22:00:00.000Z,
        endOfMonth: 2024-05-31T21:59:59.999Z,
        totalUsers: 1,
        modifiedTotalUsers: 0,
        users: [
            {
                createdAt: 2024-05-07T06:53:19.000Z,
                UserName: 'Naonic97',
                LastSeen: 2024-07-22T06:08:48.000Z
            }
        ]
    }
]


*/