import React from 'react';
import "./index.scss";

import ContentHeader from "./ContentHeader";
import ContentChat from "./ContentChat";
import ContentSendMessge from "./ContentSendMessage";

export default function ChatContent(props) {

  return (
    <div style={{ display: props.sidebar && window.innerWidth <= 1100 ? 'none' : 'block' }} className='component__chatContent'>
      {
        props.activeChat?.ChatRoomID ? <>
          <ContentHeader activeChat={props.activeChat} buttonStyle={{border: '1px solid #FFF', backgroundColor: '#000'}} />
          <ContentChat activeChat={props.activeChat} buttonStyle={{border: '1px solid #FFF', backgroundColor: '#000'}} />
          <ContentSendMessge activeChat={props.activeChat} buttonStyle={{border: '1px solid #FFF', backgroundColor: '#000'}} />
        </>
          : <div className='component__chatContent__startMessage'>
            <h2>Select a conversation on the left to start a conversation</h2>
          </div>
      }
    </div>
  )
}