export const createRandomString = (length) => {
    let result           = '';
    let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
   };
   return result;
};

export const lookingFor = {
	1: "Just Friends",
	2: "Casual Dating",
	3: "Affairs",
	4: "Friendship",
	5: "Friends with Benefits",
	6: "Serious Relationship",
	7: "Long-term Relationship",
	8: "Short-term Relationship",
	9: "Socializing",
	10: "Love",
	11: "Chatting",
	12: "Dating",
	13: "Flirting",
	14: "Travel Companions",
	15: "Open Relationship",
	16: "Just Browsing",
	17: "Marriage"
};