import React from 'react';
import "./index.scss";

import CustomInput from '../../components/customComponents/CustomInput';
import Button from '../../components/customComponents/Button';
import Spinner from '../../components/customComponents/Spinner';
import { FilteredCustomTable } from "../../components/customComponents/Table";
import DataSorter from "../../components/customComponents/DataSorter";

import * as backendModule from "../../modules/backendModule";
import axios, { all } from "axios";
import moment from 'moment';

import Dropdown from '../../components/customComponents/Dropdown';

import { animateBox } from "../../modules/componentAnimation";

export default function Gifts(props) {
  const [filters, setFilters] = React.useState([]);
  const [orders, setOrders] = React.useState([]);
  const [spinner, setSpinner] = React.useState(false);

  const getAllGifts = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/gifts/getAllGiftsCpanel`,
      data: {
          filters: filters,
          orders: orders
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === 'ok') {
          props.setGifts(res.data);
      }
      else {

      }
    }).catch(() => {

    });
  }

  React.useEffect(() => {
    getAllGifts();
  }, [filters, orders]);

  const deleteGift = (id) => {
    setSpinner(true);
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/gifts/removeGift`,
      data: {
          ID: id
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === 'ok') {
        getAllGifts();
      }
      else {

      }
    }).catch(() => {

    }).finally(() => {
      setSpinner(false);
    });
  }

  const addGiftsToTable = () => {
    if (props.gifts.data) {
      return props.gifts?.data?.map((item) => {
        return [
          { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.ID}</p></div> },
          { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Name}</p></div> },
          { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Credits}</p></div> },
          { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Type}</p></div> },
          { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.DomainID}</p></div> },
          { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{moment(item.updatedAt).format('DD.MM.YYYY.')}</p></div> },
          { keyID: String(item.ID), type: "groupNewline", group: [
            {
              keyID: String(item.ID), type: "button", text: "Delete", triggerDropdown: true, triggerData: e => {
                return (<div style={{ display: 'flex', flexDirection: 'column', justfyContent: 'center', alignItems: 'center' }}>
                  <p style={{ color: 'white' }}>Are you sure?</p>
                  <br></br>
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Button style={{ display: spinner ? 'none' : 'block', marginRight: '10px' }} accent='rgb(72, 82, 92)' value='YES' onClick={() => deleteGift(item.ID)} />
                    <Button style={{ display: spinner ? 'none' : 'block' }} accent='rgb(72, 82, 92)' value='NO' onClick={c => { e() }} />
                    <div style={{ display: spinner ? 'block' : 'none' }}><Spinner color='white' width='32px' /></div>
                  </div>
                </div>);
              },
            },
            {
              keyID: String(item.ID), type: "button", text: "Open image", triggerDropdown: true, onClick: e => {
                window.open(`/#/image/${String(item.Image).replace(`${backendModule.backendURL}/staticContent/images/`, '')}`, '_blank');
              }
            },
            {
              keyID: String(item.ID), type: "button", text: "Edit", triggerDropdown: true, onClick: e => {
                animateBox(e, <EditModal curGift={
                  {
                    ID: item.ID,
                    Name: item.Name,
                    Credits: item.Credits,
                    Type: item.Type,
                    DomainID: item.DomainID,
                    Image: item.Image
                  }
                } />)
              }
            }
          ]}
        ];
      });
    }
  }

  const EditModal = (props) => {
    const newNameRef = React.useRef(null);
    const newCreditsRef = React.useRef(null);
    const [newSelectedDomain, setNewSelectedDomain] = React.useState(null);
    const [newSelectedImage, setNewSelectedImage] = React.useState(null);
    const [giftType, setGiftType] = React.useState(props.curGift.Type);
    const msgRef = React.useRef(null);

    const openDomainsModal = (e, isEdit, setNewSelectedDomain) => {
      animateBox(e, <SelectDomainModal isEdit={isEdit} setNewSelectedDomain={setNewSelectedDomain} />);
    }

    const SelectDomainModal = (props) => {
      const [filters, setFilters] = React.useState(false);
      const [domains, setDomains] = React.useState(false);

      const getAllDomains = () => {
        axios({
          method: "POST",
          url: `${backendModule.backendURL}/domains/getAllDomains`,
          data: {
            filters: filters
          },
          ...backendModule.axiosConfig
        }).then(res => {
          if (res.data.status === 'ok') {
            setDomains(res.data);
          }
          else {
            setDomains('GET_ALL_DOMAINS_ERROR');
          }
        }).catch(() => {
          setDomains('GET_ALL_DOMAINS_ERROR');
        });
      }

      React.useEffect(() => {
        getAllDomains();
      }, [filters]);

      const addDomainsToTable = () => {
        if (domains.data) {
          return domains?.data?.map((item) => {
            return [
              { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.ID}</p></div> },
              { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Domain}</p></div> },
              { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.WebsiteName}</p></div> },
              { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center', background: item.forOffer ? 'green' : 'red', width: 'fit-content', marginLeft: 'auto', marginRight: 'auto', padding: '5px', borderRadius: '10px' }}>{item.forOffer ? 'YES' : 'NO'}</p></div> },
              { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{moment(item.updatedAt).format('DD.MM.YYYY.')}</p></div> },
              {
                keyID: String(item.ID), type: "groupNewline", group: [
                  {
                    keyID: String(item.ID), type: "button", text: "Select", triggerDropdown: true, onClick: e => {
                      setNewSelectedDomain({ ID: item.ID, URL: item.Domain });
                      props.onClose();
                    }
                  }
                ]
              }
            ];
          });
        }
      }

      return <div className='route__emailServers__addNewModal__selectDomainModal'>
          <div id='box'>
              <div id='table'>
                  <FilteredCustomTable
                    id='table'
                    accent='#48525C'
                    theme='dark'
                    headers={['ID', 'Domain', 'Website name', 'For offer', 'Created at']}
                    filters={[
                      { name: "ID", friendlyName: "ID", type: "number" },
                      { name: "Domain", friendlyName: "Domain", type: "string" },
                      { name: "WebsiteName", friendlyName: "Website name", type: "string" },
                      { name: "updatedAt", friendlyName: "Date", type: "date" },
                    ]}
                    filterCB={f => setFilters(f)}
                    data={domains.data?.length > 0 ? addDomainsToTable() : [[{ keyID: 'noData', type: 'custom', data: <p>No data to display</p> }]]}
                  />
              </div>
              <div id="controles">
                <Button value='Cancel' accent='transparent' style={{ borderBottom: '1px solid #e53333', borderRadius: '0px', color: '#e53333' }} onClick={props.onClose} />
              </div>
          </div>
      </div>
    }

    const saveGift = (close) => {
      setSpinner(true);

      if (!newSelectedImage) {
        axios({
          method: "POST",
          url: `${backendModule.backendURL}/gifts/editGift`,
          data: {
            ID: props.curGift.ID,
            Name: newNameRef.current.value ? newNameRef.current.value : props.curGift.Name,
            Credits: newCreditsRef.current.value ? newCreditsRef.current.value : props.curGift.Credits,
            Type: giftType,
            DomainID: newSelectedDomain ? newSelectedDomain.ID : props.curGift.DomainID,
            Image: props.curGift.Image
          },
          ...backendModule.axiosConfig
        }).then(res => {
          if (res.data.status === 'ok') {
            getAllGifts();
            close();
          }
          else {
            msgRef.current.innerText = 'Something went wrong';
          }
        }).catch(() => {
          msgRef.current.innerText = 'Something went wrong';
        }).finally(() => {
          setSpinner(false);
        });
      } else {
        let imgFile = new FormData();
        imgFile.append('image', newSelectedImage);
        imgFile.append('ImageName', newSelectedImage.name);
        imgFile.append('tag', 'logo-img');

        let oldImage = props.curGift.Image.replace(`${backendModule.backendURL}/staticContent/images/`, '');

        axios({
          method: "POST",
          url: `${backendModule.backendURL}/images/getAllImages`,
          ...backendModule.axiosConfig,
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }).then(allimgs => {
          allimgs.data.data.map((item) => {
          if (String(item.URL).replace(`${backendModule.backendURL}/staticContent/images/`, '') === oldImage) {
            axios({
              method: "POST",
              url: `${backendModule.backendURL}/images/deleteImage`,
              ...backendModule.axiosConfig,
              data: {
                id: item.ID
              },
              headers: {
                "Content-Type": "multipart/form-data"
              }
            }).then(() => {
              axios({
                method: "POST",
                url: `${backendModule.backendURL}/images/uploadImage`,
                data: imgFile,
                ...backendModule.axiosConfig,
                headers: {
                  "Content-Type": "multipart/form-data"
                }
              }).then(imgres => {
                axios({
                  method: "POST",
                  url: `${backendModule.backendURL}/gifts/editGift`,
                  data: {
                    ID: props.curGift.ID,
                    Name: newNameRef.current.value ? newNameRef.current.value : props.curGift.Name,
                    Credits: newCreditsRef.current.value ? newCreditsRef.current.value : props.curGift.Credits,
                    Type: giftType,
                    DomainID: newSelectedDomain.ID,
                    Image: imgres.data.data
                  },
                  ...backendModule.axiosConfig
                }).then(res => {
                  if (res.data.status === 'ok') {
                    getAllGifts();
                    close();
                  }
                  else {
                    msgRef.current.innerText = 'Something went wrong';
                  }
                }).catch(() => {
                  msgRef.current.innerText = 'Something went wrong';
                }).finally(() => {
                  setSpinner(false);
                });
              }).catch(() => {

              })
            }).catch(() => { }).finally(() => {
              setSpinner(false);
            })
          }});
        }).catch(() => { });
      }
    }

    return <div className='route__gifts__editModal'>
      <div id='box'>
        <h1>Edit {props.curGift.Name}</h1>

        <CustomInput autocomplete="off" placeholder="Name" ref={newNameRef} defaultValue={props.curGift.Name} accent="#FFF" theme="dark" type="text" style={{ width: "100%", marginBottom: '25px' }} />
        <CustomInput autocomplete="off" placeholder="Credits" ref={newCreditsRef} defaultValue={props.curGift.Credits} accent="#FFF" theme="dark" type="number" style={{ width: "100%", marginBottom: '25px' }} />
        <input
          id="img-input"
          type='file'
          name='websitelogo'
          onChange={
            (event) => {
              setNewSelectedImage(event.target.files[0]);
            }
          }
        />
        <p id="img-p">{newSelectedImage?.name}</p>
        <Dropdown style={{marginBottom: '20px'}} inlinePlaceholder='Select Gift Type' theme='dark' data={[
          {name: 'Regular Gift', value: 'Regular'},
          {name: 'Monthly Gift', value: 'Monthly'},
          {name: 'Premium Gift', value: 'Premium'},
          {name: 'Reward Gift', value: 'Reward'},
          {name: 'Shop Gift', value: 'Shop'},
        ].map(item => {
        return {name: item.name, value: item.value}
        })} onChange={e => {setGiftType(e.value)}} />
        <Button value={newSelectedDomain === null ? 'CHANGE DOMAIN' : newSelectedDomain.URL} accent='#000' style={{border: '1px solid #2E4C6E'}} className='selectDomainButton' onClick={(e) => openDomainsModal(e, false)} />

        <div id="controles" style={{ display: !spinner ? 'flex' : 'none' }}>
          <Button value='Cancel' accent='transparent' style={{ borderBottom: '1px solid #e53333', borderRadius: '0px', color: '#e53333' }} onClick={props.onClose} />
          <Button value='Save' accent='transparent' style={{ borderBottom: '1px solid #00A3FF', borderRadius: '0px', color: '#00A3FF' }} onClick={() => saveGift(props.onClose)} />
        </div>
        <div style={{ display: spinner ? 'flex' : 'none', flexDirection: 'row', justifyContent: 'flex-end' }}>
          <Spinner color='white' align='right' width='32px' />
        </div>
        <p id="msg" ref={msgRef}></p>
      </div>
    </div>
  }

  return (
    <div className='route__gifts'>
      <DataSorter sorters={[
        {name: 'Credits', value: 'Credits'},
        {name: 'Type', value: 'Type'},
        {name: 'Name', value: 'Name'},
        {name: 'Updated Time', value: 'updatedAt'},
        {name: 'Creation Time', value: 'createdAt'},
      ]} setOrders={setOrders} />
      <FilteredCustomTable
        id='table'
        accent='#000'
        dropdownBackground='#000'
        inputBottomBorderColor='#FFF'
        placeholderStyle={{backgroundColor: '#000'}}
        theme='dark'
        filterHeadStyle={{backgroundColor: 'rgba(25, 35, 67, 0.2)'}}
        tableHeadStyle={{backgroundColor: 'rgba(25, 35, 67, 0.2)'}}
        buttonStyle={{border: '1px solid #FFF', backgroundColor: '#000'}}
        // style={{colors: index % 2 === 0 ? '#1923438e' : '#192343'}}
        style={{colors: '#000'}}
        color={'#000'}
        headers={['ID', 'Name', 'Credits', 'Type', 'Domain ID', 'Updated at']}
        filters={[
          { name: "ID", friendlyName: "ID", type: "string" },
          { name: "Name", friendlyName: "Name", type: "string" },
          { name: "Credits", friendlyName: "Credits", type: "number" },
          { name: "DomainID", friendlyName: "Domain ID", type: "string" },
          { name: "Type", friendlyName: "Type", type: "string" },
          { name: "updatedAt", friendlyName: "Updated at", type: "string" }
        ]}
        filterCB={f => setFilters(f)}
        data={props.gifts.data?.length > 0 ? addGiftsToTable() : [[{ keyID: 'noData', type: 'custom', data: <p>No data to display</p> }]]}
      />
    </div>
  )
}

const AddNewGiftModal = (props) => {
  const [spinner, setSpinner] = React.useState(false);
  const [selectedDomain, setSelectedDomain] = React.useState(null);
  const [selectedImage, setSelectedImage] = React.useState(null);
  const [giftType, setGiftType] = React.useState(null);
  const msgRef = React.useRef(null);

  const nameRef = React.useRef(null);
  const creditsRef = React.useRef(null);

  const getAllGifts = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/gifts/getAllGiftsCpanel`,
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === 'ok') {
        props.setGifts(res.data);
      }
      else {

      }
    }).catch(() => {

    });
  }

  React.useEffect(() => {
    getAllGifts();
  }, []);

  const openDomainsModal = (e, isEdit, setNewSelectedDomain) => {
    animateBox(e, <SelectDomainModal isEdit={isEdit} setNewSelectedDomain={setNewSelectedDomain} />);
  }

  const SelectDomainModal = (props) => {
    const [filters, setFilters] = React.useState(false);
    const [domains, setDomains] = React.useState(false);

    const getAllDomains = () => {
      axios({
        method: "POST",
        url: `${backendModule.backendURL}/domains/getAllDomains`,
        data: {
          filters: filters
        },
        ...backendModule.axiosConfig
      }).then(res => {
        if (res.data.status === 'ok') {
          setDomains(res.data);
        }
        else {
          setDomains('GET_ALL_DOMAINS_ERROR');
        }
      }).catch(() => {
        setDomains('GET_ALL_DOMAINS_ERROR');
      });
    }

    React.useEffect(() => {
      getAllDomains();
    }, [filters]);

    const addDomainsToTable = () => {
      if (domains.data) {
        return domains?.data?.map((item) => {
          return [
            { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.ID}</p></div> },
            { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Domain}</p></div> },
            { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.WebsiteName}</p></div> },
            { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center', background: item.forOffer ? 'green' : 'red', width: 'fit-content', marginLeft: 'auto', marginRight: 'auto', padding: '5px', borderRadius: '10px' }}>{item.forOffer ? 'YES' : 'NO'}</p></div> },
            { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{moment(item.updatedAt).format('DD.MM.YYYY.')}</p></div> },
            {
              keyID: String(item.ID), type: "groupNewline", group: [
                {
                  keyID: String(item.ID), type: "button", text: "Select", triggerDropdown: true, onClick: e => {
                    setSelectedDomain({ ID: item.ID, URL: item.Domain });
                    props.onClose();
                  }
                }
              ]
            }
          ];
        });
      }
    }

    return <div className='route__emailServers__addNewModal__selectDomainModal'>
      <div id='box'>
        <div id='table'>
          <FilteredCustomTable
            id='table'
            accent='#48525C'
            theme='dark'
            headers={['ID', 'Domain', 'Website name', 'For offer', 'Created at']}
            filters={[
              { name: "ID", friendlyName: "ID", type: "number" },
              { name: "Domain", friendlyName: "Domain", type: "string" },
              { name: "WebsiteName", friendlyName: "Website name", type: "string" },
              { name: "updatedAt", friendlyName: "Date", type: "date" },
            ]}
            filterCB={f => setFilters(f)}
            data={domains.data?.length > 0 ? addDomainsToTable() : [[{ keyID: 'noData', type: 'custom', data: <p>No data to display</p> }]]}
          />
        </div>
        <div id="controles">
          <Button value='Cancel' accent='transparent' style={{ borderBottom: '1px solid #e53333', borderRadius: '0px', color: '#e53333' }} onClick={props.onClose} />
        </div>
      </div>
    </div>
  }

  const addPackage = (close) => {
    setSpinner(true);

    if(!nameRef.current.value){
      setSpinner(false);
      return msgRef.current.innerText = 'Name missing!';
    }

    if(!creditsRef.current.value){
      setSpinner(false);
      return msgRef.current.innerText = 'Credits missing!';
    }

    if(!selectedDomain){
      setSpinner(false);
      return msgRef.current.innerText = 'Domain missing!';
    }

    if(!giftType){
      setSpinner(false);
      return msgRef.current.innerText = 'Gift type missing!';
    }

    let imgFile = new FormData();
    imgFile.append('image', selectedImage);
    imgFile.append('ImageName', selectedImage.name);
    imgFile.append('tag', 'logo-img');

    axios({
      method: "POST",
      url: `${backendModule.backendURL}/images/uploadImage`,
      data: imgFile,
      ...backendModule.axiosConfig,
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then(imgres => {
      axios({
        method: "POST",
        url: `${backendModule.backendURL}/gifts/addGift`,
        data: {
          Name: nameRef.current.value,
          Credits: creditsRef.current.value,
          Type: giftType,
          DomainID: selectedDomain.ID,
          Image: imgres.data.data
        },
        ...backendModule.axiosConfig
      }).then(res => {
          if (res.data.status === 'ok') {
            getAllGifts();
            close();
          }
          else {
            msgRef.current.innerText = 'Something went wrong';
          }
      }).catch(() => {
          msgRef.current.innerText = 'Something went wrong';
      }).finally(() => {
          setSpinner(false);
      });
    }).catch(() => {

    });
  }

  return <div className='route__gifts__addNewModal'>
    <div id='box'>
      <h1>Add new gift</h1>

      <CustomInput autocomplete="off" placeholder="Name" ref={nameRef} accent="#FFF" theme="dark" type="text" style={{ width: "100%", marginBottom: '25px' }} />
      <CustomInput autocomplete="off" placeholder="Credits" ref={creditsRef} accent="#FFF" theme="dark" type="number" style={{ width: "100%", marginBottom: '25px' }} />
      <input
        id="img-input"
        type='file'
        name='websitelogo'
        onChange={
          (event) => {
            setSelectedImage(event.target.files[0]);
          }
        }
      />
      <p id="img-p">{selectedImage?.name}</p>

      <Dropdown style={{marginBottom: '20px'}} inlinePlaceholder='Select Gift Type' theme='dark' data={[
        {name: 'Regular Gift', value: 'Regular'},
        {name: 'Monthly Gift', value: 'Monthly'},
        {name: 'Premium Gift', value: 'Premium'},
        {name: 'Reward Gift', value: 'Reward'},
        {name: 'Shop Gift', value: 'Shop'},
      ].map(item => {
        return {name: item.name, value: item.value}
      })} onChange={e => {setGiftType(e.value)}} />

      <Button value={selectedDomain === null ? 'SELECT DOMAIN' : selectedDomain.URL} accent='rgb(72, 82, 92)' className='selectDomainButton' onClick={(e) => openDomainsModal(e, false)} />

      <div id="controles" style={{ display: !spinner ? 'flex' : 'none' }}>
        <Button value='Cancel' accent='transparent' style={{ borderBottom: '1px solid #e53333', borderRadius: '0px', color: '#e53333' }} onClick={props.onClose} />
        <Button value='Add' accent='transparent' style={{ borderBottom: '1px solid #00A3FF', borderRadius: '0px', color: '#00A3FF' }} onClick={() => addPackage(props.onClose)} />
      </div>
      <div style={{ display: spinner ? 'flex' : 'none', flexDirection: 'row', justifyContent: 'flex-end' }}>
        <Spinner color='white' align='right' width='32px' />
      </div>
      <p id="msg" ref={msgRef}></p>
    </div>
  </div>
}

export { AddNewGiftModal }