import React from 'react';
import "./index.scss";

import Button from '../../components/customComponents/Button';

import * as backendModule from "../../modules/backendModule";

export default function Tools() {
  return (
    <div className='component__tools'>
        <Button className='component__tools__button' value ='Stream server statistics' onClick={() => window.open(backendModule.servicesURL.streamStatistics)} />
    </div>
  )
}
