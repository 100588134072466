import React from 'react';
import "./index.scss";

import ChatSidebar from "../Chat/ChatSidebar";
import ChatContent from "../Chat/ChatContent";

import { useSelector } from 'react-redux';

import { useParams } from 'react-router-dom';
import axios from 'axios';

import * as backendModule from "../../modules/backendModule";
import * as socketModule from '../../modules/socketModule';
import Spinner from '../../components/customComponents/Spinner';

export default function Chat(props) {
    const [sidebar, setSidebar] = React.useState(true);
    const [activeChat, setActiveChat] = React.useState(null);
    const chatRoomsSelector = useSelector(state => state?.chatRooms ?? []);

    const [spinner, setSpinner] = React.useState(false);
    const [switchID, setSwitchID] = React.useState(null);
    const [userID, setUserID] = React.useState(null);
    const [internalID, setInternalID] = React.useState(null);

    React.useEffect(() => {
        if (userID && internalID) {
            setSpinner(true);
            socketModule.internal__createChatRoom("", [userID.ID, internalID.ID]);

            axios({
                method: "POST",
                url: `${backendModule.backendURL}/users/getSecondaryUserByID`,
                data: {
                    ID: userID
                },
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === "ok") {
                    setSwitchID(res.data.data);
                } else {
                    setSpinner(false);
                };
            }).catch(() => {
                setSpinner(false);
            }).finally(() => {
                setSpinner(false);
            });
        };
    }, [userID, internalID]);

    React.useEffect(() => {
        const handler = (chatRoom) => {
            let uID = userID;
            if (!userID) return;
            setActiveChat({
                ClientName: uID.user.Name,
                ClientImage: uID.user.Image,
                ClientID: uID.user.ID,
                ChatRoomID: chatRoom.ID,
                AgentID: internalID.secondaryID,
                AgentName: `${internalID.user.FirstName} ${internalID.user.LastName}`,
                AgentImage: internalID.user.Images[0],
                ModerationEnabled: chatRoom.ModerationEnabled
            });
        }
        socketModule.on('createChatRoomSwitch', handler);

        return () => socketModule.off("createChatRoomSwitch", handler);
    }, [chatRoomsSelector.length, userID, internalID]);

    return (
        <div className='route__chat'>
            <div className="root__internalSpinner" style={{
                opacity: spinner ? 1 : 0,
                pointerEvents: spinner ? "all" : "none"
            }} onClick={e => spinner && e.stopPropagation()}>
                <Spinner align="center" color="#6664E5" style={{ pointerEvents: "none" }} />
                <p>Creating chat</p>
            </div>
            <div style={{ display: sidebar ? 'none' : 'block' }} className='route__chat__button'>
                <button onClick={() => setSidebar(true)}>BACK</button>
            </div>
            <ChatSidebar setInternalID={setInternalID} setUserID={setUserID} setActiveChat={setActiveChat} activeChat={activeChat} sidebar={sidebar} setSidebar={setSidebar} />
            <ChatContent activeChat={activeChat} sidebar={sidebar} setSidebar={setSidebar} />
        </div>
    )
}
