import React from "react";
import "./index.scss";

import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import * as backendModule from "../../modules/backendModule";
import * as timestmapActions from "../../actions/timestampAction";

import useDefer from "../../modules/hooks/useDefer";
import useOnScreen from "../../modules/hooks/useOnScreen";

import Button from "../../components/customComponents/Button";
import CustomInput from "../../components/customComponents/CustomInput";
import { FilteredCustomTable } from "../../components/customComponents/Table";
import Dropdown from "../../components/customComponents/Dropdown";
import Spinner from "../../components/customComponents/Spinner";
import DataSorter from "../../components/customComponents/DataSorter";
import { animateBox } from "../../modules/componentAnimation";

const BuildABitch = () => {
    const [data, setData] = React.useState();
    const [canPaginate, setCanPaginate] = React.useState(false);
    const [spinner, setSpinner] = React.useState(false);
    const [filters, setFilters] = React.useState([]);
    const [orders, setOrders] = React.useState([]);

    const curOnScreen = useOnScreen();
    const curDefer = useDefer();
    const timestampSelector = useSelector(state => state?.timestamp ?? null);

    const currentTimestampRef = React.useRef();

    const getData = (ts) => {
        if (canPaginate) setCanPaginate(false);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/buildABitch/getAllBitches`,
            data: {
                offset: 0,
                limit: 20,
                filters,
                orders
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (ts !== currentTimestampRef.current) return;
            setData(res.data);
            if (res.data.status === "ok") {
                if (res.data.data.length === 20 && !canPaginate) setCanPaginate(true);
            };
        }).catch(() => {
            setData({status: "error", data: "SERVER_ERROR"});
        });
    };

    const continueData = (ts) => {
        if (canPaginate) setCanPaginate(false);

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/buildABitch/getAllBitches`,
            data: {
                offset: 0,
                limit: 20,
                filters: [
                    ...filters,
                    {name: "ID", op: "notIn", value: data.data.map(d => d.ID)}
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (ts !== currentTimestampRef.current) return;

            if (res.data.status === "ok") {
                if (res.data.data.length === 20 && !canPaginate) setCanPaginate(true);
                setData(d => {
                    return {
                        ...d,
                        data: [
                            ...d.data,
                            ...res.data.data
                        ]
                    };
                });
            };
        }).catch(() => {
            setData({status: "error", data: "SERVER_ERROR"});
        }).finally(() => {
            setSpinner(false);
        });
    };

    React.useEffect(() => {
        curDefer(() => {
            if (!canPaginate) return;
            if (!curOnScreen.isIntersecting) return;
    
            try {
                curOnScreen.observer.unobserve(curOnScreen.measureRef.current);
            } catch {};

            const ts = Date.now();
            currentTimestampRef.current = ts;

            continueData(ts);
        }, 500);
    }, [curOnScreen.isIntersecting, canPaginate, data]);

    React.useEffect(() => {
        let ts = Date.now();
        currentTimestampRef.current = ts;
        getData(ts);
    }, [filters, timestampSelector, orders]);

    return <div className="route__buildABitch">
        <DataSorter sorters={[
            {name: 'Credits', value:'Credits'},
            {name: 'Unlocked Times', value:'UnlockedTimes'},
            {name: 'Name', value:'Name'},
            {name: 'Race', value:'Race'},
            {name: 'Weight', value:'Weight'},
            {name: 'Ambient', value:'Ambient'},
            {name: 'Age', value:'Age'},
            {name: 'Created at', value:'createdAt'},
            {name: 'Updated at', value:'updatedAt'}
        ]} setOrders={setOrders} />
        <FilteredCustomTable
            filterCB={c => setFilters(c)}
            id='table'
            accent='#000'
            dropdownBackground='#000'
            inputBottomBorderColor='#FFF'
            placeholderStyle={{backgroundColor: '#000'}}
            theme='dark'
            filterHeadStyle={{backgroundColor: 'rgba(25, 35, 67, 0.2)'}}
            tableHeadStyle={{backgroundColor: 'rgba(25, 35, 67, 0.2)'}}
            buttonStyle={{border: '1px solid #FFF', backgroundColor: '#000'}}
            // style={{colors: index % 2 === 0 ? '#1923438e' : '#192343'}}
            style={{colors: '#000'}}
            color={'#000'}
            filters={[
                {name: "ID", friendlyName: "ID", type: "string"},
                {name: "Name", friendlyName: "Name", type: "string"},
                {name: "Race", friendlyName: "Race", type: "string"},
                {name: "Age", friendlyName: "Age", type: "number"},
                {name: "Gender", friendlyName: "Gender", type: "custom", varType: "custom", data: [
                    {text: "Male", value: true},
                    {text: "Female", value: false}
                ]},
                {name: "Ambient", friendlyName: "Ambient", type: "string"},
                {name: "Credits", friendlyName: "Credits", type: "number"},
                {name: "UnlockedTimes", friendlyName: "Unlocked times", type: "number"}
            ]}
            headers={["ID", "Name", "Race", "Age", "Gender", "Ambient", "Credits", "Unlocked"]}
            data={(()=>{
                let out = [];

                if (!data) return [[{keyID: "noData-spinner", type: "spinner"}]];
                if (data.status === "error") return [[{keyID: "noData-error", type: "text", text: "Error while fetching data"}]];

                for (let item of data.data) {
                    out.push([
                        {keyID: item.ID, type: "text", text: item.ID},
                        {keyID: item.ID, type: "text", text: item.Name},
                        {keyID: item.ID, type: "text", text: item.Race},
                        {keyID: item.ID, type: "text", text: item.Age},
                        {keyID: item.ID, type: "text", text: item.Gender ? "Female" : "Male"},
                        {keyID: item.ID, type: "text", text: item.Ambient},
                        {keyID: item.ID, type: "text", text: item.Credits},
                        {keyID: item.ID, type: "text", text: item.UnlockedTimes},
                        {keyID: item.ID, type: "groupNewline", group: [
                            {keyID: item.ID, type: "button", text: "Get Chat code", triggerDropdown: true, triggerData: () => <p>{`//${item.ChatCode}`}</p>},
                            {keyID: item.ID, type: "button", text: "Delete", triggerDropdown: true, triggerData: c => <RemoveBitch ID={item.ID} onClose={c} onChange={() => {
                                let ts = Date.now();
                                currentTimestampRef.current = ts;
                                getData(ts);
                            }} />}
                        ]}
                    ])
                };

                if (spinner) out.push([{keyID: "data-spinner", type: "spinner"}]);
                if (out.length === 0) out.push([{keyID: "noData-noData", type: "text", text: "Nothing to show..."}]);

                return out;
            })()}
        />
        {canPaginate && <div ref={curOnScreen.measureRef} style={{ width: '1px', height: '1px', opacity: '0' }}></div>}
    </div>
};

const RemoveBitch = props => {
    const [spinner, setSpinner] = React.useState(false);
    const [infoP, setInfoP] = React.useState("");

    const removeBitch = () => {
        setInfoP("");
        setSpinner(true);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/buildABitch/removeBitch`,
            data: {
                ID: props.ID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.onClose().then(() => {
                    props.onChange();
                })
            } else {
                setInfoP("Error while cleaning da hoe...");
            };
        }).catch(() => {
            setInfoP("Server timed out");
        }).finally(() => {
            setSpinner(false);
        });
    };

    if (spinner) return <Spinner color="white" />
    if (infoP) return <>
        {infoP}
        <Button style={{  }} accent='rgb(72, 82, 92)' value='Close' onClick={props.onClose} />
    </>;

    return <div className="route__buildABitch__removeBitch">
        <p>Are you sure?</p>
        <Button style={{ marginRight: '10px' }} accent='rgb(72, 82, 92)' value='YES' onClick={() => removeBitch()} />
        <Button style={{  }} accent='rgb(72, 82, 92)' value='NO' onClick={props.onClose} />
    </div>
};

const AddBitch = props => {
    const [spinner, setSpinner] = React.useState(false);
    const [infoP, setInfoP] = React.useState("");
    const [gender, setGender] = React.useState();
    const [image, setImage] = React.useState();
    const [blurredImage, setBlurredImage] = React.useState();
    const [ladyID, setLadyID] = React.useState(null);

    const nameRef = React.useRef();
    const ageRef = React.useRef();
    const raceRef = React.useRef();
    const weightRef = React.useRef();
    const ambientRef = React.useRef();
    const creditsRef = React.useRef();

    const curDispatch = useDispatch();

    const saveBitch = () => {
        if(spinner) return;
        setSpinner(true)
        setInfoP("");

        const data = {
            Name: nameRef.current.value,
            Age: ageRef.current.value,
            Race: raceRef.current.value,
            Weight: weightRef.current.value,
            Gender: gender,
            Ambient: ambientRef.current.value,
            Credits: creditsRef.current.value,
            Image: image,
            BlurredImage: blurredImage,
            LadyID: ladyID
        };

        if (!data.Name) return setInfoP("Name missing");
        if (!data.Age) return setInfoP("Age missing");
        if (!data.Race) return setInfoP("Race missing");
        if (!data.Weight) return setInfoP("Weight missing");
        if (data.Gender === null || data.Gender === undefined) return setInfoP("Gender missing");
        if (!data.Credits) return setInfoP("Credits missing");
        if (!data.Image) return setInfoP("Image missing");
        if (!data.LadyID) return setInfoP("Lady not selected");

        let fd = new FormData();
        for (let key of Object.keys(data)) fd.append(key, data[key]);

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/buildABitch/addBitch`,
            data: fd,
            ...backendModule.axiosConfig,
            headers: {
                ...backendModule.axiosConfig.headers,
                "Content-Type": "multipart/form-data"
            }
        }).then(res => {
            if (res.data.status === "ok") {
                curDispatch(timestmapActions.setTimestamp());
                props.onClose();
            } else {
                setInfoP("Check your info and try again");
            };
        }).catch(() => {
            setInfoP("Server timed out");
        }).finally(() => {
            setSpinner(false);
        });
    };

    return <div className="route__buildABitch__addBitch">
        <div className="route__buildABitch__addBitch__wrap">
            <div className="route__buildABitch__addBitch__wrap__spinner" style={{
                opacity: spinner ? 1 : 0,
                pointerEvents: spinner ? "all" : "none"
            }} onClick={e => spinner && e.stopPropagation()}>
                <Spinner color="white" />
            </div>

            <CustomInput ref={nameRef} accent="#fff" theme="dark" placeholder="Name" />
            <CustomInput ref={ageRef} accent="#fff" theme="dark" placeholder="Age" />
            <CustomInput ref={raceRef} accent="#fff" theme="dark" placeholder="Race" />
            <CustomInput ref={weightRef} accent="#fff" theme="dark" placeholder="Weight" />
            <CustomInput ref={ambientRef} accent="#fff" theme="dark" placeholder="Ambient" />
            <CustomInput ref={creditsRef} accent="#fff" theme="dark" placeholder="Credits" />
            <Dropdown
                accent="#fff"
                theme="dark"
                data={[
                    {name: "Male", value: true},
                    {name: "Female", value: false}
                ]}
                inlinePlaceholder="Gender"
                selected={(()=>{
                    if (gender === null || gender === undefined) return -1;
                    if (gender) return 0;
                    return 1;
                })()}
                onChange={e => {
                    setGender(e?.value);
                }}
            />
            <br />
            <p>Image</p>
            <input type="file" accent="#fff" theme="dark" accept="image/*" onChange={e => {
                if (e.target.files[0]) {
                    setImage(e.target.files[0]);
                } else {
                    setImage();
                };
            }} />
            <br />
            <p>Blurred image</p>
            <input type="file" accent="#fff" theme="dark" accept="image/*" onChange={e => {
                if (e.target.files[0]) {
                    setBlurredImage(e.target.files[0]);
                } else {
                    setBlurredImage();
                };
            }} />

            <br/>
            <br/>

            <Button value={ladyID === null ? 'SELECT LADY' : `Lady: ${ladyID}`} accent='rgb(72, 82, 92)' className='selectDomainButton' onClick={(e) => {
                animateBox(e, <SelectLadyModal onChange={e => setLadyID(e.ID)} />);
            }} style={{width: "100%"}} />

            <div className="route__buildABitch__addBitch__wrap__controls">
                <Button value='Cancel' accent='transparent' style={{ borderBottom: '1px solid #e53333', borderRadius: '0px', color: '#e53333' }} onClick={props.onClose} />
                <Button value='Save' accent='transparent' style={{ borderBottom: '1px solid #00A3FF', borderRadius: '0px', color: '#00A3FF' }} onClick={() => saveBitch()} />
            </div>

            {infoP && <p style={{color: "#ff8f8f", marginTop: "20px"}}>{infoP}</p>}
        </div>
    </div>
};

const SelectLadyModal = (props) => {
    const [filters, setFilters] = React.useState([]);
    const [data, setData] = React.useState(false);

    const getData = () => {
      axios({
        method: "POST",
        url: `${backendModule.backendURL}/ladies/getAllLadies2`,
        data: {
          filters: [
            ...filters
          ],
          onlyOffline: false,
          offset: 0,
          limit: null
        },
        ...backendModule.axiosConfig
      }).then(res => {
        if (res.data.status === 'ok') {
          setData(res.data);
        }
        else {
        setData('GET_ALL_DOMAINS_ERROR');
        }
      }).catch(() => {
        setData('GET_ALL_DOMAINS_ERROR');
      });
    }

    React.useEffect(() => {
        getData();
    }, [filters]);

    const addDataToTable = () => {
      if (data.data) {
        return data?.data?.map((item) => {
          return [
            { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.ID}</p></div> },
            { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.DomainID}</p></div> },
            { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{`${item.FirstName} ${item.LastName}`}</p></div> },
            {
              keyID: String(item.ID), type: "groupNewline", group: [
                {
                  keyID: String(item.ID), type: "button", text: "Select", triggerDropdown: true, onClick: e => {
                    props.onChange(item);
                    props.onClose();
                  }
                }
              ]
            }
          ];
        });
      }
    }

    return <div className='route__emailServers__addNewModal__selectDomainModal'>
      <div id='box'>
        <div id='table'>
          <FilteredCustomTable
            id='table'
            accent='#48525C'
            theme='dark'
            headers={['ID', 'Domain', "Name"]}
            filters={[
              { name: "ID", friendlyName: "ID", type: "number" },
              { name: "DomainID", friendlyName: "Domain ID", type: "string" },
              { name: "FirstName", friendlyName: "First name", type: "string" },
              { name: "LastName", friendlyName: "Last name", type: "string" }
            ]}
            filterCB={f => setFilters(f)}
            data={data.data?.length > 0 ? addDataToTable() : [[{ keyID: 'noData', type: 'custom', data: <p>No data to display</p> }]]}
          />
        </div>
        <div id="controles">
          <Button value='Cancel' accent='transparent' style={{ borderBottom: '1px solid #e53333', borderRadius: '0px', color: '#e53333' }} onClick={props.onClose} />
        </div>
      </div>
    </div>
  }

export default BuildABitch;
export {AddBitch as AddNewBitchModal};