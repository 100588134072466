import React from "react";
import "./index.scss";

import axios from "axios";
import moment from "moment";
import { animateBox } from "../../../modules/componentAnimation";
import * as backendModule from "../../../modules/backendModule";

import { FilteredCustomTable } from "../../../components/customComponents/Table";
import CustomInput from "../../../components/customComponents/CustomInput";
import CustomButtonSmall from "../../../components/customComponents/Button";

const UserStats = () => {
    const [data, setData] = React.useState();
    const [filters, setFilters] = React.useState("today");
    const [customFilters, setCustomFilters] = React.useState();

    const prepareFilters = () => {
        if (filters === "custom" && !customFilters) return;

        switch (filters) {
            case "today": return {start: moment().startOf("day"), end: moment().endOf("day")};
            case "yesterday": return {start: moment().add(-1, "day").startOf("day"), end: moment().add(-1, "day").endOf("day")};
            case "7days": return {start: moment().add(-6, "days").startOf("day"), end: moment().endOf("day")};
            case "30days": return {start: moment().add(-29, "days").startOf("day"), end: moment().endOf("day")};
            case "60days": return {start: moment().add(-59, "days").startOf("day"), end: moment().endOf("day")};
            case "custom":
                if (customFilters) return customFilters;
                return null;
            default: return {start: null, end: null};
        };
    };

    const getData = () => {
        let dateFilters = prepareFilters();
        if (!dateFilters) return;

        let finalFilters = [];
        if (dateFilters.start && dateFilters.end) {
            finalFilters.push({name: "createdAt", op: "eq", value: dateFilters.start.toDate().getTime()});
            finalFilters.push({name: "createdAt", op: "eq", value: dateFilters.end.toDate().getTime()});
        };

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/statistics/userStatistics`,
            data: {
                filters: finalFilters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setData(res.data);
        }).catch(() => {
            setData({status: "error", data: "SERVER_ERROR"});
        });
    };

    React.useEffect(() => {
        let tmp = prepareFilters();
        if (!tmp) return;

        getData();
    }, [filters, customFilters]);

    return <div className="route__analytics__userStats">
        <div className="route__analytics__userStats__filters">
            <div className={`route__analytics__userStats__filters__button ${filters === "all" ? "route__analytics__userStats__filters__button--active" : ""}`} onClick={() => setFilters("all")}>All</div>
            <div className={`route__analytics__userStats__filters__button ${filters === "today" ? "route__analytics__userStats__filters__button--active" : ""}`} onClick={() => setFilters("today")}>Today</div>
            <div className={`route__analytics__userStats__filters__button ${filters === "yesterday" ? "route__analytics__userStats__filters__button--active" : ""}`} onClick={() => setFilters("yesterday")}>Yesterday</div>
            <div className={`route__analytics__userStats__filters__button ${filters === "7days" ? "route__analytics__userStats__filters__button--active" : ""}`} onClick={() => setFilters("7days")}>7 days</div>
            <div className={`route__analytics__userStats__filters__button ${filters === "30days" ? "route__analytics__userStats__filters__button--active" : ""}`} onClick={() => setFilters("30days")}>30 days</div>
            <div className={`route__analytics__userStats__filters__button ${filters === "60days" ? "route__analytics__userStats__filters__button--active" : ""}`} onClick={() => setFilters("60days")}>60 days</div>
            <div className={`route__analytics__userStats__filters__button ${filters === "custom" ? "route__analytics__userStats__filters__button--active" : ""}`} onClick={(e) => {
                setCustomFilters();
                animateBox(e, <CustomDateModal onChange={e => {
                    setFilters("custom");
                    setCustomFilters(e);
                }} />);
            }}>Custom</div>
        </div>

        <div className="route__analytics__userStats__tableWrap">
            <FilteredCustomTable
                accent='#48525C'
                theme='dark'
                headers={[
                    "User", "Email", "Last active", "Money spent",
                    "Messages", "Credits", "Purchased packages", "Monthly subscription",
                    "Subscription days left",
                    "Gifts", "Stickers", "Boosts", "Animated banners", "GIF banners", "Normal banners",
                    "Normal badges", "Special badges", "Animated badges", "Gift cards"
                ]}
                data={(()=>{
                    let out = [];

                    if (!data) return [[{keyID: "noData-spinner", type: "spinner"}]];
                    if (data.status === "error") return [[{keyID: "noData-error", type: "text", text: "Error while fetching data"}]];

                    for (let item of data.data) {
                        // TODO ...
                        out.push([
                            {keyID: item.ID, type: "text", text: item.Username},
                            {keyID: item.ID, type: "text", text: item.Email},
                            {keyID: item.ID, type: "text", text: (()=>{
                                if (!item.LastLogin) return "Never";
                                if (moment(item.LastLogin).isAfter(moment().add(-30, "seconds"))) return "Active";
                                return (new Date(item.LastLogin)).toLocaleString()
                            })()},
                            {keyID: item.ID, type: "text", text: item.MoneySpent},
                            {keyID: item.ID, type: "text", text: `${item.MessagesSent} / ${item.MessagesReceived}`},
                            {keyID: item.ID, type: "text", text: item.Credits},
                            {keyID: item.ID, type: "text", text: item.PackagesPurchased},
                            {keyID: item.ID, type: "text", text: item.MonthlySubscription},
                            {keyID: item.ID, type: "text", text: item.MonthlySubscriptionDaysLeft},
                            {keyID: item.ID, type: "text", text: item.OwnedGifts},
                            {keyID: item.ID, type: "text", text: item.OwnedStickers},
                            {keyID: item.ID, type: "text", text: item.Boosts},
                            {keyID: item.ID, type: "text", text: item.AnimatedBanners},
                            {keyID: item.ID, type: "text", text: item.GIFBanners},
                            {keyID: item.ID, type: "text", text: item.NormalBanners},
                            {keyID: item.ID, type: "text", text: item.NormalBadges},
                            {keyID: item.ID, type: "text", text: item.SpecialBadges},
                            {keyID: item.ID, type: "text", text: item.AnimatedBadges},
                            {keyID: item.ID, type: "text", text: item.GiftCards},
                        ])
                    };

                    if (out.length === 0) return [[{keyID: "noData-noData", type: "text", text: "Nothing to show..."}]];
                    return out;
                })()}
            />
        </div>
    </div>
};

const CustomDateModal = props => {
    const startDateRef = React.useRef();
    const endDateRef = React.useRef();

    return <div className="route__analytics__customDate">
        <div className="route__analytics__customDate__wrap">
            <CustomInput ref={startDateRef} accent="#FFF" theme="dark" placeholder="Start date" type="date" />
            <CustomInput ref={endDateRef} accent="#FFF" theme="dark" placeholder="End date" type="date" />

            <div className="route__analytics__customDate__wrap__buttons">
                <CustomButtonSmall value='Cancel' accent='transparent' style={{ borderBottom: '1px solid #e53333', borderRadius: '0px', color: '#e53333' }} onClick={props.onClose} />
                <CustomButtonSmall value='Save' accent='transparent' style={{ borderBottom: '1px solid #00A3FF', borderRadius: '0px', color: '#00A3FF' }} onClick={() => {
                    const start = moment(startDateRef.current.value);
                    const end = moment(endDateRef.current.value);

                    if (!start.isValid() || !end.isValid()) return alert("Dates can't be empty");
                    props.onChange({start: start.startOf("day"), end: end.endOf("day")});
                    props.onClose();
                }} />
            </div>
        </div>
    </div>
};

export default UserStats;