import React from 'react';
import "./index.scss";

import { useSelector, useDispatch } from "react-redux";
import * as backendModule from "../../../modules/backendModule";

import { animateBox } from '../../../modules/componentAnimation';

import Spinner from '../../../components/customComponents/Spinner';
import * as socketModule from "../../../modules/socketModule";

import axios from "axios";

export default function ChatSidebar(props) {

  const chatRoomSelector = useSelector(state => state?.chatRooms ?? null);
  const chatUsersSelector = useSelector(state => state?.chatUsers ?? null);

  const [data, setData] = React.useState()
  const [filters, setFilters] = React.useState([]);
  const [hasMoreData, setHasMoreData] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false);
  const paragraphRef = React.useRef(null);

  const [spinner, setSpinner] = React.useState(false);
  const [canPaginate, setCanPaginate] = React.useState(false);
  const [offset, setOffset] = React.useState(10);
  const itemsPerPage = 10;
  const [chatsToDelete, setChatsToDelete] = React.useState([]);

  const getClient = (roomID) => {
    const returnNull = () => {
      return {
        Client: { FirstName: "Deleted", LastName: "user", Username: 'Deleted', Image: "#" },
        Agent: { FirstName: "Deleted", LastName: "agent", Username: 'Deleted', Image: "#" }
      }
    }
    let curRoom = chatRoomSelector.find(cr => cr.ID === roomID);
    if (!curRoom) return returnNull();

    let foundUser = chatUsersSelector.filter(cu => curRoom.Participants.includes(cu.ID));

    if (!foundUser) return returnNull();

    foundUser = {
      Agent: foundUser.find(f => f.Internal),
      Client: foundUser.find(f => !f.Internal)
    };
    return { ...foundUser, roomName: curRoom.ChatRoomName };
  }

  // const getData = () => {
  //   axios({
  //     method: "POST",
  //     url: `${backendModule.backendURL}/chatRooms/getAllChatRooms`,
  //     data: {
  //       filters
  //     },
  //     ...backendModule.axiosConfig
  //   }).then(res => {
  //     setData(res.data)
  //   }).catch(() => {
  //     return { status: "error", data: "SERVER_ERROR" };
  //   });
  // }
  // const getMoreData = (filters) => {
  //   axios({
  //     method: "POST",
  //     url: `${backendModule.backendURL}/chatRooms/getAllChatRooms`,
  //     data: {
  //       filters
  //     },
  //     ...backendModule.axiosConfig
  //   }).then(res => {
  //     let newData = res.data.data;
  //     setData({status: 'ok', data: [...data.data, ...newData]})
  //   }).catch(() => {
  //     return { status: "error", data: "SERVER_ERROR" };
  //   });
  // }

  const [search, setSearch] = React.useState("");


  const removeChatRoom = () => {

    chatsToDelete.map(item => {
      axios({
        method: "POST",
        url: `${backendModule.backendURL}/chatRooms/removeChatRoom`,
        data: {
          chatRoomID: item
        },
        ...backendModule.axiosConfig
      }).then(res => {
        setData(res.data)
      }).catch(() => {
        return { status: "error", data: "SERVER_ERROR" };
      });
    })
    
  }

  const addChatToDelete = (chatRoomID) => {
    
    if(chatsToDelete.length){
      let wantedItem = chatsToDelete.find(elem => elem === chatRoomID);
      if(wantedItem){
        let temp = chatsToDelete.filter((item) => item !== chatRoomID);
        setChatsToDelete(temp)
      }else{
        setChatsToDelete([...chatsToDelete, chatRoomID])
      }
    }else{
      setChatsToDelete([...chatsToDelete, chatRoomID])
    }
  }

  const filteredChatRooms = (chatRoomSelector || []).filter(cr => {
    if (!search) return true;
    if (
      String(cr.ID).includes(search.toLowerCase()) ||
      String(cr.ChatRoomName).toLowerCase().includes(search.toLowerCase())
    ) {
      return true;
    } else {
      for (let usr of cr.Participants) {
        let user = chatUsersSelector.find(u => u.ID === usr);
        if (user) {
          if (`${user.FirstName} ${user.LastName}`.toLowerCase().includes(search.toLowerCase())) {
            return true;
          }
        }
      }
    }
    return false;
  });

  const currentChatRooms = filteredChatRooms;

  React.useEffect(() => {
    if(!canPaginate) return;
    setSpinner(true);
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setCanPaginate(false);
          let handler = (data) => {
            if(data?.length === 10) setCanPaginate(true);
            if(data?.length > 0) {
              socketModule.getSocket().emit("getAllChats", {
                limit: 10,
                offset: 0,
                ChatRoomsID: data.map(el => el.ID)
              })
            }
            socketModule.getSocket().off("getAllChatRooms", handler);
          };
          socketModule.getSocket().on("getAllChatRooms", handler);
          socketModule.getSocket().emit("getAllChatRooms", {limit: 10, offset: offset});
          setOffset(prevOffset => prevOffset + itemsPerPage);
          if (paragraphRef.current) {
            setSpinner(false)
            observer.unobserve(paragraphRef.current);
          }
        }
      });
    }, { threshold: 1.0 });

    if (paragraphRef.current) {
      observer.observe(paragraphRef.current);
    }

    return () => {
      if (paragraphRef.current) {
        setSpinner(false)
        observer.unobserve(paragraphRef.current);
      }
    };
  }, [canPaginate, paragraphRef.current, offset]);

  React.useEffect(() => {
    if(chatRoomSelector?.length === 10) setCanPaginate(true)
  }, [chatRoomSelector])

  return (
    <div style={{ display: props.sidebar ? 'block' : 'none' }} className='component__chatSidebar'>
      {/* <p style={{color: 'red', marginBottom: '10px'}} onClick={() => removeChatRoom()}>DELETE SELECTED CHATS</p> */}
      <div className='component__chatSidebar__newChatButton' onClick={(e) => animateBox(e, <NewChatModal setInternalID={props.setInternalID} setUserID={props.setUserID} />)}>Start new chat</div>
      <div className='component__chatSidebar__searchbar'>
        <input value={search} onInput={e => setSearch(e.currentTarget.value)} type='text' placeholder='Find coversation' />
        <img src='images/search.png' alt='' />
      </div>
      {currentChatRooms.map((item) => {
          let client = getClient(item.ID);
          return <div className='component__chatSidebar__chatRoom' style={{ background: props.activeChat?.ChatRoomID === item.ID ? '#192343' : '', borderBottom: props.activeChat?.ChatRoomID === item.ID ? '1px solid white' : '' }} onClick={() => {
            if (window.innerWidth <= 1100) {
              props.setSidebar(false);
            }
            socketModule.internal__changeModerationEnabled(props.activeChat?.ChatRoomID, true);
            props.setActiveChat({
              ClientName: `${client.Client.FirstName} ${client.Client.LastName}`,
              ClientImage: client.Client.Image,
              ClientID: client.Client.ID,
              ChatRoomID: item.ID,
              AgentID: client.Agent.ID,
              AgentName: `${client.Agent.FirstName} ${client.Agent.LastName}`,
              AgentImage: client.Agent.Image,
              ModerationEnabled: item.ModerationEnabled
            })
          }}>
            <img id='userPhoto' src={`${backendModule.backendURL}/StaticContent/Images/${client?.Client?.Image}`} alt='' />
            <div className='component__chatSidebar__chatRoom__right'>
              <div id='info'>
                <p>{client?.Client?.roomName ? client?.Client?.roomName : `${client?.Client?.FirstName ? client?.Client?.FirstName : 'Deleted'} ${client?.Client?.LastName ? client?.Client?.LastName : 'user'}`}</p>
                <span>
                  <div id='dot' style={{ width: '8px', height: '8px', borderRadius: '50%', background: '#01B753' }}></div>
                  <small>{client?.Agent?.FirstName ? client?.Agent?.FirstName : 'Deleted'} {client?.Agent?.LastName ? client?.Agent?.LastName : 'lady'}</small>
                </span>
              </div>
              <div style={{ display: item?.UnreadCount > 0 ? '' : 'none' }} id='notifications'>
                <div id='numdot'>{item?.UnreadCount}</div>
                <img src='images/emailIcon.png' alt='' />
              </div>
            </div>
          </div>
        })
      }
      {spinner && <Spinner style={{margin: '3px auto'}} />}
      <div ref={paragraphRef} style={{color: 'white'}}></div>
      {/* {data?.data.length &&
        data?.data.filter(cr => {
          if (!search) return true;
          if (
            String(cr.ID).includes(search.toLowerCase()) ||
            String(cr.ChatRoomName).toLowerCase().includes(search.toLowerCase())
          ) {
            return true;
          } else {
            for (let usr of JSON.parse(cr.Participants)) {
              usr = usr.replace('user:', '')
              let user = chatUsersSelector.find(u => u.ID === usr && u.Internal === false);
              if (user) {
                if (`${user.FirstName} ${user.LastName} ${user.Username}`.toLowerCase().includes(search.toLowerCase())) {
                  return true;
                };
              };
            };
            return false;
          };
        }).map((item) => {
          if(!item) return;
          let client = getClient(item.ID);
          // let chatCreator = client[Object.keys(client).find(key => client[key].ID === item.CreatedBy)];
          return <div className='component__chatSidebar__chatRoom' style={{ background: props.activeChat?.ChatRoomID === item.ID ? '#192343' : '', borderBottom: props.activeChat?.ChatRoomID === item.ID ? '1px solid white' : '' }} onClick={() => {
            if (window.innerWidth <= 1100) {
              props.setSidebar(false);
            }
            socketModule.internal__changeModerationEnabled(props.activeChat?.ChatRoomID, true);
            props.setActiveChat({
              ClientUsername: client.Client.Username ? client.Client.Username : '',
              ClientName: `${client.Client.FirstName ? client.Client.FirstName : 'noName'} ${client.Client.LastName ? client.Client.LastName : 'no'}`,
              ClientImage: client.Client.Image,
              ClientID: client.Client.ID,
              ChatRoomID: item.ID,
              AgentID: client.Agent.ID,
              AgentName: `${client.Agent.FirstName} ${client.Agent.LastName}`,
              AgentImage: client.Agent.Image,
              ModerationEnabled: item.ModerationEnabled
            })
          }}>
            <img id='userPhoto' src={`${backendModule.backendURL}/StaticContent/Images/${client?.Client?.Image}`} alt='' />
            <div className='component__chatSidebar__chatRoom__right'>
              <div id='info'>
                <p>{client?.Client?.roomName ? client?.Client?.roomName : `${client?.Client?.Username ? client?.Client?.Username : 'Deleted'}`}
                <span style={{marginLeft: 'auto', color: 'red', maxWidth: '60px'}} onClick={() => addChatToDelete(item.ID)}>DELETE</span></p>
                <span>
                  <div id='dot' style={{ width: '8px', height: '8px', borderRadius: '50%', background: '#01B753' }}></div>
                  <small>{client?.Agent?.FirstName ? client?.Agent?.FirstName : 'Deleted'} {client?.Agent?.LastName ? client?.Agent?.LastName : 'lady'}</small>
                </span>
              </div>
              <div style={{ display: item?.UnreadCount > 0 ? '' : 'none' }} id='notifications'>
                <div id='numdot'>{item?.UnreadCount}</div>
                <img src='images/emailIcon.png' alt='' />
              </div>
            </div>
          </div>
        })
      }   */}
      
    </div>
  )
}





const NewChatModal = (props) => {
  const [clients, setClients] = React.useState([]);
  const [clientsFiltered, setClientsFiltered] = React.useState([]);
  const [canPaginate, setCanPaginate] = React.useState(false);
  const [filters, setFilters] = React.useState([]);
  const scrollTriggerRef = React.useRef(null);
  const [loadingData, setLoadingData] = React.useState(false);
  const [spinner, setSpinner] = React.useState(false);
  const searchRef = React.useRef(null);

  const paginationData = (isInViewPort) => {
    if (isInViewPort && canPaginate) {
      axios({
        method: "POST",
        url: `${backendModule.backendURL}/users/getAllSecondaryUsers`,
        data: {
          offset: clients.data.length,
          filters: [
            ...filters,
          ]
        },
        ...backendModule.axiosConfig
      }).then(res => {
        if (res.data.status === "ok") {
          if (res.data.data.length > 0) {
            setLoadingData(true);
            setTimeout(() => setLoadingData(false), 100);
            res.data.data?.map((item) => {
              clients.data.push(item);
            });
            setCanPaginate(false);
            setTimeout(() => {
              setCanPaginate(true);
            }, 1000);
          }
        };
      }).catch(() => {
        setClients({ status: 'error', data: 'Data fetch failed' });
      }).finally(() => {
        setSpinner(false);
      });
    }
  }

  const useIsInViewport = () => {
    const [isIntersecting, setIsIntersecting] = React.useState(false);

    const observer = React.useMemo(
      () =>
        new IntersectionObserver(([entry]) =>
          setIsIntersecting(entry.isIntersecting),
        ),
      [],
    );

    try{
      React.useEffect(() => {
        observer.observe(scrollTriggerRef.current);
        return () => {
          observer.disconnect();
        };
      }, [scrollTriggerRef, observer]);
    }catch{

    }
    

    if (isIntersecting) {
      paginationData(isIntersecting);
    }
  }

  useIsInViewport();

  try{
    React.useEffect(() => {
      axios({
        method: "POST",
        url: `${backendModule.backendURL}/users/getAllSecondaryUsers`,
        data: {
          offset: 0,
          limit: 5000,
          filters: filters,
        },
        ...backendModule.axiosConfig
      }).then(res => {
        if (res.data.status === 'ok') {
          setClients(res.data);
          if (res.data.data.length === 20) {
            setTimeout(() => setCanPaginate(true), 1000);
          }
        }
        else {

        }
      }).catch(() => {

      });
    }, []);
  }catch{

  }
  
  try{
    React.useEffect(() => {
      if (clients) {
        setClientsFiltered(clients.data?.filter(cl => !cl.Username.startsWith('--internal')));
      }
    }, [clients]);
  }catch{

  }
  

  let curTimeout = null;

  try{
    React.useEffect(() => {
      if (!searchRef.current) return;
      let handler = () => {
        let searchVal = searchRef?.current?.value;
        clearTimeout(curTimeout);
        curTimeout = setTimeout(() => {
          axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/getAllSecondaryUsers`,
            data: {
              offset: 0,
              limit: 5000,
              filters: [
                ...filters,
                { name: '--searcher', value: searchVal }
              ]
            },
            ...backendModule.axiosConfig
          }).then(res => {
            if (res.data.status === "ok") {
              setClients(res.data);
            };
          }).catch(() => {

          });
        }, 500);
      };

      searchRef.current.addEventListener("input", handler);

      return () => {
        try {
          searchRef.current.removeEventListener("input", handler);
        } catch { };
      };
    }, [searchRef.current]);
  }catch{

  }
  

  return <div className='component__newChatModal'>
    <div className='component__newChatModal__body'>
      <img onClick={props.onClose} className='component__newChatModal__body__close' src='images/close.png' alt='' />
      <div className='component__newChatModal__body__search'><input ref={searchRef} type='text' placeholder='Search...' /></div>
      {
        clientsFiltered?.map((item) => {
          return <div className='component__newChatModal__body__block' onClick={(e) => animateBox(e, <InternalUsersModal
            setUserID={props.setUserID}
            setInternalID={props.setInternalID}
            userID={item.ID}
            user={item}
            domainID={item.DomainID}
            closeFirst={props.onClose}
          />)}>
            <img src={`${backendModule.backendURL}/staticContent/images/${item.Image}`} alt='' />
            <p>{item.Name}</p>
          </div>
        })
      }
      <div style={{ display: canPaginate ? 'block' : 'none' }} ref={scrollTriggerRef}></div>
      <Spinner style={{ display: loadingData ? 'block' : 'none', width: "64px", height: "64px" }} color="white" align="center" />
    </div>
  </div>
}


const InternalUsersModal = (props) => {
  const [internalUsers, setInternalUsers] = React.useState([]);
  const [canPaginate, setCanPaginate] = React.useState(false);
  const [filters, setFilters] = React.useState([]);
  const scrollTriggerRef = React.useRef(null);
  const [loadingData, setLoadingData] = React.useState(false);
  const [spinner, setSpinner] = React.useState(false);
  const searchRef = React.useRef(null);

  let curTimeout = null;

  try{
    React.useEffect(() => {
      if (!searchRef.current) return;
      let handler = () => {
        let searchVal = searchRef?.current?.value;
        clearTimeout(curTimeout);
        curTimeout = setTimeout(() => {
          axios({
            method: "POST",
            url: `${backendModule.backendURL}/ladies/getAllLadies`,
            data: {
              offset: 0,
              limit: 5000,
              filters: [
                ...filters,
                { name: '--searcher', value: searchVal },
                { name: 'DomainID', op: 'eq', value: props.domainID }
              ]
            },
            ...backendModule.axiosConfig
          }).then(res => {
            if (res.data.status === "ok") {
              setInternalUsers(res.data);
              setCanPaginate(false);
            };
          }).catch(() => {

          }).finally(() => {
            if (searchRef.current.value === '') {
              setCanPaginate(true);
            }
          });
        }, 500);
      };

      searchRef.current.addEventListener("input", handler);

      return () => {
        try {
          searchRef.current.removeEventListener("input", handler);
        } catch { };
      };
    }, [searchRef.current]);
  }catch{

  }
  

  const createChatRoom = (userID, internalID, internalUser) => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/users/getAllSecondaryUsers`,
      data: {
        limit: 5000,
        filters: [
          ...filters,
          { name: 'Username', op: 'like', value: `--internal-${internalID}` }
        ]
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === 'ok') {
        props.setUserID({ ID: userID, user: props.user });
        props.setInternalID({ ID: internalID, secondaryID: res.data.data[0].ID, user: internalUser });
        props.onClose();
        props.closeFirst();
      }
    })
  }

  const paginationData = (isInViewPort) => {
    if (isInViewPort && canPaginate) {
      axios({
        method: "POST",
        url: `${backendModule.backendURL}/ladies/getAllLadies`,
        data: {
          offset: internalUsers.data.length,
          filters: [
            ...filters,
            { name: 'DomainID', op: 'eq', value: props.domainID }
          ]
        },
        ...backendModule.axiosConfig
      }).then(res => {
        if (res.data.status === "ok") {
          if (res.data.data.length > 0) {
            setLoadingData(true);
            setTimeout(() => setLoadingData(false), 100);
            res.data.data?.map((item) => {
              internalUsers.data.push(item);
            });
            setCanPaginate(false);
            setTimeout(() => {
              setCanPaginate(true);
            }, 1000);
          }
        };
      }).catch(() => {
        setInternalUsers({ status: 'error', data: 'Data fetch failed' });
      }).finally(() => {
        setSpinner(false);
      });
    }
  }


  const getAllLadies = () => {
    setCanPaginate(false);
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/ladies/getAllLadies2`,
      data: {
        offset: 0,
        filters: [
          ...filters,
          { name: 'DomainID', op: 'eq', value: props.domainID }
        ]
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === 'ok') {
        setInternalUsers(res.data);
        if (res.data.data.length === 20) {
          setTimeout(() => setCanPaginate(true), 1000);
        }
      }
      else {
        setInternalUsers('GET_ALL_USERS_ERROR');
      }
    }).catch(() => {
      setInternalUsers('GET_ALL_USERS_ERROR');
    });
  }

  try{
    React.useEffect(() => {
      getAllLadies();
    }, [])
  }catch{

  }
  

  return <div className='component__internalUsersModal'>
    <div className='component__internalUsersModal__body'>
      <img onClick={props.onClose} className='component__internalUsersModal__body__close' src='images/close.png' alt='' />
      <div className='component__newChatModal__body__search'><input ref={searchRef} type='text' placeholder='Search...' /></div>
      {
        internalUsers?.data?.map((item) => {
          return <div className='component__internalUsersModal__body__block' onClick={() => createChatRoom(props.userID, item.ID, item)} >
            <img src={item.Images[0]} alt='' />
            <p>{item.FirstName} {item.LastName}</p>
          </div>
        })
      }
      <div style={{ display: canPaginate ? 'block' : 'none' }} ref={scrollTriggerRef}></div>
      <Spinner style={{ display: loadingData ? 'block' : 'none', width: "64px", height: "64px" }} color="white" align="center" />
    </div>
  </div>
}
