import { HashRouter as Router, Routes, Route } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import Header from "./components/Header";
import ContentWrapper from "./components/ContentWrapper";
import Spinner from "./components/customComponents/Spinner";
import ViewImage from "./routes/ViewImage";

import Login from "./routes/Login";
import Sidebar from "./components/Sidebar";
// import Dashboard from "./routes/Dashboard"; // old one
import Dashboard from "./routes/Dashboard-v2";
import GeneralStatistics from "./routes/GeneralStatistics";
import Analitycs from "./routes/Analitycs";
import EmailAnalitycs from "./routes/EmailAnalitycs";
import Ladies from "./routes/Ladies";
import Chat from "./routes/Chat";
import ChatStatistics from "./routes/ChatStatistics";
import PaymentPageStats from "./routes/PaymentPageStats";
import Users from "./routes/Users";
import Domains from "./routes/Domains";
import EmailServers from "./routes/EmailServers";
import Packages from "./routes/Packages";
import SecondaryUsers from "./routes/SecondaryUsers";
import Gifts from "./routes/Gifts";
import GiftCards from "./routes/GiftCards";
import Tools from "./routes/Tools";
import Banners from "./routes/Banners";
import Boosts from "./routes/Boosts";
import Stickers from "./routes/Stickers";
import UsersSurveys from "./routes/UsersSurveys";
import SecondaryUsersEndpoints from "./routes/SecondaryUserEndpoints";
import DeletionSurveys from "./routes/DeletionSurveys";
import DeletedUsers from "./routes/DeletedUsers";
import StickerCategories from "./routes/StickerCategories";
import Badges from "./routes/Badges";
import BuildABitch from "./routes/BuildABitch";
import CronJobs from "./routes/CronJobs";
import TransactionHistory from "./routes/TransactionHistory";
import Stream from "./routes/Stream";
import Terces from "./routes/Terces";

import * as backendModule from "../src/modules/backendModule";
import * as socketModule from "./modules/socketModule";
import * as userActions from "./actions/userActions";
import * as serverStatusActions from "./actions/serverStatusActions";
import axios from "axios";
import React from "react";

const App = () => {
  const [activeTab, setActiveTab] = React.useState('dashboard');
  const [newUserModal, setNewUserModal] = React.useState(false);
  const [newDomainModal, setNewDomainModal] = React.useState(false);
  const [newLadieModal, setNewLadieModal] = React.useState(false);
  const [emailServers, setEmailServers] = React.useState([]);
  const [packages, setPackages] = React.useState([]);
  const [gifts, setGifts] = React.useState([]);
  const [giftCards, setGiftCards] = React.useState([]);
  const [banners, setBanners] = React.useState([]);
  const [boosts, setBoosts] = React.useState([]);
  const [stickers, setStickers] = React.useState([]);
  const [stickerCategories, setStickerCategories] = React.useState([]);
  const [badges, setBadges] = React.useState([]);
  const [navbar, setNavbar] = React.useState(false);

  const mainDispatch = useDispatch();
  const userSelector = useSelector(state => state?.user?.UserInfo?.ID ?? null);
  const userInfoSelector = useSelector(state => state?.user?.UserInfo ?? null);
  const curUserInfoSelector = useSelector(state => state?.user ?? null);
  const serverStatusSelector = useSelector(state => state.serverStatus?.status);
  const timestampSelector = useSelector(state => state.timestamp);

  const [firstTime, setFirstTime] = React.useState(true);


  const checkLogin = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/auth/checkLogin`,
      ...backendModule.axiosConfig
    }).then(res => {
      mainDispatch(serverStatusActions.setServerStatus(true));
      if (res.data.status === "ok") {
        if (res.data.data.AccountType === 'main-user') {
          mainDispatch(userActions.updateUser(res.data.data));
        }
        else {
          mainDispatch(userActions.updateUser(null));
        }
      }
      else {
        mainDispatch(userActions.updateUser(null));
      }
    }).catch(() => {
      mainDispatch(serverStatusActions.setServerStatus(false));
    }).finally(() => {
      setFirstTime(false);
    });
  };

  React.useEffect(() => {
    const intervalLength = serverStatusSelector ? 10000 : 2000;

    checkLogin();
    let interval = setInterval(checkLogin, intervalLength);

    return () => clearInterval(interval);
  }, [timestampSelector, userSelector, serverStatusSelector]);

  React.useEffect(() => {
    if (userSelector) {
      socketModule.init();
    } else {
      socketModule.disconnect();
    };
  }, [userSelector]);

  React.useEffect(() => {
    switch(window.location.hash){
      case '#/dashboard':
        setActiveTab('dashboard');
      break;
      case '#/generalstatistics':
        setActiveTab('generalstatistics');
      break;
      case '#/analitycs':
        setActiveTab('analitycs');
      break;
      case '#/emailanalityc':
        setActiveTab('emailanalityc');
      break;
      case '#/ladies':
        setActiveTab('ladies');
      break;
      case '#/chat':
        setActiveTab('chat');
      break;
      case '#/users':
        setActiveTab('users');
      break;
      case '#/domains':
        setActiveTab('domains');
      break;
      case '#/emailservers':
        setActiveTab('emailservers');
      break;
      case '#/packages':
        setActiveTab('packages');
      break;
      case '#/secondary':
        setActiveTab('secondary');
      break;
      case '#/gifts':
        setActiveTab('gifts');
      break;
      case '#/giftcards':
        setActiveTab('giftcards');
      break;
      case '#/tools':
        setActiveTab('tools');
      break;
      case '#/banners':
        setActiveTab('banners');
      break;
      case '#/boosts':
        setActiveTab('boosts');
      break;
      case '#/stickers':
        setActiveTab('stickers');
      break;
      case '#/regSurveys':
        setActiveTab('regSurveys');
      break;
      case '#/delSurveys':
        setActiveTab('delSurveys');
      break;
      case '#/stickerCategories':
        setActiveTab('stickerCategories');
      break;
      case '#/badges':
        setActiveTab('badges');
      break;
      case '#/transactionHistory':
        setActiveTab('transactionHistory');
      break;
      case '#/buildABitch':
        setActiveTab('buildABitch');
      case '#/secondaryUsersEndpoints':
        setActiveTab('secondaryUsersEndpoints');
      break;
    }
  }, []);


  if (firstTime) return <Spinner style={{ width: "64px", height: "64px" }} color="#3F73FF" align="center" />

  return <Router>
    <Header
      logged={userSelector}
      loggedUser={curUserInfoSelector}
      setNewUserModal={setNewUserModal}
      setNewDomainModal={setNewDomainModal}
      setNewLadieModal={setNewLadieModal}
      setEmailServers={setEmailServers}
      setPackages={setPackages}
      navbar={navbar}
      setNavbar={setNavbar}
      setGifts={setGifts}
      setGiftCards={setGiftCards}
      setBanners={setBanners}
      setBoosts={setBoosts}
      setStickers={setStickers}
      setStickerCategories={setStickerCategories}
      setBadges={setBadges}
      activeTab={activeTab}
    />
    {
      userSelector ? <>
        <ContentWrapper>
          <Sidebar
            navbar={navbar}
            setNavbar={setNavbar}
            logged={userSelector}
            loggedUser={curUserInfoSelector}
            setActiveTab={setActiveTab}
            activeTab={activeTab}
          />
          <div className="route__wrapper">
            <Routes>
              <Route path="/" element={
                userInfoSelector.Flags.isMessageAgent ?
                  <Chat /> :
                  userInfoSelector.Flags.isStreamer ?
                  <></> :
                  <Dashboard />
              } />
              <Route path="/image/:src" element={<ViewImage />} />
              {userInfoSelector.Flags.isAdmin && <Route path="/analitycs" element={<Analitycs />} />}
              {userInfoSelector.Flags.isAdmin && <Route path="/generalstatistics" element={<GeneralStatistics />} />}
              {userInfoSelector.Flags.isAdmin && <Route path="/emailanalityc" element={<EmailAnalitycs />} />}
              {userInfoSelector.Flags.isAdmin && <Route path="/ladies" element={<Ladies newLadieModal={newLadieModal} setNewLadieModal={setNewLadieModal} />} />}
              <Route path="/chat" element={<Chat />} />
              <Route path="/chStatistics" element={<ChatStatistics />} />
              <Route path="/paymentPageStats" element={<PaymentPageStats />} />
              {userInfoSelector.Flags.isAdmin && <Route path="/users" element={<Users loggedUser={userInfoSelector} newUserModal={newUserModal} setNewUserModal={setNewUserModal} />} />}
              {userInfoSelector.Flags.isAdmin && <Route path="/domains" element={<Domains newDomainModal={newDomainModal} setNewDomainModal={setNewDomainModal} />} />}
              {userInfoSelector.Flags.isAdmin && <Route path="/emailservers" element={<EmailServers setEmailServers={setEmailServers} emailServers={emailServers} />} />}
              {userInfoSelector.Flags.isAdmin && <Route path="/packages" element={<Packages setPackages={setPackages} packages={packages} />} />}
              {userInfoSelector.Flags.isAdmin && <Route path="/secondary" element={<SecondaryUsers />} />}
              {userInfoSelector.Flags.isAdmin && <Route path="/gifts" element={<Gifts gifts={gifts} setGifts={setGifts} />} />}
              {userInfoSelector.Flags.isAdmin && <Route path="/giftcards" element={<GiftCards giftCards={giftCards} setGiftCards={setGiftCards} />} />}
              {userInfoSelector.Flags.isAdmin && <Route path="/tools" element={<Tools />} />}
              {userInfoSelector.Flags.isAdmin && <Route path="/banners" element={<Banners banners={banners} setBanners={setBanners}/>} />}
              {userInfoSelector.Flags.isAdmin && <Route path="/boosts" element={<Boosts boosts={boosts} setBoosts={setBoosts}/>} />}
              {userInfoSelector.Flags.isAdmin && <Route path="/stickers" element={<Stickers stickers={stickers} setStickers={setStickers}  stickerCategories={stickerCategories} setStickerCategories={setStickerCategories}/>} />}
              {userInfoSelector.Flags.isAdmin && <Route path="/regSurveys" element={<UsersSurveys />} />}
              {userInfoSelector.Flags.isAdmin && <Route path="/delSurveys" element={<DeletionSurveys />} />}
              {userInfoSelector.Flags.isAdmin && <Route path="/delUsers" element={<DeletedUsers />} />}
              {userInfoSelector.Flags.isAdmin && <Route path="/stickerCategories" element={<StickerCategories stickerCategories={stickerCategories} setStickerCategories={setStickerCategories}/>} />}
              {userInfoSelector.Flags.isAdmin && <Route path="/badges" element={<Badges badges={badges} setBadges={setBadges}/>} />}
              {userInfoSelector.Flags.isAdmin && <Route path="/transactionHistory" element={<TransactionHistory />} />}
              {userInfoSelector.Flags.isAdmin && <Route path="/buildABitch" element={<BuildABitch />} />}
              {userInfoSelector.Flags.isAdmin && <Route path="/secondaryUserEndpoints" element={<SecondaryUsersEndpoints />} />}
              {userInfoSelector.Flags.isAdmin && <Route path="/terces" element={<Terces />} />}
              {userInfoSelector.Flags.isAdmin && <Route path="/cronJobs" element={<CronJobs />} />}
              {userInfoSelector.Flags.isStreamer && <Route path="/streams" element={<Stream />} />}
            </Routes>
          </div>
        </ContentWrapper>
      </> : <>
          <Routes>
            <Route path="*" element={<Login />} />
          </Routes>
      </>
    }
  </Router>
};

export default App;
