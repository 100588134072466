import React from "react";
import "./index.scss";

import axios from "axios";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import * as backendModule from "../../modules/backendModule";
import * as timestmapActions from "../../actions/timestampAction";

import useDefer from "../../modules/hooks/useDefer";
import useOnScreen from "../../modules/hooks/useOnScreen";

import Button from "../../components/customComponents/Button";
import CustomInput from "../../components/customComponents/CustomInput";
import { FilteredCustomTable } from "../../components/customComponents/Table";
import Dropdown from "../../components/customComponents/Dropdown";
import Spinner from "../../components/customComponents/Spinner";
import DataSorter from "../../components/customComponents/DataSorter";
import { animateBox } from "../../modules/componentAnimation";

const CronJobs = () => {
  const [data, setData] = React.useState();
  const [filters, setFilters] = React.useState([]);
  const [orders, setOrders] = React.useState([]);

  const getData = (ts) => {

    axios({
      method: "POST",
      url: `${backendModule.backendURL}/crons/getAllTasks`,
      data: {
        filters,
        orders
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        setData(res.data);
      };
    }).catch(() => {
      setData({ status: "error", data: "SERVER_ERROR" });
    });
  };

  const executeTask = (name) => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/crons/executeSpecificTask`,
      data: {
        taskName: name,
        filters,
        orders
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        setData(res.data);
      };
    }).catch(() => {
      setData({ status: "error", data: "SERVER_ERROR" });
    });
  }

  React.useEffect(() => {
    getData();
  }, [filters, orders]);

  return <div className="route__crons">
    <DataSorter sorters={[
      { name: 'Name', value: 'Name' },
      { name: 'ExecDate', value: 'ExecDate' },
      { name: 'Updated at', value: 'updatedAt' }
    ]} setOrders={setOrders} />
    <FilteredCustomTable
      filterCB={c => setFilters(c)}
      id='table'
      accent='#000'
      dropdownBackground='#000'
      inputBottomBorderColor='#FFF'
      placeholderStyle={{ backgroundColor: '#000' }}
      theme='dark'
      filterHeadStyle={{ backgroundColor: 'rgba(25, 35, 67, 0.2)' }}
      tableHeadStyle={{ backgroundColor: 'rgba(25, 35, 67, 0.2)' }}
      buttonStyle={{ border: '1px solid #FFF', backgroundColor: '#000' }}
      style={{ colors: '#000' }}
      color={'#000'}
      filters={[
        { name: "ID", friendlyName: "ID", type: "string" },
        { name: "Name", friendlyName: "Name", type: "string" },
        { name: "updatedAt", friendlyName: "Updated", type: "date" },
        { name: "ExecDate", friendlyName: "ExecDate", type: "date" },
      ]}
      headers={["ID", "Name", "ExecDate", "updatedAt"]}
      data={(() => {
        let out = [];

        if (!data) return [[{ keyID: "noData-spinner", type: "spinner" }]];
        if (data.status === "error") return [[{ keyID: "noData-error", type: "text", text: "Error while fetching data" }]];

        for (let item of data.data) {
          out.push([
            { keyID: item.ID, type: "text", text: item.ID },
            { keyID: item.ID, type: "text", text: item.Name },
            { keyID: item.ID, type: "text", text: moment(item.ExecDate).format('DD.MM.YYYY - HH:mm') },
            { keyID: item.ID, type: "text", text: moment(item.updatedAt).format('DD.MM.YYYY - HH:mm') },
            {
              keyID: String(item.ID), type: "groupNewline", group: [
                {
                  keyID: String(item.ID), type: "button", text: "Execute Task", triggerDropdown: true, triggerData: e => {
                    return (<div style={{ display: 'flex', flexDirection: 'column', justfyContent: 'center', alignItems: 'center' }}>
                      <p style={{ color: 'white' }}>Are you sure?</p>
                      <br></br>
                      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Button style={{ marginRight: '10px' }} accent='rgb(72, 82, 92)' value='YES' onClick={() => executeTask(item.Name)} />
                        <Button accent='rgb(72, 82, 92)' value='NO' onClick={c => { e() }} />
                      </div>
                    </div>);
                  },
                },
              ]
            }
          ])
        };
        if (out.length === 0) out.push([{ keyID: "noData-noData", type: "text", text: "Nothing to show..." }]);

        return out;
      })()}
    />
  </div>
};

export default CronJobs;