import React from 'react';
import "./index.scss";

import CustomInput from '../../components/customComponents/CustomInput';
import Button from '../../components/customComponents/Button';
import { packagesColors } from '../../packesColors';
import Spinner from '../../components/customComponents/Spinner';
import { FilteredCustomTable } from "../../components/customComponents/Table";
import DataSorter from "../../components/customComponents/DataSorter";
import Dropdown from '../../components/customComponents/Dropdown';

import * as backendModule from "../../modules/backendModule";
import axios, { all } from "axios";
import moment from 'moment';

import { animateBox } from "../../modules/componentAnimation";

export default function Packages(props) {
  const [filters, setFilters] = React.useState([]);
  const [orders, setOrders] = React.useState([]);
  const [spinner, setSpinner] = React.useState(false);

  const getAllPackages = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/packages/getAllPackages`,
      data: {
        filters: filters,
        orders: orders
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === 'ok') {
        props.setPackages(res.data);
      }
      else {

      }
    }).catch(() => {

    });
  }

  React.useEffect(() => {
    getAllPackages();
  }, [filters, orders]);

  const deletePackage = (id) => {
    setSpinner(true);
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/packages/removePackage`,
      data: {
        ID: id
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === 'ok') {
        getAllPackages();
      }
      else {

      }
    }).catch(() => {

    }).finally(() => {
      setSpinner(false);
    });
  }

  const addPackagesToTable = () => {
    if (props.packages.data) {
      return props.packages?.data?.map((item) => {
        return [
          { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.ID}</p></div> },
          { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Name}</p></div> },
          { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Credits}</p></div> },
          { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Price}</p></div> },
          { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.PackageType}</p></div> },
          { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.DomainID}</p></div> },
          { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{moment(item.updatedAt).format('DD.MM.YYYY.')}</p></div> },
          {
            keyID: String(item.ID), type: "groupNewline", group: [
              {
                keyID: String(item.ID), type: "button", text: "Delete", triggerDropdown: true, triggerData: e => {
                  return (<div style={{ display: 'flex', flexDirection: 'column', justfyContent: 'center', alignItems: 'center' }}>
                    <p style={{ color: 'white' }}>Are you sure?</p>
                    <br></br>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Button style={{ display: spinner ? 'none' : 'block', marginRight: '10px' }} accent='rgb(72, 82, 92)' value='YES' onClick={() => deletePackage(item.ID)} />
                      <Button style={{ display: spinner ? 'none' : 'block' }} accent='rgb(72, 82, 92)' value='NO' onClick={c => { e() }} />
                      <div style={{ display: spinner ? 'block' : 'none' }}><Spinner color='white' width='32px' /></div>
                    </div>
                  </div>);
                },
              },
              {
                keyID: String(item.ID), type: "button", text: "Edit", triggerDropdown: true, onClick: e => {
                  animateBox(e, <EditModal curPackage={
                    {
                      ID: item.ID,
                      Name: item.Name,
                      Credits: item.Credits,
                      Price: item.Price,
                      DomainID: item.DomainID,
                      PackageType: item.PackageType,
                      Message: item.Message,
                      IsVisible: item.IsVisible,
                      Duration: item.Duration
                    }
                  } />)
                }
              },
              {
                keyID: String(item.ID), type: "button", text: "More details", triggerDropdown: true, onClick: e => {
                  animateBox(e, <MoreDetailsModal currPackage={
                    {
                      ID: item.ID,
                      DomainID: item.DomainID,
                      Credits: item.Credits,
                      Name: item.Name,
                      Message: item.Message,
                      PackageType: item.PackageType,
                      Price: item.Price,
                      IsVisible: item.IsVisible,
                      Duration: item.Duration,
                      BoughtTimes: item.BoughtTimes
                    }
                  } />)
                }
              },
            ]
          }
        ];
      });
    }
  }

  const EditModal = (props) => {
    const newNameRef = React.useRef(null);
    const newCreditsRef = React.useRef(null);
    const newPriceRef = React.useRef(null);
    const isVisibleRef = React.useRef(null);
    const messageRef = React.useRef(null);
    const [newSelectedDomain, setNewSelectedDomain] = React.useState(null);
    const msgRef = React.useRef(null);
    const [packageType, setPackageType] = React.useState(null);
    const durationRef = React.useRef();

    const openDomainsModal = (e, isEdit, setNewSelectedDomain) => {
      animateBox(e, <SelectDomainModal isEdit={isEdit} setNewSelectedDomain={setNewSelectedDomain} />);
    }

    const SelectDomainModal = (props) => {
      const [filters, setFilters] = React.useState(false);
      const [domains, setDomains] = React.useState(false);

      const getAllDomains = () => {
        axios({
          method: "POST",
          url: `${backendModule.backendURL}/domains/getAllDomains`,
          data: {
            filters: filters
          },
          ...backendModule.axiosConfig
        }).then(res => {
          if (res.data.status === 'ok') {
            setDomains(res.data);
          }
          else {
            setDomains('GET_ALL_DOMAINS_ERROR');
          }
        }).catch(() => {
          setDomains('GET_ALL_DOMAINS_ERROR');
        });
      }

      React.useEffect(() => {
        getAllDomains();
      }, [filters]);

      const addDomainsToTable = () => {
        if (domains.data) {
          return domains?.data?.map((item) => {
            return [
              { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.ID}</p></div> },
              { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Domain}</p></div> },
              { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.WebsiteName}</p></div> },
              { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center', background: item.forOffer ? 'green' : 'red', width: 'fit-content', marginLeft: 'auto', marginRight: 'auto', padding: '5px', borderRadius: '10px' }}>{item.forOffer ? 'YES' : 'NO'}</p></div> },
              { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{moment(item.updatedAt).format('DD.MM.YYYY.')}</p></div> },
              {
                keyID: String(item.ID), type: "groupNewline", group: [
                  {
                    keyID: String(item.ID), type: "button", text: "Select", triggerDropdown: true, onClick: e => {
                      setNewSelectedDomain({ ID: item.ID, URL: item.Domain });
                      props.onClose();
                    }
                  }
                ]
              }
            ];
          });
        }
      }

      return <div className='route__emailServers__addNewModal__selectDomainModal'>
        <div id='box'>
          <div id='table'>
            <FilteredCustomTable
              id='table'
              accent='#48525C'
              theme='dark'
              headers={['ID', 'Domain', 'Website name', 'For offer', 'Created at']}
              filters={[
                { name: "ID", friendlyName: "ID", type: "number" },
                { name: "Domain", friendlyName: "Domain", type: "string" },
                { name: "WebsiteName", friendlyName: "Website name", type: "string" },
                { name: "updatedAt", friendlyName: "Date", type: "date" },
              ]}
              filterCB={f => setFilters(f)}
              data={domains.data?.length > 0 ? addDomainsToTable() : [[{ keyID: 'noData', type: 'custom', data: <p>No data to display</p> }]]}
            />
          </div>
          <div id="controles">
            <Button value='Cancel' accent='transparent' style={{ borderBottom: '1px solid #e53333', borderRadius: '0px', color: '#e53333' }} onClick={props.onClose} />
          </div>
        </div>
      </div>
    }

    const savePackage = (close) => {
      setSpinner(true);

      const data = {
        ID: props.curPackage.ID,
        Name: newNameRef.current.value ? newNameRef.current.value : props.curPackage.Name,
        Credits: newCreditsRef.current.value ? newCreditsRef.current.value : props.curPackage.Credits,
        Price: newPriceRef.current.value ? newPriceRef.current.value : props.curPackage.Price,
        DomainID: newSelectedDomain ? newSelectedDomain.ID : props.curPackage.DomainID,
        IsVisible: isVisibleRef.current.checked ? 1 : 0,
        PackageType: packageType ? packageType : props.curPackage.PackageType,
        Message: messageRef.current.value ? messageRef.current.value : props.curPackage.Message,
        Duration: durationRef.current.value ? durationRef.current.value : props.curPackage.Duration
      };

      axios({
        method: "POST",
        url: `${backendModule.backendURL}/packages/editPackage`,
        data: data,
        ...backendModule.axiosConfig
      }).then(res => {
        if (res.data.status === 'ok') {
          getAllPackages();
          close();
        }
        else {
          msgRef.current.innerText = 'Something went wrong';
        }
      }).catch(() => {
        msgRef.current.innerText = 'Something went wrong';
      }).finally(() => {
        setSpinner(false);
      });
    }

    return <div className='route__packages__editModal'>
      <div id='box'>
        <h1>Edit {props.curPackage.Name}</h1>

        <CustomInput autocomplete="off" placeholder="Name" ref={newNameRef} accent="#FFF" theme="dark" type="text" style={{ width: "100%", marginBottom: '15px' }} />
        <CustomInput autocomplete="off" placeholder="Credits" ref={newCreditsRef} accent="#FFF" theme="dark" type="number" style={{ width: "100%", marginBottom: '15px' }} />
        <CustomInput autocomplete="off" placeholder="Price" ref={newPriceRef} accent="#FFF" theme="dark" type="number" style={{ width: "100%", marginBottom: '15px' }} />
        <div className='route__packages__addNewModal__checkboxes'>
          <div className='route__packages__addNewModal__checkboxes__item' style={{ marginBottom: '15px' }} >
            <input type="checkbox" id="isVisible" name="isVisible" ref={isVisibleRef} />
            <label for="isVisible">Is Visible?</label>
          </div>
          <Dropdown inlinePlaceholder='Select Package Type' theme='dark' data={[
            { name: 'Premium Package', value: 'Premium' },
            { name: 'Standard Package', value: 'Standard' },
            { name: 'BrowseMembers Package', value: 'BrowseMembers' },
            { name: 'Welcome Offer', value: 'Welcome' },
            { name: 'New User Package', value: 'New' },
            { name: 'Deletion Package', value: 'Deleteion' },
          ].map(item => {
            return { name: item.name, value: item.value }
          })} onReturn={e => { setPackageType(e.value) }} />
        </div>

        <CustomInput autocomplete="off" placeholder="Message" ref={messageRef} accent="#FFF" theme="dark" type="text" style={{ width: "100%", marginBottom: '10px' }} />

        <CustomInput autocomplete="off" placeholder="Duration in days" ref={durationRef} accent="#FFF" theme="dark" type="number" style={{ width: "100%", marginBottom: '15px' }} />

        <div id="controles" style={{ display: !spinner ? 'flex' : 'none' }}>
          <Button value='Cancel' accent='transparent' style={{ borderBottom: '1px solid #e53333', borderRadius: '0px', color: '#e53333' }} onClick={props.onClose} />
          <Button value='Save' accent='transparent' style={{ borderBottom: '1px solid #00A3FF', borderRadius: '0px', color: '#00A3FF' }} onClick={() => savePackage(props.onClose)} />
        </div>
        <div style={{ display: spinner ? 'flex' : 'none', flexDirection: 'row', justifyContent: 'flex-end' }}>
          <Spinner color='white' align='right' width='32px' />
        </div>
        <p id="msg" ref={msgRef}></p>
      </div>
    </div>
  }

  return (
    <div className='route__packages'>
      <DataSorter sorters={[
        {name: 'Credits', value: 'Credits'},
        {name: 'Price', value:'Price'},
        {name: 'Name', value:'Name'},
        {name: 'Package Type', value:'PackageType'},
      ]} setOrders={setOrders} />
      <FilteredCustomTable
        id='table'
        accent='#000'
        dropdownBackground='#000'
        inputBottomBorderColor='#FFF'
        placeholderStyle={{backgroundColor: '#000'}}
        filterHeadStyle={{backgroundColor: 'rgba(25, 35, 67, 0.2)'}}
        tableHeadStyle={{backgroundColor: 'rgba(25, 35, 67, 0.2)'}}
        buttonStyle={{border: '1px solid #FFF', backgroundColor: '#000'}}
        theme='dark'
        headers={['ID', 'Name', 'Credits', 'Price', 'Type', 'Domain ID', 'Updated at']}
        filters={[
          { name: "ID", friendlyName: "ID", type: "string" },
          { name: "Name", friendlyName: "Name", type: "string" },
          { name: "Credits", friendlyName: "Credits", type: "number" },
          { name: "Price", friendlyName: "Price", type: "number" },
          { name: "Type", friendlyName: "Type", type: "string" },
          { name: "DomainID", friendlyName: "Domain ID", type: "string" },
          { name: "updatedAt", friendlyName: "Updated at", type: "date" }
        ]}
        filterCB={f => setFilters(f)}
        data={props.packages.data?.length > 0 ? addPackagesToTable() : [[{ keyID: 'noData', type: 'custom', data: <p>No data to display</p> }]]}
      />
    </div>
  )
}

const AddNewPackageModal = (props) => {
  const [spinner, setSpinner] = React.useState(false);
  const [selectedDomain, setSelectedDomain] = React.useState(null);
  const [packageType, setPackageType] = React.useState(null);
  const msgRef = React.useRef(null);

  const nameRef = React.useRef(null);
  const creditsRef = React.useRef(null);
  const priceRef = React.useRef(null);
  const isVisibleRef = React.useRef(null);
  const messageRef = React.useRef(null);
  const durationRef=React.useRef();

  const getAllPackages = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/packages/getAllPackages`,
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === 'ok') {
        props.setPackages(res.data);
      }
      else {

      }
    }).catch(() => {

    });
  }

  React.useEffect(() => {
    getAllPackages();
  }, []);

  const openDomainsModal = (e, isEdit, setNewSelectedDomain) => {
    animateBox(e, <SelectDomainModal isEdit={isEdit} setNewSelectedDomain={setNewSelectedDomain} />);
  }

  const SelectDomainModal = (props) => {
    const [filters, setFilters] = React.useState(false);
    const [domains, setDomains] = React.useState(false);

    const getAllDomains = () => {
      axios({
        method: "POST",
        url: `${backendModule.backendURL}/domains/getAllDomains`,
        data: {
          filters: filters,
        },
        ...backendModule.axiosConfig
      }).then(res => {
        if (res.data.status === 'ok') {
          setDomains(res.data);
        }
        else {
          setDomains('GET_ALL_DOMAINS_ERROR');
        }
      }).catch(() => {
        setDomains('GET_ALL_DOMAINS_ERROR');
      });
    }

    React.useEffect(() => {
      getAllDomains();
    }, [filters]);

    const addDomainsToTable = () => {
      if (domains.data) {
        return domains?.data?.map((item) => {
          return [
            { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.ID}</p></div> },
            { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Domain}</p></div> },
            { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.WebsiteName}</p></div> },
            { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center', background: item.forOffer ? 'green' : 'red', width: 'fit-content', marginLeft: 'auto', marginRight: 'auto', padding: '5px', borderRadius: '10px' }}>{item.forOffer ? 'YES' : 'NO'}</p></div> },
            { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{moment(item.updatedAt).format('DD.MM.YYYY.')}</p></div> },
            {
              keyID: String(item.ID), type: "groupNewline", group: [
                {
                  keyID: String(item.ID), type: "button", text: "Select", triggerDropdown: true, onClick: e => {
                    setSelectedDomain({ ID: item.ID, URL: item.Domain });
                    props.onClose();
                  }
                }
              ]
            }
          ];
        });
      }
    }

    return <div className='route__emailServers__addNewModal__selectDomainModal'>
      <div id='box'>
        <div id='table'>
          <FilteredCustomTable
            id='table'
            accent='#48525C'
            theme='dark'
            headers={['ID', 'Domain', 'Website name', 'For offer', 'Created at']}
            filters={[
              { name: "ID", friendlyName: "ID", type: "number" },
              { name: "Domain", friendlyName: "Domain", type: "string" },
              { name: "WebsiteName", friendlyName: "Website name", type: "string" },
              { name: "updatedAt", friendlyName: "Date", type: "date" },
            ]}
            filterCB={f => setFilters(f)}
            data={domains.data?.length > 0 ? addDomainsToTable() : [[{ keyID: 'noData', type: 'custom', data: <p>No data to display</p> }]]}
          />
        </div>
        <div id="controles">
          <Button value='Cancel' accent='transparent' style={{ borderBottom: '1px solid #e53333', borderRadius: '0px', color: '#e53333' }} onClick={props.onClose} />
        </div>
      </div>
    </div>
  }


  const addPackage = (close) => {
    setSpinner(true);

    if (!nameRef.current.value) {
      setSpinner(false);
      return msgRef.current.innerText = 'Name missing!';
    }

    if (!creditsRef.current.value) {
      setSpinner(false);
      return msgRef.current.value = 'Credits missing!';
    }

    if (!priceRef.current.value) {
      setSpinner(false);
      return msgRef.current.innerText = 'Price missing!';
    }

    if (!selectedDomain) {
      setSpinner(false);
      return msgRef.current.innerText = 'Domain missing!';
    }

    if (!packageType) {
      setSpinner(false);
      return msgRef.current.innerText = 'Package type missing';
    }

    const data = {
      Name: nameRef.current.value,
      Credits: creditsRef.current.value,
      Price: priceRef.current.value,
      IsVisible: isVisibleRef.current.checked ? 1 : 0,
      PackageType: packageType,
      Message: messageRef.current.value,
      DomainID: selectedDomain.ID,
      Duration: durationRef.current.value
    };

    axios({
      method: "POST",
      url: `${backendModule.backendURL}/packages/addPackage`,
      data: data,
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === 'ok') {
        getAllPackages();
        close();
      }
      else {
        msgRef.current.innerText = 'Something went wrong';
      }
    }).catch((e) => {
      console.log("Adding Package Error :: ", e)
      msgRef.current.innerText = 'Something went wrong';
    }).finally(() => {
      setSpinner(false);
    });
  }

  return <div className='route__packages__addNewModal'>
    <div id='box'>
      <h1>Add new package</h1>

      <CustomInput autocomplete="off" placeholder="Name" ref={nameRef} accent="#FFF" theme="dark" type="text" style={{ width: "100%", marginBottom: '10px' }} />
      <CustomInput autocomplete="off" placeholder="Credits" ref={creditsRef} accent="#FFF" theme="dark" type="number" style={{ width: "100%", marginBottom: '10px' }} />
      <CustomInput autocomplete="off" placeholder="Price" ref={priceRef} accent="#FFF" theme="dark" type="number" style={{ width: "100%", marginBottom: '10px' }} />
      <div className='route__packages__addNewModal__checkboxes'>
        <div className='route__packages__addNewModal__checkboxes__item' >
          <input type="checkbox" id="isVisible" name="isVisible" ref={isVisibleRef} />
          <label for="isVisible">Is Visible ?</label>
        </div>
        <Dropdown inlinePlaceholder='Select Package Type' theme='dark' data={[
          { name: 'Premium Package', value: 'Premium' },
          { name: 'Standard Package', value: 'Standard' },
          { name: 'BrowseMembers Package', value: 'BrowseMembers' },
          { name: 'Welcome Offer', value: 'Welcome' },
          { name: 'New User Package', value: 'New' },
          { name: 'Deletion Package', value: 'Deleteion' },
        ].map(item => {
          return { name: item.name, value: item.value }
        })} onReturn={e => { setPackageType(e.value) }} />
      </div>

      <CustomInput autocomplete="off" placeholder="Message" ref={messageRef} accent="#FFF" theme="dark" type="text" style={{ width: "100%", marginBottom: '10px' }} />
      <CustomInput autocomplete="off" placeholder="Duration in days" ref={durationRef} accent="#FFF" theme="dark" type="number" style={{ width: "100%", marginBottom: '15px' }} />

      <Button value={selectedDomain === null ? 'SELECT DOMAIN' : selectedDomain.URL} accent='rgb(72, 82, 92)' className='selectDomainButton' onClick={(e) => openDomainsModal(e, false)} />

      <div id="controles" style={{ display: !spinner ? 'flex' : 'none' }}>
        <Button value='Cancel' accent='transparent' style={{ borderBottom: '1px solid #e53333', borderRadius: '0px', color: '#e53333' }} onClick={props.onClose} />
        <Button value='Add' accent='transparent' style={{ borderBottom: '1px solid #00A3FF', borderRadius: '0px', color: '#00A3FF' }} onClick={() => addPackage(props.onClose)} />
      </div>
      <div style={{ display: spinner ? 'flex' : 'none', flexDirection: 'row', justifyContent: 'flex-end' }}>
        <Spinner color='white' align='right' width='32px' />
      </div>
      <p id="msg" ref={msgRef}></p>
    </div>
  </div>
}

const MoreDetailsModal = (props) => {
  const [date, setDate] = React.useState([{ name: "createdAt", op: "deq", value: new Date().getTime() }]);
  const [totalData, setTotalData] = React.useState([]);
  const [spinner, setSpinner] = React.useState(true);

  return <div className='route__secondaryUsers__moreDetailsModal'>
    <div id='box'>
      <span id='domain-id'><strong>DOMAIN ID:</strong> <p>{props.currPackage.DomainID}</p></span>
      <div id='gridbox'>
        <div id='top-right'>
          <p><strong>Name:</strong> {props.currPackage.Name}</p>
          <p><strong>Price:</strong> {props.currPackage.Price}</p>
          <p><strong>Credits:</strong> {props.currPackage.Credits}</p>
          <p><strong>Package Type:</strong> {props.currPackage.PackageType}</p>
        </div>
        <div id='middle-left'>
          <p><strong>Is Visible:</strong> {props.currPackage.IsVisible ? 'true' : 'false'}</p>
          <p><strong>Message:</strong> {props.currPackage.Message}</p>
          <p><strong>Duration in Days:</strong> {props.currPackage.Duration? props.currPackage.Duration:"Package without duration"}</p>
          <p><strong>Bought times:</strong> {props.currPackage.BoughtTimes ? props.currPackage.BoughtTimes : '0'}</p>
        </div>
        <div id="controles">
          <Button value='Close' accent='transparent' style={{ borderBottom: '1px solid #e53333', borderRadius: '0px', color: '#e53333' }} onClick={() => props.onClose()} />
        </div>
      </div>
    </div>
  </div>
}

export { AddNewPackageModal }