import React from 'react';
import "./index.scss";

import axios from 'axios';
import * as backendModule from "../../../../modules/backendModule";
import { animateBox } from '../../../../modules/componentAnimation';
import { useSelector } from "react-redux";
import moment from 'moment';

export default function ContentHeader(props) {
  const userSelector = useSelector(state => state?.user?.UserInfo?.ID ?? null);
  const chatRoomSelector = useSelector(state => state?.chatRooms ?? null);
  const [curChat, setCurChat] = React.useState(null);

  React.useEffect(() => {
    setCurChat(chatRoomSelector.find(cr => cr.ID === props.activeChat.ChatRoomID));
  }, [props.activeChat]);

  React.useEffect(() => {
  }, [curChat])

  const endSession = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/ladies/removeResponsiblePerson`,
      data: {
        ChatRoomID: props.activeChat?.ChatRoomID,
        UserID: userSelector
      },
      ...backendModule.axiosConfig
    }).then(res => {
    }).catch(() => {})
  }

  const ClientDataModal = (props) => {
    const [filters, setFilters] = React.useState([]);
    const [curClientData, setCurClientData] = React.useState([]);

    React.useEffect(() => {
      axios({
        method: "POST",
        url: `${backendModule.backendURL}/users/getAllSecondaryUsers`,
        data: {
          limit: 1,
          filters: [
            ...filters,
            { name: 'ID', op: 'eq', value: props.id },
          ],
        },
        ...backendModule.axiosConfig
      }).then(res => {
        if (res.data.status === 'ok') {
          setCurClientData(res.data.data[0]);
        }
        else {

        }
      }).catch(() => { });
    }, [props.activeChat, filters]);

    React.useEffect(() => {
      axios({
        method: "POST",
        url: `${backendModule.backendURL}/users/getAllSecondaryUsers`,
        data: {
          limit: 1,
          filters: [
            ...filters,
            { name: 'ID', op: 'eq', value: props.ladyID },
          ],
        },
        ...backendModule.axiosConfig
      }).then(res => {
        axios({
          method: "POST",
          url: `${backendModule.backendURL}/ladies/countLadyVisit`,
          data: {
            UserID: props.id,
            LadyID: String(res.data.data[0].Username).replace('--internal-', '')
          },
          ...backendModule.axiosConfig
        }).then(res => { }).catch(() => { })
      }).catch(() => { })
    }, [props.activeChat])


    return <div className='component__contentHeader__clientDataModal'>
      <div className='component__contentHeader__clientDataModal__box'>
        <img className='component__contentHeader__clientDataModal__box__close' src='images/close.png' alt='' onClick={props.onClose} />
        <div className='component__contentHeader__clientDataModal__box__image'>
          <img className='component__contentHeader__clientDataModal__box__image__banner' src={`${curClientData?.ActiveBanner}`} alt='' />
          <img className='component__contentHeader__clientDataModal__box__image__badge' src={`${curClientData?.ActiveBadge}`} alt='' />
          <img className='component__contentHeader__clientDataModal__box__image__img' src={`${backendModule.backendURL}/StaticContent/Images/${curClientData?.Image}`} alt='' />
        </div>
        <span><strong>Name: </strong> <p>{curClientData?.Name}</p></span>
        <div className='component__contentHeader__clientDataModal__box__bio'><strong>Bio: </strong><p>{curClientData?.Bio}</p></div>
        <span><strong>Gender: </strong><p>{curClientData?.Gender === true ? 'Male' : curClientData?.Gender === false ? 'Female' : 'Other'}</p></span>
        <span><strong>Interested in gender: </strong><p>{curClientData?.InterestedInGender === true ? 'Male' : curClientData?.InterestedInGender === false ? 'Female' : 'Other'}</p></span>
        <span><strong>Country: </strong><p>{curClientData?.Country}</p></span>
        <span><strong>ZIP code: </strong><p>{curClientData?.ZIPCode}</p></span>
        { curClientData.Boost && <span><strong>Account boosted!! </strong></span>}
        <span><strong>Last login: </strong><p>{moment(curClientData?.LastLogin).format('DD.MM.YYYY hh:mm:ss')}</p></span>
        <div className='component__contentHeader__clientDataModal__box__lookingFor'>
          <strong>Looking for: </strong>
          {
            curClientData?.LookingFor?.map((item) => {
              return <p>
                -{
                  item === 1 ? 'Just Friends' :
                    item === 2 ? 'Casual Sex' :
                      item === 3 ? 'Affairs' :
                        item === 4 ? 'Sugar Daddies' :
                          item === 5 ? 'Cyber Sex' :
                            item === 6 ? 'Sex' :
                              item === 7 ? 'Relationships' :
                                item === 8 ? 'Group Sex' : ''
                }
              </p>
            })
          }
        </div>
      </div>
    </div>
  }

  return (
    <div className='component__contentHeader'>
      <div className='component__contentHeader__clientInfo'>
        {props.activeChat?.ClientImage && <img src={`${backendModule.backendURL}/StaticContent/Images/${props.activeChat?.ClientImage}`} alt='' />}
        {props.activeChat?.ClientName && <p>{props.activeChat?.ClientName}</p>}
        {props.activeChat?.Boost && <p>Account Boosted !</p>}
      </div>
      <div className='component__contentHeader__buttons'>
        {props.activeChat?.ClientName && <div onClick={(e) => animateBox(e, <ClientDataModal ladyID={props.activeChat?.AgentID} id={props.activeChat?.ClientID} />)} 
        className='component__contentHeader__buttons__viewProfileButton'
        style={props.buttonStyle ? props.buttonStyle : null}><img src='images/myProfileIcon.png' alt='' /><p>View profile</p></div>}
        {props.activeChat?.ClientName && <div onClick={(e) => animateBox(e, <UserNotes id={props.activeChat?.ClientID} />)} 
        className='component__contentHeader__buttons__viewProfileButton'
        style={props.buttonStyle ? props.buttonStyle : null}><img src='images/myProfileIcon.png' alt='' /><p>View notes</p></div>}
        {curChat !== null && <div onClick={() => endSession()} 
        className='component__contentHeader__buttons__viewProfileButton'
        style={props.buttonStyle ? props.buttonStyle : null}><img src='images/myProfileIcon.png' alt='' /><p>End session</p></div>}
      </div>
    </div>
  )
}

const UserNotes = (props) => {
  const [notes, setNotes] = React.useState();
  const noteRef = React.useRef()
  React.useEffect(() => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/users/getAllSecondaryUsers`,
      data: {
        limit: 1,
        filters: [
          { name: 'ID', op: 'eq', value: props.id },
        ],
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === 'ok') {
        setNotes(res.data.data[0].Notes)
      }
      else {

      }
    }).catch(() => { });
  }, [props.activeChat]);
  const saveNotes = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/auth/addNotes`,
      data: {
        ID: props.id,
        Notes: noteRef.current.value
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === 'ok') {
        props.onClose()
      }
      else {

      }
    }).catch(() => { });
  }

  return <div className='component__userNotes'>
    <img className='component__contentHeader__clientDataModal__box__close' src='images/close.png' alt='' onClick={props.onClose} />
    <textarea ref={noteRef} defaultValue={notes} >

    </textarea>
    <div>
      <button onClick={() => { saveNotes() }}>Save</button>
    </div>
  </div>
}