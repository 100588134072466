import React from 'react';
import "./index.scss";

import CustomInput from '../../components/customComponents/CustomInput';
import Button from '../../components/customComponents/Button';
import Spinner from '../../components/customComponents/Spinner';
import { FilteredCustomTable } from "../../components/customComponents/Table";
import DataSorter from "../../components/customComponents/DataSorter";

import * as backendModule from "../../modules/backendModule";
import axios, { all } from "axios";
import moment from 'moment';

import Dropdown from '../../components/customComponents/Dropdown';

import { animateBox } from "../../modules/componentAnimation";

export default function Stickers(props) {
    const [filters, setFilters] = React.useState([]);
    const [orders, setOrders] = React.useState([]);
    const [spinner, setSpinner] = React.useState(false);
    const [stickerCategories, setStickerCategories] = React.useState(null);

    const getAllStickers = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/stickers/getAllStickersCpanel`,
            data: {
                filters: filters,
                orders: orders
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                props.setStickers(res.data);
            }
            else {

            }
        }).catch(() => {

        });
    }

    const getAllStickerCategories = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/stickerCategories/getAllStickerCategoriesCpanel`,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                setStickerCategories(res.data.data);
            }
            else {

            }
        }).catch(() => {

        });
    }

    React.useEffect(() => {
        getAllStickers();
        getAllStickerCategories();
    }, [filters, orders]);

    const deleteSticker = (id) => {
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/stickers/removeSticker`,
            data: {
                ID: id
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                getAllStickers();
            }
            else {

            }
        }).catch(() => {

        }).finally(() => {
            setSpinner(false);
        });
    }

    const addStickersToTable = () => {
        if (props.stickers.data) {
            return props.stickers?.data?.map((item) => {
                return [
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.ID}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Name}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Price}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Category}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.DomainID}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{moment(item.updatedAt).format('DD.MM.YYYY.')}</p></div> },
                    {
                        keyID: String(item.ID), type: "groupNewline", group: [
                            {
                                keyID: String(item.ID), type: "button", text: "Delete", triggerDropdown: true, triggerData: e => {
                                    return (<div style={{ display: 'flex', flexDirection: 'column', justfyContent: 'center', alignItems: 'center' }}>
                                        <p style={{ color: 'white' }}>Are you sure?</p>
                                        <br></br>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Button style={{ display: spinner ? 'none' : 'block', marginRight: '10px' }} accent='rgb(72, 82, 92)' value='YES' onClick={() => deleteSticker(item.ID)} />
                                            <Button style={{ display: spinner ? 'none' : 'block' }} accent='rgb(72, 82, 92)' value='NO' onClick={c => { e() }} />
                                            <div style={{ display: spinner ? 'block' : 'none' }}><Spinner color='white' width='32px' /></div>
                                        </div>
                                    </div>);
                                },
                            },
                            {
                                keyID: String(item.ID), type: "button", text: "Open image", triggerDropdown: true, onClick: e => {
                                    window.open(`/#/image/${String(item.Image).replace(`${backendModule.backendURL}/staticContent/images/`, '')}`, '_blank');
                                }
                            },
                            {
                                keyID: String(item.ID), type: "button", text: "Edit", triggerDropdown: true, onClick: e => {
                                    animateBox(e, <EditModal curSticker={
                                        {
                                            ID: item.ID,
                                            Name: item.Name,
                                            Price: item.Price,
                                            Type: item.Category,
                                            DomainID: item.DomainID,
                                            Image: item.Image,
                                            StickerCategories: stickerCategories
                                        }
                                    } />)
                                }
                            }
                        ]
                    }
                ];
            });
        }
    }

    const EditModal = (props) => {
        const newNameRef = React.useRef(null);
        const newPriceRef = React.useRef(null);
        const [newSelectedDomain, setNewSelectedDomain] = React.useState(null);
        const [newSelectedImage, setNewSelectedImage] = React.useState(null);
        const [stickerType, setStickerType] = React.useState(props.curSticker.Type);
        const msgRef = React.useRef(null);

        const openDomainsModal = (e, isEdit, setNewSelectedDomain) => {
            animateBox(e, <SelectDomainModal isEdit={isEdit} setNewSelectedDomain={setNewSelectedDomain} />);
        }

        const SelectDomainModal = (props) => {
            const [filters, setFilters] = React.useState(false);
            const [domains, setDomains] = React.useState(false);

            const getAllDomains = () => {
                axios({
                    method: "POST",
                    url: `${backendModule.backendURL}/domains/getAllDomains`,
                    data: {
                        filters: filters
                    },
                    ...backendModule.axiosConfig
                }).then(res => {
                    if (res.data.status === 'ok') {
                        setDomains(res.data);
                    }
                    else {
                        setDomains('GET_ALL_DOMAINS_ERROR');
                    }
                }).catch(() => {
                    setDomains('GET_ALL_DOMAINS_ERROR');
                });
            }

            React.useEffect(() => {
                getAllDomains();
            }, [filters]);

            const addDomainsToTable = () => {
                if (domains.data) {
                    return domains?.data?.map((item) => {
                        return [
                            { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.ID}</p></div> },
                            { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Domain}</p></div> },
                            { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.WebsiteName}</p></div> },
                            { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center', background: item.forOffer ? 'green' : 'red', width: 'fit-content', marginLeft: 'auto', marginRight: 'auto', padding: '5px', borderRadius: '10px' }}>{item.forOffer ? 'YES' : 'NO'}</p></div> },
                            { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{moment(item.updatedAt).format('DD.MM.YYYY.')}</p></div> },
                            {
                                keyID: String(item.ID), type: "groupNewline", group: [
                                    {
                                        keyID: String(item.ID), type: "button", text: "Select", triggerDropdown: true, onClick: e => {
                                            setNewSelectedDomain({ ID: item.ID, URL: item.Domain });
                                            props.onClose();
                                        }
                                    }
                                ]
                            }
                        ];
                    });
                }
            }

            return <div className='route__emailServers__addNewModal__selectDomainModal'>
                <div id='box'>
                    <div id='table'>
                        <FilteredCustomTable
                            id='table'
                            accent='#48525C'
                            theme='dark'
                            headers={['ID', 'Domain', 'Website name', 'For offer', 'Created at']}
                            filters={[
                                { name: "ID", friendlyName: "ID", type: "number" },
                                { name: "Domain", friendlyName: "Domain", type: "string" },
                                { name: "WebsiteName", friendlyName: "Website name", type: "string" },
                                { name: "updatedAt", friendlyName: "Date", type: "date" },
                            ]}
                            filterCB={f => setFilters(f)}
                            data={domains.data?.length > 0 ? addDomainsToTable() : [[{ keyID: 'noData', type: 'custom', data: <p>No data to display</p> }]]}
                        />
                    </div>
                    <div id="controles">
                        <Button value='Cancel' accent='transparent' style={{ borderBottom: '1px solid #e53333', borderRadius: '0px', color: '#e53333' }} onClick={props.onClose} />
                    </div>
                </div>
            </div>
        }

        const saveSticker = (close) => {
            setSpinner(true);

            if (!newSelectedImage) {
                axios({
                    method: "POST",
                    url: `${backendModule.backendURL}/stickers/editSticker`,
                    data: {
                        ID: props.curSticker.ID,
                        Name: newNameRef.current.value ? newNameRef.current.value : props.curSticker.Name,
                        Price: newPriceRef.current.value ? newPriceRef.current.value : props.curSticker.Price,
                        Category: stickerType,
                        DomainID: newSelectedDomain ? newSelectedDomain.ID : props.curSticker.DomainID,
                        Image: props.curSticker.Image
                    },
                    ...backendModule.axiosConfig
                }).then(res => {
                    if (res.data.status === 'ok') {
                        getAllStickers();
                        close();
                    }
                    else {
                        msgRef.current.innerText = 'Something went wrong';
                    }
                }).catch(() => {
                    msgRef.current.innerText = 'Something went wrong';
                }).finally(() => {
                    setSpinner(false);
                });
            }
            else {
                let imgFile = new FormData();
                imgFile.append('image', newSelectedImage);
                imgFile.append('ImageName', newSelectedImage.name);
                imgFile.append('tag', 'logo-img');

                let oldImage = props.curSticker.Image.replace(`${backendModule.backendURL}/staticContent/images/`, '');

                axios({
                    method: "POST",
                    url: `${backendModule.backendURL}/images/getAllImages`,
                    ...backendModule.axiosConfig,
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                }).then(allimgs => {
                    allimgs.data.data.map((item) => {
                        if (String(item.URL).replace(`${backendModule.backendURL}/staticContent/images/`, '') === oldImage) {
                            axios({
                                method: "POST",
                                url: `${backendModule.backendURL}/images/deleteImage`,
                                ...backendModule.axiosConfig,
                                data: {
                                    id: item.ID
                                },
                                headers: {
                                    "Content-Type": "multipart/form-data"
                                }
                            }).then(() => {
                                axios({
                                    method: "POST",
                                    url: `${backendModule.backendURL}/images/uploadImage`,
                                    data: imgFile,
                                    ...backendModule.axiosConfig,
                                    headers: {
                                        "Content-Type": "multipart/form-data"
                                    }
                                }).then(imgres => {
                                    axios({
                                        method: "POST",
                                        url: `${backendModule.backendURL}/stickers/editSticker`,
                                        data: {
                                            ID: props.curSticker.ID,
                                            Name: newNameRef.current.value ? newNameRef.current.value : props.curSticker.Name,
                                            Price: newPriceRef.current.value ? newPriceRef.current.value : props.curSticker.Price,
                                            Category: stickerType,
                                            DomainID: newSelectedDomain.ID,
                                            Image: imgres.data.data
                                        },
                                        ...backendModule.axiosConfig
                                    }).then(res => {
                                        if (res.data.status === 'ok') {
                                            getAllStickers();
                                            close();
                                        }
                                        else {
                                            msgRef.current.innerText = 'Something went wrong';
                                        }
                                    }).catch(() => {
                                        msgRef.current.innerText = 'Something went wrong';
                                    }).finally(() => {
                                        setSpinner(false);
                                    });
                                }).catch(() => {

                                })
                            }).catch(() => { }).finally(() => {
                                setSpinner(false);
                            })
                        }
                    });
                }).catch(() => { });
            }
        }

        return <div className='route__stickers__editModal'>
            <div id='box'>
                <h1>Edit {props.curSticker.Name}</h1>

                <CustomInput autocomplete="off" placeholder="Name" ref={newNameRef} defaultValue={props.curSticker.Name} accent="#FFF" theme="dark" type="text" style={{ width: "100%", marginBottom: '25px' }} />
                <CustomInput autocomplete="off" placeholder="Price" ref={newPriceRef} defaultValue={props.curSticker.Price} accent="#FFF" theme="dark" type="number" style={{ width: "100%", marginBottom: '25px' }} />
                <input
                    id="img-input"
                    type='file'
                    name='websitelogo'
                    onChange={
                        (event) => {
                            setNewSelectedImage(event.target.files[0]);
                        }
                    }
                />
                <p id="img-p">{newSelectedImage?.name}</p>
                <Dropdown style={{marginBottom: '20px'}} inlinePlaceholder='Select Sticker Category' theme='dark' data={props.curSticker.StickerCategories ? props.curSticker.StickerCategories.map(item => {
                return {name: item.Name, value: item.ID}
                }) : null} onChange={e => {setStickerType(e.value)}} />
                <Button value={newSelectedDomain === null ? 'CHANGE DOMAIN' : newSelectedDomain.URL} accent='rgb(72, 82, 92)' className='selectDomainButton' onClick={(e) => openDomainsModal(e, false)} />

                <div id="controles" style={{ display: !spinner ? 'flex' : 'none' }}>
                    <Button value='Cancel' accent='transparent' style={{ borderBottom: '1px solid #e53333', borderRadius: '0px', color: '#e53333' }} onClick={props.onClose} />
                    <Button value='Save' accent='transparent' style={{ borderBottom: '1px solid #00A3FF', borderRadius: '0px', color: '#00A3FF' }} onClick={() => saveSticker(props.onClose)} />
                </div>
                <div style={{ display: spinner ? 'flex' : 'none', flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <Spinner color='white' align='right' width='32px' />
                </div>
                <p id="msg" ref={msgRef}></p>
            </div>
        </div>
    }

    return (
        <div className='route__stickers'>
            <DataSorter sorters={[
                {name: 'Price', value:'Price'},
                {name: 'Name', value:'Name'},
                {name: 'Created at', value:'createdAt'},
                {name: 'Updated at', value:'updatedAt'}
            ]} setOrders={setOrders} />
            <FilteredCustomTable
                id='table'
                accent='#48525C'
                theme='dark'
                headers={['ID', 'Name', 'Price', 'Category', 'Domain ID', 'Updated at']}
                filters={[
                    { name: "ID", friendlyName: "ID", type: "string" },
                    { name: "Name", friendlyName: "Name", type: "string" },
                    { name: "Price", friendlyName: "Price", type: "number" },
                    { name: "DomainID", friendlyName: "Domain ID", type: "string" },
                    { name: "Type", friendlyName: "Type", type: "string" },
                    { name: "updatedAt", friendlyName: "Updated at", type: "string" }
                ]}
                filterCB={f => setFilters(f)}
                data={props.stickers.data?.length > 0 ? addStickersToTable() : [[{ keyID: 'noData', type: 'custom', data: <p>No data to display</p> }]]}
            />
        </div>
    )
}

const AddNewStickerModal = (props) => {
    const [spinner, setSpinner] = React.useState(false);
    const [selectedDomain, setSelectedDomain] = React.useState(null);
    const [selectedImage, setSelectedImage] = React.useState(null);
    const [stickerType, setStickerType] = React.useState(null);
    const [stickerCategories, setStickerCategories] = React.useState(null);
    const msgRef = React.useRef(null);

    const nameRef = React.useRef(null);
    const priceRef = React.useRef(null);

    const getAllStickers = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/stickers/getAllStickersCpanel`,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                props.setStickers(res.data);
            }
            else {

            }
        }).catch(() => {

        });
    }

    const getAllStickerCategories = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/stickerCategories/getAllStickerCategoriesCpanel`,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                setStickerCategories(res.data.data);
            }
            else {

            }
        }).catch(() => {

        });
    }

    React.useEffect(() => {
        getAllStickers();
        getAllStickerCategories();
    }, []);

    const openDomainsModal = (e, isEdit, setNewSelectedDomain) => {
        animateBox(e, <SelectDomainModal isEdit={isEdit} setNewSelectedDomain={setNewSelectedDomain} />);
    }

    const SelectDomainModal = (props) => {
        const [filters, setFilters] = React.useState(false);
        const [domains, setDomains] = React.useState(false);

        const getAllDomains = () => {
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/domains/getAllDomains`,
                data: {
                    filters: filters
                },
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === 'ok') {
                    setDomains(res.data);
                }
                else {
                    setDomains('GET_ALL_DOMAINS_ERROR');
                }
            }).catch(() => {
                setDomains('GET_ALL_DOMAINS_ERROR');
            });
        }

        React.useEffect(() => {
            getAllDomains();
        }, [filters]);

        const addDomainsToTable = () => {
            if (domains.data) {
                return domains?.data?.map((item) => {
                    return [
                        { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.ID}</p></div> },
                        { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Domain}</p></div> },
                        { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.WebsiteName}</p></div> },
                        { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center', background: item.forOffer ? 'green' : 'red', width: 'fit-content', marginLeft: 'auto', marginRight: 'auto', padding: '5px', borderRadius: '10px' }}>{item.forOffer ? 'YES' : 'NO'}</p></div> },
                        { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{moment(item.updatedAt).format('DD.MM.YYYY.')}</p></div> },
                        {
                            keyID: String(item.ID), type: "groupNewline", group: [
                                {
                                    keyID: String(item.ID), type: "button", text: "Select", triggerDropdown: true, onClick: e => {
                                        setSelectedDomain({ ID: item.ID, URL: item.Domain });
                                        props.onClose();
                                    }
                                }
                            ]
                        }
                    ];
                });
            }
        }

        return <div className='route__emailServers__addNewModal__selectDomainModal'>
            <div id='box'>
                <div id='table'>
                    <FilteredCustomTable
                        id='table'
                        accent='#48525C'
                        theme='dark'
                        headers={['ID', 'Domain', 'Website name', 'For offer', 'Created at']}
                        filters={[
                            { name: "ID", friendlyName: "ID", type: "number" },
                            { name: "Domain", friendlyName: "Domain", type: "string" },
                            { name: "WebsiteName", friendlyName: "Website name", type: "string" },
                            { name: "updatedAt", friendlyName: "Date", type: "date" },
                        ]}
                        filterCB={f => setFilters(f)}
                        data={domains.data?.length > 0 ? addDomainsToTable() : [[{ keyID: 'noData', type: 'custom', data: <p>No data to display</p> }]]}
                    />
                </div>
                <div id="controles">
                    <Button value='Cancel' accent='transparent' style={{ borderBottom: '1px solid #e53333', borderRadius: '0px', color: '#e53333' }} onClick={props.onClose} />
                </div>
            </div>
        </div>
    }

    const addPackage = (close) => {
        setSpinner(true);

        if(!nameRef.current.value){
            setSpinner(false);
            return msgRef.current.innerText = 'Name missing!';
        }

        if(!priceRef.current.value){
            setSpinner(false);
            return msgRef.current.innerText = 'Price missing!';
        }

        if(!selectedDomain){
            setSpinner(false);
            return msgRef.current.innerText = 'Domain missing!';
        }

        if(!stickerType){
            setSpinner(false);
            return msgRef.current.innerText = 'Sticker type missing!';
        }

        let imgFile = new FormData();
        imgFile.append('image', selectedImage);
        imgFile.append('ImageName', selectedImage.name);
        imgFile.append('tag', 'logo-img');

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/images/uploadImage`,
            data: imgFile,
            ...backendModule.axiosConfig,
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then(imgres => {
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/stickers/addSticker`,
                data: {
                    Name: nameRef.current.value,
                    Price: priceRef.current.value,
                    Category: stickerType,
                    DomainID: selectedDomain.ID,
                    Image: imgres.data.data
                },
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === 'ok') {
                    getAllStickers();
                    close();
                }
                else {
                    msgRef.current.innerText = 'Something went wrong';
                }
            }).catch(() => {
                msgRef.current.innerText = 'Something went wrong';
            }).finally(() => {
                setSpinner(false);
            });
        }).catch(() => {

        });
    }

    return <div className='route__stickers__addNewModal'>
        <div id='box'>
            <h1>Add new sticker</h1>

            <CustomInput autocomplete="off" placeholder="Name" ref={nameRef} accent="#FFF" theme="dark" type="text" style={{ width: "100%", marginBottom: '25px' }} />
            <CustomInput autocomplete="off" placeholder="Price" ref={priceRef} accent="#FFF" theme="dark" type="number" style={{ width: "100%", marginBottom: '25px' }} />
            <input
                id="img-input"
                type='file'
                name='websitelogo'
                onChange={
                    (event) => {
                        setSelectedImage(event.target.files[0]);
                    }
                }
            />
            <p id="img-p">{selectedImage?.name}</p>

            <Dropdown style={{marginBottom: '20px'}} inlinePlaceholder='Select Sticker Type' theme='dark' data={stickerCategories ? stickerCategories.map(item => {
            return {name: item.Name, value: item.ID}
            }) : null} onChange={e => {setStickerType(e.value)}} />

            <Button value={selectedDomain === null ? 'SELECT DOMAIN' : selectedDomain.URL} accent='rgb(72, 82, 92)' className='selectDomainButton' onClick={(e) => openDomainsModal(e, false)} />

            <div id="controles" style={{ display: !spinner ? 'flex' : 'none' }}>
                <Button value='Cancel' accent='transparent' style={{ borderBottom: '1px solid #e53333', borderRadius: '0px', color: '#e53333' }} onClick={props.onClose} />
                <Button value='Add' accent='transparent' style={{ borderBottom: '1px solid #00A3FF', borderRadius: '0px', color: '#00A3FF' }} onClick={() => addPackage(props.onClose)} />
            </div>
            <div style={{ display: spinner ? 'flex' : 'none', flexDirection: 'row', justifyContent: 'flex-end' }}>
                <Spinner color='white' align='right' width='32px' />
            </div>
            <p id="msg" ref={msgRef}></p>
        </div>
    </div>
}

export { AddNewStickerModal }