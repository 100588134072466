import React from 'react';
import "./index.scss";

import * as backendModule from "../../modules/backendModule";
import axios from "axios";
import moment from 'moment';

import CustomInput from "../../components/customComponents/CustomInput";
import Button from "../../components/customComponents/Button";
import Spinner from '../../components/customComponents/Spinner';

import { useDispatch } from 'react-redux';
import * as timestampActions from "../../actions/timestampAction";

import { useNavigate } from "react-router-dom";
import animateModule from "../../modules/animateModule";
import FilterPanel from '../../components/customComponents/FilterPanelNew';
import DataSorter from "../../components/customComponents/DataSorter";
import { useSelector } from "react-redux"

export default function EmailAnalitycs() {
    const curNavigate = useNavigate();
    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".component__contentWrapper"));
    const [spiner, setSpinner] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [filters, setFilters] = React.useState([]);
    const [orders, setOrders] = React.useState([]);
    const [data, setData] = React.useState();
    const [users, setUsers] = React.useState();
    const chatUsersSelector = useSelector(state => state?.chatUsers ?? null);

    const getData = async () => {
        setError(false);
        setData({ status: "error", data: "SERVER_ERROR" })


        setSpinner(true);
        await axios({
            method: "POST",
            url: `${backendModule.backendURL}/statistics/getAllEmailsAnalitics`,
            data: {
                filters: filters,
                orders: orders
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setData(res.data)
        }).catch(() => {
            return { status: "error", data: "SERVER_ERROR" };
        });


        setSpinner(false);
    };
    const getUser = (ID) => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/getSecondaryUserByID`,
            data: {
                ID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            return res.data
        }).catch(() => {
            return 'error'
        });
    }
    React.useEffect(() => {
        getData();
    }, [filters, orders])

    return (
        <div className='route__emailDashboard'>
            <div className='route__emailDashboard__head'>
                <DataSorter dropdownBackground='#000' dropdownStyle={{backgroundColor: '#000'}} sorters={[
                    {name: 'ID', value: 'ID'},
                    {name: 'UserID', value: 'UserID'},
                    {name: 'FirstEmail', value: 'FirstMessage'},
                    {name: 'SecondEmail', value: 'SecondMessage'},
                    {name: 'ThirdEmail', value: 'ThirdMessage'},
                    {name: 'UnreadMessage', value: 'UnreadMessageEmail'},
                    {name: 'UnreadMessage-Converted', value: 'UnreadMessageEmailConverted'},
                    {name: 'ProfileVisit', value: 'ProfileVisitEmail'},
                    {name: 'ProfileVisit-Converted', value: 'ProfileVisitEmailConverted'},
                    {name: 'VerificationEmail', value: 'VerificationEmail'},
                    {name: 'VerificationEmail-converted', value: 'VerificationEmailConverted'},
                    {name: 'createdAt', value: 'createdAt'},
                    {name: 'updatedAt', value: 'updatedAt'},
                ]} setOrders={setOrders} />
                <FilterPanel
                    theme="dark"
                    accent="rgb(62, 87, 166)"
                    filters={[
                        { name: "createdAt", friendlyName: "Date created", type: "date" },
                        { name: "updatedAt", friendlyName: "Last update date", type: "date" },
                        { name: "ProfileVisitEmail", friendlyName: "Profile visit email", type: "date" },
                        { name: "UnreadMessageEmail", friendlyName: "Unread message email", type: "date" },
                        {
                            name: "Converted", friendlyName: "Is notification Converted", type: "custom", varType: "number", data: [
                                { text: "No", value: 0 },
                                { text: "Yes, after first e-mail", value: 1 },
                                { text: "Yes, after second e-mail", value: 2 },
                                { text: "Yes, after third e-mail", value: 3 }

                            ]
                        },
                        { name: "UserID", friendlyName: "UserID", type: "string" },

                    ]}
                    filterCB={f => {
                        setSpinner(true);
                        setData();
                        setFilters(f);
                    }}
                />
            </div>
            <div className='route__emailDashboard__kpi'>
                <div className='route__emailDashboard__kpi__single'>
                    <p className='route__emailDashboard__kpi__single__head'>Total users</p>
                    <p className='route__emailDashboard__kpi__single__number'>{
                        data?.status !== 'ok' ? <Spinner color='#00a3ff' align='center' /> : <p> {data?.data.data.length}</p>
                    }</p>
                </div>
                <div className='route__emailDashboard__kpi__single'>
                    <p className='route__emailDashboard__kpi__single__head'>Users converted after first e-mail</p>
                    <p className='route__emailDashboard__kpi__single__number'>{
                        data?.status !== 'ok' ? <Spinner color='#00a3ff' align='center' /> : <p> {data?.data.convFirst}</p>
                    }</p>
                </div>
                <div className='route__emailDashboard__kpi__single'>
                    <p className='route__emailDashboard__kpi__single__head'>Users converted after second e-mail</p>
                    <p className='route__emailDashboard__kpi__single__number'>{
                        data?.status !== 'ok' ? <Spinner color='#00a3ff' align='center' /> : <p> {data?.data.convSecond}</p>
                    }</p>
                </div>
                <div className='route__emailDashboard__kpi__single'>
                    <p className='route__emailDashboard__kpi__single__head'>Users converted after third e-mail</p>
                    <p className='route__emailDashboard__kpi__single__number'>{
                        data?.status !== 'ok' ? <Spinner color='#00a3ff' align='center' /> : <p> {data?.data.convThird}</p>
                    }</p>
                </div>
                <div className='route__emailDashboard__kpi__single'>
                    <p className='route__emailDashboard__kpi__single__head'>Message Email Converted</p>
                    <p className='route__emailDashboard__kpi__single__number'>{
                        data?.status !== 'ok' ? <Spinner color='#00a3ff' align='center' /> : <p> {data?.data.messageConverted}</p>
                    }</p>
                </div>
                <div className='route__emailDashboard__kpi__single'>
                    <p className='route__emailDashboard__kpi__single__head'>Visit Email Converted</p>
                    <p className='route__emailDashboard__kpi__single__number'>{
                        data?.status !== 'ok' ? <Spinner color='#00a3ff' align='center' /> : <p> {data?.data.visitConverted}</p>
                    }</p>
                </div>
                <div className='route__emailDashboard__kpi__single'>
                    <p className='route__emailDashboard__kpi__single__head'>Verifications Confirmed</p>
                    <p className='route__emailDashboard__kpi__single__number'>{
                        data?.status !== 'ok' ? <Spinner color='#00a3ff' align='center' /> : <p> {data?.data.verificationConverted}</p>
                    }</p>
                </div>
            </div>

            <div className='route__emailDashboard__table'>
                <p className='route__emailDashboard__table__headline'>Email statistics table</p>
                <div className='route__emailDashboard__table__head'>
                    <p>UserID</p>
                    <p>Name</p>
                    <p>First E-mail</p>
                    <p>Second E-mail</p>
                    <p>Third E-mail</p>
                    <p>Converted</p>
                    <p>Message Email</p>
                    <p>Message Email Converted</p>
                    <p>Visit Email</p>
                    <p>Visit Email Converted</p>
                    <p>Verification Email</p>
                    <p>Verification Email Converted</p>
                </div>
                {data?.status === 'ok' && data?.data.data.length ? data?.data.data.map((item, index) => {
                return <div className='route__emailDashboard__table__item' style={{ backgroundColor: index % 2 === 0 ? '#192343' : '#1923438e' }}>
                    <p>{item.UserID}</p>
                    <p>{(() => {
                        let tmp
                        chatUsersSelector.forEach(element => {
                            if (element.ID === item.UserID) tmp = element.FirstName + ' ' + element.LastName
                        });
                        return tmp
                    })()}</p>
                    <p>{item.FirstMessage ? moment(item.FirstMessage).format("DD/MM/YYYY hh:mm") : "E-mail not send"}</p>
                    <p>{item.SecondMessage ? moment(item.SecondMessage).format("DD/MM/YYYY hh:mm") : "E-mail not send"}</p>
                    <p>{item.ThirdMessage ? moment(item.ThirdMessage).format("DD/MM/YYYY hh:mm") : "E-mail not send"}</p>
                    <p>{item.Converted > 0 ? <span className='route__emailDashboard__table__row__true'>Yes</span> : <span className='route__emailDashboard__table__row__false'>No</span>}</p>
                    <p>{item.UnreadMessageEmail ? moment(item.UnreadMessageEmail).format("DD/MM/YYYY hh:mm") : "E-mail not send"}</p>
                    <p>{item.UnreadMessageEmailConverted > 0 ? <span className='route__emailDashboard__table__row__true'>Yes</span> : <span className='route__emailDashboard__table__row__false'>No</span>}</p>
                    <p>{item.ProfileVisitEmail ? moment(item.ProfileVisitEmail).format("DD/MM/YYYY hh:mm") : "E-mail not send"}</p>
                    <p>{item.ProfileVisitEmailConverted > 0 ? <span className='route__emailDashboard__table__row__true'>Yes</span> : <span className='route__emailDashboard__table__row__false'>No</span>}</p>
                    <p>{item.VerificationEmail ? moment(item.VerificationEmail).format("DD/MM/YYYY hh:mm") : "E-mail not send"}</p>
                    <p>{item.VerificationEmailConverted > 0 ? <span className='route__emailDashboard__table__row__true'>Yes</span> : <span className='route__emailDashboard__table__row__false'>No</span>}</p>
                </div>
                }): <p style={{padding: '20px', color:'white'}}>No results</p> }
            </div>

        </div>
    )
}
