import React from "react";
import "./index.scss";

import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import * as backendModule from "../../modules/backendModule";
import * as timestmapActions from "../../actions/timestampAction";

import useDefer from "../../modules/hooks/useDefer";
import useOnScreen from "../../modules/hooks/useOnScreen";

import Button from "../../components/customComponents/Button";
import CustomInput from "../../components/customComponents/CustomInput";
import { FilteredCustomTable } from "../../components/customComponents/Table";
import Dropdown from "../../components/customComponents/Dropdown";
import Spinner from "../../components/customComponents/Spinner";
import DataSorter from "../../components/customComponents/DataSorter";
import { animateBox } from "../../modules/componentAnimation";

const SecondaryUsersEndpoints = () => {
    const [data, setData] = React.useState();
    const [canPaginate, setCanPaginate] = React.useState(false);
    const [spinner, setSpinner] = React.useState(false);
    const [filters, setFilters] = React.useState([]);
    const [orders, setOrders] = React.useState([]);

    const [totalOnRegister, setTotalOnRegister] = React.useState(0);
    const [totalOnBrowse, setTotalOnBrowse] = React.useState(0);
    const [total, setTotal] = React.useState(0);
    const [registerPercentage, setRegisterPercentage] = React.useState(0);
    const [browsePercentage, setBrowsePercentage] = React.useState(0);

    const curOnScreen = useOnScreen();
    const curDefer = useDefer();
    const timestampSelector = useSelector(state => state?.timestamp ?? null);

    const currentTimestampRef = React.useRef();

    const getData = (ts) => {
        if (canPaginate) setCanPaginate(false);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/secondaryUserEndpoints/getAllSecondaryUserEndpoints`,
            data: {
                offset: 0,
                limit: 20,
                filters,
                orders
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (ts !== currentTimestampRef.current) return;
            setData(res.data);
            if (res.data.status === "ok") {
                if (res.data.data.length === 20 && !canPaginate) setCanPaginate(true);
            };
        }).catch(() => {
            setData({status: "error", data: "SERVER_ERROR"});
        });
    };

    const getTotals = (ts) => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/secondaryUserEndpoints/getAllSecondaryUserEndpointsTotals`,
            ...backendModule.axiosConfig
        }).then(res => {
            if(res.data.status === 'ok'){
                setTotalOnRegister(res.data.data.registerCount);
                setTotalOnBrowse(res.data.data.browseCount);
                setTotal(res.data.data.totalCount);
                setRegisterPercentage((res.data.data.registerCount / res.data.data.totalCount) * 100);
                setBrowsePercentage((res.data.data.browseCount / res.data.data.totalCount) * 100);
            }
            
        }).catch((er) => {
            console.log("errr ", er)
        });
    };

    const continueData = (ts) => {
        if (canPaginate) setCanPaginate(false);

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/secondaryUserEndpoints/getAllSecondaryUserEndpoints`,
            data: {
                offset: 0,
                limit: 20,
                filters: [
                    ...filters,
                    {name: "ID", op: "notIn", value: data.data.map(d => d.ID)}
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (ts !== currentTimestampRef.current) return;

            if (res.data.status === "ok") {
                if (res.data.data.length === 20 && !canPaginate) setCanPaginate(true);
                setData(d => {
                    return {
                        ...d,
                        data: [
                            ...d.data,
                            ...res.data.data
                        ]
                    };
                });
            };
        }).catch(() => {
            setData({status: "error", data: "SERVER_ERROR"});
        }).finally(() => {
            setSpinner(false);
        });
    };

    React.useEffect(() => {
        curDefer(() => {
            if (!canPaginate) return;
            if (!curOnScreen.isIntersecting) return;
    
            try {
                curOnScreen.observer.unobserve(curOnScreen.measureRef.current);
            } catch {};

            const ts = Date.now();
            currentTimestampRef.current = ts;

            continueData(ts);
        }, 500);
    }, [curOnScreen.isIntersecting, canPaginate, data]);

    React.useEffect(() => {
        let ts = Date.now();
        currentTimestampRef.current = ts;
        getData(ts);
    }, [filters, timestampSelector, orders]);

    React.useEffect(() => {
        getTotals();
    }, [])

    return <div className="route__secondaryUserEndpoints">
        {/* <DataSorter sorters={[
            {name: 'Credits', value:'Credits'},
            {name: 'Unlocked Times', value:'UnlockedTimes'},
            {name: 'Name', value:'Name'},
            {name: 'Race', value:'Race'},
            {name: 'Weight', value:'Weight'},
            {name: 'Ambient', value:'Ambient'},
            {name: 'Age', value:'Age'},
            {name: 'Created at', value:'createdAt'},
            {name: 'Updated at', value:'updatedAt'}
        ]} setOrders={setOrders} /> */}
        <div className="route__secondaryUserEndpoints__total">
            <p className="route__secondaryUserEndpoints__total"> Total users on register : {totalOnRegister && registerPercentage ? <span>{totalOnRegister} ({registerPercentage}%)</span> : 0}</p>
            <p className="route__secondaryUserEndpoints__total"> Total users on browse : {totalOnBrowse && browsePercentage ? <span>{totalOnBrowse} ({browsePercentage}%)</span> : 0}</p>
            <p className="route__secondaryUserEndpoints__total"> Total : <span>{total ? total : 0}</span></p>
        </div>
        
        <FilteredCustomTable
            filterCB={c => setFilters(c)}
            id='table'
            accent='#000'
            dropdownBackground='#000'
            inputBottomBorderColor='#FFF'
            placeholderStyle={{backgroundColor: '#000'}}
            theme='dark'
            filterHeadStyle={{backgroundColor: 'rgba(25, 35, 67, 0.2)'}}
            tableHeadStyle={{backgroundColor: 'rgba(25, 35, 67, 0.2)'}}
            buttonStyle={{border: '1px solid #FFF', backgroundColor: '#000'}}
            // style={{colors: index % 2 === 0 ? '#1923438e' : '#192343'}}
            style={{colors: '#000'}}
            color={'#000'}
            filters={[
                {name: "SecondaryUserID", friendlyName: "SecondaryUserID", type: "string"},
                {name: "CameFrom", friendlyName: "CameFrom", type: "string"},
                {name: "EndPoint", friendlyName: "EndPoint", type: "string"},
            ]}
            headers={["SecondaryUserID", "CameFrom", "EndPoint"]}
            data={(()=>{
                let out = [];

                if (!data) return [[{keyID: "noData-spinner", type: "spinner"}]];
                if (data.status === "error") return [[{keyID: "noData-error", type: "text", text: "Error while fetching data"}]];

                for (let item of data.data) {
                    out.push([
                        {keyID: item.ID, type: "text", text: item.SecondaryUserID},
                        {keyID: item.ID, type: "text", text: item.CameFrom},
                        {keyID: item.ID, type: "text", text: item.EndPoint},
                    ])
                };

                if (spinner) out.push([{keyID: "data-spinner", type: "spinner"}]);
                if (out.length === 0) out.push([{keyID: "noData-noData", type: "text", text: "Nothing to show..."}]);

                return out;
            })()}
        />
        {canPaginate && <div ref={curOnScreen.measureRef} style={{ width: '1px', height: '1px', opacity: '0' }}></div>}
    </div>
};


export default SecondaryUsersEndpoints;